import React, { useState, useEffect } from "react";
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { Group, Text, Stack, Menu, ActionIcon, TextInput, Tooltip, Modal, Button } from '@mantine/core';
import DataTable from 'react-data-table-component';
import { IconSearch, IconTrash, IconDotsVertical, IconPlus, IconUpload, IconCheck } from '@tabler/icons-react';
import Axios from 'axios';
export const ListOfAppLanguages = ({notifications}) => {
    const countPerPage = 10;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [Code, setCode] = useState('')
    const [LanguageName, setLanguageName] = useState('')
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UploadLanguageModal, setUploadLanguageModal] = useState(false)
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchLanguages();
    }, [DefaultLanguage]);
    const fetchLanguages = async() => {
        setLoading(true);   
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const handleLanguageName = (event) => {
        setLanguageName(event.target.value)
    }
    const handleLanguageCode = (event) => {
        setCode(event.target.value)
    }
    const UploadLanguages = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Language',
            message: 'Language will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UploadEP = `/Languages?name=`+LanguageName+`&languageCode=`+Code+``
        await api.post(UploadEP).then((result) => {
            setTimeout(() => {
                fetchLanguages();
                setUploadLanguageModal(false)
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Announcement was uploaded',
                    message: 'Announcment successsfully uploaded',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 3000);
        }).catch(error => {
        })   
    }
    const RemoveLanguage = async(id) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Language',
            message: 'Language will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RemoveLanguageEP = `Languages/`+id+``
        await api.delete(RemoveLanguageEP, {
        }).then(result => {
                if(result['status'] === 204){
                    fetchLanguages();
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Language was deleted',
                            message: 'Language successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{  
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx = {{fontFamily : 'poppins'}}>LANGUAGES</Text>,
            cell: row => 
            <Group position="apart" sx={{width : '100%'}}>
                <Group sx={{maxWidth : '95%'}} className="EventContent">
                    <Stack spacing={2} justify="flex-start">
                        <Text fw={700} sx={{ color : '#4a1e9e', fontSize : 18, fontFamily : 'poppins'}}>{row.name}</Text>
                        <Text fw={600} fz="sm" sx = {{fontFamily : 'poppins'}}>{row.languageCode}</Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx = {{fontFamily : 'poppins'}}>ACTION</Text></Menu.Label>
                            <Menu.Item color = "red" icon = {<IconTrash/>} sx = {{fontFamily : 'poppins'}} onClick={() => {RemoveLanguage(row.id)}}>Delete Language</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group> 
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
        <Group>
            <TextInput
                icon={<IconSearch/>}
                radius="md"
                size="md"
                placeholder="Search Languages"
                onChange={ handleSearch }
            />
            <Tooltip
                label= "Upload Languages"
                sx={{background : '#6c2be2'}}
                withArrow
            >
                <ActionIcon
                    sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick={() => {setUploadLanguageModal(true)}}
                >
                    <IconPlus />
                </ActionIcon>
            </Tooltip>
        </Group>
        <br/>
        <DataTable
            columns={columns}
            data={
                data.filter((SearchLanguage) => {
                    if(search === ""){
                        return SearchLanguage
                    }else if(SearchLanguage.name.toLowerCase().includes(search.toLowerCase())){
                        return SearchLanguage
                    }
                })
            }
            progressPending={loading}
            pagination
            paginationTotalRows={data.length}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
            responsive = ""
        />
        <Modal size = "lg" opened={UploadLanguageModal} onClose={() => {setUploadLanguageModal(false)}} title = "Upload Languages"  >
                <Group grow>
                    <TextInput
                        size='md'
                        label = "Language Name"
                        placeholder="Enter Language Name"
                        onChange={handleLanguageName}
                        withAsterisk
                    />
           
                    <TextInput
                        size='md'
                        label = "Language Code"
                        placeholder="Enter Language Code"
                        onChange={handleLanguageCode}
                        withAsterisk
                    />
                </Group>
                <br/>
                <Group grow>
                    <Button onClick = {UploadLanguages} leftIcon = {<IconUpload/>} color="violet" size="md"> UPLOAD LANGUAGE </Button>
                </Group>
        </Modal>
        </div>
    )
}