import '../../Assets/css/style.css'
import { useState, useEffect } from 'react'
import  TheOneLogo  from '../../Assets/images/logo/main-logo.png'
import { IconLogin, IconExclamationCircle, IconBrandGoogle, IconBrandGoogleOne } from '@tabler/icons-react';
import { motion } from 'framer-motion'
import { Image, Flex, Text, Divider, Stack, TextInput, PasswordInput, Button,createStyles, Paper, Group } from '@mantine/core'
import { api, ToasterMessage, Token } from '../../Utils'
import { GoogleLogin } from '@leecheuk/react-google-login';
import jwt_decode from "jwt-decode";
import Axios from 'axios';
export const Login = () => {
    let message, icon, color, idType = ""
    const [EmailAddress, SetEmailAddress] = useState("")
    const [Password, SetPassword] = useState("")
    const [AdminStatus, setAdminStatus] = useState("")
    let AdminToken = '/Auth/Token/Admin';
    let UserToken = '/Auth/Login';

    //! TRANSLATION
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TitleTranslate, setTitleTranslate] = useState('Welcome to The One Admin')
    const [BibleVerseTranslate, setBibleVerseTranslate] = useState('Where there is jealousy and selfishness, there will be confusion and every kind of evil.James 3 : 16')
    const [BtnGoogleTranslate, setBtnGoogleTranslate] = useState('Continue with Google')
    const [ContinueTranslate, setContinueTranslate] = useState('Continue with Email and Password')
    const [EmailAddressTranslate, setEmailAddressTranslate] = useState('Email Address')
    const [PlaceHolderEmailTranslate, setPlaceHolderEmailTranslate] = useState('Enter your email address')
    const [PasswordTranslate, setPasswordTranslate] = useState('Password')
    const [PlaceHolderPasswordTranslate, setPlaceHolderPasswordTranslate] = useState('Enter your password')
    const [BtnLoginTranslate, setBtnLoginTranslate] = useState('Login')
    const [EmailPassEmpty, setEmailPassEmpty] = useState('Email address or Password is empty')
    const [EmailPassError, setEmailPassError] = useState('Invalid email or password')
    const [AdminLoginError, setAdminLoginError] = useState('only ADMIN user can login in this page!')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BibleVerseTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBibleVerseTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnGoogleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnGoogleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ContinueTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setContinueTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EmailAddressTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEmailAddressTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceHolderEmailTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceHolderEmailTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PasswordTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPasswordTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceHolderPasswordTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceHolderPasswordTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnLoginTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnLoginTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EmailPassError,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEmailPassError(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AdminLoginError,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAdminLoginError(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EmailPassEmpty,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEmailPassEmpty(translated[0])
            })
        }).then((error) =>{
        })
    }
    const LoginWithEmailAndPassword = async () => {
        if(EmailAddress === "" || Password === ""){
            message = EmailPassEmpty
            icon = <IconExclamationCircle size="20" />
            color = "red"
            idType = "EmptyEmailOrPassword"
            ToasterMessage(message, icon, color, idType)
        }else{
            const LoginEP = AdminToken
            await api.post(LoginEP, {
                email: EmailAddress,
                password: Password
            }).then(result => {
                if(result.status === 200){
                    const Token = result.data.accessToken
                    const RefreshToken = result.data.refreshToken
                    const UID = result.data.message
                    localStorage.setItem("__SESS__U__TOWA__", Token)
                    localStorage.setItem("__SESS__RTK__TOWA__", RefreshToken)
                    localStorage.setItem("__SESS__UID__TOWA__", UID)
                    window.location.href = '/'
                }
            }).catch(error =>{
                console.log(error.response.data.message)
            if(error.response.data.message === 'Invalid email or password'){
                message = EmailPassError
                icon = <IconExclamationCircle size="20"/>
                color = "red"
                idType = "WrongEmailOrPassword"
                ToasterMessage(message, icon, color, idType)
            }else if (error.response.data.message === 'Roles is required'){
                message = AdminLoginError
                icon = <IconExclamationCircle size="20"/>
                color = "red"
                ToasterMessage(message, icon, color, idType)
            }
            })
        }
    }
    const responseGoogle = (response) => {
        GoogleAuth(response.accessToken)
    }
    const Redirect = () => {
        window.location.href = '/'
    }
    const GoogleAuth = async (token) => {
        const GoogleAuthEndPoint = "/auth/google"
        await api.post(GoogleAuthEndPoint, {
            accessToken: token
        }).then(result => {
            const Token = result.data.accessToken
            const DecodedToken = jwt_decode(Token)
            Object.keys(DecodedToken).map((item, i) => {
                if(DecodedToken[item] === 'Admin' || DecodedToken[item] === 'Super Admin'){
                    const RefreshToken = result.data.refreshToken
                    const UID = result.data.message
                    localStorage.setItem("__SESS__U__TOWA__", Token)
                    localStorage.setItem("__SESS__RTK__TOWA__", RefreshToken)
                    localStorage.setItem("__SESS__UID__TOWA__", UID) 
                    Redirect()
                }else{
                    setAdminStatus('Accessed')
                }
            })
            if(AdminStatus === 'Accessed'){
                message = AdminLoginError
                icon = <IconExclamationCircle size="20"/>
                color = "red"
                ToasterMessage(message, icon, color, idType)
            }
        }).catch(error => {
            console.log(error)
        })
    }
    const useStyles = createStyles((theme) => ({
        inner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.spacing.xl * 3,
            paddingBottom: theme.spacing.xl * 3,

            [theme.fn.smallerThan('md')]: {
                paddingTop: theme.spacing.xl * 1,
                paddingTop: theme.spacing.xl * 1,
            },
        },
        content: {
            maxWidth: 580,
            [theme.fn.smallerThan('md')]: {
                maxWidth: '100%',
            },
        },
        control: {
            [theme.fn.smallerThan('md')]: {
                flex: 1,
            },
        }
    }));
    const { classes } = useStyles();
  return (
    <div >
        <motion.div  className={classes.inner} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className={`${classes.content} LoginPage`}>
                <Paper shadow="xl" p="xl" withBorder>
                    <Image maw={300} mx="auto" radius="md" src={TheOneLogo} alt="TheOneAdmin" />
                    <Flex gap="xs" justify="center" align="center" direction="column">
                        <Text fz={32} fw="bolder" ta="center" color='black' sx={{fontFamily : 'poppins'}}>{TitleTranslate}</Text>
                        <Text fz = {15} c="black" ta="center" sx={{fontFamily : 'poppins'}}>{BibleVerseTranslate}</Text>
                    </Flex>
                    <br/>
                    <Group spacing="xs">
                        <GoogleLogin
                            clientId={process.env.REACT_APP_G_CLIENT_ID}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            render={renderProps => (
                                <Button 
                                    leftIcon={<IconBrandGoogle/>} 
                                    color='red'
                                    onClick={renderProps.onClick}
                                    radius="xl"
                                    size="md"
                                    fullWidth
                                    sx={{fontFamily : 'poppins'}}
                                >
                                    {BtnGoogleTranslate}
                                </Button>
                            )}
                        />
                    </Group>
                    <Divider c="black" color='black' label={ContinueTranslate} labelPosition="center" my="xl" sx={{fontFamily : 'poppins'}} />
                    <Stack>
                        <Stack spacing={0}>
                            <Text color='black' c="black" fw={500} fz="lg" sx={{fontFamily : 'poppins'}}>{EmailAddressTranslate}</Text>
                            <TextInput
                                placeholder={PlaceHolderEmailTranslate}
                                onChange={(e) => SetEmailAddress(e.target.value) }
                                value={EmailAddress}
                                variant='filled'
                                size='md'
                            />
                        </Stack>
                        <Stack spacing={0}>
                            <Text color='black' c="black" fw={500} fz="lg" sx={{fontFamily : 'poppins'}}>{PasswordTranslate}</Text>
                            <PasswordInput
                                placeholder={PlaceHolderPasswordTranslate}
                                onChange={(e) => SetPassword(e.target.value) }
                                value={Password}
                                variant='filled'
                                size='md'
                            />
                        </Stack>
                        <Button  onClick={() => LoginWithEmailAndPassword()} leftIcon = {<IconLogin></IconLogin>} color="violet" radius="xs" size="md" sx={{fontFamily : 'poppins'}} uppercase>{BtnLoginTranslate}</Button>
                    </Stack>
                </Paper>
            </div>
        </motion.div>
    </div>
  );
}
