import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Button, TextInput, Group, Tooltip, ActionIcon, Modal, Text, Stack, Menu, Image, Anchor } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconPlus, IconDotsVertical} from '@tabler/icons-react';
import { UploadBibleCover } from "../BibleCover/UploadBibleCover";
import Axios from 'axios';
export const ListofBibleCover = ({notifications}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const countPerPage = 10
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [search, setSearch] = useState('')
    const [BookCoverID, setBookCoverID] = useState('')
    const [OpenUploadModal, setOpenUploadModal] = useState(false)
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblBookCoverTranslate, setTblBookCoverTranslate] = useState('BOOK COVER')
    const [SearchBookCoverTranslate, setSearchBookCoverTranslate]  = useState('Search Book Cover')
    const [TooltipUploadBookCover, setTooltipUploadBookCover] = useState('Upload Book Cover')
    const [RemoveBookCoverTranslate, setRemoveBookCoverTranslate] = useState('Remove Book Cover')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Deleting')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Delete this Book Cover?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchBookCover();
        fetchTranslation();
    }, [DefaultLanguage])
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblBookCoverTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblBookCoverTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchBookCoverTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchBookCoverTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipUploadBookCover,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipUploadBookCover(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveBookCoverTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveBookCoverTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const CancelConfirmation = () => {
        setConfirmModal(false)
    }
    const CallConfirmModal = (ID) => {
        setConfirmModal(true)
        setBookCoverID(ID)
    }
    const fetchBookCover = async() => {
        setLoading(true);   
        const ListBookCoverEP = `/BookCovers`
        await api.get(ListBookCoverEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const DeleteBookCover = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Book Cover',
            message: 'Book Cover will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const BlogsEP = `/BookCovers/`+BookCoverID+``
        await api.delete(BlogsEP, {
        }).then(result => {
                if(result['status'] === 204){
                    fetchBookCover();
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Book Cover was deleted',
                            message: 'Book Cover successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{  
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx = {{fontFamily : 'poppins'}}>{TblBookCoverTranslate}</Text>,
            cell : row =>
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group spacing={8} sx={{maxWidth : '95%'}}>
                    <Image 
                        src={row.image}
                        maw={90}
                        withPlaceholder
                        placeholder={<Text align="center">No Thumbnail Found</Text>} 
                    />
                    <Stack spacing={1} justify="flex-start" className="ListOfAudioContent">
                        <Text fw={700} sx={{lineHeight: 1, color : '#4a1e9e', fontSize : 18, wordBreak : 'break-all', fontFamily : 'poppins'}}>{row.fileName}</Text>
                        <Anchor href={row.image} target="_blank">
                            <Text fw={600} sx={{ fontSize : 16, fontFamily : 'poppins'}}>{row.image}</Text>
                        </Anchor>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{ActionTranslate}</Text></Menu.Label>
                            <Menu.Item sx = {{fontFamily : 'poppins'}} icon = {<IconTrash/>} onClick={() => {CallConfirmModal(row.id)}} color="red">{RemoveBookCoverTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group>
                <TextInput
                        icon={<IconSearch/>}
                        radius="sm"
                        size="md"
                        placeholder={SearchBookCoverTranslate}
                        onChange={ handleSearch }
                    />
                <Tooltip
                    label={TooltipUploadBookCover}
                    sx={{background : '#6c2be2'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadModal(true)}}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <br/>
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchBook) => {
                        if(search === ""){
                            return SearchBook
                        }else if(SearchBook.fileName.toLowerCase().includes(search.toLowerCase())){
                            return SearchBook
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
                <Group>
                    <Group grow>
                        <Text sx = {{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                    </Group>
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {DeleteBookCover} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
                </Group>
            </Modal>
            <UploadBibleCover
                notifications = {notifications}
                OpenModal = {OpenUploadModal}
                CloseModal = {() => {setOpenUploadModal(false)}}
                fetchBookCover = {fetchBookCover}
            />
        </div>
    )
}