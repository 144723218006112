import { showNotification } from '@mantine/notifications'
import DefaultImage from '../Assets/images/logo/theone-default.png'
import axios from 'axios'
export const Token = localStorage.getItem("__SESS__U__TOWA__") || null
export const RefreshToken = localStorage.getItem("__SESS__RTK__TOWA__") || null
export const CurrentUser = localStorage.getItem("__SESS__UID__TOWA__") || null
export const BibleVersion = localStorage.getItem("SelectedBibleVersion") || 1
const APILink = process.env.REACT_APP_API_LINK
const url = 'https://eastasia.tts.speech.microsoft.com/'
const url_key = '52fdcf7d332e45728943fc918079ce0d'
//! Configure Base URL and Header
export const azure_api = axios.create({
    baseURL: url,
    headers: { 
        'Ocp-Apim-Subscription-Key': url_key
    }
})
export const api_multipart = axios.create({
    baseURL: APILink+`/api`,
    headers: { 
        "Authorization": "Bearer "+Token,
        'Content-Type': 'multipart/form-data',
    }
})
export const api = axios.create({
    baseURL: APILink+`/api`,
    headers: { "Authorization": "Bearer "+Token }
})
export const apiv2 = axios.create({
    baseURL: APILink+`/api/v2`,
    headers: { "Authorization": "Bearer "+Token }
})
//! Get New Token or Refresh Token
export const GetNewToken = async(Token, RefreshToken) => {
    const NewTokenEP = "/auth/token/refresh"
    await api.post(NewTokenEP , {
        "token": Token,
        "refreshToken": RefreshToken
    }).then(result => {
        const token = result.data.accessToken
        const refreshtoken = result.data.refreshToken

        localStorage.setItem("__SESS__U__TOWA__", token)
        localStorage.setItem("__SESS__RTK__TOWA__", refreshtoken)
    }).catch(error => {
        console.log(error)

        const Response = error.response.status
        if(Response === 400){
            localStorage.removeItem("__SESS__U__TOWA__")
            localStorage.removeItem("__SESS__UID__TOWA__")
            localStorage.removeItem("__SESS__RTK__TOWA__")
            window.location.href = "login"
        }
    })
}
//! Toaster Message
export const ToasterMessage = (message, icon, color, idType, radius) => {
    showNotification({
        id: idType || "",
        message: message,
        icon: icon,
        color: color,
        radius: radius || 'sm'
    })
}
//! Logout
export const Logout = () => {
    localStorage.removeItem("__SESS__U__TOWA__")
    localStorage.removeItem("__SESS__UID__TOWA__")
    localStorage.removeItem("__SESS__RTK__TOWA__")
    window.location.href = "/"
}
//! Change Blog Post Not Found Image to Default Landscape Image
//! Set default image when fetched image is not found
export const ImageNotFound = (image) => {
    image.target.src = DefaultImage
}
//! Special Character Remover
export const TextCleaner = (text) => {
    return text.replace(/[^\w\s!?]/g,'').replaceAll(" ", "-")
}
//! Get Host
export const AppHost = window.location.hostname