import React, { useState, useEffect, useMemo } from "react";
import {Button, Group, Modal, useMantineTheme, TextInput,Accordion,ScrollArea, List, ThemeIcon, CloseButton, Tooltip, ActionIcon} from '@mantine/core';
import { IconPlus, IconUpload, IconCheck, IconAd, IconLocation } from '@tabler/icons-react';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
export const UploadAdLocation = ({notifications, fetchAdLocation}) => {
    const [OpenAdlocationModal, setOpenAdLocationModal] = useState(false);
    const [OpenAdProviderModal, setOpenAdProviderModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const[InputProvider, setInputProvider] = useState("");
    const[Location, setLocation] = useState("")
    const[Ios, setIos] = useState("");
    const[Android, setAndroid] = useState("");
    const[SelectedProviderID, setSelectedProviderID] = useState("");
    const theme = useMantineTheme();
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    //! PROVIDER TRANSLATE
    const [CreateProviderTranslate, setCreateProviderTranslate] = useState('Create Provider')
    const [AddNewProviderTranslate, setAddNewProviderTranslate] = useState('Add New Provider')
    const [ProviderTranslate, setProviderTranslate] = useState('Provider')
    const [PlaceholderProviderTranslate, setPlaceholderProviderTranslate] = useState('Enter Provider')
    const [BtnUploadProvider, setBtnUploadProvider] = useState('UPLOAD')
    //! AD LOCATION
    const [CreateLocationTranslate, setCreateLocationTranslate] = useState('Create Location')
    const [AddNewLocationTranslate, setAddNewLocationTranslate] = useState('Add New Location')
    const [SelectProviderTranslate, setSelectProviderTranslate] = useState('Select Provider')
    const [LocationTranslate, setLocationTranslate] = useState('Location')
    const [LocationDescTranslate, setLocationDescTranslate] = useState('location where do you want to display the ads')
    const [PlaceholderLocation, setPlaceholderLocation] = useState('Enter Location')
    const [IOSAdUnitTranslate, setIOSAdUnitTranslate] = useState('IOS Ad Unit')
    const [ANDROIDAdUnitTranslate, setANDROIDAdUnitTranslate] = useState('ANDROID Ad Unit')
    const [PlaceholderIOSTranslate, setPlaceholderIOSTranslate] = useState('Enter IOS Ad Unit')
    const [PlaceholderANDROIDTranslate, setPlaceholderANDROIDTranslate] = useState('Enter ANDROID Ad Unit')
    const [BtnUploadLocation, setBtnUploadLocation] = useState('UPLOAD')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchAds();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateLocationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateLocationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddNewProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddNewProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadProvider,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadProvider(translated[0])
            })
        }).then((error) =>{
        })

        //! AD LOCATION
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddNewLocationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddNewLocationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SelectProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSelectProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + LocationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setLocationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + LocationDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setLocationDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderLocation,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderLocation(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + IOSAdUnitTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setIOSAdUnitTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ANDROIDAdUnitTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setANDROIDAdUnitTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderIOSTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderIOSTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderANDROIDTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderANDROIDTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadLocation,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadLocation(translated[0])
            })
        }).then((error) =>{
        })
        
        
    }
    const fetchAds = async () => {
        setLoading(true);   
        const ListAdsEP = `/TheOneAd`
        await api.get(ListAdsEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const UploadProvider = async () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Provider',
            message: 'Provider will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const ProviderEP = `/TheOneAd/?providerName=`+InputProvider+``
        await api.post(ProviderEP, {
        }).then(result => {
            if(result['status'] === 201){
                fetchAds();
                setTimeout(() => {
                    setInputProvider("")
                    setOpenAdProviderModal(false)
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Provider was uploaded',
                        message: 'Provider successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                });
                }, 1000); 
            }else{}
        }).catch(error =>{
        })
    }
    const RemoveProvider = async (ProviderID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Provider',
            message: 'Provider will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const ProviderEP = `/TheOneAd/`+ProviderID+``
            await api.delete(ProviderEP, {
            }).then(result => {
                if(result['status'] === 204){
                    setTimeout(() => {
                        fetchAds();
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Provider was deleted',
                            message: 'Provider successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
        })
    }
    const SelectProvider = async (ProviderID, ) => {
        setSelectedProviderID(ProviderID)
    }
    const UploadLocation = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Location',
            message: 'Location will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UploadProviderEP = `/AdLocations`
         api.post(UploadProviderEP, {
            name: Location,
            adProviderId: SelectedProviderID,
            androidAdUnit : Android,
            iosAdUnit : Ios
        }).then(result => {
        if(result['status'] === 201){
            fetchAdLocation();
            setTimeout(() => {
                //! RESET INPUTS
                setLocation("")
                setIos("")
                setAndroid("")
                setSelectedProviderID("")
                setOpenAdLocationModal(false)
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Location was uploaded',
                    message: 'Location successsfully uploaded',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
        })
    }
    return(
    <>
        <Group spacing="xs" position='right'>
            <Tooltip
                label={CreateProviderTranslate}
                sx={{background : '#6c2be2'}}
                withArrow
            >
                <ActionIcon
                    sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick={() => {setOpenAdProviderModal(true)}}
                >
                    <IconPlus />
                </ActionIcon>
            </Tooltip>  
            <Tooltip
                label={CreateLocationTranslate}
                sx={{background : '#6c2be2'}}
                withArrow
            >
                <ActionIcon
                    sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick={() => {setOpenAdLocationModal(true)}}
                >
                    <IconLocation />
                </ActionIcon>
            </Tooltip>
        </Group>
        <br></br>
        <Modal 
            opened={OpenAdlocationModal}
            onClose={() => {setOpenAdLocationModal(false)}}
            title={AddNewLocationTranslate}
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered>
                <Group grow>
                    <Accordion chevronPosition="left" maw={400} mx="auto">
                    <Accordion.Item value="customization">
                    <Accordion.Control sx = {{fontFamily : 'poppins'}}>{SelectProviderTranslate}</Accordion.Control>
                    <Accordion.Panel>
                        <ScrollArea h={150} type="auto" offsetScrollbars>
                            {
                                data.map((provider, index)=>
                                    <List key = {index}>
                                        <List.Item
                                            sx = {{fontFamily : 'poppins'}}
                                            onClick = {() => { SelectProvider(provider.id, provider.adProvider) }}
                                            className="ListProvider" 
                                            icon={
                                                provider.id === SelectedProviderID ?  
                                                <ThemeIcon color="green" size={30} radius="xl">
                                                    <IconCheck/>
                                                </ThemeIcon>
                                                : 
                                                <ThemeIcon color="blue" size={30} radius="xl">
                                                    <IconAd/>
                                                </ThemeIcon>
                                            }>
                                            <Group grow>
                                                {provider.adProvider}
                                                <CloseButton onClick = {() =>{RemoveProvider(provider.id)}} title="Close popover" size="md" iconSize={20} />
                                            </Group>
                                        </List.Item>
                                    </List>
                                )   
                            }
                        </ScrollArea>
                    </Accordion.Panel>
                    </Accordion.Item>
                    </Accordion>
                </Group>
                <br></br>
                <Group grow>
                    <TextInput
                        placeholder={PlaceholderLocation}
                        label={LocationTranslate}
                        onChange={(e) => {setLocation(e.target.value)}}
                        description={LocationDescTranslate}
                        radius="md"
                        size="md"
                        withAsterisk
                        sx = {{fontFamily : 'poppins'}}
                    />
                </Group>
                <br></br>
                <Group grow>
                    <TextInput
                        placeholder={PlaceholderIOSTranslate}
                        label={IOSAdUnitTranslate}
                        radius="md"
                        size="md"
                        onChange={(e) => {setIos(e.target.value)}}
                        withAsterisk
                        sx = {{fontFamily : 'poppins'}}
                    />
                     <TextInput
                        placeholder={PlaceholderANDROIDTranslate}
                        label={ANDROIDAdUnitTranslate}
                        radius="md"
                        size="md"
                        onChange={(e) => {setAndroid(e.target.value)}}
                        withAsterisk
                        sx = {{fontFamily : 'poppins'}}
                    />
                </Group>
                <br></br>
                <Group grow>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {UploadLocation} leftIcon = {<IconUpload/>} color="violet" radius="sm" size="md" uppercase>
                        {BtnUploadLocation}
                    </Button>
                </Group>
        </Modal>
        <Modal 
            opened={OpenAdProviderModal}
            onClose={() => {setOpenAdProviderModal(false)}}
            title={AddNewProviderTranslate}
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered>
            <Group grow>
                <TextInput
                    style = {{textTransform : 'capitalize'}}
                    placeholder={PlaceholderProviderTranslate}
                    label={ProviderTranslate}
                    radius="md"
                    size="md"
                    onChange={(e) => {setInputProvider(e.target.value.toUpperCase())}}
                    value = {InputProvider}
                    withAsterisk
                    sx = {{fontFamily : 'poppins'}}
                />
            </Group>
            <br></br>
            <Group grow>
                <Button sx = {{fontFamily : 'poppins'}} onClick = {UploadProvider} leftIcon = {<IconUpload/>} color="violet" radius="sm" size="md" uppercase>
                    {BtnUploadProvider}
                </Button>
            </Group>
        </Modal>
    </>
    )
}