import './Assets/css/style.css'
import { Token } from './Utils'
import { AnimatedRoutes, NewNavBar } from './Components/Component';
function App() {
  return (
    <>
      <AnimatedRoutes user={Token} />
      <NewNavBar/>
    </>
  );
}
export default App;
