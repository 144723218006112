import React, { useState, useEffect, useRef } from "react";
import DataTable from 'react-data-table-component';
import { Button, TextInput, Group, Tooltip, ActionIcon, Avatar, Text, Modal, Stack, Menu, Divider, Image, Grid, Card, Spoiler, ScrollArea, Transition, Textarea } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconHeartbeat, IconMicrophone, IconMusic, IconCheck, IconFileMusic, IconDotsVertical, IconVideo, IconPlayerPlay, IconPlayerTrackPrev, IconPlayerTrackNext, IconPlayerPause, IconPlayerTrackPrevFilled, IconPlayerTrackNextFilled, IconPlus, IconFileUpload, IconFileExport, IconPencilCheck, IconPencil} from '@tabler/icons-react';
import  LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
import ReactAudioPlayer from 'react-audio-player';
import { UploadWorship } from "../Component";
import WorshipHeader from "../../Assets/images/logo/profile-bg.png"
import Axios from 'axios';
import { motion, transform } from "framer-motion";
import useSound from "use-sound";
import { useWindowScroll } from '@mantine/hooks';
export const ListofWorship = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const [data, setData] = useState([]);
    const [PreacherData, setPreacherData] = useState([])
    const [BibleStoryData, setBibleStoryData] = useState([])
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [OpenUploadWorship, setOpenUploadWorship] = useState(false);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [WorshipID, setWorshipID] = useState('');
    const [search, setSearch] = useState('');
    const [SelectedCategory, setSelectedCategory] = useState('Music')
    const [DescriptionCategiory, setDescriptionCategiory] = useState('Worship music is praise. The majority of biblical songs contain elements of praise, if it is not their primary theme. Praise-songs include several genres: psalms, canticles, hymns, and doxologies.')
    const countPerPage = 10;
    const [isPlaying, setIsPlaying] = useState(false);
    const [SelectedMusic, setSelectedMusic] = useState('https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/music-uploads/10000%20Reasons%20Bless%20The%20Lord%20by%20Matt%20Redman.mp3')
    const [SelectedCover, setSelectedCover] = useState('https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/thumbnail-uploads/10000ReasonsBlessTheLord.jpg')
    const [SelectedTitle, setSelectedTitle] = useState('10000 Reasons Bless The Lord')
    const [SelectedArtist, setSelectedArtist] = useState('Matt Redman')
    const [OpenBibleStoryModal, setOpenBibleStoryModal] = useState(false)
    const [Link, setLink] = useState('');
    const [Title, setTitle] = useState('')
    const [Description, setDescription] = useState('')
    const [PreacherDataModal, setPreacherDataModal] = useState(false)
    const [GetPreacherID, setGetPreacherID] = useState('')
    const [GetPreacherProfile, setGetPreacherProfile] = useState('')
    const [GetPreacherFullname, setGetPreacherFullname] = useState('')
    const [GetPreacherDesc, setGetPreacherDesc] = useState('')
    const [scroll, scrollTo] = useWindowScroll();
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblWorshipTranslate, setTblWorshipTranslate] = useState('AUDIO')
    const [SearchAudioTranslate, setSearchAudioTranslate] = useState('Search Audio Title')
    const [UploadWorshipTranslate, setUploadWorshipTranslate] = useState('Upload Worship')
    const [MusicTranslate, setMusicTranslate] = useState('Music')
    const [PreachingTranslate, setPreachingTranslate] = useState('Preaching')
    const [MeditationTranslate, setMeditationTranslate] = useState('Meditation')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [MusicPlayerTranslate, setMusicPlayerTranslate] = useState('MUSIC PLAYER')
    const [RemoveAudioTranslate, setRemoveAudioTranslate] = useState('Remove Audio')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Deleting')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Delete this Audio?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchWorship();
        onClickFetchWorship();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblWorshipTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblWorshipTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchAudioTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchAudioTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadWorshipTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadWorshipTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MusicTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMusicTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PreachingTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPreachingTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MeditationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMeditationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MusicPlayerTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMusicPlayerTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveAudioTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveAudioTranslate(translated[0])
            })
        }).then((error) =>{
        }) 
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const [Summary, setSummary] = useState('')
    const handleChangeSummary = (event) => {
        setSummary(event.target.value);
    }
    const fetchWorship = async () => {
        setLoading(true);   
        const ListMusicEP = `/Audios?category=0&PageNumber=1&PageSize=1000`
        await api.get(ListMusicEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
        })
    };
    const fetchPreacher = async () => {
        setLoading(true);   
        const PreacherEP = `/Artists`
        await api.get(PreacherEP).then((result) => {
            setPreacherData(result.data.data)
            setLoading(false);
        }).catch(error => {
        })
    }
    const fetchBibleStories = async (title) => {
        setData([])
        setPreacherData([])
        setSelectedCategory(title)
        setDescriptionCategiory('Bible stories can be great for teaching children. And they also have real meaning for anyone who wants to understand God and how He wants us to act.')
        setLoading(true);   
        const ListBibleStoriesEP = `/BibleStories?PageNumber=1&PageSize=100`
        await api.get(ListBibleStoriesEP).then((result) => {
            setBibleStoryData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const onClickFetchWorship = async (category) => {
        if(category === '0'){
            setSelectedCategory('Music')
            setDescriptionCategiory('Worship music is praise. The majority of biblical songs contain elements of praise, if it is not their primary theme. Praise-songs include several genres: psalms, canticles, hymns, and doxologies.')
        }else if(category === '1'){
            setSelectedCategory('Preaching')
            setDescriptionCategiory('“the explanation and application of the word in the assembled congregation of Christ.” God"s truth is declared by the preacher, and its meaning is brought home to those who listen. ')
        }else if(category === '2'){
            setSelectedCategory('Meditation')
            setDescriptionCategiory('An instrumental is a recording without any vocals, although it might include some inarticulate vocals, such as shouted backup vocals in a Big Band setting.')
        }
        setLoading(true);   
        const ListMusicEP = `/Audios?category=`+category+`&PageNumber=1&PageSize=1000`
        await api.get(ListMusicEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setWorshipID(ID)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const DeleteWorship = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Worship',
            message: 'Worship will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const BlogsEP = `/Audios/`+WorshipID+``
        await api.delete(BlogsEP, {
        }).then(result => {
                if(result['status'] === 204){
                    fetchWorship();
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Worship was deleted',
                            message: 'Worship successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{  
        })
    }
    const SelectedWorship = (filename, image, title, artist) => {
        scrollTo({ y: 0 })
        setSelectedMusic(filename)
        setSelectedCover(image)
        setSelectedTitle(title)
        setSelectedArtist(artist)

        pause();
        setIsPlaying(false);
    }
    const [time, setTime] = useState({
      min: "",
      sec: ""
    });
    const [currTime, setCurrTime] = useState({
      min: "",
      sec: ""
    });
    const [seconds, setSeconds] = useState();
    const [play, { pause, duration, sound }] = useSound(SelectedMusic);
    useEffect(() => {
      if (duration) {
        const sec = duration / 1000;
        const min = Math.floor(sec / 60);
        const secRemain = Math.floor(sec % 60);
        setTime({
          min: min,
          sec: secRemain
        });
      }
    }, [isPlaying]);
    useEffect(() => {
      const interval = setInterval(() => {
        if (sound) {
          setSeconds(sound.seek([]));
          const min = Math.floor(sound.seek([]) / 60);
          const sec = Math.floor(sound.seek([]) % 60);
          setCurrTime({
            min,
            sec
          });
        }
      }, 1000);
      return () => clearInterval(interval);
    }, [sound]);
    const playingButton = () => {
      if (isPlaying) {
        pause();
        setIsPlaying(false);
      } else {
        play();
        setIsPlaying(true);
      }
    };

    const GetPreacherInformation = async(id, profile, fullname, description) => {
        setPreacherDataModal(true)
        setGetPreacherID(id)
        setGetPreacherProfile(profile)
        setGetPreacherFullname(fullname)
        setGetPreacherDesc(description)
    }
    const PreacherPodcastData = data.filter((newVal) => {
        return newVal.artistProfileId === GetPreacherID
    })
    const [UpdateSummaryModal, setUpdateSummaryModal] = useState(false)
    const [GetAudioID, setGetAudioID] = useState('')
    const OpenUpdateSummaryModal = (id) => {
        setUpdateSummaryModal(true)
        setGetAudioID(id)
    }
    const UpdateSummaryFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Summary',
            message: 'Summary will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        var postData = {
            "summary": Summary,
        }
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };  
        Axios.put(''+APILink+'/api/Audios/'+GetAudioID, postData, axiosConfig)
        .then((res) => {
            if(res.status === 200){
                setTimeout(() => {
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Summary was updated',
                    message: 'Summary successsfully Updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
                }, 1000);
            }
        })
    }
    return(
    <div style={{paddingLeft : '70px'}} className="FirstContainer">
        <Image height={400} radius="md" src={WorshipHeader} sx={{display : 'block'}}/>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0 * 0.1 }} exit={{ opacity: 0 }} style={{position : 'relative'}}>
            <Button.Group sx={{position : 'absolute', top : -44, left : 2}}>
                <Button onClick={() => {onClickFetchWorship('0')}} size = "md" radius="md" sx={{background : 'transparent',  ":hover" : { background : '#4a1e9e', transition : '.5s ease-in-out'}}}>
                    <Text sx = {{fontFamily : 'poppins'}} className="MusicText" color="white">{<IconMusic color="white"/>}</Text>
                </Button>
                <Button onClick={() => {onClickFetchWorship('1'); fetchPreacher();}} size = "md" radius="md" sx={{background : 'transparent',  ":hover" : { background : '#4a1e9e', transition : '.5s ease-in-out'}}}>
                    <Text sx = {{fontFamily : 'poppins'}} className="PreachingText" color="white">{<IconMicrophone color="white"/>}</Text>
                </Button>
                <Button onClick={() => {onClickFetchWorship('2')}} size = "md" radius="md" sx={{background : 'transparent',  ":hover" : { background : '#4a1e9e', transition : '.5s ease-in-out'}}}>
                    <Text sx = {{fontFamily : 'poppins'}} className="MeditationText" color="white">{<IconHeartbeat color="white"/>}</Text>
                </Button>
                <Button onClick={() => {fetchBibleStories('Bible Story')}} size = "md" radius="md" sx={{background : 'transparent',  ":hover" : { background : '#4a1e9e', transition : '.5s ease-in-out'}}}>
                    <Text sx = {{fontFamily : 'poppins'}} className="MeditationText" color="white">{<IconVideo color="white"/>}</Text>
                </Button>
                <Tooltip label="Upload New File" color="violet">
                    <Button onClick={() => {setOpenUploadWorship(true)}} size = "md" radius="md" sx={{background : 'transparent',  ":hover" : { background : '#4a1e9e', transition : '.5s ease-in-out'}}}>
                        <Text sx = {{fontFamily : 'poppins'}} className="MeditationText" color="white">{<IconFileUpload color="white"/>}</Text>
                    </Button>
                </Tooltip>
            </Button.Group>
            <Group sx={{position : 'absolute', bottom : 150, left : 45}} maw={800}>
                <Text sx={{ lineHeight : .5, fontFamily : 'poppins', fontSize : 45, textShadow : '5px 2px #212529, 2px 4px #212529, 3px 5px #212529', color : 'white'}} fw={900}>{SelectedCategory}</Text>
                <Text sx={{ fontFamily : 'poppins', fontSize :16, textShadow : '5px 2px #212529, 2px 4px #212529, 3px 5px #212529'}}  color="white">{DescriptionCategiory}</Text>
            </Group>
            <Group sx={{position : 'absolute', bottom : 20, right : 20}} maw={800}>
                <Card withBorder radius="md" sx={{
                    boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                    display: 'block',
                    top: '0px',
                    textDecoration: 'none',
                    zIndex: 0,
                    overflow: 'hidden',
                    border: '1px solid #f2f8f9',
                    cursor: 'pointer',
                    background : '#e9ecef',
                    textAlign : 'center',
                    width : '380px',
                }}
                >
                     <Card.Section>
                        <Group position="center" mt={10}>
                            <Avatar radius="md" size="lg" src={SelectedCover} sx={{width : 200, height : 200}} />
                        </Group>
                    </Card.Section>
                    <div>
                        <Text sx={{fontFamily : 'poppins', fontSize : 20}} fw={500}>{SelectedTitle}</Text>
                        <Text sx={{fontFamily : 'poppins', fontSize : 16, color : 'gray'}}>{SelectedArtist}</Text>
                    </div>
                    <div>
                        <button className="playButton">
                            <IconPlayerTrackPrevFilled style={{size: "3em", color: "#27AE60"}}/>
                        </button>
                        {!isPlaying ? (
                        <button className="playButton" onClick={playingButton}>
                        <IconPlayerPlay style={{size: "3em", color: "#27AE60"}}/>
                        </button>
                        ) : (
                        <button className="playButton" onClick={playingButton}>
                            <IconPlayerPause style={{size: "3em", color: "#27AE60"}}/>
                        </button>
                        )}
                        <button className="playButton">
                            <IconPlayerTrackNextFilled style={{size: "3em", color: "#27AE60"}}/>
                        </button>
                        <div>
                            <div className="time">
                                <p>{currTime.min}:{currTime.sec}</p>
                                <p>{time.min}:{time.sec}</p>
                            </div>
                            <input
                                type="range"
                                min="0"
                                max={duration / 1000}
                                default="0"
                                value={seconds}
                                className="timeline"
                                onChange={(e) => {
                                    sound.seek([e.target.value]);
                                }}
                            />
                        </div>
                    </div>
                </Card>
            </Group>
        </motion.div>
        <br/>
        {SelectedCategory === 'Bible Story' ? 
        <Grid gutter="xs" grow>
            {BibleStoryData.map((story, index) => 
                <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '14.28%'}} className="AchievementContent">
                    <Grid.Col> 
                        <div style={{position : "relative"}}>
                            <div className="ImageContainer">
                                <Card withBorder radius="md" sx={{
                                    boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                    display: 'block',
                                    top: '0px',
                                    position: 'relative',
                                    textDecoration: 'none',
                                    zIndex: 0,
                                    overflow: 'hidden',
                                    border: '1px solid #f2f8f9',
                                    cursor: 'pointer',
                                    background : '#e9ecef',
                                    transition : 'transform .2s;',
                                    ":hover" : {
                                        transform: 'scale(.9)',
                                        boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                    }}}
                                    onClick={() => {setOpenBibleStoryModal(true); setLink(story.youtubeId); setTitle(story.title); setDescription(story.description)}}
                                >
                                    <Card.Section>
                                        <Group sx={{padding : 10}}>
                                            <Image src={story.image}
                                                withPlaceholder
                                                sx={{boxShadow : '0px 4px 8px rgba(38, 38, 38, 0.2)'}}
                                                placeholder={<Text align="center">No Thumbnail Found</Text>} 
                                            />
                                        </Group>
                                    </Card.Section>
                                    <Divider></Divider>
                                    <Group position="apart" mt="md" >
                                        <Text lineClamp = {1} sx={{color : '#4a1e9e', fontSize : 15, fontFamily : 'poppins', fontWeight : 700}}>{story.title}</Text>
                                    </Group>
                                    <Text size="sm" color="dark" lineClamp={5} sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{story.description}</Text> 
                                </Card>
                            </div>
                        </div>
                    </Grid.Col>
                </motion.div>
            )}
        </Grid>
        :
        ''
        }
        {SelectedCategory === 'Preaching' ? 
        <div>
        <Text fw={800} sx={{fontFamily : 'poppins', fontSize : 30, color : 'black'}}>PREACHER</Text>
        <Grid gutter="xs" grow>
            {PreacherData.map((preacher, index) => 
                <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '12.5%'}} className="AchievementContent">
                    <Grid.Col>
                    <Card withBorder radius="md" sx={{
                        boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                        display: 'block',
                        top: '0px',
                        position: 'relative',
                        textDecoration: 'none',
                        zIndex: 0,
                        overflow: 'hidden',
                        border: '1px solid #f2f8f9',
                        cursor: 'pointer',
                        background : '#e9ecef',
                        transition : 'transform .2s;',
                        ":hover" : {
                            transform: 'scale(.9)',
                            boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                        }}}
                        onClick={() => {GetPreacherInformation(preacher.id, preacher.profile, preacher.fullName, preacher.description)}}
                    >
                        <Card.Section>
                            <Group sx={{padding : 10}}>
                                    <Image src={preacher.profile}
                                    withPlaceholder
                                    sx={{boxShadow : '0px 4px 8px rgba(38, 38, 38, 0.2)'}}
                                    placeholder={<Text align="center">No Thumbnail Found</Text>} 
                                />
                            </Group>
                        
                        </Card.Section>
                        <Divider></Divider>
                        <Group position="apart" mt="md" >
                            <Text lineClamp = {1} sx={{color : '#4a1e9e', fontSize : 15, fontFamily : 'poppins', fontWeight : 700}}>{preacher.fullName}</Text>
                        </Group>
                    </Card>
                    </Grid.Col>
                </motion.div>
            )}
        </Grid>
        <Text fw={800} sx={{fontFamily : 'poppins', fontSize : 30, color : 'black'}}>PODCAST</Text>
        </div>
        :
        ''
        }
        <Grid gutter="xs" grow>
            {data.map((worship, index) => 
                <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '12.5%'}} className="AchievementContent">
                    <Grid.Col> 
                        <div style={{position : "relative"}}>
                            <div className="ImageContainer">
                                <Card withBorder radius="md" sx={{
                                    boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                    display: 'block',
                                    top: '0px',
                                    position: 'relative',
                                    textDecoration: 'none',
                                    zIndex: 0,
                                    overflow: 'hidden',
                                    border: '1px solid #f2f8f9',
                                    cursor: 'pointer',
                                    background : '#e9ecef',
                                    transition : 'transform .2s;',
                                    ":hover" : {
                                        transform: 'scale(.9)',
                                        boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                    }}}
                                >
                                    <Card.Section>
                                        <Group sx={{padding : 10}}>
                                             <Image src={worship.image}
                                                withPlaceholder
                                                sx={{boxShadow : '0px 4px 8px rgba(38, 38, 38, 0.2)'}}
                                                placeholder={<Text align="center">No Thumbnail Found</Text>} 
                                            />
                                            <div className="PlayBtnContainer">
                                                <Transition transition="slide-up" mounted={scroll.y > 0}>
                                                    {(transitionStyles) => (
                                                        <ActionIcon color="violet" size="xl" radius="xl" variant="filled" style={transitionStyles} sx={{bottom : 40}} onClick={() => {
                                                            SelectedWorship(worship.fileName, worship.image, worship.title, worship.artist); 
                                                        }}>
                                                            <IconPlayerPlay size="2.125rem"/>
                                                        </ActionIcon>
                                                    )}
                                                </Transition>
                                            </div>
                                        </Group>
                                    
                                    </Card.Section>
                                    <Divider></Divider>
                                    <Group position="apart" mt="md" >
                                        <Text lineClamp = {1} sx={{color : '#4a1e9e', fontSize : 15, fontFamily : 'poppins', fontWeight : 700}}>{worship.title}</Text>
                                    </Group>
                                    <Text size="sm" color="dark" lineClamp={1} sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{worship.artist}</Text> 
                                    <Group position="right" spacing={5}>
                                    {SelectedCategory === 'Preaching' ? 
                                        <ActionIcon color="violet" radius="xl" size="lg" variant="filled" onClick={() => {OpenUpdateSummaryModal(worship.id)}} >
                                            <IconPencilCheck size="1.125rem" />
                                        </ActionIcon>
                                        :
                                        ''
                                    }
                                        <ActionIcon color="red" radius="xl" size="lg" variant="filled" onClick={() => {CallConfirmModal(worship.id)}} >
                                            <IconTrash size="1.125rem" />
                                        </ActionIcon>
                                    </Group>
                                </Card>

                               
                            </div>
                        </div>
                    </Grid.Col>
                </motion.div>
            )}
        </Grid>
        <UploadWorship
            OpenModal = {OpenUploadWorship}
            CloseModal = {() => {setOpenUploadWorship(false)}}
            fetchWorship = {fetchWorship}
            notifications = {notifications}
        />
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text>{ConfirmMessageTranslate}</Text>
                </Group>
            </Group>
            <br/>
            <Group position='right'>
                <Button onClick = {DeleteWorship} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>  
        <Modal 
            opened={OpenBibleStoryModal} 
            onClose={() => {setOpenBibleStoryModal(false)}} 
            withCloseButton = {false} 
            transitionProps={{ transition: 'fade', duration: 100, timingFunction: 'linear' }} 
            size="xl"
            centered
           
        >
            <Group grow>
                <iframe 
                    src = {'https://www.youtube.com/embed/'+Link}
                    height={500}
                    width={500}
                    frameBorder="0" 
                    allowFullScreen>
                </iframe> 
            </Group>
            <br/>
            <Group>
                <Text fw={700}  sx={{fontSize : '30px', fontFamily : 'poppins'}}>{Title}</Text>
            </Group> 
            <Group>
                <Spoiler maxHeight={93} showLabel="Show more" hideLabel="Show less">
                    <Text sx = {{fontFamily : 'poppins'}} fw={500} fz="sm">{Description}</Text>
                </Spoiler>
            </Group> 
        </Modal>
        <Modal 
            opened={PreacherDataModal} 
            onClose={() => {setPreacherDataModal(false)}} 
            transitionProps={{ transition: 'fade', duration: 100, timingFunction: 'linear' }} 
            size="70%"
            withCloseButton = {false}
            centered 
        >
            <Group>
                <Image maw = {250} src={GetPreacherProfile}/>
                <Stack w={750}>
                    <Text sx={{fontFamily : 'poppins', color : 'black', fontSize : 35}} fw={900}>{GetPreacherFullname}</Text>
                    <Text sx={{fontFamily : 'poppins', color : 'gray', fontSize : 16}}>{GetPreacherDesc}</Text>
                </Stack>
            </Group>
            <Divider mt={10}></Divider>
           {PreacherPodcastData.map((item, index) => 
                <>
                    <Group mt={10} my={10}>
                        <Image maw = {180} src={item.image}/>
                        <Stack w={750}>
                            <Text sx={{fontFamily : 'poppins', color : 'black', fontSize : 20}} fw={900}>{item.title}</Text>
                            <Text sx={{fontFamily : 'poppins', color : 'black', fontSize : 16, lineHeight : 0}} fw={500}>{item.artist}</Text>
                            <ScrollArea h={65}>
                                <Text sx={{fontFamily : 'poppins', color : 'gray', fontSize : 14}}>{item.summary}</Text>
                            </ScrollArea>
                            <Group>
                                <ActionIcon color="violet" size="lg" radius="xl" variant="filled">
                                    <IconPlayerPlay size="1.625rem" />
                                </ActionIcon>
                                <Text sx={{fontFamily : 'poppins', color : 'black', fontSize : 16, lineHeight : 0}} fw={500}>{item.duration} sec</Text>
                            </Group>
                        </Stack>
                    </Group>
                    <Divider/>
                </>
           )}
        </Modal>
        <Modal 
            opened={UpdateSummaryModal} 
            onClose={() => {setUpdateSummaryModal(false)}} 
            transitionProps={{ transition: 'fade', duration: 100, timingFunction: 'linear' }} 
            size="xl"
            centered 
        >
            <Text ta="center" fw={900} sx={{fontFamily : 'poppins', fontSize : 25 , color : 'black'}}>UPDATE SUMMARY</Text>
            <Group mt="md" mb="xs" grow>
                <Textarea
                    variant="filled"
                    size="md"
                    label="Summary"
                    withAsterisk
                    placeholder="Enter Summary"
                    minRows={20}
                    sx={{fontFamily : 'poppins'}}
                    onChange={handleChangeSummary}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button onClick = {UpdateSummaryFunction} sx={{fontFamily : 'poppins'}} leftIcon = {<IconPencil/>} color="violet" size="md" uppercase>
                    UPDATE SUMMARY
                </Button>
            </Group>
        </Modal>
    </div>
    )
}