
import { notifications } from "@mantine/notifications"
import { ComponentFeeds } from "../../Components/Component"

import './Community.css'
export const CommunityFeeds = () => {
    return (
        <ComponentFeeds
            notifications = {notifications}
        />  
    )
}