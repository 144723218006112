import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { TextInput, Group, Text, Tooltip, ActionIcon, Modal, Button, Switch, Stack, Menu, Grid, Card, Image, Badge, Divider, Loader } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconPlus, IconEye, IconCheck, IconX, IconDotsVertical, IconPhoto, IconGrid4x4, IconList, IconPhotoX, IconDots, IconLanguage, IconUpload} from '@tabler/icons-react';
import { UploadBlogs } from "../Component";
import { Link } from "react-router-dom";
import Axios from 'axios';
import { motion } from "framer-motion";
export const ListofBlogs = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const countPerPage = 10;
    const [loading, setLoading] = useState(false)
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [BlogID, setBlogID] = useState('')
    const [OpenUploadBlogs, setOpenUploadBlogs] = useState(false)
    const [search, setSearch] = useState('')
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblReflectionTitle, setTblReflectionTitle] = useState('REFLECTIONS')
    const [SearchReflectionTranslate, setSearchReflectionTranslate] = useState('Search Reflection Title')
    const [CreateReflectionTranslate, setCreateReflectionTranslate] = useState('Create Reflection')
    const [StatusTranslate, setStatusTranslate] = useState('STATUS')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [ViewReflectionTranslate, setViewReflectionTranslate] = useState('View Reflection')
    const [EditContentTranslate, setEditContentTranslate] = useState('Edit Content')
    const [RemoveReflectionTranslate, setRemoveReflectionTranslate] = useState('Remove Reflection')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Deleting')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Delete this Reflection?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    const [ViewType, setViewType] = useState('List')
    useEffect(() => { 
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchBlog();
        fetchTranslation();
        fetchLanguages();
    }, [DefaultLanguage])
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblReflectionTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblReflectionTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchReflectionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchReflectionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateReflectionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateReflectionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + StatusTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setStatusTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ViewReflectionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setViewReflectionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EditContentTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEditContentTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveReflectionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveReflectionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const fetchBlog = async () => {
        setLoading(true);   
        const ListEventEP = `/Blogs?PageNumber=1&PageSize=100`
        await api.get(ListEventEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setBlogID(ID)
    }
    const setAsDefault = async(ID) => {
        const PublishedEP = `/Blogs/`+ID+`/`
        await api.put(PublishedEP, {
        }).then(result => {
            fetchBlog()
        }).catch(error =>{
    })
    }
    const DeleteBlogs = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Blog',
            message: 'Blog will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const BlogsEP = `/Blogs/`+BlogID+``
        await api.delete(BlogsEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchBlog();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Blog was deleted',
                        message: 'Blog successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{TblReflectionTitle}</Text>,
            cell: row => 
            <Group position="apart" sx={{width : '100%'}}>
                <Group sx={{maxWidth : '88%'}} className="BlogsContent">
                    <Stack spacing={1} justify="flex-start">
                        <Text fw={700} lineClamp = {1} sx={{color : '#4a1e9e', fontSize : 20, fontFamily : 'poppins'}}>{row.title}</Text>
                        <Text fw={600} fz="sm" sx={{fontFamily : 'poppins', fontSize : 18}}>{ row.description }</Text>
                        <Text fw={600} sx={{color : '#4a1e9e', fontSize : 13, fontFamily : 'poppins'}}>Created by : { row.authorName }</Text>
                        <Text fw={600} sx={{color : '#6c2be2', fontSize : 13, fontFamily : 'poppins'}}><i>{ row.keywords }</i></Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx={{fontFamily : 'poppins'}}>{StatusTranslate}</Text></Menu.Label>
                            <Menu.Item>
                                <Switch
                                    checked={row.isDefault}
                                    onClick={() => {setAsDefault(row.id)}}
                                    color="violet"
                                    size="lg"
                                    radius="sm"
                                    thumbIcon={
                                    row.isDefault ? (
                                        <IconCheck size="0.8rem" stroke={5} color="black" />
                                    ) : (
                                        <IconX size="0.8rem" stroke={5} color="black"/>
                                    )
                                    }
                                />
                            </Menu.Item>
                            <Menu.Label ><Text fw={800} fz="sm" color = "#6c2be2" sx={{fontFamily : 'poppins'}}>{ActionTranslate}</Text></Menu.Label>
                            <Menu.Item  sx={{fontFamily : 'poppins'}} icon = {<IconLanguage/>} onClick={() => {OpenTranslatedModal(row.id, row.title, row.description)}}>Translated Content</Menu.Item>
                            <a href = "https://reflection.bible.ph/" target="_blank" style={{textDecoration : 'none'}}>
                                <Menu.Item  sx={{fontFamily : 'poppins'}} icon = {<IconEye/>}>{ViewReflectionTranslate}</Menu.Item>
                            </a>
                            <Link to={"/blog-content"} state={{ id: row.id}} style={{textDecoration : 'none'}}>
                                <Menu.Item  sx={{fontFamily : 'poppins'}} icon = {<IconPhoto/>}>{EditContentTranslate}</Menu.Item>
                            </Link>
                            <Menu.Item  sx={{fontFamily : 'poppins'}} color = "red" icon = {<IconTrash/>} onClick={() => {CallConfirmModal(row.id)}}>{RemoveReflectionTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ]
    const ListViewFunction = () => {
        fetchBlog()
        setViewType('Grid')
    }
    const GridViewFunction = () => {
        fetchBlog()
        setViewType('List')
    }
    const [TranslatedContentModal, setTranslatedContentModal] = useState(false)
    const [LocalizationData, setLocalizationData] = useState([]);
    const [TranslateLoading, setTranslateLoading] = useState(false)
    const [GetBlogTitle, setGetBlogTitle] = useState('')
    const [GetBlogDescription, setGetBlogDescription] = useState('')
    const [GetBlogID, setGetBlogID] = useState('')
    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    let objTranslated = {}
    let ArrayTranslated = []
    const OpenTranslatedModal = async(id, title, description) => {
        setGetBlogID(id)
        setGetBlogTitle(title)
        setGetBlogDescription(description)
        setTranslatedContentModal(true)
        setTranslateLoading(true)
        const ListOfLocalization = `/Blogs/`+id+`/localizations`
        await api.get(ListOfLocalization).then((result) => {
            setLocalizationData(result.data)
            setTranslateLoading(false)
        }).catch(error => {
        })
    }
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedTitle = ''
            let GetTranslatedDescription = ''
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": GetBlogTitle},
                ]
            }).then((result) => {
                GetTranslatedTitle = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedTitle = gettranslation.text
                    })
                ))
                Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                    method: "POST",
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                        "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                    },
                    data:[
                        {"Text": GetBlogDescription}
                    ]
                }).then((result) => {
                    GetTranslatedDescription= ''
                    result.data.map((a) => (
                        a.translations.map((gettranslation) => {
                            GetTranslatedDescription = gettranslation.text
                        })
                    ))
                    objTranslated = {}
                    objTranslated['code'] = code.languageCode
                    objTranslated['title'] = GetTranslatedTitle
                    objTranslated['description'] = GetTranslatedDescription
                    ArrayTranslated.push(objTranslated)
                    setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                    setTranslateLoading(false)
                }).catch((error) => { 
                })
            }).catch((error) => { 
            })
        }
    }
    const UploadLocalization = () => {
        let ArrayPostData = []
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Blog Translation',
            message: 'Blog will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        LanguageTranslated.length = LanguageTranslated.length - 1
        LanguageTranslated.map((translated) => {
            var postData = {
                "title": translated.title,
                "description" : translated.description,
                "language" : translated.code
            }
            ArrayPostData.push(postData)
        })
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };
        Axios.post(''+APILink+'/api/Blogs/'+GetBlogID+'/localizations', ArrayPostData, axiosConfig)
        .then((res) => {
            setTimeout(() => {
                //! RESET INPUTS
                setLanguageTranslated([])
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Blog Translation was uploaded',
                    message: 'Blog Translation successsfully Updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000);
        })
        .catch((err) => {
        console.log(err);
        }) 
    }
    return (
    <div style={{paddingLeft : '50px'}} className="FirstContainer">
        <Group position="apart">
            {ViewType === 'Grid' ?
                <ActionIcon
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick = {GridViewFunction}
                    sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                >
                    <IconGrid4x4 />
                </ActionIcon>
                :
                <ActionIcon
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick = {ListViewFunction}
                    sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                >
                    <IconList />
                </ActionIcon>
            }
            <Group>
                <Tooltip
                    label={CreateReflectionTranslate}
                    sx={{background : '#6c2be2'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadBlogs(true)}}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchReflectionTranslate}
                    onChange={ handleSearch }
                />
               
            </Group>
        </Group>
        <br></br>
        {ViewType === 'Grid' ?
        <Grid gutter="xs" grow>
             {data.filter((SearchReflection) => {
                   if(search === ""){
                       return SearchReflection
                   }else if(SearchReflection.title.toLowerCase().includes(search.toLowerCase())){
                        return SearchReflection
                   }
                   }).map((relfection, index) =>
                    <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '25%'}} className="AchievementContent">
                        <Grid.Col> 
                            <Card withBorder radius="lg" sx={{
                                boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                display: 'block',
                                top: '0px',
                                position: 'relative',
                                textDecoration: 'none',
                                zIndex: 0,
                                overflow: 'hidden',
                                border: '1px solid #f2f8f9',
                                cursor: 'pointer',
                                ":hover" : {
                                    boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                }}}
                            >
                                <Card.Section>
                                    <Image 
                                        height={200}
                                        src={relfection.cover} 
                                        withPlaceholder
                                        placeholder={
                                            <Group sx={{paddingTop : 20}} position="center">
                                                <IconPhotoX size="3.2rem" stroke={1.5} color="black" />
                                            </Group>
                                        }
                                    />
                                </Card.Section>
                                <Group sx={{position : 'absolute', top : 5, right : 5}}>
                                    <Menu shadow="md" width={250} position="right-start" offset={-10} withArrow>
                                        <Menu.Target>
                                            <ActionIcon radius="xl" size="md" sx={{background : '#6c2be2',  ":hover" : {background : '#4a1e9e'}}}>
                                                <IconDots size={22} color="white" />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx={{fontFamily : 'poppins'}}>{StatusTranslate}</Text></Menu.Label>
                                            <Menu.Item>
                                                <Switch
                                                    checked={relfection.isDefault}
                                                    onClick={() => {setAsDefault(relfection.id)}}
                                                    color="violet"
                                                    size="lg"
                                                    radius="sm"
                                                    thumbIcon={
                                                        relfection.isDefault ? (
                                                        <IconCheck size="0.8rem" stroke={5} color="black" />
                                                    ) : (
                                                        <IconX size="0.8rem" stroke={5} color="black"/>
                                                    )
                                                    }
                                                />
                                            </Menu.Item>
                                            <Menu.Label ><Text fw={800} fz="sm" color = "#6c2be2" sx={{fontFamily : 'poppins'}}>{ActionTranslate}</Text></Menu.Label>
                                            <a href = "https://reflection.bible.ph/" target="_blank" style={{textDecoration : 'none'}}>
                                                <Menu.Item  sx={{fontFamily : 'poppins'}} icon = {<IconEye/>}>{ViewReflectionTranslate}</Menu.Item>
                                            </a>
                                            <Link to={"/blog-content"} state={{ id: relfection.id}} style={{textDecoration : 'none'}}>
                                                <Menu.Item  sx={{fontFamily : 'poppins'}} icon = {<IconPhoto/>}>{EditContentTranslate}</Menu.Item>
                                            </Link>
                                            <Menu.Item  sx={{fontFamily : 'poppins'}} color = "red" icon = {<IconTrash/>} onClick={() => {CallConfirmModal(relfection.id)}}>{RemoveReflectionTranslate}</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                                <Group mt="md">
                                    <Text  lineClamp = {2} sx={{color : '#4a1e9e', fontSize : 20, fontFamily : 'poppins', fontWeight : 700}}>{relfection.title}</Text>
                                </Group>
                                <Group>
                                    <Text size="sm" color="dark"sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{relfection.description}</Text>
                                </Group>
                                <Group sx={{position : 'absolute', top : 5, left : 5}}>
                                    <Badge color="orange" variant="filled">
                                        <Text fw={600} sx={{ fontSize : 10, fontFamily : 'poppins'}}>Created by : { relfection.authorName }</Text>
                                    </Badge>
                                </Group>
                            </Card>
                            
                        </Grid.Col>
                    </motion.div>
               )}   
       </Grid>
            :
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchEvent) => {
                        if(search === ""){
                            return SearchEvent
                        }else if(SearchEvent.title.toLowerCase().includes(search.toLowerCase())){
                            return SearchEvent
                        }
                    })
                }
                pagination
                progressPending={loading}
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />

        }
        <UploadBlogs
            notifications = {notifications}
            OpenModal = {OpenUploadBlogs}
            CloseModal = {() => {setOpenUploadBlogs(false)}}
            fetchBlog = {fetchBlog}
        />
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text>{ConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button onClick = {DeleteBlogs} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>
        <Modal opened={TranslatedContentModal} onClose={() => {setTranslatedContentModal(false)}} size="xl">
            <Group position = "center">
                <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 30}}>LIST OF LANGUAGE LOCALIZATION</Text>
            </Group>
            <Divider></Divider>
            <br/>
            {LocalizationData.length !== 0 ? 
                LocalizationData.map((code, index) => (
                    <Card padding="xs" radius="md" key = {index} sx={{
                        boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                        cursor : 'pointer',
                        transition : 'transform .2s ease-in-out',
                        MozTransition : 'transform .2s ease-in-out',
                        WebkitTransition : 'transform .2s ease-in-out',
                        marginTop: 10,
                        ":hover" : {
                            background : 'transparent',
                            WebkitTransform : 'scale(1.01)',
                            msTransform : 'scale(1.01)',
                            transform : 'scale(1.01)'
                        }
                    }}>
                        {TranslateLoading ? 
                            <>
                                <Group position='center'>
                                    <Loader color="violet" variant="dots" /><br/>
                                </Group>
                                <Group position='center'>
                                    <i style={{fontFamily : 'poppins', fontSize : 12}}>Translating...</i>
                                </Group>
                            </>
                            : 
                            <>
                                <Stack spacing={1}>
                                    <Group spacing={5}>
                                        <Text fw = {700} fz="lg" color="black" sx={{fontFamily : 'poppins'}}>
                                            {code.title ? code.title : <i style={{fontFamily : 'poppins', color : 'red', fontSize : 12}}>no title translated</i>}
                                        </Text>
                                        :
                                        <Group spacing={3}>
                                            <IconLanguage size={18}/> - 
                                            <Text fw = {800} color="black" sx={{fontFamily : 'poppins', fontSize : 15}}>Translated in {code.language}</Text>
                                        </Group>
                                    </Group>
                                </Stack>
                                <Group grow>
                                    <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}> {code.description}</Text>
                                </Group>
                            </>
                        }
                    </Card>
                ))
            : 
            <Group grow>
                <Button onClick = {UploadLocalization} leftIcon = {<IconUpload/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                    Upload
                </Button>
                <Button onClick = {TranslateSelectedLanguages}leftIcon = {LanguageTranslated.length === 15 ? <IconCheck/> : <IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} loading = {TranslateLoading ? true : false} uppercase>
                    {LanguageTranslated.length === 15 ? 'Translated' : 'Translate'}
                </Button>
            </Group>
            }
        </Modal>
    </div>    
    )
}