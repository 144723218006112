import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
//! Pages
import { 
    Login,
    Main,
    Announcement,
    Event,
    Blogs,
    BlogContent,
    Worship,
    Devotion,
    DailyDevotion,
    Ads,
    CustomizedAds,
    WordOfTheDay,
    BibleVersion,
    BibleBook,
    BibleStories,
    BibleQuiz,
    BibleCover,
    CommunityFeeds,
    CommunityGroup,
    CommunityBadge,
    CommunityAchievement,
    ActivityLogs,
    Church,
    PaymentMethod,
    PaymentInstruction,
    SubscriptionPlan,
    Administrator,
    Concern,
    Users,
    CalendarEvent,
    ReadingPlan,
    Voices,
    Resources,
    AppLanguages,
    AppVersion, 
    PasswordManager
} from '../../Pages/Pages'

//! Framer Motion
import { AnimatePresence } from 'framer-motion'
export const AnimatedRoutes = ({user}) => {
    const Location = useLocation()
    const Restriction = (PageComponent) => {
        return user ? PageComponent : <Navigate to={<Login/>} /> 
    }
    return (
        <AnimatePresence>
            <Routes location={Location} key={Location.pathname}>
                <Route path="/login" element={
                    user ? <Navigate to="/" /> : <Login/>
                }/>
                <Route path="/" element={<Main />} />
                <Route path="/announcement/" element={Restriction(<Announcement />)} />
                <Route path="/event" element={Restriction(<Event />)} />
                <Route path="/upload-ads" element={Restriction(<Ads />)} />
                <Route path="/customized-ads" element={Restriction(<CustomizedAds />)} />
                <Route path="/reflections" element={Restriction(<Blogs />)} />
                <Route path="/blog-content" element={Restriction(<BlogContent />)} />
                <Route path="/worship" element={Restriction(<Worship />)} />
                <Route path="/feelings" element={Restriction(<Devotion />)} />
                <Route path="/church" element={Restriction(<Church />)} />
                <Route path="/daily-devotion" element={Restriction(<DailyDevotion />)} />
                <Route path="/word-of-the-day" element={Restriction(<WordOfTheDay />)} />
                <Route path="/version" element={Restriction(<BibleVersion />)} />
                <Route path="/book" element={Restriction(<BibleBook />)} />
                <Route path="/bible-story" element={Restriction(<BibleStories />)} />
                <Route path="/bible-quiz" element={Restriction(<BibleQuiz />)} />
                <Route path="/bible-cover" element={Restriction(<BibleCover />)} />
                <Route path="/feeds" element={Restriction(<CommunityFeeds />)} />
                <Route path="/groupcommunity" element={Restriction(<CommunityGroup />)} />
                <Route path="/achievements" element={Restriction(<CommunityAchievement />)} />
                <Route path="/badges" element={Restriction(<CommunityBadge />)} />
                <Route path="/activitylogs" element={Restriction(<ActivityLogs />)} />
                <Route path="/payment-bank" element={Restriction(<PaymentMethod />)} />
                <Route path="/payment-instruction" element={Restriction(<PaymentInstruction />)} />
                <Route path="/payment-plan" element={Restriction(<SubscriptionPlan />)} />
                <Route path="/administrator" element={Restriction(<Administrator />)} />
                <Route path="/concern" element={Restriction(<Concern />)} />
                <Route path="/userlist" element={Restriction(<Users/>)} /> 
                <Route path="/calendar-event" element={Restriction(<CalendarEvent />)} />
                <Route path="/reading-plan" element={Restriction(<ReadingPlan />)} />
                <Route path="/voices" element={Restriction(<Voices />)} />
                <Route path="/resources" element={Restriction(<Resources />)} />
                <Route path="/app-languages" element={Restriction(<AppLanguages />)} />
                <Route path="/app-version" element={Restriction(<AppVersion />)} />
                <Route path="/password-manager" element={Restriction(<PasswordManager />)} />
                
                
                
            </Routes>
        </AnimatePresence>
    )
}
