import React, { useState, useEffect } from "react";
import { Chart, BarSeries, Title, ArgumentAxis, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { TotalBibleVersions, TotalUserChart, TotalViewsComment, TotalWorship, TotalUserSubscription, FlatFormChart } from "../../Components/Component";
import { Skeleton, Card, Grid, Text, Loader, Group } from '@mantine/core';
import { AchievementLeaderBoards } from "../../Components/Component";
import './Main.css'
import Axios from 'axios';
import ReactECharts from 'echarts-for-react';
export const Main = () => {
    var sourceLang = 'en';
    let ArrayMonth = []
    let MonthName = ""
    const [DownloadCount, setDownloadCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ChartLoader, setChartLoader] = useState(false)
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [ChartTitle, setChartTitle] = useState('USER AS OF THIS YEAR')
    const [Jan, setJan] = useState('Jan')
    const [Feb, setFeb] = useState('Feb')
    const [Mar, setMar] = useState('Mar')
    const [Apr, setApr] = useState('Apr')
    const [May, setMay] = useState('May')
    const [Jun, setJun] = useState('Jun')
    const [Jul, setJul] = useState('Jul')
    const [Aug, setAug] = useState('Aug')
    const [Sep, setSep] = useState('Sep')
    const [Oct, setOct] = useState('Oct')
    const [Nov, setNov] = useState('Nov')
    const [Dec, setDec] = useState('Dec')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        CountDownloads();
        fetchTranslation();
    }, [DefaultLanguage]);
    const CountDownloads = async() => {
        setChartLoader(true)
        const CountDownloadsEP = `statistics/yearlyRegisteredUser?year=2024`
        await api.get(CountDownloadsEP).then((result) => {
            setDownloadCount(result.data)
            setChartLoader(false)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    DownloadCount.sort(function(a,b){
        return new Date(a.month) - new Date(b.month);
    });
    DownloadCount.map((count) => {
       
        let obj = {}
        if(count.month === 1 ){
            MonthName = Jan
        }else if(count.month === 2){
            MonthName = Feb
        }else if(count.month === 3){
            MonthName = Mar
        }else if(count.month === 4){
            MonthName = Apr
        }else if(count.month === 5){
            MonthName = May
        }else if(count.month === 6){
            MonthName = Jun
        }else if(count.month === 7){
            MonthName = Jul
        }else if(count.month === 8){
            MonthName = Aug
        }else if(count.month === 9){
            MonthName = Sep
        }else if(count.month === 10){
            MonthName = Oct
        }else if(count.month === 11){
            MonthName = Nov
        }else if(count.month === 12){
            MonthName = Dec
        }
        obj["month"] = MonthName
        obj["count"] = count.count
        ArrayMonth.push(obj)
    })
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ChartTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChartTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Jan,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setJan(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Feb,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setFeb(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Mar,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMar(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Apr,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setApr(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + May,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMay(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Jun,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setJun(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Jul,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setJul(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Aug,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAug(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Sep,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSep(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Oct,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setOct(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Nov,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNov(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Dec,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDec(translated[0])
            })
        }).then((error) =>{
        })

    }
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: { top: 8, right: 8, bottom: 24, left: 46},
          xAxis: [
            {
              type: 'category',
              data: [ArrayMonth[0]?.month ? ArrayMonth[0]?.month : Jan,
               ArrayMonth[1]?.month ? ArrayMonth[1]?.month : Feb, 
               ArrayMonth[2]?.month ? ArrayMonth[2]?.month : Mar, 
               ArrayMonth[3]?.month ? ArrayMonth[3]?.month : Apr,
               ArrayMonth[4]?.month ? ArrayMonth[4]?.month : May,
               ArrayMonth[5]?.month ? ArrayMonth[5]?.month : Jun,
               ArrayMonth[6]?.month ? ArrayMonth[6]?.month : Jul,
               ArrayMonth[7]?.month ? ArrayMonth[7]?.month : Aug,
               ArrayMonth[8]?.month ? ArrayMonth[8]?.month : Sep,
               ArrayMonth[9]?.month ? ArrayMonth[9]?.month : Oct,
               ArrayMonth[10]?.month ? ArrayMonth[10]?.month : Nov,
               ArrayMonth[11]?.month ? ArrayMonth[11]?.month : Dec],
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Registered User',
              type: 'bar',
              barWidth: '70%',
              data: [ArrayMonth[0]?.count, ArrayMonth[1]?.count, ArrayMonth[2]?.count, ArrayMonth[3]?.count, ArrayMonth[4]?.count, ArrayMonth[5]?.count, ArrayMonth[6]?.count, ArrayMonth[7]?.count, ArrayMonth[8]?.count, ArrayMonth[9]?.count, ArrayMonth[10]?.count, ArrayMonth[11]?.count]
            }
          ]
    }
    return (
    <>
    {Token ?
        <>
            {/* WEKLY USER, VERSION, DAILY DEVOTION, SUBSCRIPTION CHART */}
            <div style={{paddingLeft : '50px'}} className="FirstContainer">
                <Grid gutter="xs" grow>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalUserChart/>
                            </Card>
                        </Grid.Col>
                    </div>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalBibleVersions/> 
                            </Card>
                        </Grid.Col>
                    </div>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalViewsComment/>
                            </Card>
                        </Grid.Col>
                    </div>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalUserSubscription/>
                            </Card>
                        </Grid.Col>
                    </div>
                </Grid>
            </div>
            {/* PLATFORM & MONTH USERS */}
            <div style={{paddingLeft : '50px', paddingTop : '10px'}} className="SecondContainer">
                <Grid gutter="xs" grow>
                    <div style={{width : '50%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <FlatFormChart/>
                            </Card>
                        </Grid.Col>
                    </div>
                    <div style={{width : '50%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <Text fw={900} align="center"  sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>{ChartTitle}</Text>
                                {ChartLoader ?
                                    <Group position="center" sx={{padding : 100}}>
                                        <Loader color="violet" variant="dots" size="md"/>
                                    </Group>
                                    :
                                    <ReactECharts option={options} />
                                }
                            </Card>
                        </Grid.Col>
                    </div>
                </Grid>
            </div>  
            {/* ACHIEVEMENT  */}
            <div style={{paddingLeft : '50px', paddingTop : '10px'}} className="SecondContainer">
                <Grid gutter="xs" grow>
                    <div style={{width : '100%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <AchievementLeaderBoards/>
                            </Card>
                        </Grid.Col>
                    </div>
                </Grid>
            </div>
            {/* WORSHIP */}
            <div style={{paddingLeft : '50px', paddingTop : '10px'}} className="SecondContainer">
                <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                    <TotalWorship/>
                </Card>
            </div>
            <br/>
        </>
        :
        <Skeleton visible={loading}>
            <div style={{paddingLeft : '50px'}} className="FirstContainer">
                <Grid gutter="xs" grow>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalUserChart/>
                            </Card>
                        </Grid.Col>
                    </div>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalBibleVersions/> 
                            </Card>
                        </Grid.Col>
                    </div>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalViewsComment/>
                            </Card>
                        </Grid.Col>
                    </div>
                    <div style={{width : '25%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                                <TotalUserSubscription/>
                            </Card>
                        </Grid.Col>
                    </div>
                </Grid>
            </div>
            <div style={{paddingLeft : '50px', paddingTop : '10px'}} className="SecondContainer">
                <Grid gutter="xs" grow>
                    <Grid.Col> 
                        <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                            <Text fw={900} align="center"  sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>{ChartTitle}</Text>
                            {ChartLoader ?
                                <Group position="center" sx={{padding : 100}}>
                                    <Loader color="violet" variant="bars" size="md"/>
                                </Group>
                                :
                                <ReactECharts option={options} />

                            }
                        </Card>
                    </Grid.Col>
                    <Grid.Col> 
                        <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                            <AchievementLeaderBoards/>
                        </Card>
                    </Grid.Col>
                </Grid>
            </div>   
            <div style={{paddingLeft : '50px', paddingTop : '10px'}} className="SecondContainer">
                <Card padding="xl" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} >
                    <TotalWorship/>
                </Card>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </Skeleton>
    }
    </> 
    )
}