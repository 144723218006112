import React, { useState, useEffect } from "react";
import { Button, Group, Modal, useMantineTheme, Textarea, Text } from '@mantine/core';
import { IconUpload, IconCheck } from '@tabler/icons-react';
import { api } from '../../Utils'
import Axios from 'axios';
export const UploadChurch = ({notifications, OpenModal, CloseModal, fetchChurch}) => {
    const theme = useMantineTheme();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [ChurchName, setChurchName] = useState('')
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [CreateChurchTranslate, setCreateChurchTranslate] = useState('Add New Church')
    const [ChurchTranslate, setChurchTranslate] = useState('Church')
    const [PlaceholderChurchTranslate, setPlaceholderChurchTranslate] = useState('Enter Church Name')
    const [BtnUploadChurch, setBtnUploadChurch] = useState('UPLOAD CHURCH')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Uploading')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Upload this Church?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateChurchTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateChurchTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ChurchTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChurchTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderChurchTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderChurchTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadChurch,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadChurch(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleChangeChurchName = (event) => {
        setChurchName(event.target.value)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const UploadChurchFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Church',
            message: 'Church will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const UploadEP = `/Churches?name=`+ChurchName+``
        api.post(UploadEP,{     
        }).then(result => {
            if(result['status'] === 201){
                setTimeout(() => {
                    CloseModal();
                    fetchChurch();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Church was uploaded',
                        message: 'Church successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        })
    }
    return(
    <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={CreateChurchTranslate}
            size="sm"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 2,
            }}
        >
            <Group grow>
                <Textarea
                    placeholder={PlaceholderChurchTranslate}
                    label={ChurchTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    minRows={5}
                    onChange={handleChangeChurchName}
                    sx = {{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group mt="md" mb="xs" grow>
                <Button sx = {{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {BtnUploadChurch}
                </Button>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text sx = {{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button sx = {{fontFamily : 'poppins'}} onClick = {UploadChurchFunction} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button sx = {{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>
    </>
    )
}