import { Card, Image, Text, Button, Group, useMantineTheme, rem, TextInput,
    Textarea, Switch, Modal, MultiSelect, Stack, ActionIcon, Loader } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX, IconPhoto, IconCalendar, IconCheck, IconRefresh, IconLanguage,  } from '@tabler/icons-react';
import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import Axios from 'axios';
import { Token, api, api_multipart, RefreshToken, GetNewToken } from '../../Utils'
import { Base64 } from 'js-base64';
import { useLocation } from 'react-router-dom'

import { LanguageCard } from '../LanguageCardContent/LanguageCard';
export const UploadWoftd = ({notifications, openModal, closeModal, fetchWordOfTheDay, ReOpenModal, fetchWordOfTheDayFuture}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [startDate, setStartDate] = useState(new Date());
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [VerseContent, setVerseContent] = useState("");
    const [Verse, setVerse] = useState("");
    const [Caption, setCaption] = useState("");
    const PostDate = Moment(startDate).format();
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [GetVerseContent, setGetVerseContent] = useState("")
    const location = useLocation()

    //! TRANSLATION
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UploadWoftTitle, setUploadWoftTitle] = useState('UPLOADING WORD OF THE DAY')
    const [DragImageTitle, setDragImageTitle] = useState('Drag images here or click to select files')
    const [DragImageDesc, setDragImageDesc] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [VerseTranslate, setVerseTranslate] = useState('Verse')
    const [VersePlaceholder, setVersePlaceholder] = useState('Enter Verse')
    const [VerseContenTranslate, setVerseContentTranslate] = useState('Verse Content')
    const [VerseContentPlaceholder, setVerseContentPlaceholder] = useState('Enter Verse Content')
    const [CaptionTranslate, setCaptionTranslate] = useState('Caption')
    const [CaptionPlaceholder, setCaptionPlaceholder] = useState('Enter Caption')
    const [DateTitle, setDateTitle] = useState('Select Date')
    const [ButtonUpload, setButtonUpload] = useState('UPLOAD')

    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [TranslateLoading, setTranslateLoading] = useState(false)
    let objTranslated = {}
    let ArrayTranslated = []
    useEffect(() => {
        if(location.state === null){}else{
            const { content } = location.state
            setGetVerseContent(content)
            ReOpenModal()
        }
        setDefaultLanguage(localStorage.getItem('SelectedCode'));
        fetchTranslation();
        fetchLanguages();
    }, [GetVerseContent, DefaultLanguage]);
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDesc,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDesc(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + VerseTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setVerseTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + VersePlaceholder,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setVersePlaceholder(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + VerseContenTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setVerseContentTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + VerseContentPlaceholder,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setVerseContentPlaceholder(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CaptionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCaptionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CaptionPlaceholder,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCaptionPlaceholder(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DateTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDateTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ButtonUpload,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setButtonUpload(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleVerse = (event) => {
        setVerse(event.target.value)
    }
    const handleVerseContent = (event) => {
        setVerseContent(event.target.value)
    }
    const handleCaption = (event) => {
        setCaption(event.target.value)
    }
    const UploadWordOfTheDay  = async () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Word of the Day',
            message: 'Word of the Day will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        var postData = {
            "verse": Verse,
            "verseContent" : VerseContent,
            "caption" : Caption,
            "date": PostDate,
        };
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };  
        Axios.post(''+APILink+'/api/wordofthedays', postData, axiosConfig)
        .then((res) => {
            if(res.status === 201){
                LanguageTranslated.map((item) => {
                    var LocalizationData = {
                        "verse": item.verse,
                        "verseContent": item.verse_content,
                        "caption": item.caption,
                        "language": item.code,
                    };
                    Axios.post(''+APILink+'/api/wordofthedays/'+res.data.id+'/localizations', LocalizationData, axiosConfig)
                    .then((res) => {
                        if(res.status === 200){
                            setTimeout(() => {
                                //! RESET INPUTS
                                fetchWordOfTheDay()
                                fetchWordOfTheDayFuture()
                                setConfirmModal(false)
                                closeModal()
                                setVerseContent("")
                                setLanguageTranslated([])
                                //! END OF RESET INPUTS
                                notifications.update({
                                    id: 'load-data',
                                    color: 'teal',
                                    title: 'Word of the Day was uploaded',
                                    message: 'Word of the Day successsfully Updated',
                                    icon: <IconCheck size="1rem" />,
                                    autoClose: 2000,
                                });
                            }, 1000);
                        }
                    })
                    .catch((err) => {
                    })
                })
            }
        })
        .catch((err) => {
        console.log(err);
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const DatePickerBtn = forwardRef(({ value, onClick }, ref) => (
        <>
        <Text sx = {{fontFamily : 'poppins'}}>{DateTitle}</Text>
        <Group grow>
            <Button leftIcon = {<IconCalendar/>} variant="outline" color="dark" size="md" sx = {{fontFamily : 'poppins'}} uppercase  onClick={onClick} ref={ref} >
            {value}
            </Button>
        </Group>
        </>
    )); 
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedVerse = ''
            let GetTranslatedVerseContent = ''
            let GetTranslatedCaption = ''
           
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": Verse},
                ]
            }).then((result) => {
                GetTranslatedVerse = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedVerse = gettranslation.text
                    })
                ))
                Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                    method: "POST",
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                        "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                    },
                    data:[
                        {"Text": VerseContent}
                    ]
                }).then((result) => {
                    GetTranslatedVerseContent= ''
                    result.data.map((a) => (
                        a.translations.map((gettranslation) => {
                            GetTranslatedVerseContent = gettranslation.text
                        })
                    ))
                    Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                            method: "POST",
                            headers: {
                                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                                "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                            },
                            data:[
                                {"Text": Caption}
                            ]
                        }).then((result) => {
                            GetTranslatedCaption= ''
                            result.data.map((a) => (
                                a.translations.map((gettranslation) => {
                                    GetTranslatedCaption = gettranslation.text
                                })
                            ))
                            objTranslated = {}
                            objTranslated['code'] = code.languageCode
                            objTranslated['verse'] = GetTranslatedVerse
                            objTranslated['verse_content'] = GetTranslatedVerseContent
                            objTranslated['caption'] = GetTranslatedCaption
                            ArrayTranslated.push(objTranslated)
                            setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                            setTranslateLoading(false)
                        }).catch((error) => { 
                    })
                }).catch((error) => { 
                })
            }).catch((error) => { 
            })
        }
    }
    return(
        <>
        <Modal size = "100%" opened={openModal} onClose={closeModal} centered>
            <Group align='start' position='center'>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Group position = "center">
                        <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>{UploadWoftTitle}</Text>
                    </Group>
                    <Group mt="md" mb="xs" grow>
                        <TextInput
                            label={VerseTranslate}
                            placeholder={VersePlaceholder}
                            radius="xs"
                            size="md"
                            withAsterisk
                            onChange={handleVerse}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <Group mt="md" mb="xs" grow>
                        <Textarea
                            placeholder={VerseContentPlaceholder}
                            label={VerseContenTranslate}
                            size="md"
                            variant='filled'
                            minRows={10}
                            onChange={handleVerseContent}
                            withAsterisk
                            defaultValue={Base64.decode(GetVerseContent)}
                            sx = {{fontFamily : 'poppins'}}
                        />
                         <Textarea
                            placeholder={CaptionPlaceholder}
                            label={CaptionTranslate}
                            size="md"
                            variant='filled'
                            minRows={10}
                            withAsterisk
                            onChange={handleCaption}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <Group mt="md" mb="xs" grow>
                        <DatePicker  
                            selected={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            customInput={<DatePickerBtn />}
                        />
                    </Group>
                    <Group mt="md" mb="xs" grow>
                        <Button onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            {ButtonUpload}
                        </Button>
                        <Button onClick={TranslateSelectedLanguages} leftIcon = {<IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            Translate
                        </Button>
                    </Group>


                </Card>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Stack justify="flex-start">
                        <Group position = "center">
                            <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>TRANSLATED CONTENT</Text>
                        </Group>
                        <LanguageCard
                            LanguageTranslated = {LanguageTranslated}
                            TranslateLoading = {TranslateLoading}
                        />
                    </Stack>
                </Card>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Uploading" centered>
            <Group>
                <Group grow>
                    <Text>Are you sure you want to Upload this Word of the day? 
                    </Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button onClick = {UploadWordOfTheDay} color="teal" size="sm" uppercase> Confirm </Button>
                <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> Cancel </Button>
            </Group>
        </Modal>
        </>
    )
}