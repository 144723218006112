import { Button, TextInput, Tooltip, ActionIcon, Group, Modal, useMantineTheme, Grid, Card, Text, Image, Menu, Avatar, Stack, CopyButton  } from '@mantine/core';
import { useState, useEffect, forwardRef, useRef } from 'react';
import { IconSearch, IconTrash, IconCheck, IconPlus, IconBuildingChurch, IconDotsVertical, IconFileUpload, IconUpload, IconFileBroken, IconFileAlert, IconFile3d, IconFileDescription, IconFileUnknown, IconFileCheck, IconCopy, IconDownload, IconTableOptions, IconGridPattern, IconList, IconGrid4x4, IconPhoto, IconMusic, IconFileMusic, IconVideo} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import DataTable from 'react-data-table-component';
import  LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
export const Resources = () => {
    const theme = useMantineTheme();
    const { BlobServiceClient } = require("@azure/storage-blob");
    const ResourcesURL = 'https://theonebibleappstorage.blob.core.windows.net/theone-resources/'
    const blobSasUrl = "https://theonebibleappstorage.blob.core.windows.net/?sv=2022-11-02&ss=b&srt=co&sp=rwdlaciytfx&se=2025-01-31T17:02:11Z&st=2023-09-26T09:02:11Z&spr=https&sig=N9K5dr29YhIreHPhwsKeLZx4rmzKYEQ1BJdGvix%2B7jo%3D"
    const blobServiceClient = new BlobServiceClient(blobSasUrl)
    const containerName = "theone-resources"
    const containerClient = blobServiceClient.getContainerClient(containerName)
    const [FileInput, setFileInput] = useState('')
    const [search, setSearch] = useState('');
    const [UploadModal, setUploadModal] = useState(false)
    const [ViewFileModal, setViewFileModal] = useState(false)
    const [BlobFiles, setBlobFiles] = useState([])
    const [ViewType, setViewType] = useState('Grid')
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    let ArrayFiles = []
    useEffect(() => {
        fetchFiles();
    }, []);
    const handleFileUpload = (e) => {
        setFileInput(e.target.files);
    };
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchFiles = async() => {
        try {
            let iter = containerClient.listBlobsFlat();
            let ObjFiles = {}
            let blobItem = await iter.next();
            while (!blobItem.done) {
                ObjFiles = {}
                const FileUrl = ResourcesURL + blobItem.value.name
                const FileName = blobItem.value.name
                ObjFiles['url'] = FileUrl
                ObjFiles['fileName'] = FileName
                ArrayFiles.push(ObjFiles)
                setBlobFiles(ArrayFiles)
                blobItem = await iter.next(); 
            }
        } catch (error) {
        }
    }
    const UploadFiles = async() => {
         notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload File',
            message: 'File will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        try{
            const promises = []
            for(const file of FileInput){
                const blockBlobClient = containerClient.getBlockBlobClient(file.name)
                promises.push(blockBlobClient.uploadBrowserData(file))
            }
            await Promise.all(promises)
            setTimeout(() => {
                setUploadModal(false)
                fetchFiles()
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'File was uploaded',
                    message: 'File successsfully uploaded',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }
        catch(error){
        }
    }
    const DeleteFile = async(filename) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing File',
            message: 'File will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        try {
            containerClient.deleteBlob(filename)
            setTimeout(() => {
                fetchFiles() 
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Location was deleted',
                    message: 'Location successsfully deleted',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000);
        }catch (error) {
        }
    }
    const DownloadFile = async(filename) => {
    }
    const CustomizeFileType = (filename) => {
        let fileName = filename.filename;
        let extension = fileName.split(".").pop();
        if(extension === 'txt' || extension === 'xlsx' || extension === 'zip' || extension === 'csv' ){
            return(
                <Group position='center'>
                    <IconFileCheck size={90} color='green'/>
                </Group>
            )
        }
        if(extension === 'mp3'){
            return(
                <Group position='center'>
                    <IconFileMusic size={90} color='darkblue'/>
                </Group>
            )
        }
        if(extension === 'mp4'){
            return(
                <Group position='center'>
                    <IconVideo size={90} color='darkblue'/>
                </Group>
            )
        }
        else{
            return(
                <>
                    <Group position='center'>
                        <IconFileUnknown size={70} color='red'/>
                    </Group>
                    <br/>
                    <Text fw={500} color='black' fz="lg">Unkown File Type</Text>
                </>
            )
        }
    }
    const CustomizeFileTypeList = (filename) => {
        let fileName = filename.filename;
        let extension = fileName.split(".").pop();
        if(extension === 'txt' || extension === 'xlsx' || extension === 'zip' || extension === 'csv' ){
            return(
                <Group>
                    <IconFileCheck size={20} color='green'/>
                </Group>
            )
        }
        if(extension === 'png' || extension === 'jpg' || extension === 'jpeg'){
            return(
                <Group>
                    <IconPhoto size={20} color='red'/>
                </Group>
            )
        }
        if(extension === 'mp3'){
            return(
                <Group position='center'>
                    <IconFileMusic size={20} color='darkblue'/>
                </Group>
            )
        }
        if(extension === 'mp4'){
            return(
                <Group position='center'>
                    <IconVideo size={20} color='darkblue'/>
                </Group>
            )
        }
        else{
            return(
                <Group>
                    <IconFileUnknown size={20} color='red'/>
                </Group>
            )
        }
    }
    const ListViewFunction = () => {
        fetchFiles()
        setViewType('List')
    }
    const GridViewFunction = () => {
        fetchFiles()
        setViewType('Grid')
    }
    const columns = [
        {
            name: <Text fw={500} fz={22}>NAME</Text>,
            cell : row =>
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
               <Group spacing = {4}>
                    <CustomizeFileTypeList filename = {row.fileName}/>
                    <Stack spacing={1} justify="flex-start" className="ListOfAudioContent">
                        <Text fw={700} sx={{lineHeight: 1, color : 'dark', fontSize : 14, wordBreak : 'break-all'}}>{row.fileName}</Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={0} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="md" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <CopyButton value={row.url} timeout={2000}>
                            {({copied, copy }) => (
                                <Menu.Item color = "#737373" onClick={copy} icon = {<IconCopy/>}>
                                    Copy Link
                                </Menu.Item>
                            )}
                            </CopyButton>
                            <a href={row.url} target="_blank" style={{textDecoration : 'none'}}>
                                <Menu.Item color = "#737373" icon = {<IconDownload/>}>Download File</Menu.Item>
                            </a>
                            <Menu.Item color = "#737373" icon = {<IconTrash/>} onClick={() => {DeleteFile(row.fileName)}}>Delete File</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ];
    return (
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group position='apart'> 
                <Group>
                    <TextInput
                            icon={<IconSearch/>}
                            radius="sm"
                            size="md"
                            placeholder="Search File"
                            onChange={ handleSearch }
                        />
                    <Tooltip
                        label="Upload File"
                        sx={{background : '#6c2be2'}}
                        withArrow
                    >
                        <ActionIcon
                            sx={{background : '#4a1e9e',  ":hover" : {
                                background : '#6c2be2'
                            }}}
                            size="lg" 
                            radius="xl" 
                            variant="filled"
                            onClick={() => {setUploadModal(true)}}
                        >
                            <IconFileUpload />
                        </ActionIcon>
                    </Tooltip>
                </Group>
                <Group>
                    {ViewType === 'Grid' ?
                        <ActionIcon
                            size="lg" 
                            radius="xl" 
                            variant="filled"
                            onClick = {ListViewFunction}
                            sx={{background : '#4a1e9e',  ":hover" : {
                                background : '#6c2be2'
                            }}}
                        >
                            <IconList />
                        </ActionIcon>
                        :
                        <ActionIcon
                            size="lg" 
                            radius="xl" 
                            variant="filled"
                            onClick = {GridViewFunction}
                            sx={{background : '#4a1e9e',  ":hover" : {
                                background : '#6c2be2'
                            }}}
                        >
                            <IconGrid4x4 />
                        </ActionIcon>
                    }
                </Group>
            </Group>
            <br/>
            {ViewType === 'Grid' ?
                <Grid gutter="xs" grow>
                    {BlobFiles.filter((SearchFile) => {
                        if(search === ""){
                            return SearchFile
                        }else if(SearchFile.fileName.toLowerCase().includes(search.toLowerCase())){
                            return SearchFile
                        }
                        }).map((item, index) =>
                        <div key = {index} style={{width : '16.66%'}} className='ResourcesLayout'>
                            <Grid.Col> 
                                <Card shadow="xl" padding="xl" radius="md" withBorder sx={{background : '#E7ECF0',":hover" : {
                                    background : '#DDDDDD'
                                }}}>
                                    <Card.Section>
                                        <Group position='apart' sx={{padding : 15}}>
                                            <Group sx={{maxWidth : '80%'}} className="ResourcesTitle">
                                                <Text lineClamp={1}>{item.fileName}</Text>
                                            </Group>
                                            <Group>
                                                <Menu shadow="md" width={250} position="bottom-center" offset={0} withArrow>
                                                    <Menu.Target>
                                                        <ActionIcon color="dark" size="md" radius="xs" variant="transparent">
                                                            <IconDotsVertical size="1.625rem" />
                                                        </ActionIcon>
                                                    </Menu.Target>
                                                    <Menu.Dropdown>
                                                        <CopyButton value={item.url} timeout={2000}>
                                                            {({copied, copy }) => (
                                                                <Menu.Item color = "#737373" onClick={copy} icon = {<IconCopy/>}>
                                                                    Copy Link
                                                                </Menu.Item>
                                                            )}
                                                        </CopyButton>
                                                        <a href={item.url} target="_blank" style={{textDecoration : 'none'}}>
                                                            <Menu.Item color = "#737373" icon = {<IconDownload/>}>Download File</Menu.Item>
                                                        </a>
                                                        <Menu.Item color = "#737373" icon = {<IconTrash/>} onClick={() => {DeleteFile(item.fileName)}}>Delete File</Menu.Item>
                                                    </Menu.Dropdown>
                                                </Menu>
                                            </Group>
                                        </Group>
                                    </Card.Section>
                                    <Card.Section>
                                        <Image
                                            src={item.url}
                                            height={200}
                                            withPlaceholder
                                            placeholder={<CustomizeFileType filename = {item.fileName}/>}
                                            sx={{padding : 5, cursor : 'pointer'}}
                                            radius="md"
                                            onClick = {() => {setViewFileModal(true)}}
                                        />
                                    </Card.Section>
                                </Card>
                            </Grid.Col>
                        </div>
                    )}
                </Grid>
                :
                <DataTable
                    columns={columns}
                    data={
                        BlobFiles.filter((SearchFile) => {
                            if(search === ""){
                                return SearchFile
                            }else if(SearchFile.fileName.toLowerCase().includes(search.toLowerCase())){
                                return SearchFile
                            }
                        })
                    }
                    progressPending={loading}
                    pagination
                    paginationTotalRows={BlobFiles.length}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                    responsive = ""
                />
            }
            <Modal 
                opened={UploadModal}
                onClose={() => {setUploadModal(false)}}
                title="Upload New File" 
                size="sm"
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 2,
                }}
                centered
            >
                <Group grow>
                <input type="file" onChange={handleFileUpload} multiple/>
                </ Group>
                <Group mt="md" mb="xs" grow>
                    <Button onClick={UploadFiles} color='violet' leftIcon = {<IconUpload/>}>UPLOAD FILE</Button>
                </Group>
            </Modal>
            <Modal 
                opened={ViewFileModal}
                onClose={() => {setViewFileModal(false)}}
                title="Upload New File" 
                size="sm"
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 2,
                }}
                centered
            >
            
            </Modal> 
        </div>
       
    ) 
}