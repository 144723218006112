import React, { useState, useEffect } from "react";
import { Button, Group, Modal, useMantineTheme, Textarea, Text, TextInput, Card, Image, rem, Divider, Stack } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconCheck, IconX, IconPhoto, IconLanguage } from '@tabler/icons-react';
import { Token, api } from '../../Utils'
import Axios from 'axios';
import { LanguageCardBlogs } from '../LanguageCardContent/LanguageCardBlogs';
export const UploadBlogs = ({notifications, OpenModal, CloseModal, fetchBlog}) => {
    const APILink = process.env.REACT_APP_API_LINK
    let maxCharacter = 2000
    const theme = useMantineTheme();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('')
    const [Title, setTitle] = useState("");
    const [Keyword, setKeyword] = useState("");
    const [Description, setDescription] = useState("");
    const [Author, setAuthor] = useState("");

    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UploadReflectionTranslate, setUploadReflectionTranslate] = useState('Add New Reflection')
    const [DragImageTitleTranslate, setDragImageTitleTranslate] = useState('Drag images here or click to select files')
    const [DrageImageDescTranslate, setDrageImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [ReflectionTitleTranslate, setReflectionTitleTranslate] = useState('Title')
    const [PlaceholderTitleTranslate, setPlaceholderTitleTranslate] = useState('enter Title')
    const [RelfectionDescTranslate, setRelfectionDescTranslate] = useState('Description')
    const [PlaceholderDescTranslate, setPlaceholderDescTranslate] = useState('Enter Description')
    const [ReflectionKeywordTranslate, setReflectionKeywordTranslate] = useState('Keyword')
    const [PlaceholderKeywordTranslate, setPlaceholderKeywordTranslate] = useState('Enter Keywords')
    const [ReflectionAuthorTranslate, setReflectionAuthorTranslate] = useState('Author')
    const [PlaceholderAuthorTranslate, setPlaceholderAuthorTranslate] = useState('Enter Author')
    const [BtnUploadReflection, setBtnUploadReflection] = useState('UPLOAD REFLECTION')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Uploading')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Upload this Reflection?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [TranslateLoading, setTranslateLoading] = useState(false)
    let objTranslated = {}
    let ArrayTranslated = [] 
    useEffect(() => { 
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
        fetchLanguages();
    }, [DefaultLanguage])
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadReflectionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadReflectionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DrageImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDrageImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ReflectionTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setReflectionTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RelfectionDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRelfectionDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ReflectionKeywordTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setReflectionKeywordTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderKeywordTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderKeywordTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ReflectionAuthorTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setReflectionAuthorTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderAuthorTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderAuthorTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadReflection,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadReflection(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    function handleImage(files, setImage){
        let Reader = new FileReader()
        Reader.readAsDataURL(files[0])
        Reader.onload = () => {
            setImage(Reader.result.split(",")[1])
        }
    }
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }
    const handleChangeKeyword = (event) => {
        setKeyword(event.target.value)
    }
    const handleChangeAuthor = (event) => {
        setAuthor(event.target.value)
    }
    const UploadBlogsFunction = async() => {
        let objSample = {}
        let ArraySample = []
        LanguageTranslated.length = LanguageTranslated.length - 1
        LanguageTranslated.map((translated) => {
            objSample = {}
            objSample['title'] = translated.title
            objSample['description'] = translated.description
            objSample['language'] = translated.code
            ArraySample.push(objSample) 
        })
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Blogs',
            message: 'Blogs will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        var postData = {
            "title": Title,
            "description" : Description,
            "cover" : image,
            "authorName" : Author,
            "keywords": Keyword,
            "localizations": ArraySample
        }
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };  
        Axios.post(''+APILink+'/api/Blogs', postData, axiosConfig)
        .then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchBlog()
                    CloseModal();
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Blogs was uploaded',
                        message: 'Blogs successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        })
        .catch((err) => {
        console.log(err);
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedTitle = ''
            let GetTranslatedDescription = ''
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": Title},
                ]
            }).then((result) => {
                GetTranslatedTitle = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedTitle = gettranslation.text
                    })
                ))
                Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                    method: "POST",
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                        "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                    },
                    data:[
                        {"Text": Description}
                    ]
                }).then((result) => {
                    GetTranslatedDescription= ''
                    result.data.map((a) => (
                        a.translations.map((gettranslation) => {
                            GetTranslatedDescription = gettranslation.text
                        })
                    ))
                    objTranslated = {}
                    objTranslated['code'] = code.languageCode
                    objTranslated['title'] = GetTranslatedTitle
                    objTranslated['description'] = GetTranslatedDescription
                    ArrayTranslated.push(objTranslated)
                    setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                    setTranslateLoading(false)
                }).catch((error) => { 
                })
            }).catch((error) => { 
            })
        }
    }
    return(
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={UploadReflectionTranslate}
            size="100%"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
             <Group align='start' position='center'>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Card shadow="xl" padding="lg" radius="sm" withBorder>
                    <Card.Section>
                    {
                        img.map((url, index) =>
                        <Image 
                            key = {index} 
                            maw={300} 
                            mx="auto"
                            alt="Event Image" 
                            src={url}
                            placeholder={<Text align="center">No Image Detected</Text>}
                        />
                        )
                    }  
                    </Card.Section>
                    <Card.Section >
                        <Dropzone
                            onDrop={
                                (files) => {
                                    handleImage(files, setImage)
                                    const selectedFIles =[];
                                    const targetFilesObject= [...files]
                                    targetFilesObject.map((file)=>{
                                        return selectedFIles.push(URL.createObjectURL(file))
                                    })
                                    setImg(selectedFIles);
                                } 
                            }
                            maxSize={3 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                        >
                            <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                                <Dropzone.Accept>
                                    <IconUpload
                                        size="3.2rem"
                                        stroke={1.5}
                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Accept>

                                <Dropzone.Reject>
                                    <IconX
                                        size="3.2rem"
                                        stroke={1.5}
                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Reject>

                                <Dropzone.Idle>
                                    <IconPhoto size="3.2rem" stroke={1.5} />
                                </Dropzone.Idle>

                                <div>
                                    <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                        {DragImageTitleTranslate}
                                    </Text>
                                    <Text size="sm" color="dimmed" inline mt={7} sx={{fontFamily : 'poppins'}}>
                                        {DrageImageDescTranslate}
                                    </Text>
                                </div>
                            </Group>
                        </Dropzone>
                    </Card.Section>
                    </Card>
                    <br/>
                    <Divider></Divider>
                    <br/>
                    <Group grow>
                        <TextInput
                            placeholder={PlaceholderTitleTranslate}
                            label={ReflectionTitleTranslate}
                            radius="xs"
                            size="md"
                            withAsterisk
                            onChange={handleChangeTitle}
                            sx={{fontFamily : 'poppins'}}
                        />
                    </ Group>
                    <Group grow>
                        <Textarea
                            placeholder={PlaceholderDescTranslate}
                            label={RelfectionDescTranslate}
                            size="md"
                            minRows={5}
                            withAsterisk
                            onChange={handleChangeDescription}
                            sx={{fontFamily : 'poppins'}}
                            description={
                                Description.length + " of " + maxCharacter
                            }
                            error = {
                                Description.length > maxCharacter ? 
                                'you reach the limit of characters!'
                                :
                                ''
                            }
                            minLength={100}
                        />
                    </Group>
                    <Group grow>
                        <Textarea
                            placeholder={PlaceholderKeywordTranslate}
                            label={ReflectionKeywordTranslate}
                            size="md"
                            minRows={2}
                            withAsterisk
                            onChange={handleChangeKeyword}
                            sx={{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <Group grow>
                        <TextInput
                            placeholder={PlaceholderAuthorTranslate}
                            label={ReflectionAuthorTranslate}
                            radius="xs"
                            size="md"
                            withAsterisk
                            onChange={handleChangeAuthor}
                            sx={{fontFamily : 'poppins'}}
                        />
                    </ Group>
                    <Group mt="md" mb="xs" grow>
                        <Button sx={{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                            {BtnUploadReflection}
                        </Button>
                        <Button onClick={TranslateSelectedLanguages} leftIcon = {<IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            Translate
                        </Button>
                    </Group>
                </Card>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Stack justify="flex-start">
                        <Group position = "center">
                            <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>TRANSLATED CONTENT</Text>
                        </Group>
                        <LanguageCardBlogs
                            LanguageTranslated = {LanguageTranslated}
                            TranslateLoading = {TranslateLoading}
                        />
                    </Stack>
                </Card>
             </Group>
           
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate}centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {UploadBlogsFunction} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
                </Group>
            </Modal>
        </Modal>
    )
}