
import React, { useState, useEffect } from "react";
import { Modal, Group, TextInput, Textarea, Button, Switch, Text } from '@mantine/core'
import { IconUpload, IconCheck } from "@tabler/icons-react";
import { Token } from '../../Utils'
import Axios from 'axios';
export const UploadCustomizedAds = ({notifications, OpenModal, CloseModal, fetchCustomizedAds}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const [Title, setTitle] = useState("")
    const [Name, setName] = useState("")
    const [Description, setDescription] = useState("")
    const [Url, setUrl] = useState("")
    const [Active, setActive] = useState(false)
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [CreateCustomizeAdsTranslate, setCreateCustomizeAdsTranslate] = useState('Create Customize Ads')
    const [TitleTranslate, setTitleTranslate] = useState('Title')
    const [PlaceholderTitle, setPlaceholderTitle] = useState('Enter Title')
    const [NameTranslate, setNameTranslate] = useState('Name')
    const [PlaceholderName, setPlaceholderName] = useState('Enter Name')
    const [DescriptionTranslate, setDescriptionTranslate] = useState('Description')
    const [PlaceholderDescription, setPlaceholderDescription] = useState('Enter Description')
    const [UrlTranslate, setUrlTranslate] = useState('Link')
    const [PlaceholderUrl, setPlaceholderUrl] = useState('Enter Url')
    const [SetAsDefaultTranslate, setSetAsDefaultTranslate] = useState('Set as Default')
    const [BtnUploadAds, setBtnUploadAds] = useState('UPLOAD ADS')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateCustomizeAdsTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateCustomizeAdsTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NameTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderName,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderName(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DescriptionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDescriptionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDescription,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDescription(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UrlTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUrlTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderUrl,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderUrl(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SetAsDefaultTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSetAsDefaultTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadAds,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadAds(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleTitleEvent = (event) => {
        setTitle(event.target.value)
    }
    const handleNameEvent = (event) => {
        setName(event.target.value)
    }
    const handleDescriptioneEvent = (event) => {
        setDescription(event.target.value)
    }
    const handleUrleEvent = (event) => {
        setUrl(event.target.value)
    }
    const UploadCustomizedAdsFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Customized Ads',
            message: 'Customized Ads will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        Axios({
            url : APILink+'/api/CustomizedAds',
            method : 'POST',
            headers : {
                Authorization: 'Bearer '+Token,
                'Application' : 'application/json'
            },
            data : {
                "title" : Title,
                "name" : Name,
                "description" : Description,
                "url" : Url,
                "isDefault" : Active,
            }  
        }) .then(res => {
            setTimeout(() => {
                //! RESET INPUTS
                fetchCustomizedAds();
                CloseModal(false)
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Customized Ads was uploaded',
                    message: 'Customized Ads successsfully uploaded',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }).catch(error =>{
            console.log(error)
        }) 
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={CreateCustomizeAdsTranslate} centered>
            <Group mt="md" mb="xs" grow>
                <TextInput
                    placeholder={PlaceholderTitle}
                    label={TitleTranslate}
                    radius="md"
                    size="md"
                    onChange={handleTitleEvent}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <TextInput
                    placeholder={PlaceholderName}
                    label={NameTranslate}
                    radius="md"
                    size="md"
                    onChange={handleNameEvent}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Textarea
                    placeholder={PlaceholderDescription}
                    label={DescriptionTranslate}
                    radius="md"
                    size="md"
                    minRows={6}
                    onChange={handleDescriptioneEvent}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <TextInput
                    placeholder={PlaceholderUrl}
                    label={UrlTranslate}
                    radius="md"
                    size="md"
                    type="url"
                    withAsterisk
                    onChange={handleUrleEvent}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Text fw = {600}>{SetAsDefaultTranslate}</Text>
            <Group mt="md" mb="xs" grow>
                <Switch 
                    size = "xl"
                    onLabel="ACTIVE" 
                    offLabel="INACTIVE" 
                    radius="xs" 
                    color="green"
                    checked = {Active}
                    onChange={(event) => setActive(event.currentTarget.checked)}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UploadCustomizedAdsFunction} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {BtnUploadAds}
                </Button>
            </Group>
        </Modal>
        </>
    )
}