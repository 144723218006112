import { Card, Text, Group, Loader } from '@mantine/core';
export const LanguageCardQuestion = ({LanguageTranslated, TranslateLoading, QuestionType}) => {
    return(
        LanguageTranslated.map((code, index) => (
            <Card padding="xs" radius="md" key = {index} sx={{
                boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                cursor : 'pointer',
                transition : 'transform .2s ease-in-out',
                MozTransition : 'transform .2s ease-in-out',
                WebkitTransition : 'transform .2s ease-in-out',
                marginTop: -10,
                ":hover" : {
                    background : 'transparent',
                    WebkitTransform : 'scale(1.01)',
                    msTransform : 'scale(1.01)',
                    transform : 'scale(1.01)'
                }
            }}>
                {TranslateLoading ? 
                    <>
                        <Group position='center'>
                            <Loader color="violet" variant="dots" /><br/>
                        </Group>
                        <Group position='center'>
                            <i style={{fontFamily : 'poppins', fontSize : 12}}>Translating...</i>
                        </Group>
                    </>
                   
                    : 
                    <>
                        {QuestionType === '1' ? 
                        <>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}> {code.question}</Text>
                            </Group>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="green" sx={{fontFamily : 'poppins'}}>1. {code.answer}</Text>
                            </Group>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}>2. {code.choice1}</Text>
                            </Group>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}>3. {code.choice2}</Text>
                            </Group>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}>4. {code.choice3}</Text>
                            </Group>
                            <Group position='right'>
                                <Text fw = {700} variant="gradient" gradient={{ from: 'violet', to: 'darkblue' }} sx={{fontFamily : 'poppins', fontSize : 13}}>Translated in {code.code}</Text>
                            </Group>
                        </>
                        : 
                        <>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}> {code.question}</Text>
                            </Group>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="green" sx={{fontFamily : 'poppins'}}>1. {code.answer}</Text>
                            </Group>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}>2. {code.choice1}</Text>
                            </Group>
                            <Group position='right'>
                                <Text fw = {700} variant="gradient" gradient={{ from: 'violet', to: 'darkblue' }} sx={{fontFamily : 'poppins', fontSize : 13}}>Translated in {code.code}</Text>
                            </Group>
                        </>
                        }
                      
                    </>
                }
            </Card>
        ))
    )
}