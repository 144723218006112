import React, { useState, useEffect } from "react";
import { Modal, Group, Button, Text, Card, useMantineTheme, rem, Image, TextInput} from '@mantine/core'
import { IconUpload, IconCheck, IconX, IconPhoto, IconPhotoShare } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import Axios from 'axios';
import { Token } from '../../Utils'
export const UploadBibleCover = ({notifications, OpenModal, CloseModal, fetchBookCover}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('')
    const [FileName, setFileName] = useState('')
    const [ConfirmModal, setConfirmModal] = useState(false)
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AddBibleBookCoverTranslate, setAddBibleBookCoverTranslate] = useState('Add Bible Book Cover')
    const [DragImageTitleTranslate, setDragImageTitleTranslate] = useState('Drag images here or click to select files')
    const [DrageImageDescTranslate, setDrageImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [FileNameTranslate, setFileNameTranslate] = useState('File Name')
    const [PlaceholderFileName, setPlaceholderFileName] = useState('Enter File Name')
    const [BtnUploadBookCover, setBtnUploadBookCover] = useState('UPLOAD BOOK COVER')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Uploading')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Upload this Book Cover?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')

    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage])
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddBibleBookCoverTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddBibleBookCoverTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DrageImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDrageImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + FileNameTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setFileNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderFileName,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderFileName(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadBookCover,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadBookCover(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleImage = (files) =>{
        setImage(files[0])
    }
    const handleFileName = (event) => {
        setFileName(event.target.value)
    }
    const CancelConfirmation = () => {
        setConfirmModal(false)
    }
    const CallConfirmModal = () => {
        setConfirmModal(true)
    }
    const UploadBookCoverFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Book Cover',
            message: 'Book Cover will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const formData = new FormData()
        formData.append('image', image)
        Axios.post(''+APILink+'/api/BookCovers?fileName='+FileName+'', formData,{
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchBookCover()
                    CloseModal()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Book Cover was uploaded',
                        message: 'Book Cover successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })
    }
    return(
        <>
            <Modal 
                opened={OpenModal}
                onClose={CloseModal}
                title={AddBibleBookCoverTranslate} centered
                size="lg"
            >
                <Card shadow="xl" padding="lg">
                    <Card.Section>
                        {img.map((url, index) => <Image key = {index} style = {{ padding : '30px'}} maw={340} mx="auto" radius="sm" src={url} alt="Ads" />)}
                    </Card.Section>
                    <Card.Section >
                        <Dropzone
                            onDrop={
                                (files) => {
                                    handleImage(files)
                                    const selectedFIles =[];
                                    const targetFilesObject= [...files]
                                    targetFilesObject.map((file)=>{
                                        return selectedFIles.push(URL.createObjectURL(file))
                                    })
                                    setImg(selectedFIles);
                                } 
                            }
                            maxSize={3 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                        >
                            <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                                <Dropzone.Accept>
                                    <IconUpload
                                        size="3.2rem"
                                        stroke={1.5}
                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Accept>

                                <Dropzone.Reject>
                                    <IconX
                                        size="3.2rem"
                                        stroke={1.5}
                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Reject>

                                <Dropzone.Idle>
                                    <IconPhoto size="3.2rem" stroke={1.5} />
                                </Dropzone.Idle>

                                <div>
                                    <Text size="xl" inline sx = {{fontFamily : 'poppins'}}>
                                        {DragImageTitleTranslate}
                                    </Text>
                                    <Text sx = {{fontFamily : 'poppins'}} size="xs" color="dimmed" inline mt={14}>
                                       {DrageImageDescTranslate}
                                    </Text>
                                </div>
                            </Group>
                        </Dropzone>
                        <Group mt="md" mb="xs" grow>
                            <TextInput
                                placeholder={PlaceholderFileName}
                                label={FileNameTranslate}
                                radius="sm"
                                size="md"
                                withAsterisk
                                onChange={handleFileName}
                                sx = {{fontFamily : 'poppins'}}
                            />
                        </Group>
                        <Group mt="md" mb="xs" grow>
                            <Button sx = {{fontFamily : 'poppins'}} onClick={CallConfirmModal} leftIcon = {<IconPhotoShare/>} color="violet" size="md" uppercase>
                                {BtnUploadBookCover}
                            </Button>
                        </Group>
                    </Card.Section>
                </Card>
            </Modal>
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
                <Group>
                    <Group grow>
                        <Text sx = {{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                    </Group>
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {UploadBookCoverFunction} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
                </Group>
            </Modal>
        </>
    )
}