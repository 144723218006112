import { useEffect, useState, useRef } from 'react'
import { SideBar } from '../Component'
import { IconAt, IconCamera, IconCheck, IconEdit, IconLockAccess, IconLockCheck, IconLogout, IconMenu2, IconPhone, IconUserEdit, IconX, IconLock, IconLockCode, IconSearch, IconLanguage } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom'
import { notifications } from "@mantine/notifications"
import { Image,Menu, Avatar, ActionIcon, Group, Text, Modal, Input, Divider, Button, TextInput, PasswordInput, SimpleGrid, Stack, Skeleton} from '@mantine/core';
import { api, Token, RefreshToken, GetNewToken, Logout } from '../../Utils';
import SideBarSearchItem from '../../Helpers/SideBarSearchItem'
import SideBarSettingsItem from '../../Helpers/SideBarSettingsItems';
import TheOneLogo from '../../Assets/images/logo/main-logo.png'
import TheOneLogoCircle from '../../Assets/images/logo/circular-logo.png'
import './NewNavBar.css'

import Axios from 'axios';
const DefaultLanguage = localStorage.getItem('SelectedCode')
export const NewNavBar = () => {
    const MyToken = localStorage.getItem("__SESS__U__TOWA__");
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [ToggleMenu, SetToggleMenu] = useState(false)
    const [UserProfilePic, SetUserProfilePic] = useState("")
    const [UserEmail, SetUserEmail] = useState("")
    const [FullName, setFullName] = useState("")
    const [ContactNumber, setContactNumber] = useState("")
    const [SearchModal, setSearchModal] = useState(false)
    const [UpdateAccountModal, setUpdateAccountModal] = useState(false);
    const [search, setSearch] = useState('');
    const [ClickEdit, setClickEdit] = useState('');
    const [img, setImg] = useState([]);
    const [NewFullName, setNewFullName] = useState('');
    const [NewContact, setNewContact] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [CurrentPassword, setCurrentPassword] = useState('');
    const [searchLanguage, setsearchLanguage] = useState('');
    const [Code, setCode] = useState([])
    const [isLoadingProfile, setisLoadingProfile] = useState(true)
    const [isLoadingLanguage, setisLoadingLanguage] = useState(true)


    //! TRANSLATION
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    useEffect(() => {
        Token !== null && GetUser()
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchLanguages();
    }, [DefaultLanguage])

    const fetchLanguages = async() => {
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setCode(result.data)
            setisLoadingLanguage(false)
        }).catch(error => {
        })
    } 
    const SearchModalContent = (name, desc) => {
        const [NameTranslate, setNameTranslate] = useState('')
        const [DescriptionTranslate, setDescriptionTranslate] = useState('')
        const [isLoading, setisLoading] = useState(true)
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + name.name,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + name.desc,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setisLoading(false)
                setDescriptionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        return(
            <>
                {
                isLoading ? 
                    <Stack spacing={8}>
                        <Skeleton height={10} width="200px" radius="xl" /> 
                        <Skeleton height={10} width="500px" radius="xl" /> 
                    </Stack>
                    : 
                    <>
                        <Text  fz="lg" fw={700} color = '#232323' sx={{ lineHeight: 0, fontFamily : 'poppins'}}>{NameTranslate}</Text>
                        <Text fz="sm" fw={600}  color = "#5e5e5e" lineClamp={1} sx = {{fontFamily : 'poppins'}}>{DescriptionTranslate}</Text>
                    </>
                }
            </>
        )
    }
    const OtherAppContent = (name) => {
        const [OtherAppNameTranslate, setOtherAppNameTranslate] = useState('')
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + name.name,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setOtherAppNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        
        return(
            <Text fw={600} fz="sm" sx={{color : '#4a1e9e'}}>{OtherAppNameTranslate}</Text>
        )
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const handleSearchLanguage = (event) => {
        setsearchLanguage(event.target.value);
    };
    const handleChangeNewFullName = (event) => {
        setNewFullName(event.target.value);
    };
    const handleChangeNewContact = (event) => {
        setNewContact(event.target.value);
    };
    const handleChangeNewPassword = (event) => {
        setNewPassword(event.target.value);
    };
    const handleChangeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
    };
    const handleChangeCurrentPassword = (event) => {
        setCurrentPassword(event.target.value);
    };
    const GetUser = async () => {
        const UserProfilesEP = "/userprofiles/me"
        await api.get(UserProfilesEP).then(result => {
            const ProfilePic = result.data.profileLink ? result.data.profileLink : TheOneLogoCircle
            const Email = result.data.email
            const Fullname = result.data.fullName
            const ContactNumber = result.data.contactNumber
            SetUserProfilePic(ProfilePic)
            SetUserEmail(Email ? Email : "No Email")
            setFullName(Fullname)
            setContactNumber(ContactNumber)
            setisLoadingProfile(false)
            localStorage.setItem("__SESS__UPP__TOWA__", ProfilePic)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
        })
    }
    const NavigateURL = (url) => {
        navigate('/'+url);
        setSearchModal(false);
        setSearch('');
    };
    const NavigateAppURL = (url) => {
        navigate('/'+url);
    }
    const ClickedEditFunction = (Status) => {
            if(Status === 'EditAccount'){
                setClickEdit('EditAccount')
            }else{
                setClickEdit('EditPassword')
            }
    }
    const handleClick = () => {
        inputRef.current.click();
    };
    const handleFileChange = event => {
        const selectedFIles =[];
        const targetFilesObject= [event.target.files[0]]
        targetFilesObject.map((file)=>{
            return selectedFIles.push(URL.createObjectURL(file))
        })
        setImg(selectedFIles)
    };
    const ChangePassword = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Password',
            message: 'Word of the Day will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const ChangePasswordEP = `/Auth/ChangePassword`
        api.post(ChangePasswordEP, {
            currentPassword : CurrentPassword,
            newPassword : NewPassword,
            confirmPassword : ConfirmPassword
        }).then(result => {
            if(result['status'] === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    setUpdateAccountModal(false)
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Password was Updated',
                        message: 'Password successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }else{}
        }).catch(error =>{
            setTimeout(() => {
                notifications.update({
                    id: 'load-data',
                    color: 'red',
                    title: 'Error Updating Password',
                    message: "Passwords must have at least one lowercase ('a'-'z').",
                    icon: <IconX size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000);
        })
    }
    const UpdateMyAccount = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Account',
            message: 'Account will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UpdateAccountEP = `/UserProfiles/Info?fullName=`+NewFullName+`&contactNumber=`+NewContact+``
        api.put(UpdateAccountEP, {
        }).then(result => {
        if(result['status'] === 200){
            setTimeout(() => {
                //! RESET INPUTS
                setUpdateAccountModal(false)
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Account was updated',
                    message: 'Account successsfully updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const FunctionSelected = (code) => {
        localStorage.setItem('SelectedCode', code)
        window.location.reload(false);
    }

    return (
        <>
        <header className="header">
            <div className="header__container">
                {MyToken === null ? 
                ''
                :
                <>
                    <div className="HeaderControls">
                        <ActionIcon size="md" radius="xs" sx={{
                              transition : 'transform .2s ease-in-out',
                              MozTransition : 'transform .2s ease-in-out',
                              WebkitTransition : 'transform .2s ease-in-out',
                            ":hover" : {
                                background : 'transparent',
                                WebkitTransform : 'scale(1.3)',
                                msTransform : 'scale(1.3)',
                                transform : 'scale(1.3)'
                            }
                        }} onClick={() => {setSearchModal(true)}}>
                            <IconSearch size="2.125rem" color = "#0a0908" style={{display : 'block'}}/>
                        </ActionIcon>
                        {/* <Menu position="bottom-end" withArrow arrowPosition="center" offset={2} >
                            <Menu.Target>
                                <ActionIcon size="md" radius="xs" sx={{
                                    transition : 'transform .2s ease-in-out',
                                    MozTransition : 'transform .2s ease-in-out',
                                    WebkitTransition : 'transform .2s ease-in-out',
                                    ":hover" : {
                                        background : 'transparent',
                                        WebkitTransform : 'scale(1.3)',
                                        msTransform : 'scale(1.3)',
                                        transform : 'scale(1.3)'
                                    }
                                }} >
                                    <IconApps size="2.125rem" color = "white"/>
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown sx={{ borderRadius : '5px', boxShadow : '1px 2px 5px 1px gray'}}>
                                <Menu.Item sx={{padding : '20px', borderRadius : '5px'}}>
                                    <SimpleGrid cols={3} spacing="xs" verticalSpacing="sm">
                                        {SideBarSettingsItem.map((sideBarItem, indx) => 
                                            <Group grow sx={{
                                                borderRadius: '5px',
                                                padding: '8px',
                                                transition : 'transform .2s ease-in-out',
                                                MozTransition : 'transform .2s ease-in-out',
                                                WebkitTransition : 'transform .2s ease-in-out',
                                                ":hover" : {
                                                    background : '#ddcbff',
                                                    WebkitTransform : 'scale(1.1)',
                                                    msTransform : 'scale(1.1)',
                                                    transform : 'scale(1.1)'
                                                }
                                            }}
                                            key={indx}
                                            onClick={() => {NavigateAppURL(sideBarItem.link)}}
                                            >
                                                
                                                <Stack align="center" spacing={-10} >
                                                    <Group position='center'>{sideBarItem.icon}</Group>
                                                    <OtherAppContent name = {sideBarItem.name}/>
                                                </Stack> 
                                            </Group>
                                        )}
                                    </SimpleGrid>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu> */}
                        <Menu position="bottom-end" withArrow arrowPosition="center" offset={2} >
                            <Menu.Target>
                                <ActionIcon size="md" radius="xs" sx={{
                                    transition : 'transform .2s ease-in-out',
                                    MozTransition : 'transform .2s ease-in-out',
                                    WebkitTransition : 'transform .2s ease-in-out',
                                    ":hover" : {
                                        background : 'transparent',
                                        WebkitTransform : 'scale(1.3)',
                                        msTransform : 'scale(1.3)',
                                        transform : 'scale(1.3)'
                                    }
                                }}>
                                    <IconLanguage size="2.125rem" color = "#0a0908"/>
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown sx={{ borderRadius : '5px', boxShadow : '1px 2px 5px 1px gray'}}>
                                <Group grow sx={{padding : 10}}>
                                    <TextInput
                                        icon={<IconSearch/>}
                                        radius="sm"
                                        size="md"
                                        placeholder="Search Language"
                                        onChange={ handleSearchLanguage }
                                    />
                                </Group>
                                <Menu.Item sx={{padding : '20px', borderRadius : '5px'}}>
                                   <SimpleGrid cols={1} spacing="xs" sx={{width : '100%', height : 'auto'}}>
                                    {Code.filter((SearchLanguage) => {
                                        if(searchLanguage === ""){
                                            return SearchLanguage
                                        }else if(SearchLanguage.name.toLowerCase().includes(searchLanguage.toLowerCase())){
                                            return SearchLanguage
                                        } 
                                    }).map((item, index) => 
                                     isLoadingLanguage ? 
                                        <Group key={index}>
                                            <Skeleton height={30} width="220px" radius="sm" /> 
                                        </Group>
                                        : 
                                        <Group grow sx={{
                                            borderRadius: '5px',
                                            padding: '2px',
                                            transition : 'transform .2s ease-in-out',
                                            MozTransition : 'transform .2s ease-in-out',
                                            WebkitTransition : 'transform .2s ease-in-out',
                                           ":hover" : {
                                            background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)',
                                            WebkitTransform : 'scale(1.1)',
                                            msTransform : 'scale(1.1)',
                                            transform : 'scale(1.1)'}
                                            }}
                                            key={index}
                                        >   
                                            {DefaultLanguage === item.languageCode ? 
                                                <Group spacing={3} sx={{background : '#ddcbff', borderRadius : '5px', padding: '2px'}}>
                                                    <IconCheck color='green'/>
                                                    <Text fw={700} fz="md" sx={{fontFamily : 'poppins'}} onClick = {() => {FunctionSelected(item.languageCode)}} >{item.name}</Text> 
                                                </Group>
                                                :
                                                <Text fw={700} fz="md" sx={{fontFamily : 'poppins'}} onClick = {() => {FunctionSelected(item.languageCode)}}>{item.name}</Text> 
                                            }
                                            
                                        </Group>
                                    
                                      
                                    )}
                                      
                                    </SimpleGrid>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                        <Menu position="bottom-end" withArrow arrowPosition="center" >
                            <Menu.Target>
                                <Avatar 
                                radius="xl" 
                                src={UserProfilePic} 
                                style={{cursor: "pointer"}} 
                                size=''
                                sx={{
                                transition : 'transform .2s ease-in-out',
                                MozTransition : 'transform .2s ease-in-out',
                                WebkitTransition : 'transform .2s ease-in-out',
                              ":hover" : {
                                  background : 'transparent',
                                  WebkitTransform : 'scale(1.1)',
                                  msTransform : 'scale(1.1)',
                                  transform : 'scale(1.1)'
                              }}}
                                 />
                            </Menu.Target>
                            <Menu.Dropdown sx={{ borderRadius : '5px', boxShadow : '1px 2px 5px 1px gray'}}>
                                <Menu.Item sx={{padding : '10px', borderRadius : '5px'}}>
                                    {isLoadingProfile ? 
                                     <Group position='flex-start'>
                                        <Skeleton height={100} circle mb="xl" />
                                        <Stack spacing={8}>
                                            <Skeleton height={10} width="200px" radius="xl" sx={{lineHeight: 0 }} /> 
                                            <Skeleton height={10} width="180px" radius="xl" /> 
                                            <Skeleton height={10} width="160px" radius="xl" /> 
                                         </Stack>
                                     </Group> 
                                    : 
                                    <Group>
                                        <Avatar 
                                            src={UserProfilePic} 
                                            style={{cursor: "pointer"}} 
                                            sx={{width : 100, height : 100, borderRadius : '50px'}}
                                        />
                                        <Stack spacing={8}>
                                            <Text color="black" fw={700} sx={{ lineHeight: 0 , fontSize : 23, fontFamily : 'poppins'}}>{FullName}</Text>
                                            <Text fz="sm" fw={500} color="dimmed" sx={{ fontFamily : 'poppins'}} >
                                                {UserEmail}
                                            </Text>
                                            <Text fz="sm" fw={500} color="dimmed"sx={{ lineHeight: 0, fontFamily : 'poppins' }}>
                                                {ContactNumber}
                                            </Text>
                                        </Stack>
                                    </Group>
                                    }
                                   
                                </Menu.Item>
                                <Divider/>
                                <Menu.Item sx={{padding : '10px', borderRadius : '2px'}}  onClick={() => {setUpdateAccountModal(true)}}>
                                    <Group position='left' spacing={5}   sx={{
                                        transition : 'transform .2s ease-in-out',
                                        MozTransition : 'transform .2s ease-in-out',
                                        WebkitTransition : 'transform .2s ease-in-out',
                                    ":hover" : {
                                        background : 'transparent',
                                        WebkitTransform : 'scale(1.02)',
                                        msTransform : 'scale(1.02)',
                                        transform : 'scale(1.02)'
                                    }}}>
                                        <IconUserEdit color='black' size={25}/>
                                        <Text fz="md" color='black' fw={700} sx={{ lineHeight: 0, fontFamily : 'poppins' }}>My Account</Text>
                                    </Group>
                                </Menu.Item>
                                <Menu.Item sx={{padding : '10px', borderRadius : '2px'}} onClick={Logout}>
                                    <Group position='left' spacing={5} sx={{
                                        transition : 'transform .2s ease-in-out',
                                        MozTransition : 'transform .2s ease-in-out',
                                        WebkitTransition : 'transform .2s ease-in-out',
                                    ":hover" : {
                                        background : 'transparent',
                                        WebkitTransform : 'scale(1.02)',
                                        msTransform : 'scale(1.02)',
                                        transform : 'scale(1.02)'
                                    }}}>
                                        <IconLogout color='black' size={25}/>
                                        <Text fz="md" color='black' fw={700} sx={{ lineHeight: 0, fontFamily : 'poppins' }}>Sign Out</Text>
                                    </Group>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </div> 
                    <Link to={Token ? "/" : "/login"}>
                        <Image maw={50} src={TheOneLogo} alt="TheOne" className="header__logo" />
                    </Link>
                    <div className="header__toggle" onClick={() => SetToggleMenu(!ToggleMenu)}>
                        {ToggleMenu ? <IconX/> : <IconMenu2/>}
                    </div>
                </>
                }  
            </div> 
        </header>
        <SideBar IsSideBarOpen={ToggleMenu}  ToggleSideBar={() => SetToggleMenu(!ToggleMenu)} /> 
        <Modal 
            opened={SearchModal}
            onClose={() => {setSearchModal(false)}}
            size="xl"
            overlayProps={{
                opacity: 0.55,
                blur: 3,
            }}
            transitionProps={{ transition: 'rotate-left' }}
            withCloseButton = {false}
        >   
        <Group grow>
            <Input
                icon={<IconSearch/>}
                variant="unstyled"
                placeholder="Search Item"
                radius="xs"
                size="md"
                onChange={ handleSearch }
                sx={{transition : 'transform .2s ease-in-out',
                MozTransition : 'transform .2s ease-in-out',
                WebkitTransition : 'transform .2s ease-in-out',
                fontFamily : 'poppins',
               ":hover" : {
               WebkitTransform : 'scale(1.05)',
               msTransform : 'scale(1.05)',
               transform : 'scale(1.05)'}}}
            />
        </Group>
        <Divider/>
        <br></br>
        
        {SideBarSearchItem.filter((SearchItem) => {
                    if(search === ""){
                        return SearchItem
                    }else if(SearchItem.name.toLowerCase().includes(search.toLowerCase())){
                        return SearchItem
                    }
        }).map((sideBarItem, indx) => 
        <div key = {indx}>  
            <Group  spacing = {5} sx={{ paddingTop : '10px', cursor : 'pointer', 
             transition : 'transform .2s ease-in-out',
             MozTransition : 'transform .2s ease-in-out',
             WebkitTransition : 'transform .2s ease-in-out',
            ":hover" : {
            background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)',
            WebkitTransform : 'scale(1.02)',
            msTransform : 'scale(1.02)',
            transform : 'scale(1.02)'}
            }} onClick={
                sideBarItem.link === 'changepassword' || sideBarItem.link === 'accountinformation' ? 
                () => {setUpdateAccountModal(true); setSearchModal(false)}
                :
                () => {NavigateURL(sideBarItem.link)}
                }>
                <span style={{color: '#212529'}}>{sideBarItem.icon}</span>
                <Stack spacing={8}>
                    <SearchModalContent name = {sideBarItem.name} desc = {sideBarItem.description} />
                </Stack>
            </Group>
            <Divider/>
        </div>
        )}
        </Modal>
        <Modal 
            opened={UpdateAccountModal}
            onClose={() => {setUpdateAccountModal(false)}}
            size="lg"
            overlayProps={{
                opacity: 0.55,
                blur: 3,
            }}
            transitionProps={{ transition: 'fade' }}
            centered
        >  
            <Group position='center' sx={{padding : '10px'}}>
                {ClickEdit === 'EditPassword' ?
                    <Avatar 
                        src={UserProfilePic} 
                        style={{cursor: "pointer"}} 
                        sx={{width : 200, height : 200, borderRadius : '100px', boxShadow : '1px 2px 5px 3px gray'}}
                       
                    />
                    :
                    ClickEdit === 'EditAccount' ?
                        <div style={{position: 'relative'}}>
                            <input
                                style={{display: 'none'}}
                                ref={inputRef}
                                type="file"
                                onChange={handleFileChange}
                            />
                            {
                                img.length === 0 ?
                                <Avatar
                                    src={UserProfilePic} 
                                    style={{cursor: "pointer"}} 
                                    sx={{width : 200, height : 200, borderRadius : '100px', boxShadow : '1px 2px 5px 3px gray'}}
                                />
                                :
                                img.map((url, index) =>
                                    <Avatar
                                        key = {index} 
                                        src={url} 
                                        style={{cursor: "pointer"}} 
                                        sx={{width : 200, height : 200, borderRadius : '100px', boxShadow : '1px 2px 5px 3px gray'}}
                                    />
                                )
                            }
                            <ActionIcon size="xl" radius="xl" variant="filled" sx={{
                                position: 'absolute',
                                top: '80%',
                                left: '65%',
                                background : 'rgb(71 46 121)',
                                ":hover" : {
                                    background : 'rgb(39, 20, 77)'
                                }}}
                                onClick={handleClick}
                                >
                                <IconCamera size={28} stroke={2} />
                            </ActionIcon>
                        </div>
                    :
                    <Avatar 
                        src={UserProfilePic} 
                        style={{cursor: "pointer"}} 
                        sx={{width : 200, height : 200, borderRadius : '100px', boxShadow : '1px 2px 5px 3px gray'}}
                    />
                }
            </Group>
            <Group position='center'>
                <Group spacing={3}>
                    <Text color="black" fw={700} sx={{fontSize : '40px', fontFamily : 'poppins'}}>{FullName}</Text>
                </Group>
            </Group> 
            <Group position='center'>
                <Group spacing={3}>
                    <IconAt color='black' stroke={2}/>
                    <Text color="black" fw={600} sx={{fontSize : '15px', lineHeight : 0, fontFamily : 'poppins'}}>{UserEmail}</Text>
                </Group>
                <Group spacing={3}>
                    <IconPhone color='black' stroke={2}/>
                    <Text color="black" fw={600} sx={{fontSize : '15px', lineHeight : 0, fontFamily : 'poppins'}}>{ContactNumber}</Text>
                </Group>
            </Group>
            <Group position='center' sx={{padding : '25px'}}>
                <Button leftIcon = {<IconEdit/>} variant="light" radius="sm" size="sm" color='violet' sx={{color : 'rgb(71 46 121)', fontFamily : 'poppins'}} onClick={() => {ClickedEditFunction('EditAccount')}}>
                    Edit Account
                </Button>
                <Button leftIcon = {<IconLockAccess/>} variant="light" radius="sm" size="sm" color='violet' sx={{color : 'rgb(71 46 121)', fontFamily : 'poppins'}} onClick={() => {ClickedEditFunction('EditPassword')}}>
                    Change Password
                </Button>
            </Group> 
            <Divider/>
            <br></br>
            {
                ClickEdit === 'EditPassword' ?
                <div className = "EditPasswordContent">
                    <PasswordInput
                      placeholder="Enter Current Password"
                      label="Current Password"
                      size="md"
                      withAsterisk
                      onChange={handleChangeCurrentPassword}
                      sx = {{fontFamily : 'poppins'}}
                      icon={<IconLock color='black'/>}
                  />
                  <PasswordInput
                      placeholder="Create New Password"
                      label="New Password"
                      description="Password must include at least one letter, number and special character"
                      size="md"
                      withAsterisk
                      onChange={handleChangeNewPassword}
                      sx = {{fontFamily : 'poppins'}}
                      icon={<IconLock color='black'/>}
                  />
                   <PasswordInput
                      placeholder="Confirm New Password"
                      label="Confirm New Password"
                      size="md"
                      withAsterisk
                      onChange={handleChangeConfirmPassword}
                      sx = {{fontFamily : 'poppins'}}
                      error = {
                        NewPassword != ConfirmPassword ?
                        'Confirm Password is required'
                        :
                        ''
                      }
                      icon={
                        NewPassword === ''?
                        <IconLockCode color='black'/>
                        :
                        NewPassword != ConfirmPassword ?
                        <IconX color='red'/>
                        :
                        <IconCheck color='green'/>
                      }
                  />
                  <br></br>
                  <Group grow>
                      <Button onClick={ChangePassword} leftIcon = {<IconLockCheck/>} variant="filled" radius="sm" size="md" sx={{background : 'rgb(71 46 121)', fontFamily : 'poppins', ":hover" : {
                                    background : 'rgb(39, 20, 77)'
                                }}}>
                          Update New Password
                      </Button>
                  </Group>
                </div>
                :
                ClickEdit === 'EditAccount' ?
                <div className = "EditProfileContent">
                    <Group grow>
                        <TextInput
                            placeholder="Enter new Name"
                            label="Full Name"
                            variant="filled"
                            size="md"
                            withAsterisk
                            onChange={handleChangeNewFullName}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <Group grow>
                        <TextInput
                            placeholder="Enter new Contact Number"
                            label="Contact Number"
                            variant="filled"
                            size="md"
                            withAsterisk
                            onChange={handleChangeNewContact}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <br></br>
                    <Group grow>
                        <Button onClick={UpdateMyAccount} leftIcon = {<IconEdit/>} variant="filled" radius="sm" size="md" sx={{background : 'rgb(71 46 121)',fontFamily : 'poppins',  ":hover" : {
                                    background : 'rgb(39, 20, 77)'
                                }}}>
                            Update Account
                        </Button>
                    </Group>
                </div>
                :
                ''
            }  
        </Modal>
        </>
    )
}
