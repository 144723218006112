import React, { useState, useEffect } from "react";
import { useDisclosure } from '@mantine/hooks';
import DataTable from 'react-data-table-component';
import { TextInput, Group, Text, ActionIcon, Modal, Button, Avatar, Stack, PasswordInput, Dialog, Textarea, Menu, Pagination } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconEye, IconCheck, IconKey, IconAt, IconPhone, IconSend, IconDotsVertical, IconArrowRight, IconArrowLeft, IconPower} from '@tabler/icons-react';
import UserDefaultLogo from "../../Assets/images/logo/circular-logo.png"
import Axios from 'axios';
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addLocale(en)
export const ListofUsers = ({notifications}) => {
    const countPerPage = 10;
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [UserID, setUserID] = useState('')
    const [loading, setLoading] = useState(false);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [ResetPasswordModal, setResetPasswordModal] = useState(false);
    const [ViewProfileModal, setViewProfileModal] = useState(false);
    const [NewPassword, setNewPassword] = useState('')
    const [Message, setMessage] = useState('')
    const [User, setUser] = useState([]);
    const [opened, { toggle, close }] = useDisclosure(false);
    const [NextPage, setNextPage] = useState('');
    const [PreviousPage, setPreviousPage] = useState('')
    const [PageNumber, setPageNumber] = useState(0)
    let obj = {}
    var sourceLang = 'en';
    const [TotalCountUser, setTotalCountUser] = useState(0)
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SearchUserTranslate" : 'Search User',
        "UsersTranslate" : 'USERS',
        "ActionTranslate" : 'ACTION',
        "AssignAdministratorTranslate" : 'Assign as Administrator',
        "ResetPasswordTranslate" : 'Reset Password',
        "ViewUserTranslate" : 'View User'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchUsers();
        fetchTranslation();
        fetchCountUser();
    }, [DefaultLanguage, TotalCountUser]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const handleNewPassword = (event) => {
        setNewPassword(event.target.value);
    };
    const handleMessge = (event) => {
        setMessage(event.target.value)
    }
    const fetchCountUser = async() => {
        const CountUserEP = `/UserProfiles/All/Count`
        await api.get(CountUserEP).then((result) => {

            setTotalCountUser(result.data)
            setLoading(false);
        }).catch(error => {
        })
    }
    const fetchUsers = async () => {
        setLoading(true);   
        const ListUserEP = `/UserProfiles/All?PageNumber=1&PageSize=20`
        await api.get(ListUserEP).then((result) => {
            setData(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const CallNextPage = async() => {
        Axios({
            url: NextPage,
            method: 'get',
            headers: {
                'Authorization': 'Bearer '+Token,
                'My-Custom-Header': 'foobar'
            }
        }).then(result => {
            setData(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setPreviousPage(result.data.previousPage)
        }).catch(err => {
            console.log(err);
        });
    }
    const CallPreviousPage = async() => {
        Axios({
            url: PreviousPage,
            method: 'get',
            headers: {
                'Authorization': 'Bearer '+Token,
                'My-Custom-Header': 'foobar'
            }
        }).then(result => {
            setData(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setPreviousPage(result.data.previousPage)
        }).catch(err => {
            console.log(err);
        });
    }
    const CallFirstPage = async() => {
        setLoading(true);   
        const ListUserEP = `/UserProfiles/All?PageNumber=1&PageSize=20`
        await api.get(ListUserEP).then((result) => {
            setData(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CallLastPage = async() => {
        setLoading(true);   
        const ListUserEP = `/UserProfiles/All?PageNumber=`+TotalCountUser / 20+`&PageSize=20`
        await api.get(ListUserEP).then((result) => {
            setData(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const ClickPageNumber = async(PageNumber) => {
        setLoading(true);   
        const ListUserEP = `/UserProfiles/All?PageNumber=`+PageNumber+`&PageSize=20`
        await api.get(ListUserEP).then((result) => {
            setData(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const SearchUserFunction = async() => {
        setLoading(true);   
        const ListUserEP = `/UserProfiles/All?keyword=`+search+`&PageNumber=1&PageSize=10`
        await api.get(ListUserEP).then((result) => {
            setData(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setUserID(ID)
    }
    const CallResetPasswordModal = async(ID) => {
        setResetPasswordModal(true) 
        setUserID(ID)
    }
    const CallViewProfileModal = async(ID) => {
        setViewProfileModal(true)
        setLoading(true);   
        const UserEP = `/UserProfiles/`+ID+``
        await api.get(UserEP).then((result) => {
            setUser(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const AssignAdministratorFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Assign Administrator',
            message: 'Administrator will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const AssignEP = `/Roles/Assign/`+UserID+``
        await api.post(AssignEP,{
        }).then(result => {
            if(result.status === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchUsers()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Administrator was set',
                        message: 'Administrator successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const RessetPasswordFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Reset Password',
            message: 'Reset Password will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const AssignEP = `/Auth/PasswordReset/`+UserID+`?newPassword=`+NewPassword+``
        await api.post(AssignEP,{
        }).then(result => {
            if(result.status === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchUsers()
                    CallResetPasswordModal(false)
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Password was set',
                        message: 'Password successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const NotifyUser = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Notify User',
            message: 'Message will be send, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const AssignEP = `/Notifications/Message`
        await api.post(AssignEP,{
            'email' : User.email,
            'message' : Message
        }).then(result => {
            if(result.status === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    setViewProfileModal(false)
                    close()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Message was sent',
                        message: 'Message successsfully send',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const RestrictUser = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Update User',
            message: 'Restriction will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RestrictEP = `/UserProfiles/Restriction/`+ID+``
        await api.put(RestrictEP,{
        }).then(result => {
            if(result.status === 204){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchUsers()
                    setViewProfileModal(false)
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Restriction was set',
                        message: 'Restriction successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const UnRestrictUser = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Update User',
            message: 'Unrestriction will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RestrictEP = `/UserProfiles/Restriction/`+ID+``
        await api.put(RestrictEP,{
        }).then(result => {
            if(result.status === 204){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchUsers()
                    setViewProfileModal(false)
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Unrestriction was set',
                        message: 'Unrestriction successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{DataTranslate.UsersTranslate}</Text>,
            cell: row => 
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group>
                    <Avatar  src={row.profilePicture ? row.profilePicture : UserDefaultLogo} 
                        sx={{width : 50, height : 50, borderRadius : 50}}
                        withPlaceholder
                        placeholder={<Text align="center">No Image Found</Text>} 
                    />
                    <Stack spacing={1} justify="flex-start" className="ListOfUserContent">
                        <Text fw={700} fz="lg" sx={{lineHeight: 1, color : '#4a1e9e', wordBreak : 'break-all', fontFamily : 'poppins'}}>{row.fullName}</Text>
                        <Text fw={600} fz="sm" sx={{fontFamily : 'poppins'}}>{row.email === '' ? 'no email address' : row.email}</Text>
                        <Text fw = {600} fz="xs" color="black" sx={{fontFamily : 'poppins'}} >
                            Registered <ReactTimeAgo date={Date.parse(row.createdDate)} />
                        </Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text sx={{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{DataTranslate.ActionTranslate}</Text></Menu.Label>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconCheck/>} onClick={() => {CallConfirmModal(row.id)}} color="#6c2be2">{DataTranslate.AssignAdministratorTranslate}</Menu.Item>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconKey/>} onClick={() => {CallResetPasswordModal(row.id)}}>{DataTranslate.ResetPasswordTranslate}</Menu.Item>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconEye/>} onClick={() => {CallViewProfileModal(row.id)}}>{DataTranslate.ViewUserTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ]; 
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group spacing={5}>
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    placeholder={DataTranslate.SearchUserTranslate}
                    onChange={ handleSearch }
                />
                <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="lg" 
                    variant="filled" 
                    onClick={SearchUserFunction}
                >
                    <IconSearch/>
                </ActionIcon>
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={data}
                progressPending={loading}
                 responsive = ""
            />
            <Group position="right">
                <Pagination
                    siblings={3}
                    total={ TotalCountUser/ 20 } 
                    color="violet" 
                    size="lg" 
                    radius="xl" 
                    withEdges
                    onNextPage={CallNextPage}
                    onPreviousPage={CallPreviousPage}
                    onFirstPage={CallFirstPage}
                    onLastPage={CallLastPage}
                    onChange={(val) => ClickPageNumber(val)}
                >
                </Pagination>
            </Group>
            <br/>
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Assigning Administrator" centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>Are you sure you want to Assign this as a Administrator?</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {AssignAdministratorFunction} color="teal" size="sm" uppercase> Confirm </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> Cancel </Button>
                </Group>
            </Modal>
            <Modal 
                opened={ResetPasswordModal}
                onClose={() => {setResetPasswordModal(false)}}
                title="Reset Password" 
                size="sm"
                centered
            >
                <Group grow>
                    <PasswordInput
                        placeholder="enter new password"
                        label="New Password"
                        description="Password must include at least one letter, number"
                        size="md"
                        withAsterisk
                        onChange={handleNewPassword}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <br/>
                <Group grow>
                    <Button sx={{fontFamily : 'poppins'}} color="violet" size="md" leftIcon={<IconKey/>} onClick={RessetPasswordFunction}>
                        RESSET PASSWORD
                    </Button>
                </Group>
            </Modal>
            <Modal 
                opened={ViewProfileModal}
                onClose={() => {setViewProfileModal(false)}}
                size="xl"
                centered
            >
                <Group position='center' sx={{padding : '10px'}}>
                    <Avatar 
                        src={User.profileLink ? User.profileLink : UserDefaultLogo} 
                        style={{cursor: "pointer"}} 
                        sx={{width : 200, height : 200, borderRadius : '100px', boxShadow : '1px 2px 5px 3px gray'}}
                    />
                </Group>
                <Group position='center'>
                    <Group spacing={3}>
                        <Text color="black" fw={700} sx={{fontSize : '40px', fontFamily : 'poppins'}}>{User.fullName}</Text>
                    </Group>
                </Group> 
                <Group position='center'>
                    <Group spacing={3}>
                        <IconAt color='black' stroke={2}/>
                        <Text color="black" fw={600} sx={{fontSize : '15px', lineHeight : 0, fontFamily : 'poppins'}}>{User.email ? User.email : 'No Email Address'}</Text>
                    </Group>
                    <Group spacing={3}>
                        <IconPhone color='black' stroke={2}/>
                        <Text color="black" fw={600} sx={{fontSize : '15px', lineHeight : 0, fontFamily : 'poppins'}}>{User.contactNumber? User.contactNumber : 'No Contact Number'}</Text>
                    </Group>
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} variant="filled" color="green" leftIcon={<IconSend/>} onClick={toggle}>
                        NOTIFY USER
                    </Button>
                    {
                        User.isRestricted ? 
                        <Button sx={{fontFamily : 'poppins'}} variant="filled" color="blue" leftIcon={<IconCheck/>} onClick={() => {UnRestrictUser(User.id)}}>
                            UNRESTRICT USER
                        </Button>
                        :
                        <Button sx={{fontFamily : 'poppins'}} variant="filled" color="red" leftIcon={<IconPower/>} onClick={() => {RestrictUser(User.id)}}>
                            RESTRICT USER
                        </Button>
                    }
                </Group>
                <Dialog opened={opened} withCloseButton onClose={close} size="lg" radius="sm">
                    <Group grow>
                        <Textarea
                            placeholder="Enter your message"
                            label="Message"
                            size="md"
                            withAsteris
                            minRows={5}
                            onChange={handleMessge}
                            sx={{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <br></br>
                    <Group position="right"> 
                        <Button sx={{fontFamily : 'poppins'}} onClick={NotifyUser} leftIcon={<IconSend/>} color="violet" >SEND MESSAGE</Button>
                    </Group>
                </Dialog>
            </Modal>
        </div>
    )
}