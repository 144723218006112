import React, { useState, useEffect, useRef } from "react";
import { Button, Group, Modal } from '@mantine/core'
import { api } from '../../Utils'
import { IconCheck, IconUpload } from '@tabler/icons-react';
import Axios from 'axios'; 
import XMLParser from "react-xml-parser"
export const UploadBibleBook = ({notifications, OpenModal, CloseModal, fetchBibleBooks}) => {
    const ActiveVersion =  localStorage.getItem('ActiveVersion')
    const [data, setData] = useState([])
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UploadBibleTranslate, setUploadBibleTranslate] = useState('Upload Bible')
    const [BtnUploadBible, setBtnUploadBible] = useState('UPLOAD BIBLE')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadBibleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadBibleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadBible,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadBible(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleFileUpload = async(e) => {
        const file = e.target.files[0];
        var xml = new XMLParser().parseFromString(await file.text());
        setData(xml.children)
    };
    const UploadBibleBook = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Bible',
            message: 'Bible will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        let CurrentBookID = ''
        let CurrentChapterID = ''
        for (let row of data) {
             //! Uploading Bible Books
             const UploadBookEP = `/BibleBooks?versionId=`+ActiveVersion+`&bookName=`+row.attributes.bname+`&bookCoverId=1`
             await api.post(UploadBookEP, {
             }).then(result => {
                CurrentBookID = result['data'].id
             }).catch(error =>{}) 

            for (let chapter of row.children){
              //! Upload Chapter Number
              const UploadChapterEP = `/Chapters?bookId=`+CurrentBookID+`&chapterNumber=`+chapter.attributes.cnumber
              await api.post(UploadChapterEP, {
              }).then(resultChapter => {
                  CurrentChapterID = resultChapter['data'].id
              }).catch(errorChapter =>{}) 

              for (let verse of chapter.children){
                //! Upload Verse Content
                const UploadVerseEP = `/Verses?chapterId=`+CurrentChapterID+``
                await api.post(UploadVerseEP,{
                    "title": "",
                    "verseNumber": verse.attributes.vnumber,
                    "verseContent": verse.value,
                    "isTitle": false
                }).then(resultVerse => {
                }).catch(errorVerse =>{})
              }
            }
        }
        setTimeout(() => {
            fetchBibleBooks();
            CloseModal();
            notifications.update({
                id: 'load-data',
                color: 'teal',
                title: 'Bible was uploaded',
                message: 'Bible successsfully uploaded',
                icon: <IconCheck size="1rem" />,
                autoClose: 2000,
            });
        }, 1000);
    }
    return(
    <Modal size = "xl" opened={OpenModal} onClose={CloseModal} title={UploadBibleTranslate} centered>
        <input type="file" onChange={handleFileUpload}/>
        <Group mt="md" mb="xs" grow>
            <Button onClick = { UploadBibleBook } leftIcon={<IconUpload/>} color="violet" size="md" uppercase>
                {BtnUploadBible}
            </Button>
        </Group>
    </Modal>
    )       
}