import React, { useState, useEffect, useRef } from "react";
import { Button, TextInput, Group, Title, Text, Image, ActionIcon, Grid, Modal, Paper, Stack, Tooltip, Skeleton, Select } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconPencil, IconCheck, IconBookUpload, IconDots, IconEdit, IconPhotoEdit, IconBible, IconPhotoFilled, IconEditCircle } from '@tabler/icons-react';
import { ViewChapterModal } from "../Component";
import { UploadBibleBook } from "../../Components/Component"
import Axios from 'axios'; 
export const ListofBooks = ({notifications}) => {
    const [BibleVersions, setBibleVersions] = useState([]);
    const [BibleBooks, setBibleBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [SearchCover, setSearchCover] = useState('');
    const [SelectedToVersion, setSelectedToVersion] = useState("");
    const [ActiveVersion, setActiveVersion] = useState("");
    const [DisplayChapterModal, setDisplayChapterModal] = useState(false);
    const [SelectedBookID, setSelectedBookID] = useState("")
    const [SelectedBookName, setSelectedBookName] = useState("")
    const [OpenUploadModal, setOpenUploadModal] = useState(false)
    const [OpenUpdateModal, setOpenUpdateModal] = useState(false)
    const [OpenUpdateCoverModal, setOpenUpdateCoverModal] = useState(false)
    const [BookName, setBookName] = useState('')
    const [BookID, setBookID] = useState('')
    const [BookCover, setBookCover] = useState([])
    const [BookCoverID, setBookCoverID] = useState('')
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [VersionNameDisplay, setVersionNameDisplay] = useState('')
    localStorage.setItem('ActiveVersion',ActiveVersion);
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [ListOfBookTranslate, setListOfBookTranslate] = useState('LIST OF BIBLE BOOK')
    const [SelectVersionTranslate, setSelectVersionTranslate] = useState('Select Version')
    const [SearchBookNameTranslate, setSearchBookNameTranslate] = useState('Search Book Name')
    const [ReadNowTranslate, setReadNowTranslate] = useState('Read Now')
    const [TooltipUpdateCover, setTooltipUpdateCover] = useState('Update Book Cover')
    const [TooltipUpdateBook, setTooltipUpdateBook] = useState('Update Bible Book')
    const [TooltipRemoveBook, setTooltipRemoveBook] = useState('Remove Bible Book')
    const [UpdatebookCoverTranslate, setUpdatebookCoverTranslate] = useState('UPDATE BOOK COVER')
    const [BtnSetTranslate, setBtnSetTranslate] = useState('SET')
    const [UpdateBibleBookTranslate, setUpdateBibleBookTranslate] = useState('UPDATE BIBLE BOOK')
    const [BookNameTranslate, setBookNameTranslate] = useState('Book Name')
    const [PlaceholderBookName, setPlaceholderBookName] = useState('Enter Book Name')
    const [BtnUpdateBible, setBtnUpdateBible] = useState('UPDATE BIBLE')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Deleting')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Update this Book?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchBibleVersion();
        VersionName();
        if(SelectedToVersion === ""){}else{fetchBibleBooks();}
        setActiveVersion(SelectedToVersion);
        fetchTranslation();
    }, [SelectedToVersion, DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ListOfBookTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setListOfBookTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SelectVersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSelectVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchBookNameTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchBookNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ReadNowTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setReadNowTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipUpdateCover,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipUpdateCover(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipUpdateBook,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipUpdateBook(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipRemoveBook,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipRemoveBook(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdatebookCoverTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdatebookCoverTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnSetTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnSetTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateBibleBookTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateBibleBookTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BookNameTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBookNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderBookName,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderBookName(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUpdateBible,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUpdateBible(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value)
    }
    const handleSearchCover = (event) => {
        setSearchCover(event.target.value)
    }
    const handleChangeBookName = (event) => {
        setBookName(event.target.value);
    }
    const fetchBibleVersion = async () => {
        setLoading(true);   
        const ListVersionEP = `/BibleVersions`
        await api.get(ListVersionEP).then((result) => {
            setBibleVersions(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchBibleBooks = async () => {
        setLoading(true);   
        const ListBookEP = `/BibleBooks?versionId=`+SelectedToVersion+`&includeBookCover=true`
        await api.get(ListBookEP).then((result) => {
            setBibleBooks(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const SelectedVersion = async (e) => {
        const VersionID = e.target.value
        setSelectedToVersion(VersionID)
    }
    const SelectBookID = async (BookID, BookName) => {
        setSelectedBookID(BookID)
        setSelectedBookName(BookName)
    }
    const RemoveBibleBook = async(BookID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Bible Book',
            message: 'Bible Book will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RemoveBookEP = `/BibleBooks/`+BookID+``
        await api.delete(RemoveBookEP, {
        }).then(result => {
                if(result['status'] === 204){
                    fetchBibleBooks();
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Bible Book was deleted',
                            message: 'Bible Book successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{  
        })
    }
    const OpenModalBibleFunction = (BookID) => {
        setOpenUpdateModal(true)
        setBookID(BookID)
    }
    const UpdateBible = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Bible Book',
            message: 'Bible Book will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UpdateBookEP = `/BibleBooks/BookName/`+BookID+`?bookName=`+BookName+``
        api.put(UpdateBookEP,{
        }).then(result => {
        if(result['status'] === 204){
            setTimeout(() => {
                //! RESET INPUTS
                fetchBibleBooks();
                setOpenUpdateModal(false);
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Bible Book was updated',
                    message: 'Bible Book successsfully updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const OpenModalBibleCoverFunction = async(BookID) => {
        setOpenUpdateCoverModal(true)
        setBookID(BookID)
        setLoading(true);   
        const BookCoverEP = `/BookCovers`
        await api.get(BookCoverEP).then((result) => {
            setBookCover(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const UpdateBibleCover = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Bible Cover',
            message: 'Bible Cover will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
       
        const UpdateBookEP = `/BibleBooks/`+BookID+`?bookCoverId=`+BookCoverID+``
        api.put(UpdateBookEP,{
        }).then(result => {
        if(result['status'] === 204){
            setTimeout(() => {
                //! RESET INPUTS
                fetchBibleBooks();
                setOpenUpdateCoverModal(false);
                setBookCover([])
                setSearchCover('')
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Bible Cover was updated',
                    message: 'Bible Cover successsfully updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const CallConfirmModal = async(CoverID) => {
        setBookCoverID(CoverID)
        setConfirmModal(true)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const VersionName = async () => {
        const VersionNameEP = `/BibleVersions/`+SelectedToVersion+``
        await api.get(VersionNameEP).then((result) => {
            setVersionNameDisplay(result.data.version)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    return(
        <div style={{marginLeft : '50px'}} className="FirstContainer">
            <Text ta="center" fw={800} sx={{fontSize : 40, fontFamily : 'poppins'}} color = "black">{ListOfBookTranslate}</Text>
            <Group position="center">
                <Group>
                    <select onChange={SelectedVersion} style = {{
                        padding : '8px',
                        fontSize :'15px',
                        fontWeight : 'bold',
                        borderRadius : '3px',
                        width: 410,
                        fontFamily : 'poppins'
                    }}>
                        <option value = "0">{SelectVersionTranslate}</option>
                        {BibleVersions.map((version, index) =>
                            <option key={ index } value = {version.id}>
                                    { version.version }
                            </option>
                        )}
                    </select>
                    {/* <Select
                        searchable
                        radius="sm"
                        size="md"
                        placeholder="Select Version"
                        nothingFoundMessage="Nothing found..."
                        sx = {{
                            width : 400,
                            fontFamily : 'poppins'
                        }}
                        data= {BibleVersions.map((version, index) =>
                             version.version
                        )}
                    /> */}
                </Group>
                <Group spacing={8}>
                    <TextInput
                        icon={<IconSearch/>}
                        radius="sm"
                        size="md"
                        placeholder={SearchBookNameTranslate}
                        onChange={ handleSearch }
                    />
                    <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled" 
                        onClick={() => {setOpenUploadModal(true)}}
                    >
                        <IconBookUpload/>
                    </ActionIcon>
                </Group>
            </Group>
            <br></br>
            <Paper shadow="xl" withBorder p="xl">
                <Grid gutter="xs" grow>
                {loading ? 
                <Group spacing={8}>
                    <Skeleton height={220} width="45%" mb="xl" className="PlanContent" />
                </Group>
                : 
                <>
                {
                    BibleBooks.filter((SearchBook) => {
                    if(search === ""){
                        return SearchBook
                    }else if(SearchBook.bookName.toLowerCase().includes(search.toLowerCase())){
                        return SearchBook
                    }
                    }).map((book, index) => (
                    <div key = {index} style={{width : '20%'}} className="BookContent">
                        <Grid.Col>
                            <div style={{position : "relative"}}>
                                <div className="ImageContainer">
                                    <Group>
                                        <Image
                                            className="BookCoverImage" 
                                            src={book['bookCover'].image} 
                                            maw={150}
                                            sx={{borderRadius : 10}}
                                            radius="md"
                                        />
                                        <Stack spacing={1} sx={{maxWidth : '50%'}}>
                                            <Text sx = {{fontFamily : 'poppins'}} fw={700} fz="lg" lineClamp={1} >{book.bookName}</Text>
                                            <Text sx = {{fontFamily : 'poppins'}} fw={500} fz="xs" lineClamp={1} color="rgba(107, 107, 107, 1)" className="VersionName">{VersionNameDisplay}</Text>
                                            <div style={{paddingTop : 5}}>
                                                <Button sx = {{fontFamily : 'poppins'}} leftIcon = {<IconBible/>} variant="gradient" gradient={{ from: 'rgba(75, 0, 204, 1)', to: 'rgba(21, 0, 173, 1)', deg: 90 }} onClick={() => { SelectBookID(book.id, book.bookName); setDisplayChapterModal(true); }}>
                                                    {ReadNowTranslate}
                                                </Button>
                                            </div>
                                            <Group sx={{paddingTop : 15}} spacing={4}>
                                                <Tooltip label={TooltipUpdateCover} color="rgba(85, 0, 255, 1)">
                                                    <IconPhotoEdit color = "green" size={25}  onClick={() => {OpenModalBibleCoverFunction(book.id)}} style={{cursor : 'pointer'}}/>
                                                </Tooltip>
                                                <Tooltip label={TooltipUpdateBook} color="rgba(85, 0, 255, 1)">
                                                    <IconEditCircle color = "blue" size={25} onClick={() => {OpenModalBibleFunction(book.id)}}  style={{cursor : 'pointer'}}/>
                                                </Tooltip>
                                                <Tooltip label={TooltipRemoveBook } color="rgba(85, 0, 255, 1)">
                                                    <IconTrash color="red" size={25} onClick={() => {RemoveBibleBook(book.id)}}  style={{cursor : 'pointer'}}/>
                                                </Tooltip>
                                            </Group>
                                        </Stack>
                                    </Group>
                                </div>
                            </div>
                        </Grid.Col>
                    </div>
                    ))
                }
                </>
                }
               
                </Grid>
            </Paper>
            <ViewChapterModal
                OpenChapterModal = {DisplayChapterModal}
                CloseChapterModal = {() => {setDisplayChapterModal(false)}}
                ReOpenModalChapter = {() => {setDisplayChapterModal(true)}}
                SelectedBookID = {SelectedBookID}
                SelectedToVersion = {SelectedToVersion}
                SelectedBookName = {SelectedBookName}
                notifications = {notifications}
            />
            <UploadBibleBook
                notifications = {notifications}
                OpenModal = {OpenUploadModal}
                CloseModal = {() => {setOpenUploadModal(false)}}
                fetchBibleBooks = {fetchBibleBooks}
            />
            <Modal size = "sm" opened={OpenUpdateModal} onClose={() => {setOpenUpdateModal(false)}} title = 
                {
                    <Title order={3} weight={700} align="center">
                            {UpdateBibleBookTranslate}
                    </Title>
                }
                centered
            >
                <Group grow>
                    <TextInput
                        size='md'
                        label = {BookNameTranslate}
                        placeholder={PlaceholderBookName}
                        onChange={handleChangeBookName}
                        withAsterisk
                    />
                </Group>
                <br/>
                <Group grow>
                    <Button onClick = {UpdateBible} leftIcon = {<IconPencil/>} color="violet" size="md"> {BtnUpdateBible} </Button>
                </Group>
            </Modal>
            <Modal 
                opened={OpenUpdateCoverModal}
                onClose={() => {setOpenUpdateCoverModal(false)}}
                title ={
                    <Title order={3} weight={700} align="center">
                            {UpdatebookCoverTranslate}
                    </Title>
                }
                size='xl'
            >
                <Group spacing={8}>
                    <TextInput
                        icon={<IconSearch/>}
                        radius="sm"
                        size="md"
                        placeholder={SearchBookNameTranslate}
                        onChange={ handleSearchCover }
                        sx={{paddingTop : 10}}
                    />
                </Group>
                <br/>
                <Grid gutter="xs" grow>
                {
                    BookCover.filter((Cover) => {
                    if(SearchCover === ""){
                        return Cover
                    }else if(Cover.fileName.toLowerCase().includes(SearchCover.toLowerCase())){
                        return Cover
                    }
                    }).map((cover, index) => (
                        <div key = {index} style={{width : '20%'}} className="BookContent">
                        <Grid.Col>
                            <div style={{position : "relative"}}>
                                <div className="ImageContainer">
                                    <Image
                                        className="BookCoverImage" 
                                        src={cover.image} 
                                        alt={cover.fileName}
                                    /> 
                                    <div className="ViewBtnContainer">
                                        <Button radius="xl" size="md" uppercase sx={{
                                                background : '#4a1e9e',  ":hover" : {
                                                background : '#6c2be2'
                                            }
                                        }}
                                        onClick={() => {CallConfirmModal(cover.id)}}
                                        >
                                            {BtnSetTranslate}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid.Col>
                        </div>
                    ))
                }
                </Grid>
            </Modal>
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
                <Group>
                    <Group grow>
                        <Text>{ConfirmMessageTranslate}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button onClick = {UpdateBibleCover} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                    <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
                </Group>
            </Modal>
        </div>
    )
}