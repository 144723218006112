import React, { useState, useEffect } from "react";
import { Modal, Group, Button, Text, Card, useMantineTheme, rem, Image} from '@mantine/core'
import { IconUpload, IconCheck, IconX, IconPhoto, IconPhotoShare } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import Axios from 'axios';
export const UploadAdImage = ({notifications, OpenModal, CloseModal, SelectedID, fetchCustomizedAds}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('')
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AddImageTranslate, setAddImageTranslate] = useState('Add New Image Ads')
    const [DragImageTranslate, setDragImageTranslate] = useState('Drag images here or click to select files')
    const [DragImageDescTranslate, setDragImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [BtnUploadImageAds, setBtnUploadImageAds] = useState('UPLOAD IMAGE ADS')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddImageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddImageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadImageAds,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadImageAds(translated[0])
            })
        }).then((error) =>{
        })
    }
    function handleImage(files){
        setImage(files[0])
    }
    function UploadAdsImage() {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Ads Image',
            message: 'Ads Image will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const formData = new FormData()
        formData.append('image', image)
        Axios.post(''+APILink+'/api/CustomizedAds/'+SelectedID+'/Image',
        formData).then((res) => {
            if(res.status === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchCustomizedAds()
                    CloseModal()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Ads Image was uploaded',
                        message: 'Ads Image successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        })
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={AddImageTranslate} size = "lg" centered>
            <Card shadow="xl" padding="lg">
                <Card.Section>
                    {img.map((url, index) => <Image key = {index} style = {{ padding : '30px'}} maw={340} mx="auto" radius="sm" src={url} alt="Ads" />)}
                </Card.Section>
                <Card.Section >
                    <Dropzone
                        onDrop={
                            (files) => {
                                handleImage(files)
                                const selectedFIles =[];
                                const targetFilesObject= [...files]
                                targetFilesObject.map((file)=>{
                                    return selectedFIles.push(URL.createObjectURL(file))
                                })
                                setImg(selectedFIles);
                            } 
                        }
                        maxSize={3 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                    >
                        <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>

                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>

                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                    {DragImageTranslate}
                                </Text>
                                <Text size="xs" color="dimmed" inline mt={14} sx={{fontFamily : 'poppins'}}>
                                    {DragImageDescTranslate}
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>

                    <Group mt="md" mb="xs" grow>
                        <Button sx={{fontFamily : 'poppins'}} onClick={UploadAdsImage} leftIcon = {<IconPhotoShare/>} color="violet" size="md" uppercase>
                            {BtnUploadImageAds}
                        </Button>
                    </Group>
                </Card.Section>
            </Card>
        </Modal>
        </>
    )
}