import React, { useState, useEffect, useRef } from "react";
import { Button, Loader, Image, Group, TextInput, Modal, Text, Alert, Tooltip, ActionIcon, Stack, Grid, Card, Menu, Badge, Divider, Select, useMantineTheme, rem, CloseButton } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Moment from 'moment';
import { UploadWoftd } from '../WordOfTheDay/UploadWoftd'
import { UpdateWoftd } from "../WordOfTheDay/UpdateWoftd";
import { IconSearch, IconTrash, IconCheck, IconPlus, IconX, IconList, IconGrid4x4, IconDots, IconEdit, IconPhotoX, IconDotsVertical, IconLanguageHiragana, IconLanguage, IconPencil, IconPaint, IconPaintFilled, IconColorPicker, IconColorFilter, IconPhotoPlus, IconUpload, IconRowInsertTop, IconPencilPlus, IconPhoto } from '@tabler/icons-react';
import  LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
import Axios from 'axios';
import { motion, transform } from "framer-motion";
export const ListofWordOfTheDay = ({notifications}) => {
    const theme = useMantineTheme();
    const APILink = process.env.REACT_APP_API_LINK
    const DateToday = Moment().toISOString(new Date());
    const [Data, setData] = useState([]);
    const [Data1, setData1] = useState([]);
    const [LocalizationData, setLocalizationData] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [loading, setLoading] = useState(false);
    const [UploadModal, setUploadModal] = useState(false);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [UpdateModal, setUpdateModal] = useState(false);
    const [UploadImage, setUploadImage] = useState(false);
    const [TranslatedContentModal, setTranslatedContentModal] = useState(false)
    const [WoftdGetID, setWoftdGetID] = useState("");
    const [ConfirmStatus, setConfirmStatus] = useState("")
    const [GetTitle, setGetTitle] = useState("");
    const [GetContent, setGetContent] = useState("");
    const [GetImage, setGetImage] = useState("");
    const [GetDate, setGetDate] = useState("");
    const [GetWoftdID, setGetWoftdID] = useState("");
    const [search, setSearch] = useState('');
    const [SelectedMonth, setSelectedMonth] = useState('');
    const [TranslateLoading, setTranslateLoading] = useState(false)
    const [UploadCode, setUploadCode] = useState('')
    const [UploadTitle, setUploadTitle] = useState('')
    const [UploadWord, setUploadWord] = useState('')
    const [UploadID, setUploadID] = useState('')
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [img, setImg] = useState([]);
    const [image, setImage] = useState([])
    const [WoftdThemes, setWoftdThemes] = useState([])
    const [SelectedThemeImage, setSelectedThemeImage] = useState('')

    //! TRANSLATION
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [WoftTitle, setWoftTitle] = useState('WORD OF THE DAY')
    const [SearchWoftTitle, setSearchWoftTitle] = useState('Search Word of the Day')
    const [UploadTooltipTitle, setUploadTooltipTitle] = useState('Upload Word of the Day')
    const [UploadImageTooltipTitle, setUploadImageTooltipTitle] = useState('Upload Theme Image')
    const [ActiveNowTitle, setActiveNowTitle] = useState('Active Now')
    const [EndeTitle, setEndedTitle] = useState('Ended')
    const [ComingSoonTitle, setComingSoonTitle] = useState('Coming Soon')
    const [SetWoftTooltip, setSetWoftTooltip] = useState('Click to set todays Word of the Day')
    const [UpdateWoftTooltip, setUpdateWoftTooltip] = useState('Click to update Word of the Day')
    const [DeleteWoftTooltip, setDeleteWoftTooltip] = useState('Click to remove Word of the Day')
    const [ConfirmDeleteTranslate, setConfirmDeleteTranslate] = useState('Are you sure you want to Delete this Word of the day?')
    const [ConfirmSetTranslate, setConfirmSetTranslate] = useState('Are you sure you want to Set this Word of the day?')
    const [ConfirmActionTranslate, setConfirmActionTranslate] = useState('Confirm Action')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    const [DragImageTitle, setDragImageTitle] = useState('Drag images here or click to select files')
    const [DragImageDesc, setDragImageDesc] = useState('Attach as many files as you like, each file should not exceed 5mb')
    useEffect(() => {
        fetchWordOfTheDayFuture();
        fetchWordOfTheDay();
        setDefaultLanguage(localStorage.getItem('SelectedCode'));
        fetchTranslation();
        fetchLanguages();
        fetchImages();
    }, [page, DefaultLanguage, SelectedMonth]);
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchImages = async() => {
        const ListWotfEP = `/photos`
        await api.get(ListWotfEP).then((result) => {
            setWoftdThemes(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + WoftTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setWoftTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchWoftTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchWoftTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadTooltipTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadTooltipTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadImageTooltipTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadImageTooltipTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActiveNowTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActiveNowTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EndeTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEndedTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ComingSoonTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setComingSoonTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SetWoftTooltip,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSetWoftTooltip(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateWoftTooltip,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateWoftTooltip(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DeleteWoftTooltip,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDeleteWoftTooltip(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmDeleteTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmDeleteTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmSetTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmSetTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDesc,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDesc(translated[0])
            })
        }).then((error) =>{
        })
        
    }
    const fetchWordOfTheDayFuture = async () => {
        setLoading(true);   
        const ListWotfEP = `/wordofthedays/future`
        await api.get(ListWotfEP).then((result) => {
            setData(result.data)
            setLoading(false);
        })
    }
    const fetchWordOfTheDay = async () => {
        setLoading(true);   
        const ListWotfEP = `/wordofthedays/`
        await api.get(ListWotfEP).then((result) => {
            setData1(result.data)
            setLoading(false);
        })
    }
    const OpenConfirm = async (WoftdID, Status, ActiveStatus) => {
        if(ActiveStatus === true){
            notifications.show({
                id: 'load-data',
                icon : <IconX/>,
                color: 'red',
                title: 'Current Word of the Day',
                message: 'This is The Current Word of the Day!',
                autoClose: true,
            });
        }else{
            setWoftdGetID(WoftdID)
            setConfirmModal(true)
            setConfirmStatus(Status)
        }
    }
    const RemoveWordOfTheDay = async () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Word of the Day',
            message: 'Word of the Day will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const WoftdEP = `/wordofthedays/`+WoftdGetID+``
            await api.delete(WoftdEP, {
            }).then(result => {
                if(result['status'] === 204){
                    setTimeout(() => {
                        fetchWordOfTheDay();
                        setConfirmModal(false);
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Word of the Day was deleted',
                            message: 'Word of the Day successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
            notifications.show({
                id: 'load-data',
                title: 'Removing Word of the Day',
                message: 'you cant delete Active Word of the Day!',
                color : 'red',
                icon : <IconX/>
            });
        })
    }
    const SetWordOfTheDay = async () => {
        const UpdateWoftdEP = `Daily/WordOfTheDay/`+WoftdGetID+``
        api.put(UpdateWoftdEP).then(result => {
       if(result['status'] === 204){
           notifications.show({
               id: 'load-data',
               loading: true,
               title: 'Setting Word of the Day',
               message: 'Word of the Day will be loaded, you cannot close this yet',
               autoClose: false,
               withCloseButton: false,
               });
               setTimeout(() => {
               fetchWordOfTheDay();
               setConfirmModal(false);
               notifications.update({
                   id: 'load-data',
                   color: 'teal',
                   title: 'Word of the Day was Updated',
                   message: 'Word of the Day successsfully Updated',
                   icon: <IconCheck size="1rem" />,
                   autoClose: 2000,
               });
           }, 1000); 
       }else{}
       }).catch(error =>{
       })
    }
    const OpenTranslatedModal = async(id,title,word) => {
        setUploadTitle(title)
        setUploadWord(word)
        setUploadID(id)
        setTranslatedContentModal(true)
        const ListOfLocalization = `/wordofthedays/`+id+`/localizations`
        await api.get(ListOfLocalization).then((result) => {
            setLocalizationData(result.data)
        }).catch(error => {
        })

    }
    const DeleteLocalization = async(id) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Localization',
            message: 'Localization will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setTranslatedContentModal(false)
        const LocalizationEP = `/Daily/localization/`+id+``
            await api.delete(LocalizationEP, {
            }).then(result => {
               if(result.status === 204){
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Localization was deleted',
                            message: 'Localization successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
               }
            }).catch(error =>{
        })
    }
    const DeleteThemeImage = async(id) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Themes',
            message: 'Themes will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setTranslatedContentModal(false)
        const ImageThemesEP = `/photos/`+id+``
            await api.delete(ImageThemesEP, {
            }).then(result => {
               if(result.status === 204){
                    fetchImages();
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Themes was deleted',
                            message: 'Themes successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
               }
            }).catch(error =>{
        })
    }
    const UploadLocalization = async(code) => {
        setUploadCode(code)
    }
    const ClickUploadLocalization = async() => {
        let GetTranslatedTitle = ''
        let GetTranslatedWord= ''
        Axios({
            url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+UploadCode+'',
            method: "POST",
            headers: {
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
            },
            data:[
                {"Text": UploadTitle},
            ]
        }).then((result) => {
            GetTranslatedTitle = ''
            result.data.map((a) => (
                a.translations.map((gettranslation) => {
                    GetTranslatedTitle = gettranslation.text
                })
            ))
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+UploadCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": UploadWord}
                ]
            }).then((result) => {
                GetTranslatedWord= ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedWord = gettranslation.text
                    })
                ))
                let axiosConfig = {
                    headers : {
                        Authorization: 'Bearer '+Token
                    }
                }; 
                var LocalizationData = {
                    "title": GetTranslatedTitle,
                    "word": GetTranslatedWord,
                    "language": UploadCode,
                };
                Axios.post(''+APILink+'/api/Daily/'+UploadID+'/localization', LocalizationData, axiosConfig)
                .then(async(result) => {
                    if(result.status === 200){
                        const ListOfLocalization = `/Daily/`+UploadID+`/localizations`
                        await api.get(ListOfLocalization).then((result) => {
                            setLocalizationData(result.data)
                        }).catch(error => {
                        })
                    }
                })
             
            }).catch((error) => { 
            })
        }).catch((error) => { 
        })
    }
    function handleImage(files){
        setImage(files)
    }
    const UploadImageFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Image',
            message: 'Image will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const formData = new FormData()
        image.map((files) => {
            formData.append('photo', files)
        })
        Axios.post(''+APILink+'/api/photos/',
        formData,{
            headers : {
                Authorization: 'Bearer '+Token,
                'Content-Type': 'multipart/form-data',
                'Application' : 'application/json'
            }
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    fetchImages();
                    setImage([])
                    setImg([])
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Image was uploaded',
                        message: 'Image successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    const LazyImage = ({verse, verse_content, src, WoftdDate }) => {
        const rootRef = useRef();
        const [isVisible, setIsVisible] = useState(false);
        useEffect(() => {
            const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
            };
            
            const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                setIsVisible(true);
                }
            });
            };
        
            if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
            }
        }, [isVisible]);
        return (
                <Group position="center" sx={{padding : 10}}>
                    <div className="container" style={{position : 'relative'}}>
                        <Image 
                            radius="lg"
                            src={isVisible ? src : LogoBlurd} 
                            ref={rootRef}
                            withPlaceholder
                            placeholder={
                                <Group sx={{paddingTop : 20}} position="center">
                                    <IconPhotoX size="3.2rem" stroke={1.5} color="black" />
                                </Group>
                            }
                        />
                        <Text align="center" sx={{position: 'absolute', top : 150, color : 'white', fontFamily : 'poppins'}}>
                            <p>{verse_content}</p>
                            <br></br>
                            <p>- {verse} -</p>
                        </Text>
                        <Group sx={{position : 'absolute', top : 0, left : 0, padding : 5}}>
                            {
                                Moment(DateToday).format('MMMM DD, YYYY') === Moment(WoftdDate).format('MMMM DD, YYYY')?
                                <Badge color="green" size="md" variant="filled" radioGroup="lg">
                                    <Text fz={13} sx = {{fontFamily : 'poppins'}}>{ActiveNowTitle}</Text>
                                </Badge>
                                :
                                DateToday < WoftdDate ? 
                                <Badge color="blue" size="md" variant="filled" radioGroup="lg">
                                    <Text fz={13} sx = {{fontFamily : 'poppins'}}>{ComingSoonTitle}</Text>
                                </Badge>
                                :
                                <Badge color="red" size="md" variant="filled" radioGroup="lg">
                                    <Text fz={13} sx = {{fontFamily : 'poppins'}}>{EndeTitle}</Text>
                                </Badge> 
                            }
                        </Group>
                    </div>
                </Group>
            
        )
      
    }
    const SelectedTheme = (filename) => {
        const fileLink = 'https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/image-ultra-high/'
        setSelectedThemeImage(fileLink+filename)
    }
    return(
    <>
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group position="apart">
                <Group>
                    <TextInput
                        icon={<IconSearch/>}
                        radius="md"
                        size="md"
                        placeholder={SearchWoftTitle}
                        onChange={ handleSearch }
                    />
                    <Group spacing={5}>
                        <Tooltip label={UploadTooltipTitle}  sx={{background : '#6c2be2'}} withArrow>
                            <ActionIcon sx={{background : '#4a1e9e',  ":hover" : { background : '#6c2be2'}}} size="lg"  radius="xl" variant="filled" onClick = {() => {setUploadModal(true)}}>
                                <IconPencilPlus />
                            </ActionIcon>
                        </Tooltip>
                        <Tooltip label={UploadImageTooltipTitle}  sx={{background : '#6c2be2'}} withArrow>
                            <ActionIcon sx={{background : '#4a1e9e',  ":hover" : { background : '#6c2be2'}}} size="lg"  radius="xl" variant="filled" onClick = {() => {setUploadImage(true)}}>
                                <IconPhotoPlus />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </Group>
                {/* <select onChange={(val) => {setSelectedMonth(val.target.value)}} style = {{
                        padding : '8px',
                        fontSize :'16px',
                        fontWeight : 'bold',
                        borderRadius : '10px',
                        fontFamily : 'poppins',
                        background : '#4a1e9e',
                        cursor : 'pointer',
                        color : 'white',
                        borderColor : 'transparent'
                    }}>
                    <option value = "0"> All </option>
                    <option value = "1"> January </option>
                    <option value = "2"> February </option>
                    <option value = "3"> March </option>
                    <option value = "4"> April </option>
                    <option value = "5"> May </option>
                    <option value = "6"> June </option>
                    <option value = "7"> July </option>
                    <option value = "8"> August </option>
                    <option value = "9"> September </option>
                    <option value = "10"> October </option>
                    <option value = "11"> November </option>
                    <option value = "12"> December </option>
                </select> */}
            </Group>
            <br></br>
            <Grid gutter="xs" grow>
                {Data.filter((SearchWordOfTheDay) => {
                    if(search === ""){
                            return SearchWordOfTheDay
                        }else if(SearchWordOfTheDay.verse.toLowerCase().includes(search.toLowerCase())){
                            return SearchWordOfTheDay
                        }
                    }).map((woftd, index) =>
                    <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '25%'}} className="AchievementContent">
                        <Grid.Col> 
                            <Card withBorder radius="lg" sx={{
                                boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                display: 'block',
                                top: '0px',
                                position: 'relative',
                                textDecoration: 'none',
                                zIndex: 0,
                                overflow: 'hidden',
                                border: '1px solid #f2f8f9',
                                cursor: 'pointer',
                                ":hover" : {
                                    boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                }}}
                            >

                                <Card.Section withBorder inheritPadding py="xs" sx={{background : '#6c2be2'}}>
                                    <Group position="apart">
                                        <Text fw={700} fz="xl" sx={{lineHeight: 1, color : 'white', fontFamily : 'poppins'}}>{Moment(woftd.dateActive).format('MMMM DD, YYYY')}</Text>
                                        <Menu withinPortal position="bottom-start" shadow="lg" withArrow offset={-1} width={220}>
                                            <Menu.Target>
                                                <ActionIcon radius="xl" size="md" sx={{background : '#6c2be2',  ":hover" : {background : '#4a1e9e'}}}>
                                                    <IconDots size={22} color="white" />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconLanguageHiragana size={20} />} onClick={() => {OpenTranslatedModal(woftd.id,woftd.title,woftd.verse )}}  >
                                                    Translated Content
                                                </Menu.Item>
                                                <Menu.Item 
                                                    sx = {{fontFamily : 'poppins'}} icon={<IconCheck size={20} />} 
                                                    onClick = {() => {OpenConfirm(woftd.id, 'SetWordOfTheDay', woftd.isWordOfTheDay)}}
                                                >
                                                    Set as Today
                                                </Menu.Item>
                                                <Menu.Item 
                                                    sx = {{fontFamily : 'poppins'}} icon={<IconEdit size={20} />}
                                                    onClick = {() => {
                                                        setUpdateModal(true);
                                                        setGetTitle(woftd.title);
                                                        setGetContent(woftd.verse);
                                                        setGetImage(woftd.imageUrl);
                                                        setGetDate(woftd.date);
                                                        setGetWoftdID(woftd.id)
                                                    }}
                                                >
                                                    Update
                                                </Menu.Item>
                                                <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconTrash size={20} />} color="red" onClick = {() => {OpenConfirm(woftd.id, 'RemoveWordOfTheDay', woftd.isWordOfTheDay)}} >
                                                    Remove
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </Group>
                                </Card.Section>
                                <Card.Section>
                                    <LazyImage
                                        verse = {woftd.verse}
                                        verse_content = {woftd.verseContent}
                                        src = {SelectedThemeImage ? SelectedThemeImage : 'https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/image-ultra-high/494c9932-85c3-4573-a379-2e73648f3f21.webp'}
                                        WoftdDate = {woftd.dateActive}
                                    />
                                </Card.Section>
                                <Group position="apart" mt="md" >
                                    <Text sx={{lineHeight: 1, color : '#4a1e9e', fontSize : 22, fontFamily : 'poppins', fontWeight : 700}}>{WoftTitle}</Text>
                                </Group>
                                <Text size="sm" color="dark" lineClamp={3} sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{woftd.caption}</Text> 
                            </Card>
                        </Grid.Col>
                    </motion.div>
                    )}   
            </Grid>
            <Grid gutter="xs" grow>
                {Data1.filter((SearchWordOfTheDay) => {
                    if(search === ""){
                            return SearchWordOfTheDay
                        }else if(SearchWordOfTheDay.verse.toLowerCase().includes(search.toLowerCase())){
                            return SearchWordOfTheDay
                        }
                    }).map((woftd, index) =>
                    <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '25%'}} className="AchievementContent">
                        <Grid.Col> 
                            <Card withBorder radius="lg" sx={{
                                boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                display: 'block',
                                top: '0px',
                                position: 'relative',
                                textDecoration: 'none',
                                zIndex: 0,
                                overflow: 'hidden',
                                border: '1px solid #f2f8f9',
                                cursor: 'pointer',
                                ":hover" : {
                                    boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                }}}
                            >

                                <Card.Section withBorder inheritPadding py="xs" sx={{background : '#6c2be2'}}>
                                    <Group position="apart">
                                        <Text fw={700} fz="xl" sx={{lineHeight: 1, color : 'white', fontFamily : 'poppins'}}>{Moment(woftd.dateActive).format('MMMM DD, YYYY')}</Text>
                                        <Menu withinPortal position="bottom-start" shadow="lg" withArrow offset={-1} width={220}>
                                            <Menu.Target>
                                                <ActionIcon radius="xl" size="md" sx={{background : '#6c2be2',  ":hover" : {background : '#4a1e9e'}}}>
                                                    <IconDots size={22} color="white" />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconLanguageHiragana size={20} />} onClick={() => {OpenTranslatedModal(woftd.id,woftd.title,woftd.verse )}}  >
                                                    Translated Content
                                                </Menu.Item>
                                                <Menu.Item 
                                                    sx = {{fontFamily : 'poppins'}} icon={<IconCheck size={20} />} 
                                                    onClick = {() => {OpenConfirm(woftd.id, 'SetWordOfTheDay', woftd.isWordOfTheDay)}}
                                                >
                                                    Set as Today
                                                </Menu.Item>
                                                <Menu.Item 
                                                    sx = {{fontFamily : 'poppins'}} icon={<IconEdit size={20} />}
                                                    onClick = {() => {
                                                        setUpdateModal(true);
                                                        setGetTitle(woftd.title);
                                                        setGetContent(woftd.verse);
                                                        setGetImage(woftd.imageUrl);
                                                        setGetDate(woftd.date);
                                                        setGetWoftdID(woftd.id)
                                                    }}
                                                >
                                                    Update
                                                </Menu.Item>
                                                <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconTrash size={20} />} color="red" onClick = {() => {OpenConfirm(woftd.id, 'RemoveWordOfTheDay', woftd.isWordOfTheDay)}} >
                                                    Remove
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </Group>
                                </Card.Section>
                                <Card.Section>
                                    <LazyImage
                                        verse = {woftd.verse}
                                        verse_content = {woftd.verseContent}
                                        src = {SelectedThemeImage ? SelectedThemeImage : 'https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/image-ultra-high/494c9932-85c3-4573-a379-2e73648f3f21.webp'}
                                        WoftdDate = {woftd.dateActive}
                                    />
                                </Card.Section>
                                <Group position="apart" mt="md" >
                                    <Text sx={{lineHeight: 1, color : '#4a1e9e', fontSize : 22, fontFamily : 'poppins', fontWeight : 700}}>{WoftTitle}</Text>
                                </Group>
                                <Text size="sm" color="dark" lineClamp={3} sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{woftd.caption}</Text> 
                            </Card>
                        </Grid.Col>
                    </motion.div>
                    )}   
            </Grid>
        </div>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmActionTranslate} centered>
            <Group>
                <Group grow>
                    {
                        ConfirmStatus === 'RemoveWordOfTheDay' ?
                        <Text>{ConfirmDeleteTranslate}</Text>
                        :
                        ConfirmStatus === 'SetWordOfTheDay' ?
                        <Text>{ConfirmSetTranslate}</Text>
                        :
                        ''
                    }
                </Group>
            </Group>
            <br/>
            <Group position='right'>
                <Button onClick = {
                        ConfirmStatus === 'RemoveWordOfTheDay' ?
                        RemoveWordOfTheDay
                        :
                        ConfirmStatus === 'SetWordOfTheDay' ?
                        SetWordOfTheDay
                        :
                        ''
                } color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button onClick = {() => {setConfirmModal(false)}} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>
        <Modal opened={TranslatedContentModal} onClose={() => {setTranslatedContentModal(false)}} size="xl">
            <Group position = "center">
                <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 30}}>LIST OF LANGUAGE LOCALIZATION</Text>
            </Group>
            <Divider></Divider>
            <br/>
            <Group>
                <Select
                    placeholder="Select Language"
                    sx={{
                    width : '80%',
                    fontSize :'14px',
                    borderRadius : '10px',
                    fontFamily : 'poppins',
                    cursor : 'pointer',
                    color : 'white',
                    borderColor : 'transparent' }}
                    size="md"
                    radius="sm"
                    searchable
                    dropdownPosition="bottom"
                    transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
                    withinPortal
                    onChange={(val => {UploadLocalization(val)})}
                    data={
                        DefaultLanguages.map((country) => (
                            {value: country.languageCode, label: country.name}
                        ))
                    }
                />
                <Tooltip
                    label="Upload Language Localization"
                    sx={{background : '#6c2be2'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={ClickUploadLocalization}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <br/>
            <Stack justify="flex-start">
            {LocalizationData.map((code, index) => (
                <Card padding="xs" radius="md" key = {index} sx={{
                    boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                    cursor : 'pointer',
                    transition : 'transform .2s ease-in-out',
                    MozTransition : 'transform .2s ease-in-out',
                    WebkitTransition : 'transform .2s ease-in-out',
                    marginTop: -10,
                    ":hover" : {
                        background : 'transparent',
                        WebkitTransform : 'scale(1.01)',
                        msTransform : 'scale(1.01)',
                        transform : 'scale(1.01)'
                    }
                }}>
                    {TranslateLoading ? 
                        <>
                            <Group position='center'>
                                <Loader color="violet" variant="dots" /><br/>
                            </Group>
                            <Group position='center'>
                                <i style={{fontFamily : 'poppins', fontSize : 12}}>Translating...</i>
                            </Group>
                        </>
                        : 
                        <>
                            
                            <Stack spacing={1}>
                                <Group spacing={5}>
                                    <Text fw = {700} fz="lg" color="black" sx={{fontFamily : 'poppins'}}>
                                        {code.verse ? code.verse : <i style={{fontFamily : 'poppins', color : 'red', fontSize : 12}}>no title translated</i>}
                                    </Text>
                                    :
                                    <Group spacing={3}>
                                        <IconLanguage size={18}/> - 
                                        <Text fw = {800} color="black" sx={{fontFamily : 'poppins', fontSize : 15}}>Translated in {code.language}</Text>
                                    </Group>
                                </Group>
                                
                            </Stack>
                            <Group grow>
                                <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}> {code.verseContent}</Text>
                            </Group>
                            <Group position="right" spacing={5}>
                                <ActionIcon radius="xl" variant="filled" color="blue">
                                    <IconPencil size="1.125rem" />
                                </ActionIcon>
                                <ActionIcon radius="xl" variant="filled" color="red" onClick={() => {DeleteLocalization(code.id)}}>
                                    <IconTrash size="1.125rem" />
                                </ActionIcon>
                            </Group>
                        </>
                    }
                </Card>
            ))}
            </Stack>
        </Modal>
        <Modal opened={UploadImage} onClose={() => {setUploadImage(false)}} size="xl" title = {<Text sx={{fontFamily : 'poppins', fontSize : 20}} fw={700}>Word of the day Theme</Text>} centered>
            <Card shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                <Card.Section>
                    {img.map((url, index) => <Image key = {index} style = {{ padding : '30px'}} maw={340} mx="auto" radius="sm" src={url} alt="Word of The Day" />)}
                </Card.Section>
                <Card.Section >
                    <Dropzone
                        onDrop={
                            (files) => {
                                handleImage(files, setImage)
                                const selectedFIles =[];
                                const targetFilesObject= [...files]
                                targetFilesObject.map((file)=>{
                                    return selectedFIles.push(URL.createObjectURL(file))
                                })
                                setImg(selectedFIles);
                            } 
                        }
                        maxSize={3 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                    >
                        <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>

                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>

                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline sx = {{fontFamily : 'poppins'}}>
                                {DragImageTitle}
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7} sx = {{fontFamily : 'poppins'}}>
                                    {DragImageDesc}
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>
                </Card.Section>
            </Card>
            <Group grow sx={{marginTop : 10, marginBottom : 10}}>
                <Button onClick = {UploadImageFunction} leftIcon = {<IconUpload/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>UPLOAD IMAGE</Button>
            </Group>
            <Text sx={{fontFamily : 'poppins', fontSize : 20}} fw={700}>List of Themes</Text>
            <Grid gutter="md" grow>
            {WoftdThemes.map((images, index) =>
                    <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '25%'}} className="AchievementContent"> 
                        <Grid.Col> 
                       
                            <Card withBorder radius="md" sx={{
                                boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                display: 'block',
                                top: '0px',
                                position: 'relative',
                                textDecoration: 'none',
                                zIndex: 0,
                                overflow: 'hidden',
                                border: '1px solid #f2f8f9',
                                cursor: 'pointer',
                                position : "relative",
                                ":hover" : {
                                    boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                }}}
                            >
                                <Card.Section>
                                    <Image src={`https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/image-ultra-high/${images.fileName}`} alt={images.fileName} style={{width : '100%'}} onClick={() => {SelectedTheme(images.fileName)}} />
                                    <CloseButton title="Close popover" size="sm" radius="xl" iconSize={20} style = {{position: 'absolute', top: 3, right: 3, color : 'white', background : '#4a1e9e'}} onClick={() => {DeleteThemeImage(images.id)}} />
                                </Card.Section>
                            </Card>
                        </Grid.Col>
                    </motion.div>
                    )}   
            </Grid>

        </Modal>
        <UploadWoftd
            notifications = {notifications}
            openModal = {UploadModal}
            closeModal = {() => {setUploadModal(false)}}
            fetchWordOfTheDay = {() => {fetchWordOfTheDay()}}
            fetchWordOfTheDayFuture = {() => {fetchWordOfTheDayFuture()}}
            ReOpenModal = {() => {setUploadModal(true)}}
        />
        <UpdateWoftd
            OpenUpdateModal = {UpdateModal}
            CloseUpdateModal = {() => {setUpdateModal(false)}}
            GetTitle = {GetTitle}
            GetContent = {GetContent}
            GetImage = {GetImage}
            GetDate = {GetDate}
            GetWoftdID = {GetWoftdID}
            fetchWordOfTheDay = {fetchWordOfTheDay}
            notifications = {notifications}
        />
    </>
    )}