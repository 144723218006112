import React, { useState, useEffect, useMemo, forwardRef } from "react";
import { Modal, Group, TextInput, Textarea, Button,Switch, Text, Card, Stack } from '@mantine/core'
import { IconUpload, IconCalendar, IconCheck, IconLanguage } from "@tabler/icons-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import Axios from 'axios';
import { Token, api, api_multipart, RefreshToken, GetNewToken } from '../../Utils'
import { LanguageCard } from "../LanguageCardContent/LanguageCardDailyDevotion";
export const UploadDailyDevotion = ({OpenModal, CloseModal, notifications, fetchDailyDevotion, fetchComment, PropsAddDevotionTranslate, PropsVerseTranslate, PropsPlaceholderVerse, PropsVerseContentTranslate, PropsPlaceholderVerseContent, PropsMessageTranslate, PropsPlaceholderMessage, PropsActiveTranslate, PropsInactiveTranslate, PropsActiveDailyDevotionTranslate, PropsSelectDateTranslate, PropsBtnUploadTranslate, PropsPrayerTranslate, PropsPlaceholderPrayer, PropsReflectionTranslate, PropsPlaceholderReflection}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [Verse, setVerse] = useState("")
    const [VerseContent, setVerseContent] = useState("")
    const [Message, setMessage] = useState("")
    const [Prayer, setPrayer] = useState("")
    const [Reflection, setReflection] = useState("")
    const [YesNo, setYesNo] = useState(false);
    const PostDate = Moment(startDate).format();
    const DatePickerBtn = forwardRef(({ value, onClick }, ref) => (
        <>
        <Text>{PropsSelectDateTranslate}</Text>
        <Group grow>
            <Button leftIcon = {<IconCalendar/>} variant="outline" color="dark" size="md" uppercase  onClick={onClick} ref={ref} >
            {value}
            </Button>
        </Group>
        </>
    ));
    var sourceLang = 'en';
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [TranslateLoading, setTranslateLoading] = useState(false)
    let objTranslated = {}
    let ArrayTranslated = []
    useEffect(() => {
        fetchLanguages();
    }, []);
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const handleChangeVerse = (event) => {
        setVerse(event.target.value)
    }
    const handleChangeVerseContent = (event) => {
        setVerseContent(event.target.value)
    }
    const handleChangeMessage = (event) => {
        setMessage(event.target.value)
    }
    const handleChangePrayer = (event) => {
        setPrayer(event.target.value)
    }
    const handleChangeReflection = (event) => {
        setReflection(event.target.value)
    }
    const UploadDailyDevotionFunction  = async () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Daily Devotion Version',
            message: 'Daily Devotion will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        var postData = {
            "word": VerseContent,
            "verse": Verse,
            "message": Message,
            "prayer": Prayer,
            "reflection": Reflection,
            "isDefault": YesNo,
            "date": PostDate,
            "translations": []

        };
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };  
        const UploadEP = `/DailyDevotions`
        await api.post(UploadEP, postData, axiosConfig,{
        }).then(result => {
            LanguageTranslated.map((item) => {
                var LocalizationData = [{
                    "word": item.word,
                    "verse": item.verse,
                    "message": item.message,
                    "prayer": item.prayer,
                    "reflection": item.reflection,
                    "language": item.language
                }];
                const UploadLocalizationEP = `/DailyDevotions/`+result.data.id+`/localizations`
                api.post(UploadLocalizationEP, LocalizationData, axiosConfig,{
                }).then((res) => {
                   
                })
                .catch((err) => {
                })
            })
            if(result['status'] === 201){
                setTimeout(() => {
                    fetchDailyDevotion();
                    fetchComment();
                    CloseModal();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Daily Devotion was uploaded',
                        message: 'Daily Devotion successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedVerse = ''
            let GetTranslatedVerseContent = ''
            let GetTranslatedMessage = ''
            let GetTranslatedPrayer = ''
            let GetTranslatedReflection = ''
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": Verse},
                ]
            }).then((result) => {
                GetTranslatedVerse = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedVerse = gettranslation.text
                    })
                ))
                Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                    method: "POST",
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                        "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                    },
                    data:[
                        {"Text": VerseContent},
                    ]
                }).then((result) => {
                    GetTranslatedVerseContent = ''
                    result.data.map((a) => (
                        a.translations.map((gettranslation) => {
                            GetTranslatedVerseContent = gettranslation.text
                        })
                    ))
                    Axios({
                        url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                        method: "POST",
                        headers: {
                            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                            "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                        },
                        data:[
                            {"Text": Message},
                        ]
                    }).then((result) => {
                        GetTranslatedMessage = ''
                        result.data.map((a) => (
                            a.translations.map((gettranslation) => {
                                GetTranslatedMessage = gettranslation.text
                            })
                        ))
                        Axios({
                            url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                            method: "POST",
                            headers: {
                                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                                "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                            },
                            data:[
                                {"Text": Prayer},
                            ]
                        }).then((result) => {
                            GetTranslatedPrayer = ''
                            result.data.map((a) => (
                                a.translations.map((gettranslation) => {
                                    GetTranslatedPrayer = gettranslation.text
                                })
                            ))
                            Axios({
                                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                                method: "POST",
                                headers: {
                                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                                },
                                data:[
                                    {"Text": Reflection},
                                ]
                            }).then((result) => {
                                GetTranslatedReflection = ''
                                result.data.map((a) => (
                                    a.translations.map((gettranslation) => {
                                        GetTranslatedReflection = gettranslation.text
                                    })
                                ))
                                objTranslated = {}
                                objTranslated['language'] = code.languageCode
                                objTranslated['word'] = GetTranslatedVerseContent
                                objTranslated['verse'] = GetTranslatedVerse
                                objTranslated['message'] = GetTranslatedMessage
                                objTranslated['prayer'] = GetTranslatedPrayer
                                objTranslated['reflection'] = GetTranslatedReflection
                                ArrayTranslated.push(objTranslated)
                                setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                               
                            }).catch((error) => { 
                            })
                        }).catch((error) => { 
                        })
                    }).catch((error) => { 
                    })
                }).catch((error) => { 
                })
            }).catch((error) => { 
            })
            setTranslateLoading(false)
        }   
    }
    return(
        <Modal size="100%" opened={OpenModal} onClose={CloseModal} centered>
            <Group align='start' position="center">
                <Card maw = {700} shadow="sm" padding="md" radius="md" sx={{width : '100%'}} withBorder>
                    <Group position = "center">
                        <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>{PropsAddDevotionTranslate}</Text>
                    </Group>
                    <Group grow>
                        <TextInput
                            placeholder={PropsPlaceholderVerse}
                            label={PropsVerseTranslate}
                            radius="md"
                            size="md"
                            withAsterisk
                            onChange={handleChangeVerse}
                        />
                    </Group>
                    <Group grow>
                        <Textarea
                            placeholder={PropsPlaceholderVerseContent}
                            label={PropsVerseContentTranslate}
                            radius="md"
                            size="md"
                            minRows={5}
                            withAsterisk
                            onChange={handleChangeVerseContent}
                        />
                    </Group>
                    <Group grow>
                        <Textarea
                            placeholder={PropsPlaceholderMessage}
                            label={PropsMessageTranslate}
                            radius="md"
                            size="md"
                            minRows={5}
                            withAsterisk
                            onChange={handleChangeMessage}
                        />
                    </Group>
                    <Group grow>
                        <Textarea
                            placeholder={PropsPlaceholderPrayer}
                            label={PropsPrayerTranslate}
                            radius="md"
                            size="md"
                            minRows={5}
                            withAsterisk
                            onChange={handleChangePrayer}
                        />
                    </Group>
                    <Group grow>
                        <Textarea
                            placeholder={PropsPlaceholderReflection}
                            label={PropsReflectionTranslate}
                            radius="md"
                            size="md"
                            minRows={5}
                            withAsterisk
                            onChange={handleChangeReflection}
                        />
                    </Group>
                    <br></br>
                    <Group grow>
                        <Switch
                            label={PropsActiveDailyDevotionTranslate}
                            radius="xs"
                            size="lg"
                            color="green"
                            onLabel={PropsActiveTranslate} offLabel={PropsInactiveTranslate}
                            checked = {YesNo}
                            onChange={(event) => setYesNo(event.currentTarget.checked)}
                        />
                    </Group>
                    <br></br>
                    <Group grow>
                        <DatePicker  
                            selected={startDate} 
                            onChange={(date) => setStartDate(date)} 
                            customInput={<DatePickerBtn />}
                        />
                    </Group>
                    <br></br>
                    <Group grow>
                        <Button onClick = {UploadDailyDevotionFunction} leftIcon = {<IconUpload/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            {PropsBtnUploadTranslate}
                        </Button>
                        <Button onClick={TranslateSelectedLanguages} leftIcon = {<IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            Translate
                        </Button>
                    </Group>
                </Card>
                <Card maw = {700} shadow="sm" padding="md" radius="md" sx={{width : '100%'}} withBorder>
                    <Stack justify="flex-start">
                        <Group position = "center">
                            <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>TRANSLATED CONTENT</Text>
                        </Group>
                        <LanguageCard
                            LanguageTranslated = {LanguageTranslated}
                            TranslateLoading = {TranslateLoading}
                        />
                    </Stack>
                </Card>
            </Group>
        </Modal>
    )
}