import { Modal, Group, TextInput, Textarea, Button, Text, Stack } from '@mantine/core'
import { IconUpload } from '@tabler/icons-react'
import { useState, useEffect } from 'react'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconCheck, IconFlag } from '@tabler/icons-react';
import Axios from 'axios';
export const UploadVersion = ({OpenModal, CloseModal, notifications, fetchBibleVersion}) => {
    const [VersionAcronym, setVersionAcronym] = useState("");
    const [VersionName, setVersionName] = useState("");
    const [LanguageCode, setLanguageCode] = useState('')
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AddNewBibleVersionTranslate, setAddNewBibleVersionTranslate] = useState('Add New Bible Version')
    const [VersionTranslate, setVersionTranslate] = useState('Version Acronym')
    const [PlaceholderVersion, setPlaceholderVersion] = useState('Enter Version')
    const [VersionNameTranslate, setVersionNameTranslate] = useState('Version Name')
    const [PlaceholderVersionName, setPlaceholderVersionName] = useState('Enter Version Name')
    const [LanguageCodeTranslate, setLanguageCodeTranslate] = useState('Language Code')
    const [PlaceholderLanguageCode, setPlaceholderLanguageCode] = useState('Enter Language Code')
    const [BtnUploadVersion, setBtnUploadVersion] = useState('UPLOAD VERSION')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddNewBibleVersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddNewBibleVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + VersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderVersion,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderVersion(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + VersionNameTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setVersionNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderVersionName,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderVersionName(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + LanguageCodeTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setLanguageCodeTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderLanguageCode,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderLanguageCode(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadVersion,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadVersion(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleChangeVersionAcronym = (event) => {
        setVersionAcronym(event.target.value);
    }
    const handleChangeVersionName = (event) => {
        setVersionName(event.target.value);
    }
    const handleLanguageCode = (event) => {
        setLanguageCode(event.target.value);
    }

    const UploadBibleVersion = async () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Version',
            message: 'Version will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const VersionEP = `/BibleVersions/?versionAbbreviation=`+VersionAcronym+`&version=`+VersionName+`&language=`+LanguageCode
        await api.post(VersionEP, {
        }).then(result => {
            if(result['status'] === 201){
                fetchBibleVersion();
                setTimeout(() => {
                    setVersionAcronym("")
                    setVersionName("")
                    CloseModal(false)
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Version was uploaded',
                        message: 'Version successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
        })
    }
    return(
    <Modal size = "lg" opened={OpenModal} onClose={CloseModal} title={AddNewBibleVersionTranslate} centered>
        <Group grow>
            <TextInput
                size='md'
                label = {VersionTranslate}
                placeholder={PlaceholderVersion}
                onChange={handleChangeVersionAcronym}
                value={VersionAcronym}
                withAsterisk
                sx={{fontFamily : 'poppins'}}
            />
        </Group> 
        <Group grow>
            <Textarea
                size='md'
                label = {VersionNameTranslate}
                placeholder={PlaceholderVersionName}
                onChange={handleChangeVersionName}
                value={VersionName}
                withAsterisk
                sx={{fontFamily : 'poppins'}}
            />
        </Group>
        <Group grow>
            <TextInput
                icon={<IconFlag/>}
                radius="sm"
                size="md"
                placeholder={PlaceholderLanguageCode}
                label = {LanguageCodeTranslate}
                onChange={ handleLanguageCode }
                withAsterisk
                sx={{fontFamily : 'poppins'}}
            />
        </Group>
        <br/>
        <Group grow>
            <Button sx={{fontFamily : 'poppins'}} onClick = {UploadBibleVersion} leftIcon = {<IconUpload/>} color="violet" size="md"> {BtnUploadVersion} </Button>
        </Group>
    </Modal>
    )
}