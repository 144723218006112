import React, { useState, useEffect, useRef } from "react";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { TextInput, Group, Text, Tooltip, ActionIcon, Modal, Button, Avatar, Switch, Stack, Menu } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { UploadPaymentMethod, UpdateRedirectionURL } from "../Component";
import { IconSearch, IconTrash, IconPlus, IconCheck, IconX, IconDotsVertical, IconLink } from '@tabler/icons-react';
import LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png";
import Axios from 'axios';
export const ListofPaymentMethod = ({notifications}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [OpenUploadPaymentMethod, setOpenUploadPaymentMethod] = useState(false)
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [PaymentMethodID, setPaymentMethodID] = useState('')
    const [RedirectionURL, setRedirectionURL] = useState(false)
    const [search, setSearch] = useState('');
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SearchPaymentMethodTranslate" : 'Search Payment Method',
        "CreatePaymentMethodTranslate" : 'Create Payment Method',
        "BankTranslate" : 'BANK',
        "StatusTranslate" : 'STATUS',
        "ActionTranslate" : 'ACTION',
        "AddInstructionTranslate" : 'Add Instruction',
        "AddUrlTranslate" : 'Add Url',
        "RemoveBankTranslate" : 'Remove Bank',
        "UnavailableTranslate" : 'Unavailable',
        "AddNewPaymentMethodTranslate" : 'Add New Payment Method',
        "NameTranslate" : 'Name',
        "PlaceholderName" : 'Enter Name',
        "AccountNameTranslate" : 'Account Name',
        "PlaceholderAccountName" : 'Enter Account Name',
        "AccountNumberTranslate" : 'Account Name',
        "PlacholderAccountNumber" : 'Enter Account Number',
        "DragImageTitleTranslate" : 'Drag images here or click to select files',
        "DragImageDescriptionTranslate" : 'Attatch as many files as you like, each file should not exceed 5mb',
        "ConfirmTitleTranslate" : 'Confirm Uploading',
        "ConfirmMessageTranslate" : 'Are you sure you wan to Upload this Payment Method ? ',
        "ConfirmMessage1Translate" : 'Are you sure you wan to Upload this Url ? ',
        "ConfirmTranslate" : 'Cofirm',
        "CancelTranslate" : 'Cancel',
        "BtnUploadPaymentMethod" : 'UPLOAD PAYMENT METHOD',
        "AddUrlTranslate" : 'Add URL',
        "UrlTranslate" : 'Enter URL',
        "BtnUploadUrl" : 'UPLOAD URL'

    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchPaymentMethod();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchPaymentMethod = async () => {
        setLoading(true);   
        const ListPaymentMethodEP = `/paymentMethods`
        await api.get(ListPaymentMethodEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const LazyImage = ({logo, isActive}) => {
        const rootRef = useRef();
        const [isVisible, setIsVisible] = useState(false);
        useEffect(() => {
            const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
            };
            const checkIntersections = entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                    setIsVisible(true);
                    }
                });
            };
            if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
            }
        }, [isVisible]);
        return (
        isActive ?  
            <Avatar src={isVisible ? logo : LogoBlurd}
                ref={rootRef}
                sx={{width : 139, height : 139}}
                withPlaceholder
                placeholder={<Text align="center">No Thumbnail Found</Text>} 
            /> 
        :
            <div style = {{position : 'relative', textAlign : 'center'}}>
                <Avatar src={isVisible ? logo : LogoBlurd}
                    ref={rootRef}
                    sx={{width : 139, height : 139, opacity: 0.1}}
                    withPlaceholder
                    placeholder={<Text align="center">No Thumbnail Found</Text>} 
                /> 
                <Text fw={900} style = {{position : 'absolute', top : '50%', left : '50%', transform : 'translate(-50%, -50%)', color: '#4a1e9e', fontSize : 11, fontFamily : 'poppins'}}>
                   {DataTranslate.UnavailableTranslate}
                </Text>
            </div>
        )
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setPaymentMethodID(ID)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const DeletePaymentMethod = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Payment Method',
            message: 'Payment Method will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const PaymentMethodEP = `/paymentMethods/`+PaymentMethodID+``
        await api.delete(PaymentMethodEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchPaymentMethod();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Payment Method was deleted',
                        message: 'Payment Method successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const setActive = async(ID) => {
        const ActiveEP = `/paymentMethods/`+ID+`/state`
        await api.put(ActiveEP, {
        }).then(result => {
            fetchPaymentMethod()
        }).catch(error =>{})
    }
    const GetPaymentMethodID = async(ID) => {
        setRedirectionURL(true)
        setPaymentMethodID(ID)
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{DataTranslate.BankTranslate}</Text>,
            cell: row => 
            <Group spacing={8} sx={{width : '100%'}} position="apart">
                <Group>
                    <a href = {row.redirectionUrl} target="_blank">
                        <LazyImage
                            logo = {row.logo}
                            isActive = {row.isActive}
                        />
                    </a>
                    <Stack spacing={1} justify="flex-start">
                        <Text fw={800} sx={{color : '#4a1e9e', fontSize : 25, fontFamily : 'poppins'}}>{row.name}</Text>
                        <Text fw={600}  sx={{fontSize : 13, fontFamily : 'poppins'}}>{row.accountName ? row.accountName : 'No Account Name'}</Text>
                        <Text fw={600}  sx={{fontSize : 13, fontFamily : 'poppins'}}>{row.accountNumber ? row.accountNumber : 'No Account Number'}</Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx = {{fontFamily : 'poppins'}}>{DataTranslate.StatusTranslate}</Text></Menu.Label>
                            <Menu.Item sx = {{fontFamily : 'poppins'}}>
                                <Group grow>
                                    <Switch
                                        checked={row.isActive}
                                        onClick={() => {setActive(row.id)}}
                                        color="violet"
                                        size="lg"
                                        radius="sm"
                                        thumbIcon={
                                        row.isActive ? (
                                            <IconCheck size="0.8rem" stroke={5} color="black" />
                                        ) : (
                                            <IconX size="0.8rem" stroke={5} color="black"/>
                                        )
                                        }
                                    />
                                </Group>
                            </Menu.Item>
                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx={{fontFamily : 'poppins'}}>{DataTranslate.ActionTranslate}</Text></Menu.Label>
                            <Link to={"/payment-instruction"} state={{ id: row.id}}>
                                <Menu.Item icon={<IconPlus />}>{DataTranslate.AddInstructionTranslate}</Menu.Item>
                            </Link>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon={<IconLink/>} onClick={() => {GetPaymentMethodID(row.id)}} >
                                {DataTranslate.AddUrlTranslate}
                            </Menu.Item>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon={<IconTrash/>} onClick={() => {CallConfirmModal(row.id)}} color = "red">{DataTranslate.RemoveBankTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group >
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchPaymentMethodTranslate}
                    onChange={ handleSearch }
                />
                <Tooltip
                    label={DataTranslate.CreatePaymentMethodTranslate}
                    sx={{background : 'rgb(39, 20, 77)'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : 'rgb(71 46 121)',  ":hover" : {
                            background : 'rgb(39, 20, 77)'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadPaymentMethod(true)}}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>  
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchEvent) => {
                        if(search === ""){
                            return SearchEvent
                        }else if(SearchEvent.title.toLowerCase().includes(search.toLowerCase())){
                            return SearchEvent
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            <UploadPaymentMethod
                notifications = {notifications}
                OpenModal = {OpenUploadPaymentMethod}
                CloseModal = {() => {setOpenUploadPaymentMethod(false)}}
                fetchPaymentMethod = {fetchPaymentMethod}
                PropsAddNewPaymentMethod = {DataTranslate.AddNewPaymentMethodTranslate}
                PropsDragImageTitleTranslate = {DataTranslate.DragImageTitleTranslate}
                PropsDragImageDescriptionTranslate = {DataTranslate.DragImageDescriptionTranslate}
                PropsNameTranslate = {DataTranslate.NameTranslate}
                PropsPlaceholderName = {DataTranslate.PlaceholderName}
                PropsAccountNameTranslate = {DataTranslate.AccountNameTranslate}
                PropsPlaceholderAccountName = {DataTranslate.PlaceholderAccountName}
                PropsAccountNumberTranslate = {DataTranslate.AccountNumberTranslate}
                PropsPlaceholderAccountNumber = {DataTranslate.PropsPlaceholderAccountNumber}
                PropsConfirmTitleTranslate = {DataTranslate.ConfirmTitleTranslate}
                PropsConfirmDescriptionTranslate = {DataTranslate.ConfirmMessageTranslate}
                PropsConfirmTranslate = {DataTranslate.ConfirmTranslate}
                PropsCancelTranslate = {DataTranslate.CancelTranslate}
                PropsBtnUploadPaymentMethod = {DataTranslate.BtnUploadPaymentMethod}
            />
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Deleting" centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>Are you sure you want to Delete this Payment Method?</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {DeletePaymentMethod} color="teal" size="sm" uppercase> Confirm </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> Cancel </Button>
                </Group>
            </Modal>
            <UpdateRedirectionURL
                notifications={notifications}
                OpenModal = {RedirectionURL}
                CloseModal = {() => {setRedirectionURL(false)}}
                fetchPaymentMethod = {fetchPaymentMethod}
                PaymentMethodID = {PaymentMethodID}
                PropsAddUrlTranslate = {DataTranslate.AddUrlTranslate}
                PropsUrlTranslate = {DataTranslate.UrlTranslate}
                PropsBtnUploadUrl = {DataTranslate.BtnUploadUrl}
                PropsConfirmTitleTranslate = {DataTranslate.ConfirmTitleTranslate}
                PropsConfirmDescriptionTranslate = {DataTranslate.ConfirmMessage1Translate}
                PropsConfirmTranslate = {DataTranslate.ConfirmTranslate}
                PropsCancelTranslate = {DataTranslate.CancelTranslate}
            />
        </div>
    )
}