import React, { useState, useEffect } from "react";
import { Modal, Group, Button, Text, Card, useMantineTheme, rem, Image} from '@mantine/core'
import { IconUpload, IconCheck, IconX,IconPhoto, IconPhotoShare } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import Axios from 'axios';
import { Token } from '../../Utils'
export const UpdateBibleStoryImage = ({OpenModal, CloseModal, notifications, fetchBibleStories, BibleStoryID, BibleStoryCurrentImage}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme()
    const [img, setImg] = useState([])
    const [image, setImage] = useState('')
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AddBibleStoryImage, setAddBibleStoryImage] = useState('Add Bible Story Image')
    const [DragImageTitleTranslate, setDragImageTitleTranslate] = useState('Drag images here or click to select files')
    const [DragImageDescTranslate, setDragImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [BtnUploadImage, setBtnUploadImage] = useState('UPLOAD IMAGE')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage])
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddBibleStoryImage,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddBibleStoryImage(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadImage,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadImage(translated[0])
            })
        }).then((error) =>{
        })
    }
    function handleImage(files){
        setImage(files[0])
    }
    const UploadBibleStoryImage = () =>{
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Bible Story Image',
            message: 'Bible Story Image will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const formData = new FormData()
        formData.append('image', image)
        Axios.put(''+APILink+'/api/BibleStories/'+BibleStoryID,
        formData,{
            headers : {
                Authorization: 'Bearer '+Token,
                'Content-Type': 'multipart/form-data',
                'Application' : 'application/json'
            }
        }).then((res) => {
            if(res.status === 204){
                fetchBibleStories()
                CloseModal()
                setImage('')
                setImg([])
                setTimeout(() => {
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Bible Story Image was uploaded',
                    message: 'Bible Story Image successsfully Updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
                }, 1000);
            }
        })
    }
    return(
        <Modal size = "lg" opened={OpenModal} onClose={CloseModal} title={AddBibleStoryImage} centered>
        <Card shadow="xl" padding="lg">
            <Card.Section>
                {img.map((url, index) => 
                    <Image 
                        key = {index}
                        style = {{ padding : '30px'}} 
                        maw={340} 
                        mx="auto" 
                        radius="sm" 
                        alt="Bible Story Image"
                        src={image === '' ? BibleStoryCurrentImage : url}
                    />
                )}
            </Card.Section>
            <Card.Section >
                <Dropzone
                    onDrop={
                        (files) => {
                            handleImage(files)
                            const selectedFIles =[];
                            const targetFilesObject= [...files]
                            targetFilesObject.map((file)=>{
                                return selectedFIles.push(URL.createObjectURL(file))
                            })
                            setImg(selectedFIles);
                        } 
                    }
                    maxSize={3 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                >
                    <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                        <Dropzone.Accept>
                            <IconUpload
                                size="3.2rem"
                                stroke={1.5}
                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                            />
                        </Dropzone.Accept>

                        <Dropzone.Reject>
                            <IconX
                                size="3.2rem"
                                stroke={1.5}
                                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                            />
                        </Dropzone.Reject>

                        <Dropzone.Idle>
                            <IconPhoto size="3.2rem" stroke={1.5} />
                        </Dropzone.Idle>

                        <div>
                            <Text size="xl" inline sx = {{fontFamily : 'poppins'}}>
                                {DragImageTitleTranslate}
                            </Text>
                            <Text size="xs" color="dimmed" inline mt={14} sx = {{fontFamily : 'poppins'}}>
                                {DragImageDescTranslate}
                            </Text>
                        </div>
                    </Group>
                </Dropzone>

                <Group mt="md" mb="xs" grow>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {UploadBibleStoryImage} leftIcon = {<IconPhotoShare/>} color="violet" size="md" uppercase>
                        {BtnUploadImage}
                    </Button>
                </Group>
            </Card.Section>
        </Card>
        </Modal>
    )
}
