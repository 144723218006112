import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDisclosure } from '@mantine/hooks';
import { Text, Group, Card, Image, rem, useMantineTheme, Textarea, Button, Drawer, Tooltip, ActionIcon, Modal, Paper, Stack } from '@mantine/core';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconPlus, IconTrash, IconCheck, IconUpload, IconX, IconPhoto, IconArrowLeft } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Swiper, SwiperSlide } from 'swiper/react';
import LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import Axios from 'axios';
export const UploadPaymentInstruction = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const location = useLocation();
    const { id } = location.state;
    const [loading, setLoading] = useState(false);
    const [SelctedPaymentMethod, setSelectedPaymentMethod] = useState([]);
    const [opened, { open, close }] = useDisclosure(false);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [InstructionID, setInstructionID] = useState('');
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('');
    const [Instruction, setInstruction] = useState('');
    const [GetID, setGetID] = useState('');
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "DragImageTitleTranslate" : 'Drage images here or click to select files',
        "DragImageDescriptionTranslate" : 'Attatch as many files as you like, each file should not exceed 5mb',
        "ConfirmTitleTranslate" : 'Confirm Uploading',
        "ConfirmMessageTranslate" : 'Are you sure you wan to Upload this Payment Method ? ',
        "ConfirmMessage1Translate" : 'Are you sure you wan to Upload this Url ? ',
        "ConfirmTranslate" : 'Cofirm',
        "CancelTranslate" : 'Cancel',
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        setGetID(id);
        fetchIsntruction();
        fetchTranslation();
    }, [GetID]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const fetchIsntruction = async() => {
        const ListPaymentMethodEP = `/paymentMethods/`+GetID+``
        await api.get(ListPaymentMethodEP).then((result) => {
            setSelectedPaymentMethod(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    function handleImage(files){
        setImage(files[0])
    }
    const handleChangeInstruction = (event) => {
        setInstruction(event.target.value)
    }
    const LazyImage = ({cover}) => {
        const rootRef = useRef();
        const [isVisible, setIsVisible] = useState(false);
        useEffect(() => {
            const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
            };
            
            const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                setIsVisible(true);
                }
            });
            };
        
            if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
            }
        }, [isVisible]);
        return (
            <Image 
                radius="sm" 
                src={isVisible ? cover : LogoBlurd} 
                ref={rootRef}
                withPlaceholder
                placeholder={<Text align="center">No Image Found</Text>}
            />
        )     
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setInstructionID(ID)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const DeleteInstructionFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Instruction',
            message: 'Instruction will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const PaymentMethodEP = `paymentMethods/instructions/`+InstructionID+``
        api.delete(PaymentMethodEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchIsntruction();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Instruction was deleted',
                        message: 'Instruction successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const UploadInstructionFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Instruction',
            message: 'Instruction will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const formData = new FormData();
        formData.append('image', image)
        Axios.post(''+APILink+'/api/paymentMethods/'+id+'/instructions?instruction='+Instruction+'', formData,{
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 200){
                fetchIsntruction();
                close();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Instruction was uploaded',
                        message: 'Instruction successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })
    }
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Stack
                direction="row"
                flexWrap="wrap"
                spacing={2}
                useFlexGap 
            >
                <Drawer
                    opened={opened}
                    onClose={close}
                    title="Upload Instruction"
                    transitionProps={{ transition: 'rotate-left', duration: 150, timingFunction: 'linear' }}
                    size={640}
                    overlayProps={{ opacity: 0.5, blur: 60 }}
                >
                    <Card shadow="xl" padding="lg" radius="sm" withBorder>
                        <Card.Section>
                            {img.map((url, index) =>
                                <Image 
                                    key = {index} 
                                    maw={300} 
                                    mx="auto"
                                    alt="Event Image" 
                                    src={url} 
                                    placeholder={<Text align="center">No Image Detected</Text>}
                                />
                            )}
                        </Card.Section>
                        <Card.Section >
                            <Dropzone
                                onDrop={
                                    (files) => {
                                        handleImage(files)
                                        const selectedFIles =[];
                                        const targetFilesObject= [...files]
                                        targetFilesObject.map((file)=>{
                                            return selectedFIles.push(URL.createObjectURL(file))
                                        })
                                        setImg(selectedFIles);
                                    } 
                                }
                                maxSize={3 * 1024 ** 2}
                                accept={IMAGE_MIME_TYPE}
                            >
                                <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                                    <Dropzone.Accept>
                                        <IconUpload
                                            size="3.2rem"
                                            stroke={1.5}
                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                        />
                                    </Dropzone.Accept>

                                    <Dropzone.Reject>
                                        <IconX
                                            size="3.2rem"
                                            stroke={1.5}
                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                        />
                                    </Dropzone.Reject>

                                    <Dropzone.Idle>
                                        <IconPhoto size="3.2rem" stroke={1.5} />
                                    </Dropzone.Idle>

                                    <div>
                                        <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                            Drag images here or click to select files
                                        </Text>
                                        <Text size="sm" color="dimmed" inline mt={7} sx={{fontFamily : 'poppins'}}>
                                            Attach as many files as you like, each file should not exceed 5mb
                                        </Text>
                                    </div>
                                </Group>
                            </Dropzone>
                        </Card.Section>
                    </Card>
                    <br></br>
                    <Group grow>
                        <Textarea
                            placeholder="Enter Instruction"
                            label="Instruction"
                            radius="xs"
                            size="md"
                            minRows={10}
                            withAsterisk
                            onChange={handleChangeInstruction}
                            sx={{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <Group mt="md" mb="xs" grow>
                        <Button sx={{fontFamily : 'poppins'}} onClick={UploadInstructionFunction} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                            Upload Blogs
                        </Button>
                    </Group>
                </Drawer>
                <Paper shadow="xl" p="md" >
                    <Group spacing={4}>
                        <Tooltip
                            label="Return to Payment Method"
                            sx={{background : 'rgb(39, 20, 77)'}}
                            withArrow
                        >
                            <Link to={"/payment-bank"}>
                                <ActionIcon
                                    sx={{background : 'rgb(71 46 121)',  ":hover" : {
                                        background : 'rgb(39, 20, 77)'
                                    }}}
                                    size="xl" 
                                    radius="xl" 
                                    variant="filled"
                                >
                                    <IconArrowLeft />
                                </ActionIcon>
                            </Link>
                        </Tooltip>
                        <Tooltip
                            label="Create Instruction"
                            sx={{background : 'rgb(39, 20, 77)'}}
                            withArrow
                        >
                            <ActionIcon
                                sx={{background : 'rgb(71 46 121)',  ":hover" : {
                                    background : 'rgb(39, 20, 77)'
                                }}}
                                size="xl" 
                                radius="xl" 
                                variant="filled"
                                onClick={open}
                            >
                                <IconPlus />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                    <br/>
                    <Group position="center">
                        <Image 
                            maw={250} 
                            radius="sm" 
                            src={SelctedPaymentMethod.logo} 
                            withPlaceholder
                        />
                        <Stack>
                            <Group position="center">
                                <Text color="black"  fw={700} sx={{ fontSize : '55px', fontFamily : 'poppins' }}>{SelctedPaymentMethod.name}</Text>
                            </Group>
                            <Group position="center">
                                <Text fz="lg" color="gray" fw={500} sx={{fontFamily : 'poppins'}}>
                                    {SelctedPaymentMethod.accountName}
                                </Text>
                            </Group>
                            <Group position="center">
                                <Text fz="lg" color="gray" fw={500} sx={{fontFamily : 'poppins'}}>
                                    {SelctedPaymentMethod.accountNumber}
                                </Text>
                            </Group>
                        </Stack>
                    </Group>
                </Paper>
                <Swiper
                    slidesPerView={6}
                    spaceBetween={10}
                >   
                {SelctedPaymentMethod?.instructions?.map ((image, idx) =>  
                    <SwiperSlide key = {idx}>
                        <div className="container" style={{position : 'relative'}}>
                            <LazyImage
                                cover = {image.image}
                            />
                            <Tooltip
                                label="Remove Payment Method"
                                sx={{background : 'rgb(39, 20, 77)'}}
                                withArrow
                            >
                                <ActionIcon
                                    color="red"
                                    size="lg" 
                                    radius="xl" 
                                    variant="filled"
                                    sx={{position : 'absolute', top : '6px', right : '10px'}}
                                    onClick={() => {CallConfirmModal(image.id)}}
                                    className="RemoveIcon"
                                >
                                    <IconTrash />
                                </ActionIcon>
                            </Tooltip>
                        </div>
                    </SwiperSlide>
                )}   
                </Swiper>
            </Stack>
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Deleting" centered>
                <Group>
                    <Group grow>
                        <Text>Are you sure you want to Delete this Instruction?</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {DeleteInstructionFunction} color="teal" size="sm" uppercase> Confirm </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> Cancel </Button>
                </Group>
            </Modal>
        </div>
    )
}