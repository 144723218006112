import { ListofAds } from "../../Components/DataTables/ListofAds"
import { notifications } from '@mantine/notifications';
import './Ads.css'
export const Ads = () => {
    return (
        <div>
            <ListofAds
                notifications = {notifications}
            />
        </div>
    )
}