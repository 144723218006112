import React, { useState, useEffect } from "react";
import { Text, Image, Tooltip, Switch, Group, TextInput, ActionIcon, Stack, Badge, Menu } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconPencil, IconCheck, IconPhoto, IconPlus, IconDotsVertical, IconTrash, IconPower } from '@tabler/icons-react';
import { UploadAdImage } from "../Component";
import { UploadCustomizedAds } from "../Component";
import { UpdateCustomizedAds } from "../Component";
import DataTable from 'react-data-table-component';
import Axios from 'axios';
export const ListofCustomizedAds = ({notifications}) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [CustomizedAds, setCustomizedAds] = useState([]);
    const [UploadImageModal, setUploadImageModal] = useState(false)
    const [UploadCustomizedAdsModal, setUploadCustomizedAdsModal] = useState(false)
    const [UpdateCustomizedAdsModal, setUpdateCustomizedAdsModal] = useState(false)
    const [GetID, setGetID] = useState("");
    const [GetTitle, setGetTitle] = useState("");
    const [GetName, setGetName] = useState("");
    const [GetDescription, setGetDescription] = useState("");
    const [GetURL, setGetURL] = useState("");
    const [search, setSearch] = useState('');
    const [SelectedID, setSelectedID] = useState("")
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblCustomizeAds, setTblCustomizeAds] = useState('PROVIDER')
    const [SearchProviderTranslate, setSearchProviderTranslate] = useState('Search Provider')
    const [StatusTranslate, setStatusTranslate] = useState('STATUS')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [UpdateTranslate, setUpdateTranslate] = useState('Update Ads')
    const [AddImageTranslate, setAddImageTranslate] = useState('Add Image')
    const [TurnOffTranslate, setTurnOffTranslate] = useState('Turn Off All')
    const [RemoveAdsTranslate, setRemoveAdsTranslate] = useState('Remove Ads')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchCustomizedAds();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblCustomizeAds,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblCustomizeAds(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + StatusTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setStatusTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddImageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddImageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TurnOffTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTurnOffTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveAdsTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveAdsTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchCustomizedAds = async () => {
        setLoading(true);   
        const ListCustomizedAdsEP = `/CustomizedAds`
        await api.get(ListCustomizedAdsEP).then((result) => {
            setCustomizedAds(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const setUploadImage = async(CustomizedAdsID) => {
        setUploadImageModal(true)
        setSelectedID(CustomizedAdsID)
    }
    const TurnOnOff = async (id) => {
        const SetDefaultEP = `/CustomizedAds/`+id+`/Default`
            await api.put(SetDefaultEP, {
            }).then(result => {
                console.log(result)
                fetchCustomizedAds()
            }).catch(error =>{
        })
    }
    const TurnOffAll = async () => {
        const SetDefaultEP = `/CustomizedAds/Default`
        await api.put(SetDefaultEP, {
        }).then(result => {
            console.log(result)
            fetchCustomizedAds()
        }).catch(error =>{
    })
    }
    const RemoveCustomizeAds = async (SelectedID) => {
        const AdsEP = `/CustomizedAds/`+SelectedID+``
            await api.delete(AdsEP, {
            }).then(result => {
                if(result['status'] === 204){
                    notifications.show({
                        id: 'load-data',
                        loading: true,
                        title: 'Removing Ads Version',
                        message: 'Ads will be deleted, you cannot close this yet',
                        autoClose: false,
                        withCloseButton: false,
                        });
                        setTimeout(() => {
                        fetchCustomizedAds()
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Ads was deleted',
                            message: 'Ads successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx = {{fontFamily : 'poppins'}}>{TblCustomizeAds}</Text>,
            cell : row =>
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group>
                    <a href={row.url}>
                        <Tooltip
                            label="Click to view this url"
                            color="violet"
                            withArrow
                        >
                            <Image 
                                className="AdsImage" 
                                radius="sm" 
                                src={row.image} 
                            />
                        </Tooltip>
                    </a>
                    <Stack spacing={1}>
                        <Text sx = {{fontFamily : 'poppins'}} fw={700} sx={{lineHeight: 2, color : '#4a1e9e', fontSize: 30}}>{row.title}</Text>
                        <Group spacing={5}>
                            {row.isDefault ? <Badge sx={{color : '#4a1e9e', fontFamily : 'poppins'}} color="yellow" variant="light" size="lg"><i>Active</i></Badge> : <Badge sx={{color : 'red'}} color="yellow" variant="light" size="lg"><i>InActive</i></Badge>}
                        </Group>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{StatusTranslate}</Text></Menu.Label>
                            <Menu.Item>
                                <Switch 
                                    onClick = {() => {TurnOnOff(row.id)}} 
                                    size = "xl"
                                    onLabel="ACTIVE" 
                                    offLabel="INACTIVE" 
                                    radius="xs" 
                                    color="green" 
                                    checked= {row.isDefault} 
                                /> 
                            </Menu.Item>
                            <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{ActionTranslate}</Text></Menu.Label>
                            <Menu.Item sx = {{fontFamily : 'poppins'}} icon = {<IconPencil/>} onClick={() => {
                                setUpdateCustomizedAdsModal(true);
                                setGetID(row.id)
                                setGetTitle(row.title)
                                setGetName(row.name)
                                setGetDescription(row.description)
                                setGetURL(row.url)
                            }}>{UpdateTranslate}</Menu.Item>
                            <Menu.Item sx = {{fontFamily : 'poppins'}} icon = {<IconPhoto/>} onClick = {() => {setUploadImage(row.id)}}>{AddImageTranslate}</Menu.Item>
                            <Menu.Item sx = {{fontFamily : 'poppins'}} color = "red" icon = {<IconPower/>} onClick={TurnOffAll}>{TurnOffTranslate}</Menu.Item>
                            <Menu.Item sx = {{fontFamily : 'poppins'}} color = "red" icon = {<IconTrash/>} onClick={() => {RemoveCustomizeAds(row.id)}}>{RemoveAdsTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>        
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group >
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchProviderTranslate}
                    onChange={ handleSearch }
                />
                <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled" onClick = {() => {setUploadCustomizedAdsModal(true)}}
                >
                    <IconPlus/>
                </ActionIcon>
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={
                    CustomizedAds.filter((SearchAds) => {
                        if(search === ""){
                            return SearchAds
                        }else if(SearchAds.title.toLowerCase().includes(search.toLowerCase())){
                            return SearchAds
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={CustomizedAds.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />      
            <UploadAdImage
                OpenModal={UploadImageModal}
                CloseModal={() => {setUploadImageModal(false)}}
                notifications={notifications}
                SelectedID = {SelectedID}
                fetchCustomizedAds = {fetchCustomizedAds}
            />
            <UploadCustomizedAds
                OpenModal={UploadCustomizedAdsModal}
                CloseModal={() => {setUploadCustomizedAdsModal(false)}}
                fetchCustomizedAds = {fetchCustomizedAds}
                notifications={notifications}
            />
            <UpdateCustomizedAds
                OpenModal={UpdateCustomizedAdsModal}
                CloseModal={() => {setUpdateCustomizedAdsModal(false)}}
                fetchCustomizedAds = {fetchCustomizedAds}
                notifications={notifications}
                GetID={GetID}
                GetName={GetName}
                GetTitle={GetTitle}
                GetDescription={GetDescription}
                GetURL={GetURL}
            />
        </div>
       

    )
}