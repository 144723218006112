
import { useState, useEffect  } from 'react';
import { Stepper, Button, Group, Text, Tooltip, TextInput, Image, Title, Paper, Blockquote, Textarea, Divider} from '@mantine/core';
import { IconMoodSmile, IconVersionsFilled, IconBible, IconBooks, IconBook2, IconSearch, IconSquareRoundedArrowLeftFilled, IconUpload } from '@tabler/icons-react';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
export const UploadVerseDevotion = ({DevotionID, dataVersion, PropsBackTranslate, PropsStep1Translate, PropsStep2Translate, PropsStep3Translate, PropsStep4Translate, PropsStep5Translate, PropsChapterNumberTranslate, PropsVerseNumberTranslate, PropsExplanationTranslate, PropsPlaceholderExplanationTranslate, PropsSearchBookTranslate, PropsBtnUploadDevotion}) => {
    //! Steps Descriptions
    const Feelings = "Select Feelings";
    const Version = "Select Bible Version";
    const Books = "Select Bible Books";
    const Chapter = "Select Chapter Number";
    const Verse     = "Select Verse Number";
    const [active, setActive] = useState(1);
    const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
    //! Search Function
    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const [BibleBooks, setBibleBooks] = useState([]);
    const [BibleVersion, setBibleVersion] = useState("");
    const [BibleVersionID, setBibleVersionID] = useState("");
    const SelectBibleVersion = (VersionID, VersioName) => {
        setBibleVersion(VersioName)
        setBibleVersionID(VersionID)
        nextStep()
        //! FETCH BIBLE BOOKS DATA ONCLICK FUNCTION  
        const ListBooknEP = `/BibleBooks?versionId=`+VersionID+`&includeBookCover=true`
        api.get(ListBooknEP).then((result) => {
            setBibleBooks(result.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const [BibleChapter, setBibleChapter] = useState([]);
    const [BookName, setBookName] = useState("");
    const SelectBookID = (BookID, BookName) => {
        console.log(BookID)
        setBookName(BookName)
        nextStep()
        //! FETCH BIBLE CHAPTER DATA ONCLICK FUNCTION  
        const ListChapterEP = `/Chapters?bookId=`+BookID+``
        api.get(ListChapterEP).then((result) => {
            setBibleChapter(result.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const [BibleVerse, setBibleVerse] = useState([]);
    const [ChapterNumber, setChapterNumber] = useState("");
    const SelectChapterID = (ChapterID, ChapterNumber) => {
        setChapterNumber(ChapterNumber)
        nextStep()
          //! FETCH BIBLE CHAPTER DATA ONCLICK FUNCTION  
        const ListVerseEP = `/Verses?chapterId=`+ChapterID+``
          api.get(ListVerseEP).then((result) => {
            setBibleVerse(result.data)
          }).catch(error => {
              const Response = error.response.status
              if(Response === 401){
                  GetNewToken(Token, RefreshToken)
              }
              if(Response === 404){
              }
          })
    }
    const [BibleVerseContent, setBibleVerseContent] = useState("");
    const [VerseNumber, setVerseNumber] = useState("");
    const [VerseID, setVerseID] = useState("")
    const SelectVerseID = (VerseID, VerseNumber) => {
        setVerseNumber(VerseNumber)
        setVerseID(VerseID)
        nextStep()
        //! FETCH BIBLE CHAPTER DATA ONCLICK FUNCTION  
        const ListVerseContentEP = `/Verses/`+VerseID+``
        api.get(ListVerseContentEP).then((result) => {
        setBibleVerseContent(result.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const UploadDevotion = () => {}
    return(
        <>
         <Group position="left" mt="xl">
            <Button sx = {{fontFamily : 'poppins'}} leftIcon = {<IconSquareRoundedArrowLeftFilled/>} onClick={prevStep} color="red" radius="xl" size="xl" compact uppercase>{PropsBackTranslate}</Button>
         </Group>
         <br></br>
         <Stepper color="violet" size="md" active={DevotionID != '' ? active : ''} onStepClick={setActive} breakpoint="sm" allowNextStepsSelect={false}>
            <Stepper.Step  icon = {<IconMoodSmile/>} label={PropsStep1Translate} description={<Text sx = {{fontFamily : 'poppins'}} color = "white">{Feelings}</Text>}>
            </Stepper.Step>

            <Stepper.Step icon = {<IconVersionsFilled/>} label={PropsStep2Translate} description={<Text sx = {{fontFamily : 'poppins'}} color = "white">{Version}</Text>}>
                <Group position='center' spacing="xs">
                    {dataVersion.map((version, index) => 
                        <Tooltip
                            key = {index}
                            label={version.version}
                            color="violet"
                            transitionProps={{ transition: 'skew-down', duration: 300 }}
                            withArrow
                        >
                            <Button 
                                onClick={() => {SelectBibleVersion(version.id, version.version)}}
                                className='VersionBTN'
                                variant="gradient"
                                gradient={{ from: 'purple', to: 'darkblue' }}
                                size="xl" 
                                compact 
                                uppercase
                                style = {{width : '154.5px', fontFamily : 'poppins'}}
                            >
                                {version.versionAbbreviation}
                            </Button>
                        </Tooltip>
                    )} 
                </Group>
            </Stepper.Step>

            <Stepper.Step icon = {<IconBible/>} label={PropsStep3Translate} description={<Text sx = {{fontFamily : 'poppins'}} color = "white">{Books}</Text>}>
                <br></br>
                <Group grow>
                    <TextInput
                        icon={<IconSearch/>}
                        radius="md"
                        size="md"
                        placeholder={PropsSearchBookTranslate}
                        onChange={ handleSearch }
                    />
                </Group>
                <br></br>
                <Group position='center' spacing="xs">
                    {
                        BibleBooks.filter((SearchBook) => {
                        if(search === ""){
                            return SearchBook
                        }else if(SearchBook.bookName.toLowerCase().includes(search.toLowerCase())){
                            return SearchBook
                        }
                        }).map((book, index) => (
                            <div key = {index} style = {{position : 'relative', textAlign : 'center', color : 'black'}}>
                                <Image 
                                    maw={200} 
                                    mx="auto"
                                    radius="md"
                                    src={book['bookCover'].image} 
                                    alt={book.bookName}
                                    onClick={() => { SelectBookID(book.id, book.bookName) }} 
                                /> 
                                <Text fw={700} style = {{position : 'absolute', top : '90%', left : '50%', transform : 'translate(-50%, -50%)', fontFamily : 'poppins'}}>
                                    {book.bookName}
                                </Text>
                            </div>
                        ))
                    }
                </Group>
            </Stepper.Step>

            <Stepper.Step icon = {<IconBooks/>} label={PropsStep4Translate} description={<Text sx = {{fontFamily : 'poppins'}} color = "white">{Chapter}</Text>}>
                <Group position='center' spacing="xs" >
                    <Title className = "PageTitle" color = "black" order={4} size="h1" sx = {{fontFamily : 'poppins'}}>
                            {PropsChapterNumberTranslate}
                    </Title>
                </Group>
                <br></br>
                <Group position='center' spacing="xs" >
                    {BibleChapter.map((chapter, index) =>
                        <Button
                            key = {index}
                            className='ChapterBtn'
                            variant="gradient"
                            gradient={{ from: 'purple', to: 'darkblue' }} 
                            style = {{width : '80px', height : '70px'}}
                            onClick={() => { SelectChapterID(chapter.id,chapter.chapterNumber) }}
                            >
                                {chapter.chapterNumber}
                        </Button>  
                    )}
                </Group>
            </Stepper.Step>

            <Stepper.Step icon = {<IconBook2/>} label={PropsStep5Translate} description={<Text sx = {{fontFamily : 'poppins'}} color = "white">{Verse}</Text>}>
                <Group position='center' spacing="xs" >
                    <Title className = "PageTitle" color = "black" order={4} size="h1" sx = {{fontFamily : 'poppins'}}>
                           {PropsVerseNumberTranslate}
                    </Title>
                </Group>
                <br></br>
                <Group position='center' spacing="xs" >
                    {BibleVerse.map((verse, index) =>
                        <Button
                            key = {index}
                            className='VerseBtn'
                            variant="gradient"
                            gradient={{ from: 'purple', to: 'darkblue' }} 
                            style = {{width : '80px', height : '70px'}}
                            onClick={() => { SelectVerseID(verse.id, verse.verseNumber) }}
                            >
                                {verse.verseNumber}
                        </Button>  
                    )}
                </Group>
            </Stepper.Step>
            <Stepper.Completed>
            <Group position='center' spacing="xs">
                <div className = "VerseContent" style = {{ width : '45%',height : 'auto', textAlign : 'center'}}>
                    <Paper  shadow="xl" radius="md" p="xs" >
                        <Blockquote sx = {{fontFamily : 'poppins'}} color="violet" cite={BibleVersion+" — "+BookName+' '+ChapterNumber+' : '+VerseNumber}>
                            {BibleVerseContent.verseContent}
                        </Blockquote>
                    </Paper>
                    <br></br>
                    <Divider/>
                    <br></br>
                    <Group grow>
                        <Textarea
                            label = {PropsExplanationTranslate}
                            placeholder={PropsPlaceholderExplanationTranslate}
                            size="md"
                            withAsterisk
                            minRows={20}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <br></br>
                    <Group position='right'>
                        <Button sx = {{fontFamily : 'poppins'}} leftIcon = {<IconUpload/>} variant="filled" color="green" radius="sm" uppercase>
                            {PropsBtnUploadDevotion}
                        </Button>
                    </Group>
                    <br></br>
                </div>
                
            </Group>
           
            </Stepper.Completed>
        </Stepper>
        </>
    )
}