import { notifications } from "@mantine/notifications"
import { ComponentGroup } from "../../Components/Component"
import './Community.css'
export const CommunityGroup = () => {
    return (
        <>
        <ComponentGroup
            notifications = {notifications}
        />
        </>
    )
}