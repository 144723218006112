import React, { useState, useEffect, useMemo } from "react";
import { Button, Group, Modal, useMantineTheme, TextInput, Accordion,ScrollArea, List, ThemeIcon, Alert, ActionIcon, Tooltip } from '@mantine/core';
import { IconAd, IconUpload, IconCheck, IconRefresh, IconAlertCircle } from '@tabler/icons-react';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
export const UpdateAdLocation = ({notifications, fetchAdLocation, UpdateProviderID, UpdateId, UpdateName, UpdateProvider, UpdateIos, UpdateAndroid, OpenUpdateModal, CloseUpdateModal}) => {
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const[Location, setLocation] = useState("")
    const[Ios, setIos] = useState("");
    const[Android, setAndroid] = useState("");  
    const[SelectedProviderID, setSelectedProviderID] = useState();
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UpdateAdProviderTranslate, setUpdateAdProviderTranslate] = useState('Update Ad Provider')
    const [SelectProviderTranslate, setSelectProviderTranslate] = useState('Select Provider')
    const [NoticeTranslate, setNoticeTranslate] = useState('Notice!')
    const [NoticeProviderTranslate, setNoticeProviderTranslate] = useState('Choose to update new Provider')
    const [LocationTranslate, setLocationTranslate] = useState('Location')
    const [NoticeLocationTranslate, setNoticeLocationTranslate] = useState('Ad Location Name not allowed to change')
    const [IOSAdUnitTranslate, setIOSAdUnitTranslate] = useState('IOS Ad Unit')
    const [ANDROIDAdUnitTranslate, setANDROIDAdUnitTranslate] = useState('ANDROID Ad Unit')
    const [TooltipRefresh, setTooltipRefresh] = useState('Refresh Information')
    const [BtnUpdateLocation, setBtnUpdateLocation] = useState('UPDATE LOCATION')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchAds();
        setIos(UpdateIos);
        setAndroid(UpdateAndroid);
        setSelectedProviderID(UpdateProviderID);
        fetchTranslation();
    }, [UpdateIos, UpdateAndroid, UpdateProviderID, DefaultLanguage]);
    const fetchAds = async () => {
        setLoading(true);   
        const ListAdsEP = `/TheOneAd`
        await api.get(ListAdsEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const SelectProvider = async (ProviderID, ) => {
        setSelectedProviderID(ProviderID)
    }
    const RefreshInformation = () => {
        fetchAdLocation();
        setIos(UpdateIos);
        setAndroid(UpdateAndroid);
        setSelectedProviderID(UpdateProviderID)
    } 
    const UpdateAdLocation = async () => {
        const UpdateLocationEP = `/AdLocations/`+UpdateId+``
        api.put(UpdateLocationEP, {
            adProviderId: SelectedProviderID,
            androidAdUnit: Android,
            iosAdUnit : Ios
       }).then(result => {
       if(result['status'] === 204){
           fetchAdLocation();
           notifications.show({
               id: 'load-data',
               loading: true,
               title: 'Updating Location Provider',
               message: 'Location Provider will be loaded, you cannot close this yet',
               autoClose: false,
               withCloseButton: false,
               });
               setTimeout(() => {

               //! RESET INPUTS
               setLocation("")
               setIos("")
               setAndroid("")
               setSelectedProviderID("")
               CloseUpdateModal()
               //! END OF RESET INPUTS

               notifications.update({
                   id: 'load-data',
                   color: 'teal',
                   title: 'Location Provider was uploaded',
                   message: 'Location Provider successsfully uploaded',
                   icon: <IconCheck size="1rem" />,
                   autoClose: 2000,
               });
           }, 1000); 
       }else{}
       }).catch(error =>{
       })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateAdProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateAdProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SelectProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSelectProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NoticeTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNoticeTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NoticeProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNoticeProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + LocationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setLocationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NoticeLocationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNoticeLocationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + IOSAdUnitTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setIOSAdUnitTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ANDROIDAdUnitTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setANDROIDAdUnitTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipRefresh,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipRefresh(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUpdateLocation,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUpdateLocation(translated[0])
            })
        }).then((error) =>{
        })
    }
    return(  
    <Modal 
        opened={OpenUpdateModal}
        onClose={() => {CloseUpdateModal();}}
        title={UpdateAdProviderTranslate}
        overlayProps={{
            color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
            opacity: 0.55,
            blur: 3,
        }}
        centered>
            <Group position="right">
                <Tooltip position="bottom-end" label={TooltipRefresh}  color="violet" withArrow>
                    <ActionIcon onClick={() => {RefreshInformation()}} color="violet" size="md" radius="xl" variant="transparent">
                        <IconRefresh />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <Group grow>
            <Accordion chevronPosition="left" maw={500} mx="auto">
                <Accordion.Item value="customization">
                    <Accordion.Control sx = {{fontFamily : 'poppins'}}>{SelectProviderTranslate}</Accordion.Control>
                        <Accordion.Panel>
                            <Alert sx = {{fontFamily : 'poppins'}} icon={<IconAlertCircle size="1.4rem" />} title={NoticeTranslate} color="indigo" variant="light">
                                {NoticeProviderTranslate}
                            </Alert>
                            <br></br>
                            <ScrollArea h={150} type="auto" offsetScrollbars>
                            {
                                data.map((provider, index)=>
                                <List key = {index}>
                                    <List.Item
                                        onClick = {() => { SelectProvider(provider.id, provider.adProvider) }}
                                        className="ListProvider" 
                                        sx = {{fontFamily : 'poppins'}}
                                        icon={
                                            provider.id === SelectedProviderID ?
                                            <ThemeIcon color="green" size={30} radius="xl">
                                                <IconCheck/>
                                            </ThemeIcon>
                                            :
                                            <ThemeIcon color="blue" size={30} radius="xl">
                                                <IconAd/>
                                            </ThemeIcon>
                                        }>
                                        <Group grow>
                                            {provider.adProvider}
                                            {/* <CloseButton onClick = {() =>{RemoveProvider(provider.id)}} title="Close popover" size="md" iconSize={20} /> */}
                                        </Group>
                                    </List.Item>
                                </List>
                                )   
                            }
                            </ScrollArea>
                        </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            </Group>
            <br></br>
            <Group grow>
                <TextInput
                    label={LocationTranslate}
                    onChange={(e) => {setLocation(e.target.value)}}
                    description={
                        <Alert sx = {{fontFamily : 'poppins'}} icon={<IconAlertCircle size="1.4rem" />} title={NoticeTranslate} color="orange" variant="light">
                            {NoticeLocationTranslate}
                        </Alert>
                    }
                    radius="md"
                    size="md"
                    withAsterisk
                    value={UpdateName}
                    disabled = {true}
                    sx = {{fontFamily : 'poppins'}}
                />
            </Group>
            <br></br>
            <Group grow>
                <TextInput
                    label={IOSAdUnitTranslate}
                    radius="md"
                    size="md"
                    onChange={(e) => {setIos(e.target.value)}}
                    withAsterisk
                    value={Ios}
                    error={UpdateProviderID != SelectedProviderID ? 'Please input new IOS Ad Unit' : ''}
                    sx = {{fontFamily : 'poppins'}}
                />   
            </Group>
            <br></br>
            <Group grow>
                <TextInput
                    label={ANDROIDAdUnitTranslate}
                    radius="md"
                    size="md"
                    onChange={(e) => {setAndroid(e.target.value)}}
                    withAsterisk
                    value={Android}
                    error={UpdateProviderID != SelectedProviderID ? 'Please input new ANDROID Ad Unit' : ''}
                    sx = {{fontFamily : 'poppins'}}
                />
            </Group>
            <br></br>
            <Group grow>
                <Button sx = {{fontFamily : 'poppins'}} onClick = {UpdateAdLocation} leftIcon = {<IconUpload/>} color="violet" radius="sm" size="md" uppercase>
                    {BtnUpdateLocation}
                </Button>
            </Group>
    </Modal> 
    )
}