import { IconHome, IconSpeakerphone, IconCalendarExclamation, IconBrandBlogger,
         IconMusic, IconCross, IconBuildingChurch, IconBible,  IconGitCommit, IconUpload, IconMoodSmile,
         IconFileUpload,IconMovie, IconFilePencil, IconPhotoPlus, IconBookmarkPlus, IconMicrophone, IconFileReport, IconTimeline, IconUsers, IconReport, IconSettings2, IconNews, IconUsersGroup, IconUserStar, IconBadge, IconCalendarCheck, IconCalendarEvent, IconBuildingBank, IconNotification, IconLanguage, IconVersions, IconAdCircleFilled, IconHeartBolt, IconMoodShare, IconBrandDaysCounter, IconBook, IconBook2, IconAd2, IconDeviceMobileFilled, IconDeviceMobile, IconMoneybag, IconCashBanknote, IconCash, IconSettings, IconPasswordUser
 } from '@tabler/icons-react';
const SidebarItems = [
    {
        "NavSubTitle": "Home",
        "NavSubTitleIcon": <IconHome color='#212529' style={{marginLeft : 2}}/>,
        "NavLink": "/",
        "UserStatus" : 'Super Admin & Admin'
    },
    {
        "NavSubTitle": "Daily",
        "NavSubTitleIcon" : <IconBrandDaysCounter color='#212529' style={{marginLeft : 2}}/>,
        "NavLink" : "",
        "NavContent": [
            {
                "NavItem": {
                    "NavLink": "word-of-the-day",
                    "NavIcon": <IconBible color='#0a0908'/>,
                    "NavLabel": "Word of the Day",
                }
            },
            {
                "NavItem": {
                    "NavLink": "daily-devotion",
                    "NavIcon": <IconCross color='#0a0908'/>,
                    "NavLabel": "Daily Devotion",
                }
            },
        ]
    },
    {
        "NavSubTitle": "Bible",
        "NavSubTitleIcon" : <IconBible color='#212529' style={{marginLeft : 2}}/>,
        "NavLink" : "",
        "NavContent": [
            {
                "NavItem": {
                    "NavLink": "version",
                    "NavIcon": <IconGitCommit color='#0a0908'/>,
                    "NavLabel": "Version",
                }
            },
            {
                "NavItem": {
                    "NavLink": "book",
                    "NavIcon": <IconBook2 color='#0a0908'/>,
                    "NavLabel": "Book",

                }
            },
            {
                "NavItem": {
                    "NavLink": "bible-cover",
                    "NavIcon": <IconPhotoPlus color='#0a0908'/>,
                    "NavLabel": "Bible Cover",
                }
            },
            {
                "NavItem": {
                    "NavLink": "bible-story",
                    "NavIcon": <IconMovie color='#0a0908'/>,
                    "NavLabel": "Bible Story",
                }
            },
            {
                "NavItem": {
                    "NavLink": "bible-quiz",
                    "NavIcon": <IconFilePencil color='#0a0908'/>,
                    "NavLabel": "Bible Quiz",
                }
            },
            {
                "NavItem": {
                    "NavLink": "reading-plan",
                    "NavIcon": <IconBookmarkPlus color='#0a0908'/>,
                    "NavLabel": "Reading Plan",
                }
            },
            {
                "NavItem": {
                    "NavLink": "voices",
                    "NavIcon": <IconMicrophone color='#0a0908'/>,
                    "NavLabel": "Voices",
                }
            },
            {
                "NavItem": {
                    "NavLink": "feelings",
                    "NavIcon": <IconMoodSmile color='#0a0908'/>,
                    "NavLabel": "Feelings",
                }
            }
        ]
    },
    {
        "NavSubTitle": "Ads",
        "NavSubTitleIcon" : <IconAd2 color='#212529' style={{marginLeft : 2}}/>,
        "NavLink" : "",
        "NavContent": [
            {
                "NavItem": {
                    "NavLink": "upload-ads",
                    "NavIcon": <IconUpload color='#0a0908'/>,
                    "NavLabel": "Upload Ads",
                }
            },
            {
                "NavItem": {
                    "NavLink": "customized-ads",
                    "NavIcon": <IconFileUpload color='#0a0908'/>,
                    "NavLabel": "Customize Ads",
                }
            }
        ]
    },
    {
        "NavSubTitle": "Application",
        "NavSubTitleIcon" : <IconDeviceMobile color='#212529' style={{marginLeft : 2}}/>,
        "NavLink" : "",
        "NavContent": [
            {
                "NavItem": {
                    "NavLink": "announcement",
                    "NavIcon": <IconSpeakerphone color='#0a0908'/>,
                    "NavLabel": "Announcement",
                }
            },
            {
                "NavItem": {
                    "NavLink": "event",
                    "NavIcon": <IconCalendarExclamation color='#0a0908'/>,
                    "NavLabel": "Events",
                }
            },
            {
                "NavItem": {
                    "NavLink": "reflections",
                    "NavIcon": <IconBrandBlogger color='#0a0908'/>,
                    "NavLabel": "Reflections",
                }
            },
            {
                "NavItem": {
                    "NavLink": "worship",
                    "NavIcon": <IconMusic color='#0a0908'/>,
                    "NavLabel": "Worship",
                }
            },
            {
                "NavItem": {
                    "NavLink": "church",
                    "NavIcon": <IconBuildingChurch color='#0a0908'/>,
                    "NavLabel": "Church",
                }
            },
            {
                "NavItem": {
                    "NavLink": "achievements",
                    "NavIcon": <IconUserStar color='#0a0908'/>,
                    "NavLabel": "Achievements",
                }
            },
            {
                "NavItem": {
                    "NavLink": "calendar-event",
                    "NavIcon": <IconCalendarEvent color='#0a0908'/>,
                    "NavLabel": "Calendar",
                }
            }, 
            {
                "NavItem": {
                    "NavLink": "groupcommunity",
                    "NavIcon": <IconUsersGroup color='#0a0908'/>,
                    "NavLabel": "Group",
                }
            },
            {
                "NavItem": {
                    "NavLink": "app-languages",
                    "NavIcon": <IconLanguage color='#0a0908'/>,
                    "NavLabel": "App Languages",
                }
            },
            {
                "NavItem": {
                    "NavLink": "app-version",
                    "NavIcon": <IconVersions color='#0a0908'/>,
                    "NavLabel": "App Version",
                }
            },
        ]
    },
    {
        "NavSubTitle": "Payment",
        "NavSubTitleIcon" : <IconCash color='#212529' style={{marginLeft : 2}}/>,
        "NavLink" : "",
        "NavContent": [
            {
                "NavItem": {
                    "NavLink": "payment-bank",
                    "NavIcon": <IconBuildingBank color='#0a0908'/>,
                    "NavLabel": "Bank",
                }
            },
            {
                "NavItem": {
                    "NavLink": "payment-plan",
                    "NavIcon": <IconNotification color='#0a0908'/>,
                    "NavLabel": "Plan",
                }
            },
        ]
    },
    {
        "NavSubTitle": "Utilities",
        "NavSubTitleIcon" : <IconSettings color='#212529' style={{marginLeft : 2}}/>,
        "NavLink" : "",
        "NavContent": [
            {
                "NavItem": {
                    "NavLink": "administrator",
                    "NavIcon": <IconSettings2 color='#0a0908'/>,
                    "NavLabel": "Administrator",
                }
            },
            {
                "NavItem": {
                    "NavLink": "activitylogs",
                    "NavIcon": <IconTimeline color='#0a0908'/>,
                    "NavLabel": "Activity",
                }
            },
           
           
            {
                "NavItem": {
                    "NavLink": "concern",
                    "NavIcon": <IconReport color='#0a0908'/>,
                    "NavLabel": "Concern",
                }
            },
            // {
            //     "NavItem": {
            //         "NavLink": "feeds",
            //         "NavIcon": <IconNews color='#0a0908'/>,
            //         "NavLabel": "Feeds"
            //     }
            // },
           
           
            // {
            //     "NavItem": {
            //         "NavLink": "reports",
            //         "NavIcon": <IconFileReport color='#0a0908'/>,
            //         "NavLabel": "Reports"
            //     }
            // },
            {
                "NavItem": {
                    "NavLink": "resources",
                    "NavIcon": <IconFileUpload color='#0a0908'/>,
                    "NavLabel": "Resources",
                }
            },
            {
                "NavItem": {
                    "NavLink": "userlist",
                    "NavIcon": <IconUsers color='#0a0908'/>,
                    "NavLabel": "Users",
                }
            },
        ]
    },
    {
        "NavSubTitle": "Password Manager",
        "NavSubTitleIcon": <IconPasswordUser color='#212529' style={{marginLeft : 2}}/>,
        "NavLink": "password-manager",
        "UserStatus" : 'Super Admin'
    },
]

export default SidebarItems