import React, { useState, useEffect, useRef } from "react";
import {Button, Group, Modal, useMantineTheme, Textarea, Select, NumberInput, Text, Divider, Card, Stack} from '@mantine/core';
import { IconUpload, IconCheck, IconLanguage } from '@tabler/icons-react';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios'; 
import { LanguageCardQuestion } from '../LanguageCardContent/LanguageCardQuestion';
export const UploadQuestions = ({notifications, OpenModal, CloseModal, fetchQuestions}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [Question, setQuestion] = useState("")
    const [Answer, setAnswer] = useState("")
    const [QuestionType, setQuestionType] = useState(null)
    const [Difficulty, setDifficulty] = useState(null)
    const [Choice1, setChoice1] = useState("")
    const [Choice2, setChoice2] = useState("")
    const [Choice3, setChoice3] = useState("")
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AddNewQuestionTranslate, setAddNewQuestionTranslate] = useState('Add New Question')
    const [QuestionTypeTranslate, setQuestionTypeTranslate] = useState('Question Type')
    const [QuestionTypeDescTranslate, setQuestionTypeDescTranslate] = useState('Select Multiple Choice or True | False')
    const [PlaceholderQuestionType, setPlaceholderQuestionType] = useState('Pick One')
    const [TrueOrFalseTranslate, setTrueOrFalseTranslate] = useState('True or False')
    const [MultipleChoiceTranslate, setMultipleChoiceTranslate] = useState('Multiple Choice')
    const [QuestionDifficultyTranslate, setQuestionDifficultyTranslate] = useState('Question Difficulty')
    const [QuestionDifficultyDescTranslate, setQuestionDifficultyDescTranslate] = useState('Select Question Difficulty')
    const [PlaceholderQuestionDifficulty, setPlaceholderQuestionDifficulty] = useState('Pick One')
    const [EasyTranslate, setEasyTranslate] = useState('Easy')
    const [MediumTranslate, setMediumTranslate] = useState('Medium')
    const [HardTranslate, setHardTranslate] = useState('Hard')
    const [QuestionTranslate, setQuestionTranslate] = useState('Question')
    const [PlaceholderQuestion, setPlaceholderQuestion] = useState('Enter Question')
    const [CorrectAnswerTranslate, setCorrectAnswerTranslate] = useState('Correct Answer')
    const [PlaceholderCorrectAnswer, setPlaceholderCorrectAnswer] = useState('Enter Correct Answer')
    const [Choice1Translate, setChoice1Translate] = useState('Choice No. 1')
    const [PlaceholderChoice1, setPlaceholderChoice1] = useState('Enter Choice No. 1')
    const [Choice2Translate, setChoice2Translate] = useState('Choice No. 2')
    const [PlaceholderChoice2, setPlaceholderChoice2] = useState('Enter Choice No. 2')
    const [Choice3Translate, setChoice3Translate] = useState('Choice No. 3')
    const [PlaceholderChoice3, setPlaceholderChoice3] = useState('Enter Choice No. 3')
    const [BtnUploadQuestion, setBtnUploadQuestion] = useState('UPLOAD QUESTION')

    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [TranslateLoading, setTranslateLoading] = useState(false)
    let objTranslated = {}
    let ArrayTranslated = []

    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
        fetchLanguages();
    }, [DefaultLanguage]);
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddNewQuestionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddNewQuestionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + QuestionTypeTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setQuestionTypeTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + QuestionTypeDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setQuestionTypeDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderQuestionType,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderQuestionType(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TrueOrFalseTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTrueOrFalseTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MultipleChoiceTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMultipleChoiceTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + QuestionDifficultyTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setQuestionDifficultyTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + QuestionDifficultyDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setQuestionDifficultyDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderQuestionDifficulty,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderQuestionDifficulty(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EasyTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEasyTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MediumTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMediumTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + HardTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setHardTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + QuestionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setQuestionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderQuestion,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderQuestion(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CorrectAnswerTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCorrectAnswerTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderCorrectAnswer,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderCorrectAnswer(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Choice1Translate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChoice1Translate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderChoice1,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderChoice1(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Choice2Translate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChoice2Translate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderChoice2,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderChoice2(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + Choice3Translate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChoice3Translate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderChoice3,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderChoice3(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadQuestion,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadQuestion(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleChangeQuestion = (event) => {
        setQuestion(event.target.value)
    }
    const handleChangeAnswer = (event) => {
        setAnswer(event.target.value)
    }
    const handleChangeChoice1 = (event) => {
        setChoice1(event.target.value)
    }
    const handleChangeChoice2 = (event) => {
        setChoice2(event.target.value)
    }
    const handleChangeChoice3 = (event) => {
        setChoice3(event.target.value)
    }
    const handleChangeQuestionType = (value) => {
        setQuestionType(value)
    }
    const handleChangeDiffuclty = (value) => {
        setDifficulty(value)
    }
    const UploadQuestionFunction = async () => {
        let objQuestion = {}
        let objAnswer = {}
        let objChoice1 = {}
        let objChoice2 = {}
        let objChoice3 = {}

        let ArrayQuestion = []
        let ArrayAnswer = []
        let ArrayChoice1 = []
        let ArrayChoice2 = []
        let ArrayChoice3 = []

        LanguageTranslated.length = LanguageTranslated.length - 1
        LanguageTranslated.map((translated) => {

            objQuestion = {}
            objAnswer = {}

            objQuestion['text'] = translated.question
            objQuestion['language'] = translated.code
            ArrayQuestion.push(objQuestion) 

            objAnswer['value'] = translated.answer
            objAnswer['language'] = translated.code
            ArrayAnswer.push(objAnswer)

            //! Choice 1
            objChoice1 = {}
            objChoice1['value'] = translated.choice1
            objChoice1['language'] = translated.code
            ArrayChoice1.push(objChoice1) 
            
            //! Choice 2
            objChoice2 = {}
            objChoice2['value'] = translated.choice2
            objChoice2['language'] = translated.code
            ArrayChoice2.push(objChoice2) 

            //! Choice 3
            objChoice3 = {}
            objChoice3['value'] = translated.choice3
            objChoice3['language'] = translated.code
            ArrayChoice3.push(objChoice3) 
        })
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Question',
            message: 'Question will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        var postData = {
            "text": Question,
            "type" : QuestionType,
            "difficulty" : Difficulty,
            "answer": Answer,
            "questionLocalization" : ArrayQuestion,
            "answerLocalization" : ArrayAnswer
        };
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };
        if(QuestionType === '1'){
            Axios.post(''+APILink+'/api/questions', postData, axiosConfig)
            .then((result) => {
                var postDataChoice1 = ArrayChoice1
                Axios.post(''+APILink+'/api/questions/'+result.data.id+'/choices?answer='+Choice1+'', postDataChoice1, axiosConfig)
                .then((res) => {
                    var postDataChoice2 = ArrayChoice2
                    Axios.post(''+APILink+'/api/questions/'+result.data.id+'/choices?answer='+Choice2+'', postDataChoice2, axiosConfig)
                    .then((res) => {
                        var postDataChoice3 = ArrayChoice3
                        Axios.post(''+APILink+'/api/questions/'+result.data.id+'/choices?answer='+Choice3+'', postDataChoice3, axiosConfig)
                        .then((res) => {
                            if(res.status === 200){
                                setTimeout(() => {
                                    CloseModal();
                                    fetchQuestions();
                                    localStorage.removeItem('value')
                                    notifications.update({
                                        id: 'load-data',
                                        color: 'teal',
                                        title: 'Question was uploaded',
                                        message: 'Question successsfully uploaded',
                                        icon: <IconCheck size="1rem" />,
                                        autoClose: 2000,
                                    });
                                }, 1000); 
                            }else{}
                        }).catch((err) => {
                        }) 
                    }).catch((err) => {
                    })
                }).catch((err) => {
                })
            }).catch((err) => {
            })
        }
        else
        {
            Axios.post(''+APILink+'/api/questions', postData, axiosConfig)
            .then((result) => {
                if(result.status === 201){
                    var postDataChoice1 = ArrayChoice1
                    Axios.post(''+APILink+'/api/questions/'+result.data.id+'/choices?answer='+Choice1+'', postDataChoice1, axiosConfig)
                    .then((res) => {
                        if(res.status === 200){
                            setTimeout(() => {
                                CloseModal();
                                fetchQuestions();
                                localStorage.removeItem('value')
                                notifications.update({
                                    id: 'load-data',
                                    color: 'teal',
                                    title: 'Question was uploaded',
                                    message: 'Question successsfully uploaded',
                                    icon: <IconCheck size="1rem" />,
                                    autoClose: 2000,
                                });
                            }, 1000); 
                        }else{}
                    }).catch((err) => {
                    })
                }else{}
            }).catch((err) => {
            })
        }
    }
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedQuestion = ''
            let GetTranslatedAnswer = ''
            let GetTranslatedChoice1 = ''
            let GetTranslatedChoice2 = ''
            let GetTranslatedChoice3 = ''
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": Question},
                ]
            }).then((result) => {
                GetTranslatedQuestion = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedQuestion = gettranslation.text
                    })
                ))
                Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                    method: "POST",
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                        "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                    },
                    data:[
                        {"Text": Answer},
                    ]
                }).then((result) => {
                    GetTranslatedAnswer = ''
                    result.data.map((a) => (
                        a.translations.map((gettranslation) => {
                            GetTranslatedAnswer = gettranslation.text
                        })
                    ))
                    Axios({
                        url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                        method: "POST",
                        headers: {
                            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                            "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                        },
                        data:[
                            {"Text": Choice1},
                        ]
                    }).then((result) => {
                        GetTranslatedChoice1 = ''
                        result.data.map((a) => (
                            a.translations.map((gettranslation) => {
                                GetTranslatedChoice1 = gettranslation.text
                            })
                        ))
                        Axios({
                            url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                            method: "POST",
                            headers: {
                                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                                "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                            },
                            data:[
                                {"Text": Choice2},
                            ]
                        }).then((result) => {
                            GetTranslatedChoice2 = ''
                            result.data.map((a) => (
                                a.translations.map((gettranslation) => {
                                    GetTranslatedChoice2 = gettranslation.text
                                })
                            ))
                            Axios({
                                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                                method: "POST",
                                headers: {
                                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                                },
                                data:[
                                    {"Text": Choice3},
                                ]
                            }).then((result) => {
                                GetTranslatedChoice3 = ''
                                result.data.map((a) => (
                                    a.translations.map((gettranslation) => {
                                        GetTranslatedChoice3 = gettranslation.text
                                    })
                                ))
                                objTranslated = {}
                                objTranslated['code'] = code.languageCode
                                objTranslated['question'] = GetTranslatedQuestion
                                objTranslated['answer'] = GetTranslatedAnswer
                                objTranslated['choice1'] = GetTranslatedChoice1
                                objTranslated['choice2'] = GetTranslatedChoice2
                                objTranslated['choice3'] = GetTranslatedChoice3
                                ArrayTranslated.push(objTranslated)
                                setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                                setTranslateLoading(false)
                            }).catch((error) => { 
                            })
                        }).catch((error) => { 
                        })
                    }).catch((error) => { 
                    })
                }).catch((error) => { 
                })
            }).catch((error) => { 
            })
        }
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={AddNewQuestionTranslate}
            size="100%"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered>
            <Group align='start' position='center'>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Group grow>
                        <Select
                            label={QuestionTypeTranslate}
                            placeholder={PlaceholderQuestionType}
                            description={<Text sx = {{fontFamily : 'poppins'}}>{QuestionTypeDescTranslate}</Text>}
                            size="md"
                            data={[
                                { value: '0', label: TrueOrFalseTranslate},
                                { value: '1', label: MultipleChoiceTranslate },
                            ]}
                            dropdownPosition="bottom"
                            transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
                            withinPortal
                            onChange={handleChangeQuestionType}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    <Select
                            label={QuestionDifficultyTranslate}
                            placeholder={PlaceholderQuestionDifficulty}
                            description={<Text sx = {{fontFamily : 'poppins'}}>{QuestionDifficultyDescTranslate}</Text>}
                            size="md"
                            data={[
                                { value: '0', label: EasyTranslate },
                                { value: '1', label: MediumTranslate },
                                { value: '2', label: HardTranslate },
                            ]}
                            dropdownPosition="bottom"
                            transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
                            withinPortal
                            onChange={handleChangeDiffuclty}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <br/>
                    <Group grow>
                        <Textarea
                            placeholder={PlaceholderQuestion}
                            label={QuestionTranslate}
                            size="md"
                            minRows={5}
                            withAsterisk
                            onChange={handleChangeQuestion}
                            sx = {{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <br/>
                    {QuestionType === '1' ? 
                        <Group grow>
                            <Textarea
                                placeholder={PlaceholderCorrectAnswer}
                                label={CorrectAnswerTranslate}
                                size="sm"
                                minRows={3}
                                withAsterisk
                                onChange={handleChangeAnswer}
                                sx = {{fontFamily : 'poppins'}}
                            />
                            <Textarea
                                placeholder={PlaceholderChoice1}
                                label={Choice1Translate}
                                size="sm"
                                minRows={3}
                                withAsterisk
                                onChange={handleChangeChoice1}
                                sx = {{fontFamily : 'poppins'}}
                            />
                            <Textarea
                                placeholder={PlaceholderChoice2}
                                label={Choice2Translate}
                                size="sm"
                                minRows={3}
                                withAsterisk
                                onChange={handleChangeChoice2}
                                sx = {{fontFamily : 'poppins'}}
                            />
                            <Textarea
                                placeholder={PlaceholderChoice3}
                                label={Choice3Translate}
                                size="sm"
                                minRows={3}
                                withAsterisk
                                onChange={handleChangeChoice3}
                                sx = {{fontFamily : 'poppins'}}
                            />
                        </Group>
                        :
                        <Group grow>
                            <Textarea
                                placeholder={PlaceholderCorrectAnswer}
                                label={CorrectAnswerTranslate}
                                size="sm"
                                minRows={3}
                                withAsterisk
                                onChange={handleChangeAnswer}
                                sx = {{fontFamily : 'poppins'}}
                            />
                            <Textarea
                                placeholder={PlaceholderChoice1}
                                label={Choice1Translate}
                                size="sm"
                                minRows={3}
                                withAsterisk
                                onChange={handleChangeChoice1}
                                sx = {{fontFamily : 'poppins'}}
                            />
                        </Group>
                    }
                    <br/>
                    <Group grow>
                        <Button sx = {{fontFamily : 'poppins'}} color="violet" size="md" leftIcon ={<IconUpload/>} onClick={UploadQuestionFunction}>
                            {BtnUploadQuestion}
                        </Button> 
                        <Button onClick={TranslateSelectedLanguages} leftIcon = {<IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            Translate
                        </Button>
                    </Group>   
                </Card>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Stack justify="flex-start">
                        <Group position = "center">
                            <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>TRANSLATED CONTENT</Text>
                        </Group>
                        <LanguageCardQuestion
                            LanguageTranslated = {LanguageTranslated}
                            TranslateLoading = {TranslateLoading}
                            QuestionType = {QuestionType}
                        />
                    </Stack>
                </Card>
            </Group>
        </Modal>
        </>
    )
}