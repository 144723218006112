import React, { useState, useEffect, useMemo } from "react";
import { Modal, Group, TextInput, Textarea, Button, Text, Switch } from '@mantine/core';
import { IconAd, IconUpload, IconCheck, IconRefresh,IconAlertCircle, IconPencil } from '@tabler/icons-react';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
export const UpdateCustomizedAds = ({OpenModal, CloseModal, fetchCustomizedAds, notifications, GetID, GetTitle, GetName, GetDescription, GetURL}) => {
    const [Title, setTitle] = useState("");
    const [Name, setName] = useState("");
    const [Description, setDescription] = useState("");
    const [Url, setUrl] = useState("");
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UpdateCustomizeAdsTranslate, setUpdateCustomizeAdsTranslate] = useState('Update Customized Ads')
    const [TitleTranslate, setTitleTranslate] = useState('Title')
    const [PlaceholderTitle, setPlaceholderTitle] = useState('Enter Title')
    const [NameTranslate, setNameTranslate] = useState('Name')
    const [PlaceholderName, setPlaceholderName] = useState('Enter Name')
    const [DescriptionTranslate, setDescriptionTranslate] = useState('Description')
    const [PlaceholderDescription, setPlaceholderDescription] = useState('Enter Description')
    const [UrlTranslate, setUrlTranslate] = useState('Link')
    const [PlaceholderUrl, setPlaceholderUrl] = useState('Enter Url')
    const [BtnUpdateInformation, setBtnUpdateInformation] = useState('UPDATE INFORMATION')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateCustomizeAdsTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateCustomizeAdsTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NameTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNameTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderName,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderName(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DescriptionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDescriptionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDescription,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDescription(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UrlTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUrlTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderUrl,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderUrl(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUpdateInformation,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUpdateInformation(translated[0])
            })
        }).then((error) =>{
        }) 
    }
    const handleTitleContent = (event) => {
        setTitle(event.target.value)
    }
    const handleNameContent = (event) => {
        setName(event.target.value)
    }
    const handleDescriptionContent = (event) => {
        setDescription(event.target.value)
    }
    const handleUrlContent = (event) => {
        setUrl(event.target.value)
    }
    const UpdateCustomizeAdsFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Ads Provider',
            message: 'Ads will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UpdateLocationEP = `/CustomizedAds/`+GetID+``
        api.put(UpdateLocationEP, {
            title: Title,
            name: Name,
            description: Description,
            url: Url
        }).then(result => {
        if(result['status'] === 204){
            setTimeout(() => {
                //! RESET INPUTS
                fetchCustomizedAds();
                CloseModal();
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Location Ads was uploaded',
                    message: 'Location Ads successsfully uploaded',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    return(
        <Modal 
            opened={OpenModal}
            onClose={() => {CloseModal();}}
            title={UpdateCustomizeAdsTranslate}
            centered>
            <Group mt="md" mb="xs" grow>
                <TextInput
                    placeholder={PlaceholderTitle}
                    label={TitleTranslate}
                    radius="md"
                    size="md"
                    defaultValue = {GetTitle}
                    onChange={handleTitleContent}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <TextInput
                    placeholder={PlaceholderName}
                    label={NameTranslate}
                    radius="md"
                    size="md"
                    defaultValue = {GetName}
                    onChange={handleNameContent}
                    sx={{fontFamily : 'poppins'}}
                    
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Textarea
                    placeholder={PlaceholderDescription}
                    label={DescriptionTranslate}
                    radius="md"
                    size="md"
                    minRows={6}
                    defaultValue = {GetDescription}
                    onChange={handleDescriptionContent}
                    sx={{fontFamily : 'poppins'}}
                    
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <TextInput
                    placeholder={PlaceholderUrl}
                    label={UrlTranslate}
                    radius="md"
                    size="md"
                    type="url"
                    withAsterisk
                    defaultValue = {GetURL}
                    onChange={handleUrlContent}
                    sx={{fontFamily : 'poppins'}}
                    
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UpdateCustomizeAdsFunction} leftIcon = {<IconPencil/>} color="violet" size="md" uppercase>
                    {BtnUpdateInformation}
                </Button>
            </Group>
        </Modal>
    )
}