
import React, { useState, forwardRef, useEffect } from "react";
import { Button, Group, Modal, useMantineTheme, Textarea, Select, Text, TextInput } from '@mantine/core';
import { IconUpload, IconCheck, IconCalendar } from '@tabler/icons-react';
import { api } from '../../Utils'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
export const UploadEvent = ({notifications, OpenModal, CloseModal, fetchEvent}) => {
    const theme = useMantineTheme();
    const [startDate, setStartDate] = useState(new Date());
    const [Title, setTitle] = useState("");
    const [Description, setDescription] = useState("");
    const [Keyword, setKeyword] = useState("");
    const [Location, setLocation] = useState("");
    const [ConfirmModal, setConfirmModal] = useState(false);
    let maxCharacter = 100
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [CreateEventTitle, setCreateEventTitle] = useState('Add New Event')
    const [EventTitleTranslate, setEventTitleTranslate] = useState('Title')
    const [PlaceholderTitleTranslate, setPlaceholderTitleTranslate] = useState('Enter Title')
    const [EventDescTranslate, setEventDescTranslate] = useState('Description')
    const [PlaceholderDescTranslate, setPlaceholderDescTranslate] = useState('Enter Description')
    const [EventKeywordTranslate, setEventKeywordTranslate] = useState('Keyword')
    const [PlaceholderKeywordTranslate, setPlaceholderKeywordTranslate] = useState('Enter Keywords')
    const [EventDateTranslate, setEventDateTranslate] = useState('Select Date')
    const [EventLocationTranslate, setEventLocationTranslate] = useState('Location')
    const [PlaceholderLocationTranslate, setPlaceholderLocationTranslate] = useState('Enter Location')
    const [BtnUploadEventTranslate, setBtnUploadEventTranslate] = useState('UPLOAD EVENT')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Uploading')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Upload this Event?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateEventTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateEventTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EventTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEventTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EventDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEventDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EventKeywordTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEventKeywordTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderKeywordTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderKeywordTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EventDateTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEventDateTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EventLocationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEventLocationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderLocationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderLocationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadEventTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadEventTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const DatePickerBtn = forwardRef(({ value, onClick }, ref) => (
        <>
        <Text  sx = {{fontFamily : 'poppins'}} fw={500}>{EventDateTranslate}</Text>
        <Group grow>
            <Button  sx = {{fontFamily : 'poppins'}} leftIcon = {<IconCalendar/>} variant="outline" color="dark" size="md" uppercase  onClick={onClick} ref={ref} >
            {value}
            </Button>
        </Group>
        </>
    ));
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }
    const handleChangeKeyword = (event) => {
        setKeyword(event.target.value)
    }
    const handleChangeLocation = (event) => {
        setLocation(event.target.value)
    }
    const UploadEventFunction = async() =>{
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Event',
            message: 'Event will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const UploadEP = `/TheOneEvents`
        api.post(UploadEP, {
            title : Title,
            description : Description,
            location : Location,
            keywords : Keyword,
            date : startDate,
        }).then(result => {
            if(result['status'] === 201){
                setTimeout(() => {
                    CloseModal();
                    fetchEvent();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Event was uploaded',
                        message: 'Event successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    return (
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={CreateEventTitle}
            size="lg"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
        <Group grow>
            <TextInput
                label={EventTitleTranslate}
                placeholder={PlaceholderTitleTranslate}
                radius="xs"
                size="md"
                withAsterisk
                onChange={handleChangeTitle}
                sx = {{fontFamily : 'poppins'}}
            />
        </ Group>
        <Group grow>
            <Textarea
                label={EventDescTranslate}
                placeholder={PlaceholderDescTranslate}
                size="md"
                minRows={5}
                withAsterisk
                onChange={handleChangeDescription}
                description={
                    Description.length + " of " + maxCharacter
                }
                error = {
                    Description.length > maxCharacter ? 
                    'you reach the limit of characters!'
                    :
                    ''
                }
                minLength={100}
                sx = {{fontFamily : 'poppins'}}
            />
        </Group>
        <Group grow>
            <Textarea
                label={EventKeywordTranslate}
                placeholder={PlaceholderKeywordTranslate}
                size="md"
                minRows={2}
                withAsterisk
                onChange={handleChangeKeyword}
                sx = {{fontFamily : 'poppins'}}
            />
        </Group>
        <Group grow>
            <DatePicker  
                selected={startDate} 
                onChange={(date) => setStartDate(date)} 
                customInput={<DatePickerBtn />}
            />
        </Group>
        <Group grow>
            <TextInput
                label={EventLocationTranslate}
                placeholder={PlaceholderLocationTranslate}
                radius="xs"
                size="md"
                withAsterisk
                onChange={handleChangeLocation}
                sx = {{fontFamily : 'poppins'}}
            />
        </ Group>
        <Group mt="md" mb="xs" grow>
            <Button  sx = {{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
               {BtnUploadEventTranslate}
            </Button>
        </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text  sx = {{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                </Group>
            </Group>
            <br/>
            <Group position='right'>
                <Button  sx = {{fontFamily : 'poppins'}} onClick = {UploadEventFunction} color="teal" size="sm" uppercase>{ConfirmTranslate}</Button>
                <Button  sx = {{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase>{CancelTranslate}</Button>
            </Group>
        </Modal>
        </>
    )
}