import React, { useState } from "react";
import { Modal, Group, TextInput, Textarea, Button, Text, Divider, Alert, Select } from '@mantine/core'
import { IconUpload, IconCheck, IconAlertCircle } from "@tabler/icons-react";
import { api } from '../../Utils'
export const UploadAchievement = ({OpenModal, CloseModal, fetchAchievement, notifications, PropsAddNewAchievementTranslate, PropsAchievementTitleTranslate, PropsPlaceholderAchievementTitle, PropseDescriptionTranslate, PropsPlaceholderDescription, PropsAchievementDescriptionTranslate, PropsPlaceholderAchievementDescription, PropsAchievementSubtitleTranslate, PropsPlaceholderAchievementSubtitle, PropsPointSourceTranslate, PropsPlaceholderPointSource, PropsNoticeTranslate, PropsTimeFormatTranslate, PropsPlaceholderTimeFormat, PropsSecondTranslate, PropsMinuteTranslate, PropsHourTranslate, PropsTimeValueTranslate, PropsPlaceholderTimeValue, PropsRequiredPointsTranslate, PropsPlaceholderRequiredPoints, PropsExperiencePointsTranslate, PropsPlaceholderExperiencePoints, PropsBtnUploadAchievementTranslate, PropsConfirmTitleTranslate, PropsConfirmMessageTranslate, PropsConfirmTranslate, PropsCancelTranslate}) => {
    const [AchievementTitle, setAchievementTitle] = useState("")
    const [Description, setDescription] = useState("")
    const [AchievementDescription, setAchievementDescription] = useState("")
    const [AchievementSubtitle, setAchievementSubtitle] = useState("")
    const [PointSource, setPointSource] = useState("")
    const [TimeFormat, setTimeFormat] = useState("")
    const [TimeValue, setTimeValue] = useState("")
    const [RequiredPoints, setRequiredPoints] = useState("")
    const [ExperiencePoints, setExperiencePoints] = useState("")
    const [ConfirmModal, setConfirmModal] = useState(false)
    let Computation = TimeFormat * TimeValue
    const handleChangeAchievementTitle = (event) => {
        setAchievementTitle(event.target.value)
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }
    const handleChangeAchievementDescription = (event) => {
        setAchievementDescription(event.target.value)
    }
    const handleChangeAchievementSubtitle = (event) => {
        setAchievementSubtitle(event.target.value)
    }
    const handleChangePointSource = (event) => {
        setPointSource(event.target.value)
    }
    const handleChangeTimeValue = (event) => {
        setTimeValue(event.target.value)
    }
    const handleChangeRequiredPoints = (event) => {
        if(Computation === 0){
            setRequiredPoints(event.target.value)
        }else{
            setRequiredPoints(Computation)
        } 
    }
    const handleChangeExperiencePoints = (event) => {
        setExperiencePoints(event.target.value)
    }
    const handleChangeTimeFormat = (value) => {
        setTimeFormat(value)
    }
    const UploadAchievementFunction = async() => {
        setConfirmModal(false)
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Achievement',
            message: 'Achievement will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UploadEP = `/Achievements`
        await api.post(UploadEP, {
            achievementName : AchievementTitle,
            description : Description,
            achievementDescription : AchievementDescription,
            subtitle : AchievementSubtitle,
            pointSource : PointSource,
            requiredPoints : RequiredPoints,
            experiencePoints : ExperiencePoints,
        }).then(result => {
            if(result['status'] === 201){
                setTimeout(() => {
                    CloseModal();
                    fetchAchievement();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Achievement was uploaded',
                        message: 'Achievement successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={PropsAddNewAchievementTranslate}
            size="lg" 
            centered
        >
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderAchievementTitle}
                    label={PropsAchievementTitleTranslate}
                    variant="filled"
                    size="md"
                    withAsterisk
                    onChange={handleChangeAchievementTitle}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <br/>
            <Group grow>
                <Textarea
                    placeholder={PropsPlaceholderDescription}
                    label={PropseDescriptionTranslate}
                    variant="filled"
                    size="md"
                    withAsterisk
                    minRows={5}
                    onChange={handleChangeDescription}
                    sx={{fontFamily : 'poppins'}}
                />
                <Textarea
                    placeholder={PropsPlaceholderAchievementDescription}
                    label={PropsAchievementDescriptionTranslate}
                    variant="filled"
                    size="md"
                    withAsterisk
                    minRows={5}
                    onChange={handleChangeAchievementDescription}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group grow>
                <Textarea
                    placeholder={PropsPlaceholderAchievementSubtitle}
                    label={PropsAchievementSubtitleTranslate}
                    variant="filled"
                    size="md"
                    withAsterisk
                    minRows={5}
                    onChange={handleChangeAchievementSubtitle}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <br/>
            <Group grow>
            <TextInput
                placeholder={PropsPlaceholderPointSource}
                label={PropsPointSourceTranslate}
                variant="filled"
                size="md"
                withAsterisk
                onChange={handleChangePointSource}
                sx={{fontFamily : 'poppins'}}
            />
            </Group>
            <br/>
            <Divider/>
            <br/>
            <Group>
                <Alert sx={{fontFamily : 'poppins'}} icon={<IconAlertCircle size="1rem"/>} title={PropsNoticeTranslate} color="red" variant="light"/>
            </Group>
            <Group grow>
            <Select
                label={PropsTimeFormatTranslate}
                placeholder={PropsPlaceholderTimeFormat}
                data={[
                    { value: '1000', label: PropsSecondTranslate },
                    { value: '60000', label: PropsMinuteTranslate },
                    { value: '3600000', label: PropsHourTranslate }
                ]}
                onChange={handleChangeTimeFormat}
                sx={{fontFamily : 'poppins'}}
            />
            </Group>
            <br/>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderTimeValue}
                    label={PropsTimeValueTranslate}
                    variant="filled"
                    size="md"
                    withAsterisk
                    onChange={handleChangeTimeValue}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <br/>
            <Divider/>
            <br/>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderRequiredPoints}
                    label={PropsRequiredPointsTranslate}
                    variant="filled"
                    size="md"
                    withAsterisk
                    onChange={handleChangeRequiredPoints}
                    sx={{fontFamily : 'poppins'}}
                    value={
                        Computation === 0 ?
                        RequiredPoints
                        :
                        Computation
                    }
                />
            </Group>
            <br/>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderExperiencePoints}
                    label={PropsExperiencePointsTranslate}
                    variant="filled"
                    size="md"
                    withAsterisk
                    onChange={handleChangeExperiencePoints}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} size="md" uppercase  sx={{background : 'rgb(71 46 121)',  ":hover" : {
                        background : 'rgb(39, 20, 77)'
                    }}}>
                    {PropsBtnUploadAchievementTranslate}
                </Button>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={PropsConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text sx={{fontFamily : 'poppins'}}>{PropsConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button sx={{fontFamily : 'poppins'}}onClick = {UploadAchievementFunction} color="teal" size="sm" uppercase> {PropsConfirmTranslate} </Button>
                <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {PropsCancelTranslate} </Button>
            </Group>
        </Modal>
        </>
    )
}