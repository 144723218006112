import React, { useState, useEffect } from "react";
import { Group, Divider, Text, Loader } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
import ReactECharts from 'echarts-for-react';
import Moment from 'moment';
import { IconFaceMaskOff } from "@tabler/icons-react";
export const TotalUserSubscription = () => {
    var sourceLang = 'en';
    const DateToday = Moment().toISOString(new Date());
    const [DailySubscription, setDailySubscription] = useState([])
    const [loading, setLoading] = useState(false)
    const [TotalSubscriber, setTotalSubscriber] = useState('')
    const [TotalSubscriberToday, setTotalSubscriberToday] = useState('')
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [SubscriptionTitle, setSubscriptionTitle] = useState('SUBSCRIPTIONS')
    const [TotalSubscriptionTitle, setTotalSubscriptionTitle] = useState('TOTAL SUBSCRIBE')
    const [SubscribeTodayTitle, setSubscribeTodayTitle] = useState('SUBSCRIBE TODAY')
    const [NoSubscribeTranslate, setNoSubscribeTranslate] = useState('NO SUBSCRIPTIONS THIS WEEK')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        GetTotalSubscriber()
        GetTotalSubscriberToday()
        fetchTranslation()
        fetchDailySubscrription()
    }, [DefaultLanguage]);
    const GetTotalSubscriber = async() => {
        setLoading(true);  
        const TotalUserEP = `/subscriptions/subscribercount`
        await api.get(TotalUserEP).then((result) => {
            setTotalSubscriber(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const GetTotalSubscriberToday = async() => {
        setLoading(true);  
        const TotalUserEP = `/subscriptions/subscribercount/today`
        await api.get(TotalUserEP).then((result) => {
            setTotalSubscriberToday(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SubscriptionTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSubscriptionTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TotalSubscriptionTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTotalSubscriptionTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SubscribeTodayTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSubscribeTodayTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NoSubscribeTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNoSubscribeTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const fetchDailySubscrription = async() => {
        setLoading(true);  
        const TotalUserTodayEP = `statistics/dailySubscription?date=`+DateToday+`&lastDays=7`
        await api.get(TotalUserTodayEP).then((result) => {
            setDailySubscription(result.data)
            setLoading(false);
        }).catch(error => {
        })
    }
    const options = {
        grid: { top: 8, right: 8, bottom: 24, left: 46},
        xAxis: {
          type: 'category',
          data: [Moment(DailySubscription[0]?.date).format('ddd'), Moment(DailySubscription[1]?.date).format('ddd'), Moment(DailySubscription[2]?.date).format('ddd'), Moment(DailySubscription[3]?.date).format('ddd'), Moment(DailySubscription[4]?.date).format('ddd'), Moment(DailySubscription[5]?.date).format('ddd'), Moment(DailySubscription[6]?.date).format('ddd')],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [DailySubscription[0]?.count, DailySubscription[1]?.count, DailySubscription[2]?.count, DailySubscription[3]?.count, DailySubscription[4]?.count, DailySubscription[5]?.count, DailySubscription[6]?.count],
            type: 'line',
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
    }
    return(
        <>  
            <Group position="center">
                <Text fw = {900} sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>{SubscriptionTitle}</Text>
            </Group>
            {loading ?
            <Group position="center" sx={{padding : 20}}>
                <Loader color="violet" variant="dots" size="md"/>
            </Group>
            :
            DailySubscription ? 
            <Group position="center" sx={{margin : 12}}>
                <Text align = "center" fz="md" fw = {700} sx={{fontFamily : 'poppins'}}>{NoSubscribeTranslate}</Text>
            </Group>
             :  
             <ReactECharts option={options} />
            }
        </>
    )
}