import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { TextInput, Group, Text, Tooltip, ActionIcon, Modal, Button, Stack, Menu, Grid, Card, Image } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconPlus, IconEye, IconCheck, IconPhoto, IconDotsVertical, IconGrid4x4, IconList, IconPhotoX, IconLocationCheck, IconGlobe, IconPinFilled, IconLocationShare, IconAddressBook, IconPlanet, IconWorld, IconDots  } from '@tabler/icons-react';
import { UploadEvent, UploadImageEvent } from "../Component";
import Axios from 'axios';
import { motion } from "framer-motion";
export const ListofEvents = ({notifications}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [OpenUploadEvent, setOpenUploadEvent] = useState(false)
    const [OpenUploadImages, setOpenUploadImages] = useState(false)
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [EventID, setEventID] = useState("")
    const [search, setSearch] = useState('');
    const [ViewType, setViewType] = useState('List')
    const countPerPage = 10;
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblEventTranslate, setTblEventTranslate] = useState('EVENT')
    const [PlaceHolderSearch, setPlaceHolderSearch] = useState('Search Event Title')
    const [TooltipCreateEvent, setTooltipCreateEvent] = useState('Create Event')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [ViewEventTranslate, setViewEventTranslate] = useState('View Event')
    const [EditContentTranslate, setEditContentTranslate] = useState('Edit Content')
    const [RemoveEventTranslate, setRemoveEventTranslate] = useState('Remove Event')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Deleting')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Delete this Event?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchEvent();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblEventTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblEventTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceHolderSearch,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceHolderSearch(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipCreateEvent,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipCreateEvent(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ViewEventTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setViewEventTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EditContentTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEditContentTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveEventTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveEventTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setEventID(ID)
    }
    const DeleteEvent = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Event',
            message: 'Event will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const EventEP = `/TheOneEvents/`+EventID+``
        await api.delete(EventEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchEvent();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Event was deleted',
                        message: 'Event successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const UploadImageModal = async(ID) => {
        setOpenUploadImages(true)
        setEventID(ID)
    }
    const fetchEvent = async () => {
        setLoading(true);   
        const ListEventEP = `/TheOneEvents?PageNumber=1&PageSize=150&resolution=1`
        await api.get(ListEventEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx = {{fontFamily : 'poppins'}}>{TblEventTranslate}</Text>,
            cell: row => 
            <Group position="apart" sx={{width : '100%'}}>
                <Group sx={{maxWidth : '95%'}} className="EventContent">
                    <Stack spacing={1} justify="flex-start">
                        <Text fw={700} lineClamp = {1} sx={{lineHeight: 1.5, color : '#4a1e9e', fontSize : 22, fontFamily : 'poppins'}}>{row.title}</Text>
                        <Text fw={600} lineClamp = {3} fz="sm" sx = {{fontFamily : 'poppins'}}>{row.description}</Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx = {{fontFamily : 'poppins'}}>{ActionTranslate}</Text></Menu.Label>
                            <a href = "https://blog.bible.ph/" target="_blank">
                                <Menu.Item icon = {<IconEye/>} sx = {{fontFamily : 'poppins'}}>{ViewEventTranslate}</Menu.Item>
                            </a>
                            <Menu.Item icon = {<IconPhoto/>} onClick={() => {UploadImageModal(row.id)}} sx = {{fontFamily : 'poppins'}}>{EditContentTranslate}</Menu.Item>
                            <Menu.Item color = "red" icon = {<IconTrash/>} onClick={() => {CallConfirmModal(row.id)}} sx = {{fontFamily : 'poppins'}}>{RemoveEventTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group> 
        }
    ];
    const ListViewFunction = () => {
        fetchEvent()
        setViewType('Grid')
    }
    const GridViewFunction = () => {
        fetchEvent()
        setViewType('List')
    }
    return (
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group position="apart">
                {ViewType === 'Grid' ?
                    <ActionIcon
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick = {GridViewFunction}
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                    >
                        <IconGrid4x4 />
                    </ActionIcon>
                    :
                    <ActionIcon
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick = {ListViewFunction}
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                    >
                        <IconList />
                    </ActionIcon>
                }
                <Group >
                    <Tooltip
                        label={TooltipCreateEvent}
                        sx={{background : '#6c2be2'}}
                        withArrow
                    >
                        <ActionIcon
                            sx={{background : '#4a1e9e',  ":hover" : {
                                background : '#6c2be2'
                            }}}
                            size="lg" 
                            radius="xl" 
                            variant="filled"
                            onClick={() => {setOpenUploadEvent(true)}}
                        >
                            <IconPlus />
                        </ActionIcon>
                    </Tooltip>  
                    <TextInput
                        icon={<IconSearch/>}
                        radius="sm"
                        size="md"
                        placeholder={PlaceHolderSearch}
                        onChange={ handleSearch }
                    />
                   
                </Group>   
            </Group>
            <br></br>
            {ViewType === 'Grid' ?
            <Grid gutter="xs" grow>
                  {data.filter((SearchEvent) => {
                        if(search === ""){
                            return SearchEvent
                        }else if(SearchEvent.title.toLowerCase().includes(search.toLowerCase())){
                             return SearchEvent
                        }
                        }).map((event, index) =>
                        <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '25%'}} className="AchievementContent">
                            <Grid.Col> 
                                <Card withBorder radius="lg" sx={{
                                    boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                    display: 'block',
                                    top: '0px',
                                    position: 'relative',
                                    textDecoration: 'none',
                                    zIndex: 0,
                                    overflow: 'hidden',
                                    border: '1px solid #f2f8f9',
                                    cursor: 'pointer',
                                    ":hover" : {
                                        boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                    }}}
                                >
                                    <Card.Section>
                                        {event.images.map((img, index) =>
                                            <Image 
                                                height={200}
                                                key = {index}
                                                src={img.name} 
                                                withPlaceholder
                                                placeholder={
                                                    <Group sx={{paddingTop : 20}} position="center">
                                                        <IconPhotoX size="3.2rem" stroke={1.5} color="black" />
                                                    </Group>
                                                }
                                            />
                                        )}
                                    </Card.Section>
                                    <Group sx={{position : 'absolute', top : 3, right : 3}}>
                                        <Menu shadow="md" width={250} position="right-start" offset={-10} withArrow>
                                            <Menu.Target>
                                                <ActionIcon radius="xl" size="md" sx={{background : '#6c2be2',  ":hover" : {background : '#4a1e9e'}}}>
                                                    <IconDots size={22} color="white" />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx = {{fontFamily : 'poppins'}}>{ActionTranslate}</Text></Menu.Label>
                                                <a href = "https://blog.bible.ph/" target="_blank">
                                                    <Menu.Item icon = {<IconEye/>} sx = {{fontFamily : 'poppins'}}>{ViewEventTranslate}</Menu.Item>
                                                </a>
                                                <Menu.Item icon = {<IconPhoto/>} onClick={() => {UploadImageModal(event.id)}} sx = {{fontFamily : 'poppins'}}>{EditContentTranslate}</Menu.Item>
                                                <Menu.Item color = "red" icon = {<IconTrash/>} onClick={() => {CallConfirmModal(event.id)}} sx = {{fontFamily : 'poppins'}}>{RemoveEventTranslate}</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </Group>
                                    <Group position="apart" mt="md" >
                                        <Text  lineClamp = {1} sx={{color : '#4a1e9e', fontSize : 20, fontFamily : 'poppins', fontWeight : 700}}>{event.title}</Text>
                                    </Group>
                                    <Text size="sm" color="dark" fw={800} sx={{ fontFamily : 'poppins', lineHeight : 1}}>
                                        <Group spacing={0}>
                                            <IconWorld size="1.5rem" stroke={1.5}/>
                                            {event.location}
                                        </Group>
                                    </Text>
                                    <Text size="sm" color="dark" lineClamp={4} sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{event.description}</Text>
                                </Card>
                            </Grid.Col>
                        </motion.div>
                    )}   
            </Grid>
            :
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchEvent) => {
                        if(search === ""){
                            return SearchEvent
                        }else if(SearchEvent.title.toLowerCase().includes(search.toLowerCase())){
                            return SearchEvent
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            }
            <UploadEvent
                notifications = {notifications}
                OpenModal = {OpenUploadEvent}
                CloseModal = {() => {setOpenUploadEvent(false)}}
                fetchEvent = {fetchEvent}
            />
            <UploadImageEvent
              notifications = {notifications}
              OpenModal = {OpenUploadImages}
              CloseModal = {() => {setOpenUploadImages(false)}}
              fetchEvent = {fetchEvent}
              EventID = {EventID}
            />
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
                <Group>
                    <Group grow>
                        <Text>{ConfirmMessageTranslate}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button onClick = {DeleteEvent} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                    <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
                </Group>
            </Modal>
        </div>
    )
}
