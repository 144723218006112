import holidays from 'date-holidays'
import React, { useState, forwardRef } from "react";
import { TextInput, Group, Text, Tooltip, ActionIcon, Modal, Button, Stack, Image, Textarea, Card, rem, useMantineTheme, Grid, Drawer } from '@mantine/core'
import { Token } from '../../Utils'
import { IconSearch, IconPlus, IconX, IconPhoto, IconUpload, IconCalendar, IconCheck  } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import Axios from 'axios';
export const UploadHolidays = ({notifications, OpenModal, CloseModal, fetchCalendarEvent, AllCountry, PropsUploadHolidayTranslate, PropsSelectCountryTranslate, PropsDragImageTitleTranslate, PropsDragImageDescriptionTranslate, PropsNameTranslate, PropsPlaceholderName, PropsGreetingsTranslate, PropsPlaceholderGreetings, PropsDescriptionTranslate, PropsPlaceholderDescription, PropsSelectDateTranslate, PropsBtnUploadHoliday, PropsConfirmMessageTitle, PropsConfirmMessageDescription, PropsConfirmTranslate, PropsCancelTranslate, PropsListOfHolidayTranslate}) => {
    const [CountryCode, setCountryCode] = useState('')
    const APILink = process.env.REACT_APP_API_LINK
    const CurrentYear = Moment().format('YYYY')
    const hd_country = new holidays(CountryCode)
    const holidays_country = hd_country.getHolidays(CurrentYear)
    const theme = useMantineTheme();
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [OpenListCountryModal, setOpenListCountryModal] = useState(false)
    const [HolidayName, setHolidayName] = useState('')
    const [Greetings, setGreetings] = useState('')
    const [Description, setDescription] = useState('')
    const [ConfirmModal, setConfirmModal] = useState(false);
    const PostDate = Moment(startDate).format();
    function handleImage(files){
        setImage(files[0])
    }
    const handleGreetings = (event) => {
        setGreetings(event.target.value)
    }
    const handleDescription = (event) => {
        setDescription(event.target.value)
    }
    const handleEventName = (event) => {
        setHolidayName(event.target.value)
    }
    const DatePickerBtn = forwardRef(({ value, onClick }, ref) => (
        <>
        <Text fw={600}>{PropsSelectDateTranslate}</Text>
        <Group grow>
            <Button sx={{fontFamily : 'poppins'}} leftIcon = {<IconCalendar/>} variant="light" sx={{color : '#4a1e9e'}} color='violet' size="md" radius="sm" uppercase  onClick={onClick} ref={ref} >
                {value}
            </Button>
        </Group>
        </>
    ));
    const GetHoliday = (holday_name) => {
        setHolidayName(holday_name)
        setOpenListCountryModal(false)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const UploadHolidayFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Holiday',
            message: 'Holiday will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const formData = new FormData();
        formData.append("Image", image);
        formData.append('name', HolidayName)
        formData.append('greetings', Greetings)
        formData.append('description', Description)
        formData.append('date', PostDate)
        Axios.post(''+APILink+'/api/calendarevents', formData,{
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    CloseModal()
                    setImg([])
                    fetchCalendarEvent()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Holiday was uploaded',
                        message: 'Holiday successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })
    }
    return(
        <>
            <Drawer opened={OpenModal} onClose={CloseModal} size="xl" title={PropsUploadHolidayTranslate}>
                <Group>
                    <select onChange={(val) => {setCountryCode(val.target.value)}} style = {{
                        padding : '8px',
                        fontSize :'13px',
                        fontWeight : 'bold',
                        borderRadius : '3px',
                        fontFamily : 'poppins'
                    }}>
                        <option value = "0">{PropsSelectCountryTranslate}</option>
                        {AllCountry.map((country, index) =>
                            <option key={ index } value = {country.code}>
                                { country.name }
                            </option>
                        )}
                    </select>
                        <ActionIcon 
                        sx={{background : '#4a1e9e', fontFamily : 'poppins',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl"
                        variant="filled"
                        onClick={() => {setOpenListCountryModal(true)}}
                    >
                        <IconSearch/>
                    </ActionIcon>
                </Group>
                <br/>
                <Card shadow="xl" padding="lg">
                    <Card.Section>
                        {img.map((url, index) => <Image key = {index} style = {{ padding : '30px'}} maw={340} mx="auto" radius="sm" src={url} alt="Ads" />)}
                    </Card.Section>
                    <Card.Section >
                        <Dropzone
                            onDrop={
                                (files) => {
                                    handleImage(files)
                                    const selectedFIles =[];
                                    const targetFilesObject= [...files]
                                    targetFilesObject.map((file)=>{
                                        return selectedFIles.push(URL.createObjectURL(file))
                                    })
                                    setImg(selectedFIles);
                                } 
                            }
                            maxSize={3 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                        >
                            <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                                <Dropzone.Accept>
                                    <IconUpload
                                        size="3.2rem"
                                        stroke={1.5}
                                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Accept>

                                <Dropzone.Reject>
                                    <IconX
                                        size="3.2rem"
                                        stroke={1.5}
                                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                    />
                                </Dropzone.Reject>

                                <Dropzone.Idle>
                                    <IconPhoto size="3.2rem" stroke={1.5} />
                                </Dropzone.Idle>

                                <div>
                                    <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                        {PropsDragImageTitleTranslate}
                                    </Text>
                                    <Text size="xs" color="dimmed" inline mt={14} sx={{fontFamily : 'poppins'}}>
                                        {PropsDragImageDescriptionTranslate}
                                    </Text>
                                </div>
                            </Group>
                        </Dropzone>
                    </Card.Section>
                </Card>
                <Group mt="md" mb="xs" grow>
                    <TextInput
                        label = {PropsNameTranslate}
                        placeholder={PropsPlaceholderName}
                        size='md'
                        radius="sm"
                        withAsterisk
                        value={HolidayName}
                        onChange={handleEventName}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Textarea
                        label = {PropsGreetingsTranslate}
                        placeholder={PropsPlaceholderGreetings}
                        size='md'
                        radius="sm"
                        minRows={5}
                        withAsterisk
                        onChange={handleGreetings}
                        sx={{fontFamily : 'poppins'}}
                    />
                        <Textarea
                        label = {PropsDescriptionTranslate}
                        placeholder={PropsPlaceholderDescription}
                        size='md'
                        radius="sm"
                        minRows={5}
                        withAsterisk
                        onChange={handleDescription}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <DatePicker  
                        selected={startDate} 
                        onChange={(date) => setStartDate(date)} 
                        customInput={<DatePickerBtn />}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Button sx={{fontFamily : 'poppins'}} onClick={setConfirmModal} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                       {PropsBtnUploadHoliday}
                    </Button>
                </Group>
            </Drawer>
            <Modal size = "100%" opened={OpenListCountryModal} onClose={() => {setOpenListCountryModal(false)}} centered title = {PropsListOfHolidayTranslate}>
            <Grid gutter="xs" grow>
                {holidays_country.map((holiday, index) =>
                    <div key = {index} style={{width : '20%'}} className="PlanContent">
                        <Grid.Col> 
                            <Card shadow="xl" padding="xl" radius="sm" withBorder >
                                <Card.Section>
                                    <Group sx={{background : '#6c2be2', padding : '5px'}} position="apart">
                                        <Group>
                                            <Text sx={{fontFamily : 'poppins'}} fw={700} fz={20} color="white">{Moment(holiday.date).format('MMMM DD, YYYY')}</Text>
                                        </Group>
                                        <Group>
                                            <Tooltip
                                                label="Click to use this Holiday"
                                                sx={{background : '#4a1e9e'}}
                                                withArrow
                                            >
                                                <ActionIcon 
                                                    sx={{color : '#4a1e9e'}}
                                                    size="md" 
                                                    radius="xl" 
                                                    variant="light"
                                                    onClick={() => {GetHoliday(holiday.name, holiday.date)}}
                                                >
                                                    <IconPlus />
                                                </ActionIcon>
                                            </Tooltip>
                                        </Group>
                                    </Group>
                                </Card.Section>
                                <Stack spacing="xs" sx={{padding : 20}}>
                                    <Text fw={800} fz={15} sx={{color : '#4a1e9e', fontFamily : 'poppins'}} align='center'>{holiday.name}</Text>
                                </Stack>
                            </Card>
                        </Grid.Col>
                    </div>
                )}
            </Grid>
            </Modal>
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={PropsConfirmMessageTitle} centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>{PropsConfirmMessageDescription}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {UploadHolidayFunction} color="teal" size="sm" uppercase> {PropsConfirmTranslate} </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {PropsCancelTranslate} </Button>
                </Group>
            </Modal>
        </>
    )
}