import { ListofBooks } from "../../Components/Component"
import { notifications } from "@mantine/notifications"
import './BibleBook.css'
export const BibleBook = () => {
    return (
        <>
            <ListofBooks
                notifications = {notifications}
            />
        </>
    )
}