import { IconSpeakerphone, IconCalendarExclamation, IconBrandBlogger,
    IconMusic, IconCross, IconBuildingChurch, IconVersions, IconFileReport,
    IconTimeline, IconBible, IconNews, IconUsersGroup,
    IconUserStar,IconUsers, IconSettings2, 
    IconUserEdit, IconLockAccess, IconUpload, IconMoodSmile,
    IconFileUpload,IconMovie, IconFilePencil, IconPhotoPlus, IconCalendarEvent, IconBookmarkPlus, IconMicrophone, IconBuildingBank, IconNotification, IconReport, IconLanguage
} from '@tabler/icons-react';
const SideBarListItem = [
    {
        icon: <IconBible size={40}/>,
        name: 'Word of the Day',
        link:  "word-of-the-day",
        description: 'can Upload, Remove and Set as Word of the Day.'
    },
    {
        icon: <IconSpeakerphone size={40}/>,
        name: 'Announcement',
        link:  "announcement",
        description : 'can announce for all the Users'
    },
    {
        icon: <IconCalendarExclamation size={40}/>,
        name: 'Events',
        link:  "event",
        description: "can Upload, Remove and Set Content in Events"
    },
    {
        icon: <IconBrandBlogger size={40}/>,
        name: 'Reflections',
        link: "reflections",
        description: 'can Upload, Remove and Set Content in Blogs'
    },
    {
        icon: <IconMusic size={40}/>,
        name: 'Worship',
        link: "worship",
        description: 'can Upload, Remove and Listen to all Worship'
    },
    {
        icon: <IconBuildingChurch size={40}/>,
        name: 'Church',
        link: "church",
        description: 'can Upload and Remove Churches'
    },
    {
        icon: <IconUpload size={40}/>,
        name: 'Upload Ads',
        link: "upload-ads",
        description: 'can Upload, Remove and Set Ads'
    },
    {
        icon: <IconFileUpload size={40}/>,
        name: 'Customize Ads',
        link: "customized-ads",
        description: "can Upload, Remove and Set Customized Ads"
    },
    {
        icon: <IconVersions size={40}/>,
        name: 'Version',
        link: "version",
        description: "can Upload, Remove and Publishe Bible Versions"
    },
    {
        icon: <IconBible size={40}/>,
        name: 'Books',
        link: "book",
        description: "can Upload, Update and Remove Bible"
    },
    {
        icon: <IconPhotoPlus size={40}/>,
        name: 'Bible Cover',
        link: "bible-cover",
        description: "can Upload, Update and Remove Bible Cover"
    },
    {
        icon: <IconMovie size={40}/>,
        name: 'Bible Story',
        link: "bible-story",
        description: 'can Upload, Remove and Watch Bible Stories'
    },
    {
        icon: <IconFilePencil size={40}/>,
        name: 'Bible Quiz',
        link: "bible-quiz",
        description: "can Upload, Remove Questions in Bible Quiz"
    },
    {
        icon: <IconMoodSmile size={40}/>,
        name: 'Feelings',
        link: "feelings",
        description: "can Upload and Remove Devotions"
    },
    {
        icon: <IconCross size={40}/>,
        name: 'Daily Devotion',
        link: "daily-devotion",
        description: "can Upload, Remove and Set as Daily Devotion"
    },
    // {
    //     icon: <IconNews size={40}/>,
    //     name: 'Feeds',
    //     link: "feeds",
    //     description: "can Check all the Posts of the Users"
    // },
    {
        icon: <IconUsersGroup size={40}/>,
        name: 'Group Community',
        link: "groupcommunity",
        description: "can Check all Group Community of the Users"
    },
    {
        icon: <IconUserStar size={45}/>,
        name: 'Achievements',
        link: "achievements",
        description: 'can Upload, Remove Achievements'
    },
    {
        icon: <IconBuildingBank size={45}/>,
        name: 'Bank',
        link: "payment-bank",
        description: "can Upload, Remove and Set Instruction in Bank"
    },
    {
        icon: <IconNotification size={45}/>,
        name: 'Plan',
        link: "payment-plan",
        description: "can Upload and Remove Plans"
    },
    {
        icon: <IconUsers size={40}/>,
        name: 'User List',
        link: "userlist",
        description: "can check all the Users"
    },
    {
        icon: <IconSettings2 size={40}/>,
        name: 'Administrator',
        link: "administrator",
        description: "can give access to other Users"
    },
    {
        icon: <IconUserEdit size={40}/>,
        name: 'Account Information',
        link: "accountinformation",
        description: "can Edit Personal Information"
    },
    {
        icon: <IconLockAccess size={40}/>,
        name: 'Change Password',
        link: "changepassword",
        description: "can Edit and Change Password of your Account"
    },
    // {
    //     icon: <IconFileReport size={45}/>,
    //     name: 'Report',
    //     link: "reports",
    //     description: "can get soft Copy or Print the information"
    // },
    {
        icon: <IconTimeline size={40}/>,
        name: 'Activity Logs',
        link: "activitylogs",
        description: "can check all Activity of the Users"
    },
    {
        icon: <IconCalendarEvent size={40}/>,
        name: 'Calendar Event',
        link: "calendar-event",
        description: "can upload and set Holidays"
    },
    {
        icon: <IconReport size={40}/>,
        name: 'Concern',
        link: "concern",
        description: "can check and resolve reported post, comment and user"
    },
    {
        icon: <IconBookmarkPlus size={40}/>,
        name: 'Reading Plan',
        link: "reading-plan",
        description: "can upload Plan and set Items "
    },
    {
        icon: <IconMicrophone size={40}/>,
        name: 'Voices',
        link: "voice",
        description: "can upload and remove Voices "
    },
    {
        icon: <IconFileUpload size={40}/>,
        name: 'Resources',
        link: "resources",
        description: "can upload all types of files for resources "
    },
    {
        icon: <IconLanguage size={40}/>,
        name: 'App Languages',
        link: "app-languages",
        description: "can upload Languages and other Informations "
    },
    {
        icon: <IconVersions size={40}/>,
        name: 'App Version',
        link: "app-version",
        description: "can upload Application version and apk "
        
    }
]
export default SideBarListItem