
import { notifications } from "@mantine/notifications"
import { ListofAppVersion } from "../../Components/DataTables/ListofAppVersion"
import './AppVersion.css'
export const AppVersion = () => {
    return (
        <>
           <ListofAppVersion notifications={notifications}/>
        </>
    )
}