import { Modal, useMantineTheme, Group, Button, TextInput, Table, Paper, Blockquote } from '@mantine/core';
import React, { useState } from "react";
import { IconSearch, IconTrash } from '@tabler/icons-react';
export const ListofDevotionVerse = ({data, OpenModal, CloseModal}) => {
    const theme = useMantineTheme();
    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const rows = data.filter((SearchDevotionVerse) => {
    if(search === ""){
        return SearchDevotionVerse
    }else if(SearchDevotionVerse.book.toLowerCase().includes(search.toLowerCase())){
        return SearchDevotionVerse
    }
    }).map((devotion) => (
        <tr key={devotion.id}>
            <td>
                <div className = "VerseContent">
                    <Paper  shadow="xl" radius="md" p="xs">
                        <Blockquote sx = {{fontFamily : 'poppins'}} color="violet" cite={devotion.bible+" — "+devotion.book+" "+devotion.chapterNumber+" : "+devotion['verse'].verseNumber}>
                            {devotion['verse'].verseContent}
                        </Blockquote>
                    </Paper>
                </div>
            </td>
            <td>
                <Group grow>
                    <Button sx = {{fontFamily : 'poppins'}} leftIcon = {<IconTrash/>}color="red" radius="sm" size="sm">Remove</Button>
                </Group>
            </td>
        </tr>
    ));
    return(
        <>
            <Modal
                opened={OpenModal}
                onClose={CloseModal}
                size="xl"
                title="List of Devotion Verses"
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 3,
                }}
                centered
            >
                <Group position="right">
                    <TextInput
                        icon={<IconSearch/>}
                        radius="md"
                        size="md"
                        placeholder="Search Book Name"
                        onChange={ handleSearch }
                        sx = {{fontFamily : 'poppins'}}
                    />
                </Group>
                <br></br>
                <Table>
                    <thead>
                        <tr>
                            <th scope="col" style = {{fontFamily : 'poppins'}}>Verse</th>
                            <th scope="col" style={{width : '150px', fontFamily : 'poppins'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </Modal>
        </>
    )
}