import React, { useState, useEffect } from "react";
import { Group, Text, Menu, ActionIcon, Divider, Loader  } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import ReactECharts from 'echarts-for-react';
import Axios from 'axios';
export const TotalViewsComment = () => {
    const [TotalViews, setTotalViews] = useState(0)
    const [TotalComments, setTotalComments] = useState(0)
    const [Title, setTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    var sourceLang = 'en';
    const [DevotionTitle, setDevotionTitle] = useState('DAILY DEVOTION')
    const [TotalViewTitle, setTotalViewTitle] = useState('TOTAL VIEW')
    const [TotalCommentTitle, setTotalCommentTitle] = useState('TOTAL COMMENT')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        FetchDailyDevotion();
        fetchTranslation();
    }, [DefaultLanguage]);
    const FetchDailyDevotion = async() => {
        setLoading(true);  
        const DailyDevotionEP = `/DailyDevotions/Default`
        await api.get(DailyDevotionEP).then((result) => {
            setTotalViews(result.data.viewCount)
            setTotalComments(result.data.commentCount)
            setTitle('DAILY DEVOTION')
            setLoading(false);  
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const FetchDefaultBlogs = async() => {
        const DefaultBlogsEP = `/Blogs/Default`
        await api.get(DefaultBlogsEP).then((result) => {
            setTotalViews(result.data.views)
            setTotalComments(result.data.commentCount)
            setTitle('REFLECTION')
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DevotionTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDevotionTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TotalViewTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTotalViewTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TotalCommentTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTotalCommentTitle(translated[0])
            })
        }).then((error) =>{
        })
    }
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: [TotalViewTitle, TotalCommentTitle],
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Count',
              type: 'bar',
              barWidth: '70%',
              data: [TotalViews, TotalComments ],
            }
          ]
    }
    return(
        <>

            <Group position="center">
                <Text ta = "center" fw = {900} sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>{DevotionTitle}</Text>
            </Group>
            {/* <Group position="right">
                <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                    <Menu.Target>
                        <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                            <IconDotsVertical size="1.625rem" />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item icon = {<IconCross/>} onClick={FetchDailyDevotion}>Daily Devotion</Menu.Item>
                        <Menu.Item icon = {<IconCalendar/>} onClick={FetchDefaultBlogs}>Reflection</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group> */}
            {loading ?
                <Group position="center" sx={{padding : 20}}>
                    <Loader color="violet" variant="dots" size="md"/>
                </Group>
                :
                <ReactECharts option={options} />
                // <Group position="center" sx={{padding : 10}}>
                //     <Text fw={500} align="center" color = "black" sx={{fontSize : 20}}>{TotalViews}
                //         <Text fw={500} color = "gray" sx={{fontSize : 10}}>{TotalViewTitle}</Text>
                //     </Text>
                //     <Divider orientation="vertical" flexItem />
                //     <Text fw={500} align="center" color = "black" sx={{fontSize : 20}}>{TotalComments} 
                //         <Text fw={500} color = "gray" sx={{fontSize : 10}}>{TotalCommentTitle}</Text>
                //     </Text>
                // </Group>
            }
        </>
    )
}