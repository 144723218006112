import React, { useState, useEffect, useMemo, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { useId } from '@mantine/hooks';
import { IMaskInput } from 'react-imask';
import { Modal, Group, TextInput, Textarea, Button, Switch, Text, Flex, useMantineTheme, rem, Image, Input } from '@mantine/core'
import { IconUpload, IconCheckbox, IconX, IconPhoto, IconAt, IconCheck } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import Axios from 'axios';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
export const UploadGroup = ({OpenModal, CloseModal, fetchListOfGroup, notifications, PropsAddNewGroupTranslate, PropsDragImageTitleTranslate, PropsDragImageDescriptionTranslate, PropsTitleTranslate, PropsPlaceholderTitle, PropsDescriptionTranslate, PropsPlaceholderDescription, PropsEmailTranslate, PropsPlaceholderEmail, PropsContactNumberTranslate, PropsPlaceholderContactNumber, PropsAddressTranslate, PropsPlaceholderAddress, PropsPublicTranslate, PropsPrivateTranslate, PropsSelectGroupPrivacyTranslate, PropsBtnUploadGroup}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const id = useId()
    const theme = useMantineTheme();
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('');
    const [UserEmail, setUserEmail] = useState([])
    const [TypeUserEmail, setTypeUserEmail] = useState('');
    const [Title, setTitle] = useState('');
    const [Description, setDescription] = useState('');
    const [Email, setEmail] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [Address, setAddress] = useState('');
    const [IsPrivate, setIsPrivate] = useState(false);
    function handleImage(files){
        setImage(files[0])
    }
    const OnBlurEmail = async(e) => { 
        setTypeUserEmail(e.target.value);
        const UserEmailEP = `/UserProfiles/All?keyword=`+e.target.value+`&PageNumber=1&PageSize=1`
        await api.get(UserEmailEP).then((result) => {
            setUserEmail(result.data.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }
    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }
    const handleChangePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }
    const handleChangeAddress = (event) => {
        setAddress(event.target.value)
    }
    const UploadGroup = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Group Community',
            message: ' Group Community will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const formData = new FormData();
        formData.append("Cover", image);
        formData.append("Title", Title);
        formData.append("Private", IsPrivate);
        formData.append("Contact", PhoneNumber);
        formData.append("Address", Address);
        formData.append("Description", Description);
        formData.append("Email", Email);
        Axios.post(''+APILink+'/api/Communities', formData,{
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchListOfGroup();
                    CloseModal();
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: ' Group Community was uploaded',
                        message: ' Group Community successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        })
    }
    return(
        <>  
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={PropsAddNewGroupTranslate}
            size="lg" 
            centered
        >
            {img.map((url, index) => <Image key = {index} style = {{ padding : '30px'}} maw={340} mx="auto" radius="sm" src={url} alt="Ads" />)}
            <Dropzone
                onDrop={
                    (files) => {
                        handleImage(files)
                        const selectedFIles =[];
                        const targetFilesObject= [...files]
                        targetFilesObject.map((file)=>{
                            return selectedFIles.push(URL.createObjectURL(file))
                        })
                        setImg(selectedFIles);
                    } 
                }
                maxSize={3 * 1024 ** 2}
                accept={IMAGE_MIME_TYPE}
            >
                <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                    <Dropzone.Accept>
                        <IconUpload
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>

                    <Dropzone.Reject>
                        <IconX
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Reject>

                    <Dropzone.Idle>
                        <IconPhoto size="3.2rem" stroke={1.5} />
                    </Dropzone.Idle>

                    <div>
                        <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                            {PropsDragImageTitleTranslate}
                        </Text>
                        <Text size="xs" color="dimmed" inline mt={14} sx={{fontFamily : 'poppins'}}>
                            {PropsDragImageDescriptionTranslate}
                        </Text>
                    </div>
                </Group>
            </Dropzone>
            <Group mt="md" mb="xs" grow>
                <TextInput
                    placeholder={PropsPlaceholderTitle}
                    label={PropsTitleTranslate}
                    size="md"
                    withAsterisk
                    onChange={handleChangeTitle}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Textarea
                    placeholder={PropsPlaceholderDescription}
                    label={PropsDescriptionTranslate}
                    size="md"
                    withAsterisk
                    minRows={5}
                    onChange={handleChangeDescription}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow> 
            <Input.Wrapper
                sx={{fontFamily : 'poppins'}}
                error = {UserEmail.map((email) => 
                    email.email === TypeUserEmail ?
                    ""
                    :
                    'Email address not found!'
                )}
                sx={{fontFamily : 'poppins'}}
            >
                <Input
                    icon={<IconAt/>}
                    label = {PropsEmailTranslate}
                    placeholder={PropsPlaceholderEmail}
                    size="md"
                    onBlur={OnBlurEmail}
                    withAsterisk
                    rightSection = {
                        UserEmail.map((email, index) => 
                            email.email === TypeUserEmail ?
                            <IconCheckbox key = {index} color="green" size={20} style={{ display: 'block', opacity: 1 }}/>
                            :
                            <IconX color = "red" size={20} style={{ display: 'block', opacity: 1 }}/>
                        )
                    }
                    onChange={handleChangeEmail}
                   
                   
                    
                />
            </Input.Wrapper>
            </Group>
            <Group mt="md" mb="xs" grow>
            <Input.Wrapper id={id} label={PropsContactNumberTranslate} required  sx={{fontFamily : 'poppins'}}>
                <Input
                    component={IMaskInput}
                    mask="+63 0000 000 000"
                    id={id}
                    placeholder={PropsPlaceholderContactNumber}
                    onChange={handleChangePhoneNumber}
                   
                />
            </Input.Wrapper> 
            </Group>
            <Group mt="md" mb="xs" grow>
                <Textarea
                    placeholder={PropsPlaceholderAddress}
                    label={PropsAddressTranslate}
                    size="md"
                    withAsterisk
                    minRows={5}
                    onChange={handleChangeAddress}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Switch
                    label = {PropsSelectGroupPrivacyTranslate}
                    onLabel = {PropsPrivateTranslate}
                    offLabel = {PropsPublicTranslate}
                    size="xl"
                    radius="xs"
                    color="green"
                    onChange={(event) => setIsPrivate(event.currentTarget.checked)}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick={UploadGroup} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                   {PropsBtnUploadGroup}
                </Button>
            </Group>
        </Modal>
        </>
    )
}