import { ListofVersions } from "../../Components/Component"
import { notifications } from '@mantine/notifications';
export const BibleVersion = () => {
    return (
        <>
            <ListofVersions
                notifications = {notifications}
            />
        </>
    )
}