import React, { useState, useEffect } from "react";
import { useDisclosure } from '@mantine/hooks';
import { Button, TextInput, Group, Blockquote,Divider, Text, Alert, ActionIcon, Menu, CopyButton, Tooltip, Dialog, Textarea, Card, Grid, Stack, Badge} from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconDots, IconChecks,IconPlus, IconCalendar, IconCopy, IconX, IconAlertCircle, IconEdit } from '@tabler/icons-react'
import { UploadDailyDevotion } from "../Devotion/UploadDailyDevotion"; 
import { UploadDirection } from "../Ads/UploadDirections";
import Moment from 'moment';
import { motion } from "framer-motion";
import Axios from 'axios';
export const ListofDailyDevotion = ({notifications}) => {
    const DateToday = Moment().format('MMMM DD, YYYY')
    const [loading, setLoading] = useState(false);
    const [DailyDevotion, setDailyDevotion] = useState([])
    const [Comment, setComment] = useState([])
    const [UploadModal, setUploadModal] = useState(false)
    const [AddDirectionModal, setAddDirectionModal] = useState(false)
    const [opened, { toggle, close }] = useDisclosure(false);
    const [SelectedDirection, setSelectedDirection] = useState('');
    const [SelectedDailyDevotionID, setSelectedDailyDevotionID] = useState('');
    const [ID, setID] = useState("");
    const [search, setSearch] = useState('');
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "DailyDevotionTranslate" : 'DAILY DEVOTION',
        "SearchByDateTranslate" : 'Search by date',
        "CreateDailyDevotionTranslate" : 'Create Daily Devotion',
        "SettingsTranslate" : 'Settings',
        "SetDailyDevotionTranslate" : 'Set Daily Devotion',
        "DeleteDailyDevotionTranslate" : 'Delete Devotion',
        "ComingSoonTranslate" : 'Coming Soon',
        "ActiveNowTranslate" : 'Active Now',
        "EndedTranslate" : 'Ended',
        "SetActiveTranslate" : 'active this daily devotion now',
        "FirstTitleTranslate" : 'What has the Lord shown you?',
        "SecondTitleTranslate" : 'How do you obey it?',
        "AddDailyDevotionTranslate" : 'ADD NEW DAILY DEVOTION',
        "VerseTranslate" : 'Verse',
        "PlaceholderVerse" : 'Enter Verse',
        "VerseContentTranslate" : 'Verse Content',
        "PlaceholderVerseContent" : 'Enter Verse Content',
        "MessageTranslate" : 'Message',
        "PlaceholderMessage" : 'Enter Message',
        "ActiveTranslate" : 'Active',
        "InactiveTranslate" : 'Inactive',
        "ActiveDailyDevotion" : 'Active Daily Devotion',
        "SelectDateTranslate" : 'Select Date',
        "BtnUploadDailyDevotion" : 'UPLOAD',
        "AddDirectionTranslate" : 'ADD NEW DIRECTION',
        "DirectionTranslate" : 'Direction',
        "PlaceholderDirection" : 'Enter Direction',
        "BtnUploadDirection" : 'UPLOAD',
        "CopyTranslate" : 'Copy',
        "CopiedTranslate" : 'Copied',
        "RemoveDirectionTranslate" : 'Remove this Direction ?',
        "DeleteTranslate" : 'DELETE',
        "CancelTranslate" : 'CANCEL',
        "AddDirectionTooltipTranslate" : 'Add Direction',
        "PrayerTranslate" : 'Prayer',
        "PlaceholderPrayer" : 'Enter Prayer',
        "ReflectionTranslate" : 'Reflection',
        "PlaceholderReflection" : 'Enter Reflection'

    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchDailyDevotion();
        fetchComment();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    DailyDevotion.map((CheckDate) =>{
        if(DateToday === Moment(CheckDate.date).format('MMMM DD, YYYY') && CheckDate.isDefault === false){
            notifications.show({
                color: "red",
                title: 'Active Daily Devotion Now!',
                message: 'Daily Devotion is Ready Please set as Active today',
                autoClose: false,
            });
        }
    })
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const handleDirection = (event) => {
        setSelectedDirection(event.target.value)
    }
    const fetchDailyDevotion = async () => {
        setLoading(true);   
        const ListDailyDevotionEP = `/DailyDevotions?PageNumber=1&PageSize=500`
        await api.get(ListDailyDevotionEP).then((result) => {
            setDailyDevotion(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchComment = async (ID) => {
        // setLoading(true);   
        // const CommentEP = `/DailyDevotions/`+ID+`/Comments`
        // await api.get(CommentEP).then((result) => {
        //     setComment(result.data.data)
        //     setLoading(false);
        // }).catch(error => {
        //     const Response = error.response.status
        //     if(Response === 401){
        //         GetNewToken(Token, RefreshToken)
        //     }
        //     if(Response === 404){
        //     }
        // })
    }
    const AddDirection = async (ID) => {
        setID(ID);
        setAddDirectionModal(true);
    }
    const SetDailyDevotionFunction = async (ID) => {
        const SetDailyDevotionEP = `/DailyDevotions/`+ID+``
        api.put(SetDailyDevotionEP, {
        }).then(result => {
        if(result['status'] === 204){
            notifications.show({
                id: 'load-data',
                loading: true,
                title: 'Updating Daily Devotion',
                message: 'Daily Devotion will be loaded, you cannot close this yet',
                autoClose: false,
                withCloseButton: false,
                });
                setTimeout(() => {
                //! RESET INPUTS
                    fetchDailyDevotion();
                    fetchComment();
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Daily Devotion was updated',
                    message: 'Daily Devotion successsfully updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const RemoveDailyDevotion = async (DevotionID) => {
        const DevotionEP = `/DailyDevotions/`+DevotionID+``
        await api.delete(DevotionEP, {
        }).then(result => {
            if(result['status'] === 204){
                notifications.show({
                    id: 'load-data',
                    loading: true,
                    title: 'Daily Devotion Location',
                    message: 'Daily Devotion will be deleted, you cannot close this yet',
                    autoClose: false,
                    withCloseButton: false,
                    });
                    setTimeout(() => {
                    fetchDailyDevotion();
                    fetchComment();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Daily Devotion was deleted',
                        message: 'Daily Devotion successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
        })
    }
    const RemoveDirection = async () => {
        const DeleteDirectionEP = `/DailyDevotions/`+SelectedDailyDevotionID+`/Directions?direction=`+SelectedDirection
        await api.delete(DeleteDirectionEP, {
        }).then(result => {
            if(result['status'] === 204){
                notifications.show({
                    id: 'load-data',
                    loading: true,
                    title: 'Removing Direction',
                    message: 'Direction will be deleted, you cannot close this yet',
                    autoClose: false,
                    withCloseButton: false,
                    });
                    setTimeout(() => {
                    fetchDailyDevotion();
                    fetchComment();
                    close();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Direction was deleted',
                        message: 'Direction successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
    })
    }
    DailyDevotion.sort(function(a, b){
        return new Date(b.date) - new Date(a.date);
    });
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group>
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchByDateTranslate}
                    onChange={ handleSearch }
                />
                 <Tooltip
                    label={DataTranslate.CreateDailyDevotionTranslate}
                    sx={{background : '#4a1e9e'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e', transition: '0.3s',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick = {() => {setUploadModal(true)}}
                    >
                        <IconPlus size="1.625rem" />
                    </ActionIcon>
                </Tooltip>  
            </Group>
            <br></br>
            <Grid gutter="xs" grow>
                {DailyDevotion.filter((SearchDevotion) => {
                    if(search === ""){
                            return SearchDevotion
                        }else if(SearchDevotion.date.toLowerCase().includes(search.toLowerCase())){
                            return SearchDevotion
                        }
                    }).map((devotion, index) =>
                    <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '50%'}} className="AchievementContent">
                        <Grid.Col> 
                            <Card withBorder radius="lg" sx={{
                                boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                display: 'block',
                                top: '0px',
                                position: 'relative',
                                textDecoration: 'none',
                                zIndex: 0,
                                overflow: 'hidden',
                                border: '1px solid #f2f8f9',
                                cursor: 'pointer',
                                ":hover" : {
                                    boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                }}}
                            >
                                <Card.Section withBorder inheritPadding py="xs" sx={{background : '#6c2be2'}}>
                                    <Group position="apart">
                                        <Stack spacing={5}>
                                            <Text fw={700} fz="xl" sx={{lineHeight: 1, color : 'white', fontFamily : 'poppins'}}>{Moment(devotion.date).format('MMMM DD, YYYY')}</Text>
                                            <Group>
                                                {
                                                    devotion.isDefault ? 
                                                    <Group>
                                                        <Badge color="green" size="md" variant="filled" radioGroup="lg">
                                                            <Text fz={13} sx = {{fontFamily : 'poppins'}}>{DataTranslate.ActiveNowTranslate}</Text>
                                                        </Badge>
                                                    </Group>
                                                    :
                                                    DateToday < devotion.date ? 
                                                    <Group>
                                                        
                                                        <Badge color="blue" size="md" variant="filled" radioGroup="lg">
                                                            <Text fz={13} sx = {{fontFamily : 'poppins'}}>{DataTranslate.ComingSoonTranslate}</Text>
                                                        </Badge>
                                                    </Group>
                                                    :
                                                    <Group>
                                                        <Badge color="red" size="md" variant="filled" radioGroup="lg">
                                                            <Text fz={13} sx = {{fontFamily : 'poppins'}}>{DataTranslate.EndedTranslate}</Text>
                                                        </Badge> 
                                                    </Group>
                                                }
                                            </Group>
                                        </Stack>
                                        <Menu withinPortal position="bottom-start" shadow="lg" withArrow offset={-1} width={220}>
                                            <Menu.Target>
                                                <ActionIcon radius="xl" size="md" sx={{background : '#6c2be2',  ":hover" : {background : '#4a1e9e'}}}>
                                                    <IconDots size={22} color="white" />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Label>{DataTranslate.SettingsTranslate}</Menu.Label>
                                                <Menu.Item onClick = {() => {SetDailyDevotionFunction(devotion.id)}}icon={<IconChecks size={22} />}>{DataTranslate.SetDailyDevotionTranslate}</Menu.Item>
                                                <Menu.Item onClick = {() => {RemoveDailyDevotion(devotion.id)}}color = "red" icon={<IconTrash size={22} />}>{DataTranslate.DeleteDailyDevotionTranslate}</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </Group>
                                </Card.Section>
                                <Stack spacing={1}>
                                        <Text fw={500} sx={{fontFamily : 'poppins', textAlign : 'center', paddingTop : 20}}>{devotion.word}</Text>
                                        <Text fw={700} fz="md" sx={{fontFamily : 'poppins', textAlign : 'center'}}>" {devotion.verse} "</Text>
                                </Stack>
                                <Divider></Divider>
                                <Stack spacing={1}>
                                    <Text fw={900} sx={{fontFamily : 'poppins', paddingTop : 10, fontSize : 20}}>{DataTranslate.FirstTitleTranslate}</Text>
                                    <Text fw={500} fz="sm" sx={{fontFamily : 'poppins', textAlign : 'justify'}}>{devotion.message}</Text>
                                </Stack>
                                <Stack spacing={1}>
                                    <Group position="apart">
                                        <Text fw={900} sx={{fontFamily : 'poppins', paddingTop : 10, fontSize : 20}}>{DataTranslate.SecondTitleTranslate}</Text>
                                        <Tooltip label={DataTranslate.AddDirectionTooltipTranslate} withArrow position="right">
                                            <ActionIcon onClick = {() => {AddDirection(devotion.id)}} size="md" radius="xl" variant="filled" 
                                                sx={{background : '#4a1e9e', transition: '0.3s',  ":hover" : {
                                                    background : '#6c2be2'
                                                }}}>
                                                <IconPlus size={20} />
                                            </ActionIcon>
                                        </Tooltip>
                                    </Group>
                                    {devotion.directions.map((direction, index) =>
                                        <Group position="apart" key = {index}>
                                            <Text maw={800} fw={500} fz="sm" sx={{fontFamily : 'poppins', textAlign : 'justify'}}> • {direction.value}</Text>
                                            <CopyButton value={direction.value} timeout={2000}>
                                                {({ copied, copy }) => (
                                                    <Tooltip label={copied ? DataTranslate.CopiedTranslate : DataTranslate.CopyTranslate} withArrow position="right">
                                                        <ActionIcon 
                                                            color={copied ? 'teal' : 'gray'}
                                                            onClick={() => {copy(); toggle(); setSelectedDirection(direction.value); setSelectedDailyDevotionID(devotion.id)}}
                                                        >
                                                            {copied ? <IconCheck size={25} /> : <IconCopy size={25} />}
                                                        </ActionIcon>
                                                    </Tooltip>
                                                )}
                                            </CopyButton>
                                        </Group>
                                    )}
                                </Stack>
                                <Stack spacing={1}>
                                    <Text fw={900} sx={{fontFamily : 'poppins', paddingTop : 10, fontSize : 20}}>{DataTranslate.ReflectionTranslate}</Text>
                                    <Text fw={500} fz="sm" sx={{fontFamily : 'poppins', textAlign : 'justify'}}>{devotion.reflection}</Text>
                                </Stack>
                                <Stack spacing={1}>
                                    <Text fw={900} sx={{fontFamily : 'poppins', paddingTop : 10, fontSize : 20}}>{DataTranslate.PrayerTranslate}</Text>
                                    <Text fw={500} fz="sm" sx={{fontFamily : 'poppins', textAlign : 'justify'}}>{devotion.prayer}</Text>
                                </Stack>
                            </Card>
                        </Grid.Col>
                    </motion.div>   
                )} 
            </Grid>
            <UploadDailyDevotion
                OpenModal = {UploadModal}
                CloseModal = {() => {setUploadModal(false)}}
                notifications = {notifications}
                fetchDailyDevotion = {fetchDailyDevotion}
                fetchComment = {fetchDailyDevotion}
                PropsAddDevotionTranslate = {DataTranslate.AddDailyDevotionTranslate}
                PropsVerseTranslate = {DataTranslate.VerseTranslate}
                PropsPlaceholderVerse = {DataTranslate.PlaceholderVerse}
                PropsVerseContentTranslate = {DataTranslate.VerseContentTranslate}
                PropsPlaceholderVerseContent = {DataTranslate.PlaceholderVerseContent}
                PropsMessageTranslate = {DataTranslate.MessageTranslate}
                PropsPlaceholderMessage = {DataTranslate.PlaceholderMessage}
                PropsActiveTranslate = {DataTranslate.ActiveTranslate}
                PropsInactiveTranslate = {DataTranslate.InactiveTranslate}
                PropsActiveDailyDevotionTranslate = {DataTranslate.ActiveDailyDevotion}
                PropsSelectDateTranslate = {DataTranslate.SelectDateTranslate}
                PropsBtnUploadTranslate = {DataTranslate.BtnUploadDailyDevotion}
                PropsPrayerTranslate = {DataTranslate.PrayerTranslate}
                PropsPlaceholderPrayer = {DataTranslate.PlaceholderPrayer}
                PropsReflectionTranslate = {DataTranslate.ReflectionTranslate}
                PropsPlaceholderReflection = {DataTranslate.PlaceholderReflection}
            />
            <UploadDirection
                OpenModal = {AddDirectionModal}
                CloseModal = {() => {setAddDirectionModal(false)}}
                notifications = {notifications}
                fetchDailyDevotion = {fetchDailyDevotion}
                fetchComment = {fetchDailyDevotion}
                ID = {ID}
                PropsAddNewDiresctionTranslate = {DataTranslate.AddDirectionTranslate}
                PropsDirectionTranslate = {DataTranslate.DirectionTranslate}
                PropsPlaceholderDirection = {DataTranslate.PlaceholderDirection}
                PropsBtnUploadDirection = {DataTranslate.BtnUploadDirection}
            />
            <Dialog opened={opened} onClose={close} size="lg" radius="md">
                <Text size="sm" mb="xs" weight={500}>
                    {DataTranslate.RemoveDirectionTranslate}
                </Text>
                <Group grow>
                    <Textarea 
                        value = {SelectedDirection} 
                        sx={{ flex: 1, textAlign : 'justify' }}
                        minRows={5} 
                        variant="filled"
                        onChange={handleDirection}
                        />
                </Group>
                <br/>
                <Group grow>
                    <Button leftIcon = {<IconCheck/>} color = "red" onClick={() => {RemoveDirection()}}>{DataTranslate.DeleteTranslate}</Button>
                    <Button leftIcon = {<IconX/>} color = "blue" onClick={close}>{DataTranslate.CancelTranslate}</Button>
                </Group>
            </Dialog>
        </div>     
    )
}