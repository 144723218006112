import React, { useState, useEffect } from "react";
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { Card, Group, Text, Grid, Stack, Avatar, TextInput, Modal, Button, Menu, ActionIcon } from '@mantine/core';
import {  IconSearch, IconCheck, IconKeyOff, IconDotsVertical } from '@tabler/icons-react';
import UserDefaultLogo from "../../Assets/images/logo/circular-logo.png"
import DataTable from 'react-data-table-component'
import Axios from 'axios';
export const ListofAdministrator = ({notifications}) => {
    const [data, setData] = useState([])
    const countPerPage = 10
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [UserID, setUserID] = useState('')
    const [search, setSearch] = useState('')
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SearchUserTranslate" : 'Search User',
        "AdminTranslate" : 'ADMIN',
        "RevokeTranslate" : 'REVOKE',
        "ConfirmTitleTranslate" : 'Confirm Deleting',
        "ConfirmMessageTranslate" : 'Are you sure you wan to Delete this User ? ',
        "ConfirmTranslate" : 'Cofirm',
        "CancelTranslate" : 'Cancel',
    })
    const [AdminType, setAdminType] = useState('Admin')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchUser();
        fetchTranslation();
    }, [DefaultLanguage, AdminType])
    const AdminTypeFunction = async(admin_type) => {
        // fetchUser()
        setAdminType(admin_type)
      
    }
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const fetchUser = async() => {
        setLoading(true);   
        const ListofAdminEP = `Roles/Users?roleName=`+AdminType+``
        await api.get(ListofAdminEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const RevokeUserID = (ID) => {
        setConfirmModal(true)
        setUserID(ID)
    }
    const RevokeUserFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing User',
            message: 'User will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const RevokeEP = `/Roles/Revoke/`+UserID+``
        await api.delete(RevokeEP, {
        }).then(result => {
            if(result['status'] === 200){
                setTimeout(() => {
                    fetchUser();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'User was remove from list of admin',
                        message: 'User successsfully remove',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
    })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const columns = [
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>FULLNAME</Text>,
            cell : row => 
            <Group spacing={7}>
                <Avatar 
                    sx={{width : 50, height : 50, borderRadius : 80, boxShadow : '1px 2px 5px 1px gray'}}
                    src={row.profileLink ? row.profileLink : UserDefaultLogo} 
                />
                <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.fullName}</Text>
            </Group>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>POSITION</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{AdminType === 'Admin' ? 'Admin':'Super Admin'}</Text>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>EMAIL</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.email === null || row.email === '' ? 'no email attatched' : row.email}</Text>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>CONTACT</Text>,
            cell : row => 
            <Group sx={{width : '100%'}} position="apart">
                <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.contactNumber === null || row.contactNumber === '' ? 'no contact attatched' : row.contactNumber}</Text>
                <Menu shadow="md" width={200} position="left-start" offset={-10} withArrow>
                    <Menu.Target>
                        <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                            <IconDotsVertical size="1.625rem" />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label><Text sx={{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">ACTION</Text></Menu.Label>
                        <Menu.Item sx={{fontFamily : 'poppins'}} onClick = {() => {RevokeUserID(row.id)}} icon = {<IconKeyOff/>} color="red">Revoke User</Menu.Item>
                    </Menu.Dropdown>
                </Menu>  
            </Group>
        },
    ]
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group position="apart">
                <TextInput
                    icon={<IconSearch color="black"/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchUserTranslate}
                    onChange={ handleSearch }
                />
                <Button.Group>
                    <Button radius = "xl" variant="filled" color = "violet" onClick={() => {AdminTypeFunction('Admin')}}>Admin</Button>
                    <Button radius = "xl" variant="filled" color = "violet" onClick={() => {AdminTypeFunction('Super Admin')}}>Super Admin</Button>
                </Button.Group>
            </Group>
            <br/>
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchUser) => {
                        if(search === ""){
                            return SearchUser
                        }else if(SearchUser.fullName.toLowerCase().includes(search.toLowerCase())){
                            return SearchUser
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={DataTranslate.ConfirmTitleTranslate} centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}} >{DataTranslate.ConfirmMessageTranslate}</Text>
                    </Group>
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {RevokeUserFunction} color="teal" size="sm" uppercase> {DataTranslate.ConfirmTranslate} </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {DataTranslate.CancelTranslate} </Button>
                </Group>
            </Modal>
        </div>
    )
}