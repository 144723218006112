import { notifications } from '@mantine/notifications';
import { ListofCustomizedAds } from '../../Components/Component';
import './Ads.css'
export const CustomizedAds = () => {
    return (
        <div>
            <ListofCustomizedAds
                notifications = {notifications}
            />
        </div>
    )
}