import React, { useState } from "react";
import { Button, Group, Modal, useMantineTheme, Input, Text } from '@mantine/core';
import { IconUpload, IconCheck, IconLink } from '@tabler/icons-react';
import { api } from '../../Utils'
export const UpdateRedirectionURL = ({notifications, OpenModal, CloseModal, fetchPaymentMethod, PaymentMethodID, PropsAddUrlTranslate, PropsUrlTranslate, PropsBtnUploadUrl, PropsConfirmTitleTranslate, PropsConfirmDescriptionTranslate, PropsConfirmTranslate,PropsCancelTranslate}) => {
    const theme = useMantineTheme();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [URL, setURL] = useState("");
    const handleChangeURL = (event) => {
        setURL(event.target.value)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const UploadURLFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating URL',
            message: 'URL will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const UpdateLocationEP = `paymentMethods/`+PaymentMethodID+`/redirectionUrl?url=`+URL+``
        api.put(UpdateLocationEP,{
        }).then(result => {
        if(result['status'] === 204){
            setTimeout(() => {
                //! RESET INPUTS
                CloseModal();
                fetchPaymentMethod();
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Location URL was uploaded',
                    message: 'Location URL successsfully uploaded',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={PropsAddUrlTranslate}
            size="sm"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
            <Group grow>
                <Input
                    icon={<IconLink />}
                    variant="filled"
                    placeholder={PropsUrlTranslate}
                    size="md"
                    onChange={handleChangeURL}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {PropsBtnUploadUrl}
                </Button>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={PropsConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text sx={{fontFamily : 'poppins'}}>{PropsConfirmDescriptionTranslate} </Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UploadURLFunction} color="teal" size="sm" uppercase> {PropsConfirmTranslate} </Button>
                <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {PropsCancelTranslate} </Button>
            </Group>
        </Modal>
        </>
    )
}