import React, { useState, useEffect, useRef } from 'react';
import { Text, Group, Card, Image, rem, useMantineTheme, Divider, Textarea, Radio, Button, Drawer, Tooltip, ActionIcon, Modal, Stack, Menu, Loader  } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconCheck, IconX, IconPhoto, IconPlus, IconArrowLeft, IconPhotoX, IconLanguage, IconDotsVertical, IconTrash } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { Link, useLocation } from "react-router-dom";
import Axios from 'axios';  
import  LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
import { motion, transform } from "framer-motion";
import { LanguageCardBlogContent } from '../LanguageCardContent/LanguageCardBlogContent';
export const ListofBlogContent = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const [opened, { open, close }] = useDisclosure(false);
    const maxCharacter = 99999
    const [loading, setLoading] = useState(false);
    const theme = useMantineTheme();
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('');
    const [Description, setDescription] = useState("");
    const [value, setValue] = useState('react');
    const [Content, setContent] = useState([]);
    const [Blog, setBlog] = useState([]);
    const location = useLocation();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const { id } = location.state
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TooltipReturnTranslate, setTooltipReturnTranslate] = useState('Return to Reflection')
    const [TooltipAddContentTranslate, setTooltipAddContentTranslate] = useState('Create Reflection Content')
    const [AuthorTranslate, setAuthorTranslate] = useState('AUTHOR')
    const [CreateRelfectionTitleTranslate, setCreateRelfectionTitleTranslate] = useState('Create new Reflection Content')
    const [DragImageTitleTranslate, setDragImageTitleTranslate] = useState('Drag images here or click to select files')
    const [DragImageDescTranslate, setDragImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [RelfectionDescTranslate, setRelfectionDescTranslate] = useState('Description')
    const [PlaceholderDescTranslate, setPlaceholderDescTranslate] = useState('Enter Description')
    const [PositionTranslate, setPositionTranslate] = useState('Select Position Content')
    const [StartTranslate, setStartTranslate] = useState('Start')
    const [CenterTranslate, setCenterTranslate] = useState('Center')
    const [EndTranslate, setEndTranslate] = useState('End')
    const [BtnUploadContentTranslate, setBtnUploadContentTranslate] = useState('UPLOAD CONTENT')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Uploading')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Upload this Content?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')

    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [TranslateLoading, setTranslateLoading] = useState(false)
    let objTranslated = {}
    let ArrayTranslated = []
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        FetchBlogContent();
        FetchSelectedBlog();
        fetchTranslation();
        fetchLanguages();
    }, [DefaultLanguage]);
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipReturnTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipReturnTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipAddContentTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipAddContentTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AuthorTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAuthorTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateRelfectionTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateRelfectionTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RelfectionDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRelfectionDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PositionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPositionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + StartTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setStartTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CenterTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCenterTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EndTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEndTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadContentTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadContentTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
        
    }
    function handleImage(files, setImage){
        let Reader = new FileReader()
        Reader.readAsDataURL(files[0])
        Reader.onload = () => {
            setImage(Reader.result.split(",")[1])
        }
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }
    const FetchBlogContent = async() => {
        setLoading(true);   
        const ListBlogContentEP = `/BlogContents?blogId=`+id+``
        await api.get(ListBlogContentEP).then((result) => {
            setContent(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const FetchSelectedBlog = () => {
        setLoading(true);   
        const BlogEP = `/Blogs/`+id+``
        api.get(BlogEP).then((result) => {
            setBlog(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const UploadBlogContentFunction = async() => {
        let objSample = {}
        let ArraySample = []
        LanguageTranslated.length = LanguageTranslated.length - 1
        LanguageTranslated.map((translated) => {
            objSample = {}
            objSample['text'] = translated.description
            objSample['language'] = translated.code
            ArraySample.push(objSample) 
            
        })
        var postData = {
            "textContent": Description,
            "textAlignment" : value,
            "image" : image ? image : null,
            "localizations": ArraySample
        }
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };  
        Axios.post(''+APILink+'/api/BlogContents/'+id+'', postData, axiosConfig)
        .then((res) => {
            if(res.status === 201){
                notifications.show({
                    id: 'load-data',
                    loading: true,
                    title: 'Uploading Blogs Content',
                    message: 'Blogs Conten will be loaded, you cannot close this yet',
                    autoClose: false,
                    withCloseButton: false,
                    });
                    setTimeout(() => {
                    //! RESET INPUTS
                    FetchBlogContent()
                    setConfirmModal(false)
                    setDescription("")
                    setValue("react")
                    close()
                    setImg([])
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Blogs Conten was uploaded',
                        message: 'Blogs Conten successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        })
        .catch((err) => {
        console.log(err);
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const RemoveContent = async(ID) => {
        const BlogContentsEP = `/BlogContents/`+ID+``
        await api.delete(BlogContentsEP, {
        }).then(result => {
            if(result['status'] === 204){
                notifications.show({
                    id: 'load-data',
                    loading: true,
                    title: 'Removing Content',
                    message: 'Content will be deleted, you cannot close this yet',
                    autoClose: false,
                    withCloseButton: false,
                    });
                    FetchBlogContent();
                    setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Content was deleted',
                        message: 'Content successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const LazyImage = ({cover}) => {
        const rootRef = useRef();
        const [isVisible, setIsVisible] = useState(false);
        useEffect(() => {
            const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
            };
            
            const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                setIsVisible(true);
                }
            });
            };
        
            if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
            }
        }, [isVisible]);
        return (
            <Image 
                maw={550} 
                mx="auto" 
                radius="sm" 
                src={isVisible ? cover : LogoBlurd} 
                ref={rootRef}
                withPlaceholder
                placeholder={<Text align="center">No Image Cover Found</Text>}
            />
        )
        
    }
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedDescription = ''
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": Description},
                ]
            }).then((result) => {
                GetTranslatedDescription = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedDescription = gettranslation.text
                    })
                ))
                objTranslated = {}
                objTranslated['code'] = code.languageCode
                objTranslated['description'] = GetTranslatedDescription
                ArrayTranslated.push(objTranslated)
                setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                setTranslateLoading(false)
            }).catch((error) => { 
            })
        }
    }

    const [TranslatedContentModal, setTranslatedContentModal] = useState(false)
    const [LocalizationData, setLocalizationData] = useState([]);
    const [GetBlogContentDescription, setGetBlogContentDescription] = useState('')
    const [GetBlogContentID, setGetBlogContentID] = useState('')
    const OpenTranslatedModal = async(id, description) => {
        setGetBlogContentID(id)
        setGetBlogContentDescription(description)
        setTranslatedContentModal(true)
        setTranslateLoading(true)
        const ListOfLocalization = `/BlogContents/`+id+`/localizations`
        await api.get(ListOfLocalization).then((result) => {
            setLocalizationData(result.data)
            setTranslateLoading(false)
        }).catch(error => {
        })
    }
    const TranslatedLanguageContent = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedDescription = ''
           
                Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                    method: "POST",
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                        "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                    },
                    data:[
                        {"Text": GetBlogContentDescription}
                    ]
                }).then((result) => {
                    GetTranslatedDescription= ''
                    result.data.map((a) => (
                        a.translations.map((gettranslation) => {
                            GetTranslatedDescription = gettranslation.text
                        })
                    ))
                    objTranslated = {}
                    objTranslated['code'] = code.languageCode
                    objTranslated['description'] = GetTranslatedDescription
                    ArrayTranslated.push(objTranslated)
                    setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                    setTranslateLoading(false)
                }).catch((error) => { 
                    console.log(error)
            })
        }
    }
    const UploadLocalization = () => {
        let ArrayPostData = []
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Blog Content Translation',
            message: 'Blog Content will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        LanguageTranslated.length = LanguageTranslated.length - 1
        LanguageTranslated.map((translated) => {
            var postData = {
                "text" : translated.description,
                "language" : translated.code
            }
            ArrayPostData.push(postData)
        })
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };
        Axios.post(''+APILink+'/api/BlogContents/'+GetBlogContentID+'/localizations', ArrayPostData, axiosConfig)
        .then((res) => {
            setTimeout(() => {
                //! RESET INPUTS
                setLanguageTranslated([])
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Blog Content Translation was uploaded',
                    message: 'Blog Content Translation successsfully Updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000);
        })
        .catch((err) => {
        console.log(err);
        }) 
    }
    return(
    <div style={{paddingLeft : '50px'}} className="FirstContainer">
        {/* <Paper shadow="xl" p="md" >
            <Group spacing={4}>
                <Tooltip
                    label={TooltipReturnTranslate}
                    sx={{background : 'rgb(39, 20, 77)'}}
                    withArrow
                >
                    <Link to={"/reflections"}>
                        <ActionIcon
                            sx={{background : 'rgb(71 46 121)',  ":hover" : {
                                background : 'rgb(39, 20, 77)'
                            }}}
                            size="xl" 
                            radius="xl" 
                            variant="filled"
                        >
                            <IconArrowLeft />
                        </ActionIcon>
                    </Link>
                </Tooltip>
                <Tooltip
                    label={TooltipAddContentTranslate}
                    sx={{background : 'rgb(39, 20, 77)'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : 'rgb(71 46 121)',  ":hover" : {
                            background : 'rgb(39, 20, 77)'
                        }}}
                        size="xl" 
                        radius="xl" 
                        variant="filled"
                        onClick={open}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <Group position='center'>
                <Avatar sx={{ width: 86, height: 86, borderRadius : 80}} src={null} alt="no image here" color="violet" variant = "filled" />
            </Group>
            <Group position='center'>
                <Text fw={500} fz={25}> {Blog.authorName}</Text>
            </Group>
            <Group position='center'>
                <Text color  = "gray" fw={500} fz={18}><i>{AuthorTranslate}</i></Text>
            </Group>
            <br/>
            <Group position='center'>
                <Text lineClamp={1} fw={800} fz={35}>{Blog.title}</Text>
            </Group>
            <Group position='center'>
                <Text fw={500} fz={18} size="xs" ta="center">{Blog.description}</Text>
            </Group>
            <br/>
            <Group position='center'>
                <LazyImage
                    cover = {Blog.cover}
                />
            </Group>
            <br/>
            {Content.map((item, index) =>
                item.textAlignment === 'Start' ?
                <div key = {index}>
                    <Group position='right'>
                        <CloseButton title="Close popover" size="xl" iconSize={20} sx={{color : 'black'}} onClick={() => {RemoveContent(item.id)}} />
                    </Group>
                    <Group grow>
                        <Text fw={500} fz={16} size="xs" ta="justify">{item.text}</Text>
                    </Group>
                    <Group position='left'>
                        {item.imageUrl === null ?
                            ''
                            :
                            <Image 
                                maw={550} 
                                radius="sm" 
                                src={item.imageUrl}
                                withPlaceholder
                                placeholder={<Text align="center">No Image Content Found</Text>}
                            />
                        }
                    </Group>
                    <br/>
                </div>
                :
                item.textAlignment === 'Center' ?
                <div key = {index}>
                    <Group position='right'>
                        <CloseButton title="Close popover" size="xl" iconSize={20} sx={{color : 'black'}} onClick={() => {RemoveContent(item.id)}}/>
                    </Group>
                    <Group grow>
                        <Text fw={500} fz={16} size="xs" ta="center">{item.text}</Text>
                    </Group>
                    <Group position='center'>
                        {item.imageUrl === null ?
                            ''
                            :
                            <Image 
                                maw={550} 
                                radius="sm" 
                                src={item.imageUrl}
                                withPlaceholder
                                placeholder={<Text align="center">No Image Content Found</Text>}
                            />
                        }
                    </Group>
                    <br/>
                </div>
                :
                item.textAlignment === 'End' ?
                <div key = {index}>
                    <Group position='right'>
                        <CloseButton title="Close popover" size="xl" iconSize={20} sx={{color : 'black'}} onClick={() => {RemoveContent(item.id)}}/>
                    </Group>
                    <Group position='right'>
                        <Text fw={500} fz={16} size="xs">{item.text}</Text>
                    </Group>
                    <Group position='right'>
                        {item.imageUrl === null ?
                            ''
                            :
                            <Image 
                                maw={550} 
                                radius="sm" 
                                src={item.imageUrl}
                                withPlaceholder
                                placeholder={<Text align="center">No Image Content Found</Text>}
                            />
                        }
                    </Group>
                    <br/>
                </div>
                :
                ''
            )}
        </Paper> */}
        <Group spacing={4}>
            <Tooltip
                label={TooltipReturnTranslate}
                sx={{background : 'rgb(39, 20, 77)'}}
                withArrow
            >
                <Link to={"/reflections"}>
                    <ActionIcon
                        sx={{background : 'rgb(71 46 121)',  ":hover" : {
                            background : 'rgb(39, 20, 77)'
                        }}}
                        size="xl" 
                        radius="xl" 
                        variant="filled"
                    >
                        <IconArrowLeft />
                    </ActionIcon>
                </Link>
            </Tooltip>
            <Tooltip
                label={TooltipAddContentTranslate}
                sx={{background : 'rgb(39, 20, 77)'}}
                withArrow
            >
                <ActionIcon
                    sx={{background : 'rgb(71 46 121)',  ":hover" : {
                        background : 'rgb(39, 20, 77)'
                    }}}
                    size="xl" 
                    radius="xl" 
                    variant="filled"
                    onClick={open}
                >
                    <IconPlus />
                </ActionIcon>
            </Tooltip>
        </Group>
        <Group position='center'>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0 * 0.1 }} exit={{ opacity: 0 }} style={{width : '40%'}} className="AchievementContent">
                <Card withBorder radius="lg" sx={{
                    boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                    display: 'block',
                    top: '0px',
                    position: 'relative',
                    textDecoration: 'none',
                    zIndex: 0,
                    overflow: 'hidden',
                    border: '1px solid #f2f8f9',
                    cursor: 'pointer',
                    ":hover" : {
                        boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                    }}}
                >
                    <Card.Section>
                        <Image 
                            height = 'auto'
                            src={`https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/image-ultra-high/`+Blog.image?.name+``} 
                            withPlaceholder
                            placeholder={
                                <Group sx={{paddingTop : 20}} position="center">
                                    <IconPhotoX size="3.2rem" stroke={1.5} color="black" />
                                </Group>
                            }
                        />
                    </Card.Section>
                    <Group mt="md">
                        <Text lineClamp = {2} sx={{color : '#4a1e9e', fontSize : 22, fontFamily : 'poppins', fontWeight : 900}}>{Blog.title}</Text>
                    </Group>
                    <Group>
                        <Text ta="justify" size="sm" color="dark"sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{Blog.description}</Text>
                    </Group>
                    <Divider mt="md"/>
                    {Content.map((item, index) =>
                        <Group position="apart" key = {index} mt="md">
                            <Text maw={620} fw={500} fz={14} size="xs" ta="justify" sx={{fontFamily : 'poppins'}}>{item.text}</Text>
                            <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                                <Menu.Target>
                                    <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                        <IconDotsVertical size="1.625rem" />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Label ><Text fw={800} fz="sm" color = "#6c2be2" sx={{fontFamily : 'poppins'}}>ACTION</Text></Menu.Label>
                                    <Menu.Item  sx={{fontFamily : 'poppins'}} color = "black" icon = {<IconLanguage/>} onClick={() => {OpenTranslatedModal(item.id, item.text)}}>Translated Content</Menu.Item>
                                    <Menu.Item  sx={{fontFamily : 'poppins'}} color = "red" icon = {<IconTrash/>}>Remove</Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    )}
                </Card>
            </motion.div>
        </Group>
        <Drawer
                opened={opened}
                onClose={close}
                title={CreateRelfectionTitleTranslate}
                transitionProps={{ transition: 'rotate-left', duration: 150, timingFunction: 'linear' }}
                size={1500}
            >
            <Group align='start' position='center'>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Card padding="lg" radius="sm">
                        <Card.Section>
                        {
                            img.map((url, index) =>
                                <Image 
                                    key = {index} 
                                    maw={300} 
                                    mx="auto"
                                    alt="Event Image" 
                                    src={url}
                                    placeholder={<Text align="center">No Image Detected</Text>} 
                                />
                            )
                        }  
                        </Card.Section>
                        <br/>
                        <Card.Section >
                            <Dropzone
                                onDrop={
                                    (files) => {
                                        handleImage(files, setImage)
                                        const selectedFIles =[];
                                        const targetFilesObject= [...files]
                                        targetFilesObject.map((file)=>{
                                            return selectedFIles.push(URL.createObjectURL(file))
                                        })
                                        setImg(selectedFIles);
                                    } 
                                }
                                maxSize={3 * 1024 ** 2}
                                accept={IMAGE_MIME_TYPE}
                            >
                                <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                                    <Dropzone.Accept>
                                        <IconUpload
                                            size="3.2rem"
                                            stroke={1.5}
                                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                        />
                                    </Dropzone.Accept>

                                    <Dropzone.Reject>
                                        <IconX
                                            size="3.2rem"
                                            stroke={1.5}
                                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                        />
                                    </Dropzone.Reject>

                                    <Dropzone.Idle>
                                        <IconPhoto size="3.2rem" stroke={1.5} />
                                    </Dropzone.Idle>

                                    <div>
                                        <Text size="xl" inline>
                                            {DragImageTitleTranslate}
                                        </Text>
                                        <Text size="sm" color="dimmed" inline mt={7}>
                                            {DragImageDescTranslate}
                                        </Text>
                                    </div>
                                </Group>
                            </Dropzone>
                            <br/>
                            <Divider></Divider>
                            <br/>
                            <Group grow>
                                <Textarea
                                    placeholder={PlaceholderDescTranslate}
                                    label={RelfectionDescTranslate}
                                    size="md"
                                    minRows={15}
                                    withAsterisk
                                    onChange={handleChangeDescription}
                                    description={
                                        Description.length + " of " + maxCharacter
                                    }
                                    error = {
                                        Description.length > maxCharacter ? 
                                        'you reach the limit of characters!'
                                        :
                                        ''
                                    }
                                />
                            </Group>
                            <br/>
                            <Group grow>
                                <Radio.Group
                                    label={PositionTranslate}
                                    withAsterisk
                                    size='md'
                                    value={value}
                                    onChange={setValue}
                                    >
                                    <Group mt="xs">
                                        <Radio value="Start" label={StartTranslate} />
                                        <Radio value="Center" label={CenterTranslate} />
                                        <Radio value="End" label={EndTranslate} />
                                    </Group>
                                </Radio.Group>
                            </Group>
                            <br/>
                            <Group mt="md" mb="xs" grow>
                                <Button onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                                    {BtnUploadContentTranslate}
                                </Button>
                                <Button onClick={TranslateSelectedLanguages} leftIcon = {<IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                                    Translate
                                </Button>
                            </Group>
                        </Card.Section>
                    </Card>
                </Card> 
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Stack justify="flex-start">
                        <Group position = "center">
                            <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>TRANSLATED CONTENT</Text>
                        </Group>
                        <LanguageCardBlogContent
                            LanguageTranslated = {LanguageTranslated}
                            TranslateLoading = {TranslateLoading}
                        />
                    </Stack>
                </Card>
            </Group>
        </Drawer>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text>{ConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button onClick = {UploadBlogContentFunction} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>
        <Modal opened={TranslatedContentModal} onClose={() => {setTranslatedContentModal(false)}} size="xl">
            <Group position = "center">
                <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 30}}>LIST OF LANGUAGE LOCALIZATION</Text>
            </Group>
            <Divider></Divider>
            <br/>
            {LocalizationData.length !== 0 ? 
                LocalizationData.map((code, index) => (
                    <Card padding="xs" radius="md" key = {index} sx={{
                        boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                        cursor : 'pointer',
                        transition : 'transform .2s ease-in-out',
                        MozTransition : 'transform .2s ease-in-out',
                        WebkitTransition : 'transform .2s ease-in-out',
                        marginTop: 10,
                        ":hover" : {
                            background : 'transparent',
                            WebkitTransform : 'scale(1.01)',
                            msTransform : 'scale(1.01)',
                            transform : 'scale(1.01)'
                        }
                    }}>
                        {TranslateLoading ? 
                            <>
                                <Group position='center'>
                                    <Loader color="violet" variant="dots" /><br/>
                                </Group>
                                <Group position='center'>
                                    <i style={{fontFamily : 'poppins', fontSize : 12}}>Translating...</i>
                                </Group>
                            </>
                            : 
                            <>
                                <Stack spacing={1}>
                                    <Group spacing={5}>
                                        <Group spacing={3}>
                                            <IconLanguage size={18}/> - 
                                            <Text fw = {800} color="black" sx={{fontFamily : 'poppins', fontSize : 15}}>Translated in {code.language}</Text>
                                        </Group>
                                    </Group>
                                </Stack>
                                <Group grow>
                                    <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}> {code.text}</Text>
                                </Group>
                            </>
                        }
                    </Card>
                ))
            : 
            <Group grow>
                <Button onClick = {UploadLocalization} leftIcon = {<IconUpload/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                    Upload
                </Button>
                <Button onClick = {TranslatedLanguageContent} leftIcon = {LanguageTranslated.length === 15 ? <IconCheck/> : <IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} loading = {TranslateLoading ? true : false} uppercase>
                    {LanguageTranslated.length === 15 ? 'Translated' : 'Translate'}
                </Button>
            </Group>
            }
        </Modal>
    </div>
    )
}