import React, { useState } from "react";
import { Button, Group, Modal, useMantineTheme, Textarea, Text, TextInput, Card, Image, rem, Divider } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconCheck, IconX, IconPhoto } from '@tabler/icons-react';
import { Token } from '../../Utils'
import Axios from 'axios';
export const UploadPaymentMethod = ({notifications, OpenModal, CloseModal, fetchPaymentMethod, PropsAddNewPaymentMethod, PropsDragImageTitleTranslate, PropsDragImageDescriptionTranslate, PropsNameTranslate, PropsPlaceholderName, PropsAccountNameTranslate, PropsPlaceholderAccountName, PropsAccountNumberTranslate, PropsPlaceholderAccountNumber, PropsConfirmTitleTranslate, PropsConfirmDescriptionTranslate, PropsConfirmTranslate, PropsCancelTranslate, PropsBtnUploadPaymentMethod}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('')
    const [Name, setName] = useState('')
    const [AccoutName, setAccountName] = useState('')
    const [AccountNumber, setAccountNumber] = useState('')
    function handleImage(files){
        setImage(files[0])
    }
    const handleChangeName = (event) => {
        setName(event.target.value)
    }
    const handleChangeAccountName = (event) => {
        setAccountName(event.target.value)
    }
    const handleChangeAccountNumber = (event) => {
        setAccountNumber(event.target.value)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const UploadPaymentMethodFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Payment Method',
            message: 'Payment Method will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const formData = new FormData();
        formData.append('image', image)
        Axios.post(''+APILink+'/api/paymentMethods?name='+Name+'&accountName='+AccoutName+'&accountNumber='+AccountNumber+'', formData,{
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchPaymentMethod()
                    CloseModal();
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Payment Method was uploaded',
                        message: 'Payment Methodgs successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })

    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={PropsAddNewPaymentMethod}
            size="lg"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
            <Card shadow="xl" padding="lg" radius="sm" withBorder>
                <Card.Section>
                {
                    img.map((url, index) =>
                    <Image 
                        key = {index} 
                        maw={300} 
                        mx="auto"
                        alt="Event Image" 
                        src={url}
                        placeholder={<Text align="center">No Image Detected</Text>}
                    />
                    )
                }  
                </Card.Section>
                <Card.Section >
                    <Dropzone
                        onDrop={
                            (files) => {
                                handleImage(files)
                                const selectedFIles =[];
                                const targetFilesObject= [...files]
                                targetFilesObject.map((file)=>{
                                    return selectedFIles.push(URL.createObjectURL(file))
                                })
                                setImg(selectedFIles);
                            } 
                        }
                        maxSize={3 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                    >
                        <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>

                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>

                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                    {PropsDragImageTitleTranslate}
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7} sx={{fontFamily : 'poppins'}}>
                                    {PropsDragImageDescriptionTranslate}
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>
                </Card.Section>
            </Card>
            <br/>
            <Divider></Divider>
            <br/>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderName}
                    label={PropsNameTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    onChange={handleChangeName}
                    sx={{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderAccountName}
                    label={PropsAccountNameTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    onChange={handleChangeAccountName}
                    sx={{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderAccountNumber}
                    label={PropsAccountNumberTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    onChange={handleChangeAccountNumber}
                    sx={{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                   {PropsBtnUploadPaymentMethod}
                </Button>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={PropsConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text sx={{fontFamily : 'poppins'}}>{PropsConfirmDescriptionTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UploadPaymentMethodFunction} color="teal" size="sm" uppercase> {PropsConfirmTranslate} </Button>
                <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {PropsCancelTranslate} </Button>
            </Group>
        </Modal>
        </>
    )
}