import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Button, TextInput, Group, Tooltip, ActionIcon, Modal, Text, Stack, Menu, Divider, Avatar, Grid, Textarea,Loader } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken, azure_api } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconPlus, IconDotsVertical, IconMicrophone, IconEye, IconUpload} from '@tabler/icons-react';
import Axios from 'axios';
export const ListofVoices = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const countPerPage = 10
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [LanguageData, setLanguageData] = useState([])
    const [SelectedData, setSelectedData] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [ViewInformationModal, setViewInformationModal] = useState(false)
    const [OpenUploadModal, setOpenUploadModal] = useState(false)
    const [VoiceID, setVoiceID] = useState('')
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [Data, SetData ] = useState({
        "Name": "",
        "Gender": "",
        "DisplayName": "",
        "LocalName": "",
        "ShortName": "",
        "Local": "",
        "Hertz": "",
        "VoiceType": "",
        "Status": "",
    })
    const [DataTranslate, setDataTranslate] = useState({
        "TblVoiceTranslate" : 'VOICES',
        "SearchVoiceTranslate" : 'Search Voice',
        "TooltipUploadVoice" : 'Upload Voice',
        "ActionTranslate" : 'ACTION',
        "ViewInformationTranslate" : 'View Information',
        "RemoveVoiceTranslate" : 'Remove Voice',
        "UploadVoice": "Upload Voice",
        "SelectLanguage": "Select Language",
        "NameTranslate": "Name",
        "PlacholderName" : "Enter Name",
        "DisplayNameTranslate" : "Display Name",
        "PlaceholderDisplayName" : "Enter Display Name",
        "LocalNameTranslate" : "Local Name",
        "PlaceholderLocalName" : "Enter Local Name",
        "ShortNameTranslate" : "Short Name",
        "PlaceholderShortName" : "Enter Short Name",
        "LocalTranslate" : "Local",
        "PlaceholderLocal" : "Enter Local",
        "GenderTranslate" : "Gender",
        "PlaceholderGender" : "Enter Gender",
        "HertzTranslate" : "Hertz",
        "PlaceholderHertz" : "Enter Hertz",
        "VoiceTypeTranslate" : "Voice Type",
        "PlaceholderVoiceType" : "Enter Voice Type",
        "StatusTranslate" : "Status",
        "PlaceholderStatus" : "Enter Status",
        "BtnUploadVoice" : "UPLOAD VOICE",
        "InformationTranslate" : 'Information'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchVoice();
        FetchTextToSpeech();
        fetchTranslation();
    }, [DefaultLanguage]);
    let obj = {}
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchVoice = async () => {
        setLoading(true);   
        const ListOfVoiceEP = `/voices`
        await api.get(ListOfVoiceEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const ViewInformationFunction = async(ID) => {
        setViewInformationModal(true)
        const SelectedOfVoiceEP = `/voices/`+ID
        await api.get(SelectedOfVoiceEP).then((result) => {
            setSelectedData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setVoiceID(ID)
    }
    const UploadVoiceFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Voice',
            message: 'Voice will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        Axios.post(''+APILink+'/api/voices',{
            name: Data.Name,
            displayName: Data.DisplayName ,
            localName: Data.LocalName ,
            shortName: Data.ShortName,
            gender: Data.Gender,
            locale: Data.Local,
            sampleHertz: Data.Hertz,
            voiceType: Data.VoiceType,
            status: Data.Status
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    setOpenUploadModal(false)
                    fetchVoice()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Voice was uploaded',
                        message: 'Voice successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })
    }
    const RemoveVoiceFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Voice',
            message: 'Voice will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const RemoveVoiceEP = `/voices/`+VoiceID+``
        await api.delete(RemoveVoiceEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchVoice();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Voice was deleted',
                        message: 'Voice successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
                
            }else{}
        }).catch(error =>{  
        })
    }
    const FetchTextToSpeech = async() => {
        const TextToSpeechEP = `/cognitiveservices/voices/list`
        await azure_api.get(TextToSpeechEP).then((result) => {
            setLanguageData(result.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const GetSelectedLanguage = async(item) => {
        const data = LanguageData.find(obj => {
            return obj.Name === item
        })
        SetData({
            "Name": data?.Name,
            "Gender": data?.Gender,
            "DisplayName": data?.DisplayName,
            "LocalName": data?.LocalName,
            "ShortName": data?.ShortName,
            "Local": data?.Locale,
            "Hertz": data?.SampleRateHertz,
            "VoiceType": data?.VoiceType,
            "Status": data?.Status,
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{DataTranslate.TblVoiceTranslate}</Text>,
            cell : row =>
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group spacing={8}>
                    <IconMicrophone style={{ width : 40, height : 40}}/>
                    <Stack spacing={1} className="ListOfReasonContent">
                        <Text fw={700} fz="md" sx={{color : '#4a1e9e', wordBreak : 'break-all', fontFamily : 'poppins'}}>{row.name}</Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx={{fontFamily : 'poppins'}}>{DataTranslate.ActionTranslate}</Text></Menu.Label>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconEye/>} onClick={() => {ViewInformationFunction(row.id)}}>{DataTranslate.ViewInformationTranslate}</Menu.Item>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconTrash/>} onClick={() => {CallConfirmModal(row.id)}} color="red">{DataTranslate.RemoveVoiceTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group>
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchVoiceTranslate}
                    onChange={ handleSearch }
                />
                <Tooltip
                    label={DataTranslate.TooltipUploadVoice}
                    sx={{background : '#6c2be2'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadModal(true)}}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <br/>
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchVoice) => {
                        if(search === ""){
                            return SearchVoice
                        }else if(SearchVoice.name.toLowerCase().includes(search.toLowerCase())){
                            return SearchVoice
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                 responsive = ""
            />
            <Modal size = "lg" opened={ViewInformationModal} onClose={() => {setViewInformationModal(false)}} title={DataTranslate.InformationTranslate} centered>
                <Group position="center">
                    <Avatar variant="filled" color="violet" sx={{width: 100, height : 100, borderRadius : 50, boxShadow : '1px 2px 2px 1px rgb(114, 110, 110)'}}>{<IconMicrophone color="white" size={60}/>}</Avatar>
                </Group>
                <Group position="center">
                    <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{SelectedData.name}</Text>
                </Group>
                <Divider/>
                <br/>
                <Grid gutter="xl" grow>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.LocalNameTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.localName}</Text>
                    </Grid.Col>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.DisplayNameTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.displayName}</Text>
                    </Grid.Col>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.ShortNameTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.shortName}</Text>
                    </Grid.Col>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.GenderTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.gender}</Text>
                    </Grid.Col>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.LocalTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.locale}</Text>
                    </Grid.Col>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.HertzTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.sampleHertz}</Text>
                    </Grid.Col>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.VoiceTypeTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.voiceType}</Text>
                    </Grid.Col>
                    <Grid.Col md={6} lg={3}>
                        <Text fw={700} sx={{fontSize : 18, fontFamily : 'poppins'}} align="center">{DataTranslate.StatusTranslate}</Text>
                        <Text fw={600} sx={{fontSize : 15, color : '#4a1e9e', lineHeight : 1, wordBreak : 'break-all', fontFamily : 'poppins'}} align="center">{SelectedData.status}</Text>
                    </Grid.Col>
                </Grid>
            </Modal>
            <Modal size = "lg" opened={OpenUploadModal} onClose={() => {setOpenUploadModal(false)}} title={DataTranslate.UploadVoice}>
                <Group grow>
                    <input onChange={(val) => {GetSelectedLanguage(val.target.value)}} type="text" list="LanguageList" placeholder={DataTranslate.SelectLanguage} style = {{
                            padding : '10px',
                            fontSize :'15px',
                            fontWeight : 'bold',
                            borderRadius : '3px',
                            fontFamily : 'poppins'
                    }}/>
                    <datalist id="LanguageList">
                        {LanguageData?.map((language, index) =>
                            <option key={ index } value={language.Name}></option>
                        )}
                    </datalist >
                </Group>
                <Group grow>
                    <Textarea
                        placeholder={DataTranslate.PlacholderName}
                        label={DataTranslate.NameTranslate}
                        size="md"
                        minRows={3}
                        withAsterisk
                        value={Data?.Name}
                        onChange={(e) => SetData((current) => ({...current, Name: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group grow>
                    <TextInput
                        placeholder={DataTranslate.PlaceholderDisplayName}
                        label={DataTranslate.DisplayNameTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.DisplayName}
                        onChange={(e) => SetData((current) => ({...current, DisplayName: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                    <TextInput
                        placeholder={DataTranslate.PlaceholderLocalName}
                        label={DataTranslate.LocalNameTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.LocalName}
                        onChange={(e) => SetData((current) => ({...current, LocalName: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group grow>
                    <TextInput
                        placeholder={DataTranslate.PlaceholderShortName}
                        label={DataTranslate.ShortNameTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.ShortName}
                        onChange={(e) => SetData((current) => ({...current, ShortName: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                    <TextInput
                        placeholder={DataTranslate.PlaceholderLocal}
                        label = {DataTranslate.LocalTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.Local}
                        onChange={(e) => SetData((current) => ({...current, Local: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group grow>
                    <TextInput
                        placeholder={DataTranslate.PlaceholderGender}
                        label = {DataTranslate.GenderTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.Gender}
                        onChange={(e) => SetData((current) => ({...current, Gender: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                    <TextInput
                        placeholder={DataTranslate.PlaceholderHertz}
                        label={DataTranslate.HertzTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.Hertz}
                        onChange={(e) => SetData((current) => ({...current, Hertz: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group grow>
                    <TextInput
                        placeholder={DataTranslate.PlaceholderVoiceType}
                        label={DataTranslate.VoiceTypeTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.VoiceType}
                        onChange={(e) => SetData((current) => ({...current, VoiceType: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                    <TextInput
                        placeholder={DataTranslate.PlaceholderStatus}
                        label={DataTranslate.StatusTranslate}
                        radius="xs"
                        size="md"
                        withAsterisk
                        value={Data?.Status}
                        onChange={(e) => SetData((current) => ({...current, Status: e.target.value}))}
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {UploadVoiceFunction} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                        {DataTranslate.BtnUploadVoice}
                    </Button>
                </Group>
            </Modal>
             <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Delete" centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>Are you sure you want to Delete this Voice? </Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {RemoveVoiceFunction} color="teal" size="sm" uppercase> Confirm </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> Cancel </Button>
                </Group>
            </Modal>
        </div>
    )
}