import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import Axios from 'axios';
import { TextInput, Group, Text, Tooltip, ActionIcon, Stack, Menu, Modal, Button, Card, Image, Badge, MultiSelect, Drawer, Avatar, CloseButton, Indicator, ScrollArea } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconPlus, IconDotsVertical, IconCheck, IconEye, IconFlag, IconUpload, IconFlagFilled  } from '@tabler/icons-react';
import { UploadHolidays } from "../CalendarEvent/UploadHolidays";
import Moment from 'moment';
import AllCountry from '../../Helpers/AllCountry'
export const ListofCalendarEvent = ({notifications}) => {
    const [data, setData] = useState([]);
    const [HolidayData, setHolidayData] = useState([])
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1)
    const countPerPage = 10
    const [OpenUploadModal, setOpenUploadModal] = useState(false)
    const [Month, setMonth] = useState('')
    const [CountryCode, setCountryCode] = useState('')
    const [ToUpdatecCountry, setToUpdateCountry] = useState('')
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [HolidayID, setHolidayID] = useState('')
    const [OpenViewHolidayModal, setOpenViewHolidayModal] = useState(false)
    const [OpenListOfCountryModal, setListOfCountryModal] = useState(false)
    const [opened, { open, close }] = useDisclosure(false);
    const [ViewCountryModal, setViewCountryModal] = useState(false)
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SelectMonthTranslate" : 'Select Month',
        "SelectCountryTranslate" : 'Select Country',
        "SearchHolidayTranslate" : 'Search Holiday',
        "UploadholidayTranslate" : 'Upload Holiday',
        "HolidayTranslate" : 'Holidays',
        "ActionTranslate" : 'ACTION',
        "ViewHolidayTranslate" : 'View Holiday',
        "AssignCountryTranslate" : 'Assign Country',
        "RemoveHolidayTranslate" : 'RemoveCountry',
        "DragImageTitleTranslate" : 'Drag images here or click to select files',
        "DragImageDescriptionTranslate" : 'Attatch as many files as you like, each file should not exceed 5mb',
        "NameTranslate" : 'Name',
        "PlaceholderName" : 'Enter Name',
        "GreetingsTranslate" : 'Greetings',
        "PlaceholderGreetings" : 'Enter Greetings',
        "DescriptionTranslate" : 'Enter Description',
        "PlaceholderDescription" : 'Enter Description',
        "SelectDateTranslate" : 'Select Date',
        "ConfirmTitleTranslate" : 'Confirm Uploading',
        "ConfirmMessageTranslate" : 'Are you sure you wan to Upload this Holiday ? ',
        "ConfirmTranslate" : 'Cofirm',
        "CancelTranslate" : 'Cancel',
        "BtnUploadHoliday" : 'UPLOAD HOLIDAY',
        "ListOfHolidaysTranslate" : 'List of Holidays',
        "UpdateCountriesTranslate" : 'Update Countries',
        "CountriesTranslate" : 'Countries',
        "CountriesLabelTranslate" : 'you can select multiple countries to set this holiday',
        "SelectCountryTranslate" : 'Select Country',
        "BtnUpdateCountry" : 'UPDATE COUNTRY'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const fetchCalendarEvent = async() =>{
        setLoading(true);   
        const ListCalendarEventEP = `/calendarevents?filter=`+Month+`&languageCode=`+CountryCode+``
        await api.get(ListCalendarEventEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const CallConfirmModal = async(ID) =>{
        setHolidayID(ID)
        setConfirmModal(true)
    }
    const CallViewHolidayModal = async(ID) => {
        setOpenViewHolidayModal(true)
        setLoading(true);   
        const HolidayEP = `/calendarevents/`+ID+``
        await api.get(HolidayEP).then((result) => {
            setHolidayData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CallUpdateCountryModal = async(ID) => {
        open()
        setHolidayID(ID)
    }
    const DeleteHoliday = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Holiday',
            message: 'Holiday will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const CalendarEventEP = `/calendarevents/`+HolidayID+``
        await api.delete(CalendarEventEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchCalendarEvent();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Holiday was deleted',
                        message: 'Blog successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const UpdateCountryFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Provider',
            message: 'Provider will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        ToUpdatecCountry.map(async(country) =>{
            const UpdateEP = `/calendarevents/`+HolidayID+`/country?languageCode=`+country+``
            await api.post(UpdateEP, {
            }).then(result => {
                if(result['status'] === 200){
                    fetchCalendarEvent();
                }else{}
            }).catch(error =>{
            })

        })
        setTimeout(() => {
            close()
            notifications.update({
                id: 'load-data',
                color: 'teal',
                title: 'Provider was uploaded',
                message: 'Provider successsfully uploaded',
                icon: <IconCheck size="1rem" />,
                autoClose: 2000,
        });
        }, 1000); 
    }
    data.sort(function(a,b){
        return new Date(a.date) - new Date(b.date);
    });
    const columns = [
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{DataTranslate.HolidayTranslate}</Text>,
            cell: row => 
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group sx={{maxWidth : '95%'}} className="CalendarEventContent">
                    <Stack spacing={1} justify="flex-start" >
                        <Text fw={700} sx={{lineHeight: 1, color : '#4a1e9e', fontSize : 25, wordBreak : 'break-all', fontFamily : 'poppins'}}>{row.name}</Text>
                        <Text fw={600} sx={{ fontSize : 14, fontFamily : 'poppins'}}>{row.description}</Text>
                    </Stack>
                </Group>
                <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                    <Menu.Target>
                        <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                            <IconDotsVertical size="1.625rem" />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label><Text sx={{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">ACTION</Text></Menu.Label>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconEye/>} onClick={() => {CallViewHolidayModal(row.id)}}>{DataTranslate.ViewHolidayTranslate}</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconFlag/>} onClick={() => {CallUpdateCountryModal(row.id)}}>{DataTranslate.AssignCountryTranslate}</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconTrash/>} color="red" onClick={() => {CallConfirmModal(row.id)}}>{DataTranslate.RemoveHolidayTranslate}</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group mt="md" mb="xs" grow>
                <select onChange={(val) => {setMonth(val.target.value)}} style = {{
                    padding : '10px',
                    fontSize :'15px',
                    fontWeight : 'bold',
                    borderRadius : '3px',
                    fontFamily : 'poppins'
                }}>
                    <option value = "">{DataTranslate.SelectMonthTranslate}</option>
                    <option value = "0"> All </option>
                    <option value = "1"> January </option>
                    <option value = "2"> February </option>
                    <option value = "3"> March </option>
                    <option value = "4"> April </option>
                    <option value = "5"> May </option>
                    <option value = "6"> June </option>
                    <option value = "7"> July </option>
                    <option value = "8"> August </option>
                    <option value = "9"> September </option>
                    <option value = "10"> October </option>
                    <option value = "11"> November </option>
                    <option value = "12"> December </option>
                </select>
                {Month === '0' || Month === '' ?
                    ''
                    :
                    <select onChange={(val) => {setCountryCode(val.target.value)}} style = {{
                        padding : '10px',
                        fontSize :'15px',
                        fontWeight : 'bold',
                        borderRadius : '3px',
                        fontFamily : 'poppins'
                    }}>
                        <option value = "">{DataTranslate.SelectCountryTranslate}</option>
                        {AllCountry.map((country, index) =>
                            <option key={ index } value = {country.code}>
                                { country.name }
                            </option>
                        )}
                    </select>
                }
                <Group mt="md" mb="xs">
                    <ActionIcon 
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl"
                        variant="filled"
                        onClick={fetchCalendarEvent}
                    >
                        <IconSearch/>
                    </ActionIcon>
                </Group>
            </Group>
            <Group mt="md" mb="xs">
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchHolidayTranslate}
                    onChange={ handleSearch }
                />
                <Tooltip
                    label={DataTranslate.UploadholidayTranslate}
                    sx={{background : '#6c2be2'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e',  ":hover" : {
                            background : '#6c2be2'
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadModal(true)}}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>  
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchHoliday) => {
                        if(search === ""){
                            return SearchHoliday
                        }else if(SearchHoliday.name.toLowerCase().includes(search.toLowerCase())){
                            return SearchHoliday
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            <UploadHolidays
                notifications={notifications}
                fetchCalendarEvent={fetchCalendarEvent}
                OpenModal={OpenUploadModal}
                CloseModal={() => {setOpenUploadModal(false)}}
                AllCountry = {AllCountry}
                PropsUploadHolidayTranslate = {DataTranslate.UploadholidayTranslate}
                PropsSelectCountryTranslate = {DataTranslate.SelectCountryTranslate}
                PropsDragImageTitleTranslate = {DataTranslate.DragImageTitleTranslate}
                PropsDragImageDescriptionTranslate = {DataTranslate.DragImageDescriptionTranslate}
                PropsNameTranslate = {DataTranslate.NameTranslate}
                PropsPlaceholderName = {DataTranslate.PlaceholderName}
                PropsGreetingsTranslate = {DataTranslate.GreetingsTranslate}
                PropsPlaceholderGreetings = {DataTranslate.PlaceholderGreetings}
                PropsDescriptionTranslate = {DataTranslate.DescriptionTranslate}
                PropsPlaceholderDescription = {DataTranslate.PlaceholderDescription}
                PropsSelectDateTranslate = {DataTranslate.SelectDateTranslate}
                PropsBtnUploadHoliday = {DataTranslate.BtnUploadHoliday}
                PropsConfirmMessageTitle = {DataTranslate.ConfirmTitleTranslate}
                PropsConfirmMessageDescription = {DataTranslate.ConfirmMessageTranslate}
                PropsConfirmTranslate = {DataTranslate.ConfirmTranslate}
                PropsCancelTranslate = {DataTranslate.CancelTranslate}
                PropsListOfHolidayTranslate = {DataTranslate.ListOfHolidaysTranslate}
            />
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Deleting" centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>Are you sure you want to Delete this Holiday?</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {DeleteHoliday} color="teal" size="sm" uppercase> Confirm </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> Cancel </Button>
                </Group>
            </Modal>
            <Modal opened={OpenViewHolidayModal} onClose={() => {setOpenViewHolidayModal(false)}} size="lg" centered>
                <Card shadow="xl" padding="lg" radius="sm" withBorder>
                    <Card.Section>
                        <Image
                            src={HolidayData.image}
                        />
                    </Card.Section>
                    <Group position="apart" mt="md" mb="xs">
                        <Text sx = {{fontFamily : 'poppins'}} weight={700} fz={25} color = "#4a1e9e" lineClamp={1}>{HolidayData.name}</Text>
                        <Badge color="violet" sx={{color : 'white'}} variant="filled" size="lg">
                            {Moment(HolidayData.date).format('MMMM DD')}
                        </Badge>
                    </Group>
                    <Text sx = {{fontFamily : 'poppins'}} weight={500}>{HolidayData.greetings}</Text>
                    <Text sx = {{fontFamily : 'poppins'}} size="sm"  color="dark">{HolidayData.description}</Text>
                    <Group sx={{paddingTop : 5}} position="right">
                        <Avatar.Group spacing="sm" onClick={() => {setViewCountryModal(true)}}>
                            {HolidayData?.countries?.slice(0, 3).map((code) =>
                                <Avatar src={null} sx={{boxShadow : '1px 2px 2px 1px rgb(114, 110, 110)'}} color="violet" variant="filled" radius = 'xl'><Text uppercase>{code.languageCode}</Text></Avatar>
                            )}
                            {
                                HolidayData?.countries?.length === 0 ?
                                <Text sx = {{fontFamily : 'poppins'}} color = "red" fw={500}><i>No Country Added</i></Text>
                                :
                                HolidayData?.countries?.length <= 3 ? 
                                ''
                                :
                                <Avatar radius="xl" sx={{boxShadow : '1px 2px 2px 1px rgb(114, 110, 110)'}} onClick={() => {setListOfCountryModal(true)}}><Text color = "#4a1e9e">+{HolidayData?.countries?.length - 3}</Text></Avatar>
                            }
                        </Avatar.Group>
                    </Group>
                </Card>
            </Modal>
            <Modal opened={ViewCountryModal} onClose={() => {setViewCountryModal(false)}} size="sm" centered title = "List of Countries">
                <ScrollArea h={250} scrollbarSize={12} scrollHideDelay={0}>
                    {HolidayData?.countries?.map((code) =>
                        <Group position="apart" sx={{ cursor : 'pointer', transition: '0.3s', ":hover" : { background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)' }, padding : 8}}>
                            <Group spacing={8}>
                                <Indicator color="green" size={15} withBorder>
                                    <Avatar src={null} sx={{boxShadow : '1px 2px 2px 1px rgb(114, 110, 110)'}} color="violet" variant="filled" >
                                    <IconFlagFilled size={30}/>
                                    </Avatar>
                                </Indicator>
                                <Text sx = {{fontFamily : 'poppins'}} fw={700} fz="xl" tt="uppercase">{code.languageCode}</Text>
                            </Group>
                            <CloseButton title="Close popover" size="xl" iconSize={20} sx={{  ":hover" : { background : 'transparent' }}} />
                        </Group>
                    )}
                 </ScrollArea>
            </Modal>
            <Drawer opened={opened} onClose={close} title={DataTranslate.UpdateCountriesTranslate}>
                <Group grow>
                    <MultiSelect
                        icon={<IconFlag/>}
                        radius="xl"
                        data={
                            AllCountry.map((country) => 
                                country.code
                            )
                        }
                        label={DataTranslate.CountriesTranslate}
                        placeholder={DataTranslate.SelectCountryTranslate}
                        description = {DataTranslate.CountriesLabelTranslate}
                        size = "md"
                        transitionProps={{ duration: 150, transition: 'pop-top-left', timingFunction: 'ease' }}
                        searchable
                        withAsterisk
                        onChange={(val) => setToUpdateCountry(val)}
                        sx = {{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Button sx = {{fontFamily : 'poppins'}} onClick={UpdateCountryFunction} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                        {DataTranslate.BtnUpdateCountry}
                    </Button>
                </Group>
            </Drawer>
        </div>
    )
}