import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Button, TextInput, Group, Tooltip, ActionIcon, Modal, Text, Stack, Menu } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconPlus, IconBuildingChurch, IconDotsVertical} from '@tabler/icons-react';
import { UploadChurch } from "../Component";
import Axios from 'axios';
export const ListofChurch = ({notifications}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [ChurchID, setChurchID] = useState("")
    const [OpenUploadChurch, setOpenUploadChurch] = useState(false)
    const [search, setSearch] = useState('');
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblChurchName, setTblChurchName] = useState('CHURCH NAME')
    const [SearchChurchTranslate, setSearchChurchTranslate] = useState('Search Church Name')
    const [UploadChurchTranslate, setUploadChurchTranslate] = useState('Upload Church')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [RemoveChurchTranslate, setRemoveChurchTranslate] = useState('Remove Church')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Deleting')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Delete this Church?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')

    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchChurch();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblChurchName,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblChurchName(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchChurchTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchChurchTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadChurchTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadChurchTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveChurchTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveChurchTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchChurch = async () => {
        setLoading(true);   
        const ListChurchEP = `/Churches`
        await api.get(ListChurchEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setChurchID(ID)
    }
    const DeleteChurch = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Church',
            message: 'Church will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const BlogsEP = `/Churches/`+ChurchID+``
        await api.delete(BlogsEP, {
        }).then(result => {
                if(result['status'] === 204){
                    fetchChurch();
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Church was deleted',
                            message: 'Church successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{  
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx = {{fontFamily : 'poppins'}}>{TblChurchName}</Text>,
            cell : row =>
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group spacing={8}>
                    <IconBuildingChurch style={{ width : 32, height : 32}}/>
                    <Stack spacing={1} className="ListOfReasonContent">
                        <Text fw={700} fz="sm" sx={{color : '#4a1e9e', wordBreak : 'break-all', fontFamily : 'poppins'}}>{row.name}</Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{ActionTranslate}</Text></Menu.Label>
                            <Menu.Item sx = {{fontFamily : 'poppins'}} icon = {<IconTrash/>} color="red" onClick={() => {CallConfirmModal(row.id)}}>{RemoveChurchTranslate}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
        <Group>
            <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchChurchTranslate}
                    onChange={ handleSearch }
                />
            <Tooltip
                label={UploadChurchTranslate}
                sx={{background : '#6c2be2'}}
                withArrow
            >
                <ActionIcon
                    sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick={() => {setOpenUploadChurch(true)}}
                >
                    <IconPlus />
                </ActionIcon>
            </Tooltip>
        </Group>
        <br/>
        <DataTable
            columns={columns}
            data={
                data.filter((SearChurch) => {
                    if(search === ""){
                        return SearChurch
                    }else if(SearChurch.name.toLowerCase().includes(search.toLowerCase())){
                        return SearChurch
                    }
                })
            }
            progressPending={loading}
            pagination
            paginationTotalRows={data.length}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
            responsive = ""
        />
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text>{ConfirmMessageTranslate}</Text>
                </Group>
            </Group>
            <br/>
            <Group position='right'>
                <Button onClick = {DeleteChurch} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>
        <UploadChurch
            notifications={notifications}
            OpenModal = {OpenUploadChurch}
            CloseModal = {() => {setOpenUploadChurch(false)}}
            fetchChurch = {fetchChurch}
        />
        </div>
    )
}