import React, { useState, useEffect, useRef } from "react";
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { Card, Group, Text, Menu, ActionIcon, Grid, Stack, Avatar, TextInput, Tooltip, Modal, Button } from '@mantine/core';
import { IconCamera, IconDots, IconPlus, IconTrash, IconTrophyFilled, IconSearch, IconCheck, IconStarFilled } from '@tabler/icons-react';
import { UploadAchievement } from "./UploadAchievement";
import { AssignBadge } from "./AssignBadge";
import  LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
import Axios from 'axios';
export const ComponentAchievement = ({notifications}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [search, setSearch] = useState('')
    const [OpenUploadAchievementModal, setOpenUploadAchievementModal] = useState(false)
    const [OpenAssignBadgeModal, setOpenAssignBadgeModal] = useState(false)
    const [AchievementID, setAchievementID] = useState("'")
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SearchAchievementTranslate" : 'Search Achievement',
        "TooltipUploadAchievementTranslate" : 'Upload Achievement',
        "AssignBadgeTranslate" : 'Assign Badge',
        "DeleteAchievementTranslate" : 'Delete Achievement',
        "AddAchievementTranslate" : 'Add New Achievement',
        "AchievementTitleTranslate" : 'Achievement Title',
        "PlaceholderAchievementTitle" : 'Enter Achievement Title',
        "DescriptionTranslate" : 'Description',
        "PlaceholderDescription" : 'Enter Description',
        "AchievementDescriptionTranslate" : 'Achievement Description',
        "PlaceholderAchievementDescription" : 'Enter Achievement Description',
        "AchievementSubtitleTranslate" : 'Achievement Subtitle',
        "PlaceholderAchievementSubtitle" : 'Enter Achievement Subtitle',
        "PointSourceTranslate" : 'Point Source',
        "PlaceholderPointSource" : 'Enter Point Source',
        "NoticeTranslate" : 'this is for uploading time required points',
        "TimeFormatTranslate" : 'Time Format',
        "PlaceholderTimeFormat" : 'Pick One',
        "SecondTranslate" : 'Second',
        "MinuteTranslate" : 'Minute',
        "HourTranslate" : 'Hour',
        "TimeValueTranslate" : 'Time Value',
        "PlaceholderTimeValue" : 'Enter Time Value',
        "RequiredPointsTranslate" : 'Required Points',
        "PlaceholderRequiredPoints" : 'Enter Required Points',
        "ExperiencePointsTranslate" : 'Experience Points',
        "PlaceholderExperiencePoints" : 'Enter Experience Points',
        "BtnUploadAchievement" : 'UPLOAD ACHIEVEMENT',
        "ConfirmTitleTranslate" : 'Confirm Uploading',
        "ConfirmMessageTranslate" : 'Are you sure you wan to Upload this Achievement ? ',
        "ConfirmTranslate" : 'Cofirm',
        "CancelTranslate" : 'Cancel',
        "AssignBadgeTranslate" : 'Assign Badge',
        "SearchBadgeTranslate" : 'Search Badge Name',
        "UploadBadgeTranslate" : 'Upload Badge',
        "DragImageTitleTranslate" : 'Drage images here or click to select files',
        "DragImageDescriptionTranslate" : 'Attatch as many files as you like, each file should not exceed 5mb',
        "BadgeNameTranslate" : 'Badge Name',
        "PlaceholderBadgeName" : 'Enter BadgeName',
        "BtnUploadBadge" : 'UPLOAD IMAGE'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchAchievement();
        fetchTranslation();
    }, [DefaultLanguage])
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const fetchAchievement = async() => {
        setLoading(true);   
        const ListChurchEP = `/Achievements?PageNumber=1&PageSize=100`
        await api.get(ListChurchEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    data.sort(function(a,b){
        return (a.pointSource) - (b.pointSource);
    })
    const LazyImage = ({src}) => {
        const rootRef = useRef();
        const [isVisible, setIsVisible] = useState(false);
        useEffect(() => {
            const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
            };
            
            const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                setIsVisible(true);
                }
            });
            };
        
            if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
            }
        }, [isVisible]);
        return (
            <Avatar radius="xl" 
                sx={{width : 100, height : 100}}
                src={isVisible ? src : LogoBlurd} 
                ref={rootRef}
            />
        )
      
    }
    const RemoveAchievement = async() => {
        setConfirmModal(false)
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Achievement',
            message: 'Achievement will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const BlogsEP = `/Achievements/`+AchievementID+``
        await api.delete(BlogsEP, {
        }).then(result => {
                if(result['status'] === 204){
                    fetchAchievement();
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Achievement was deleted',
                            message: 'Achievement successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{  
        })
    }
    const ToRemoveAchievementID = (ID) => {
        setConfirmModal(true)
        setAchievementID(ID)
    }
    const ToUpdateAchievementID = (ID) => {
        setAchievementID(ID)
        setOpenAssignBadgeModal(true)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
        <Group>
            <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchAchievementTranslate}
                    onChange={ handleSearch }
                />
            <Tooltip
                label={DataTranslate.TooltipUploadAchievementTranslate}
                sx={{background : '#6c2be2'}}
                withArrow
            >
                <ActionIcon
                    sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick={() => {setOpenUploadAchievementModal(true)}}
                >
                    <IconPlus />
                </ActionIcon>
            </Tooltip>
        </Group>
        <br/>
        <Grid gutter="xs" grow>
         {data.filter((SearchAvhievement) => {
            if(search === ""){
                return SearchAvhievement
            }else if(SearchAvhievement.achievementName.toLowerCase().includes(search.toLowerCase())){
                return SearchAvhievement
            }
        }).map((achievement, index) =>
        <div key = {index} style={{width : '20%'}} className="AchievementContent">
            <Grid.Col> 
                <Card withBorder radius="lg" sx={{
                     boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                     display: 'block',
                     top: '0px',
                     position: 'relative',
                     textDecoration: 'none',
                     zIndex: 0,
                     overflow: 'hidden',
                     border: '1px solid #f2f8f9',
                     ":hover" : {
                        transition: 'all 0.2s ease-out',
                        boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                        top: '4px',
                        border: '1px solid #cccccc'
                    }
                }} >
                    <Card.Section withBorder inheritPadding py="xs" sx={{background : '#6c2be2'}}>
                        <Group position="apart">
                            <Group spacing={4}>
                                <IconTrophyFilled size={54} style={{color : 'gold'}}/>
                                <Stack spacing="xs" >
                                    <Group position="left">
                                        <Text fw={700} fz="xl" sx={{lineHeight: 1, color : 'white', fontFamily : 'poppins'}}>{achievement.achievementName}</Text>
                                    </Group>
                                    <Group position="left" spacing={2}>
                                        <IconStarFilled size={15} style={{color : 'yellow'}}/>
                                        <Text fw={500} sx={{lineHeight: 0.5, color : 'white', fontSize: '15px', fontFamily : 'poppins'}}><i>+{achievement.experiencePoints} XP</i></Text>
                                    </Group>
                                </Stack>
                            </Group>
                            <Menu withinPortal position="bottom-start" shadow="lg" withArrow offset={-1} width={220}>
                                <Menu.Target>
                                    <ActionIcon radius="xl" size="md" sx={{background : '#6c2be2',  ":hover" : {background : '#4a1e9e'}}}>
                                        <IconDots size={22} color="white" />
                                    </ActionIcon>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconCamera size={18} />} onClick={() => {ToUpdateAchievementID(achievement.id)}}>{DataTranslate.AssignBadgeTranslate}</Menu.Item>
                                    <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconTrash size={18} />} color="red" onClick={() => {ToRemoveAchievementID(achievement.id)}}>{DataTranslate.DeleteAchievementTranslate}</Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    </Card.Section>
                    <Card.Section inheritPadding mt="sm" pb="md">
                            <Group position="center">
                                <LazyImage src={achievement.imageBadge}/>
                            </Group>
                            <br/>
                            <Stack spacing="xs">
                                <Group position="left">
                                    <Text fw={700} fz="lg" sx={{lineHeight : 0.5, fontFamily : 'poppins'}}>{achievement.description}</Text>
                                </Group>
                                <Group position="left">
                                    <Text fw={500} sx={{ color : 'gray', fontSize: '13px', fontFamily : 'poppins'}}>{achievement.subtitle}</Text>
                                </Group>
                            </Stack>
                       
                    </Card.Section>
                </Card>
            </Grid.Col>
        </div>
         )}   
        </Grid>
        <br/>
        <UploadAchievement
            OpenModal = {OpenUploadAchievementModal}
            CloseModal = {() => {setOpenUploadAchievementModal(false)}}
            notifications = {notifications}
            fetchAchievement = {fetchAchievement}
            PropsAddNewAchievementTranslate = {DataTranslate.AddAchievementTranslate}
            PropsAchievementTitleTranslate = {DataTranslate.AchievementTitleTranslate}
            PropsPlaceholderAchievementTitle = {DataTranslate.PlaceholderAchievementTitle}
            PropseDescriptionTranslate = {DataTranslate.DescriptionTranslate}
            PropsPlaceholderDescription = {DataTranslate.PlaceholderDescription}
            PropsAchievementDescriptionTranslate = {DataTranslate.AchievementDescriptionTranslate}
            PropsPlaceholderAchievementDescription = {DataTranslate.PlaceholderAchievementDescription}
            PropsAchievementSubtitleTranslate = {DataTranslate.AchievementSubtitleTranslate}
            PropsPlaceholderAchievementSubtitle = {DataTranslate.PlaceholderAchievementSubtitle}
            PropsPointSourceTranslate = {DataTranslate.PointSourceTranslate}
            PropsPlaceholderPointSource = {DataTranslate.PlaceholderPointSource}
            PropsNoticeTranslate = {DataTranslate.NoticeTranslate}
            PropsTimeFormatTranslate = {DataTranslate.TimeFormatTranslate}
            PropsPlaceholderTimeFormat = {DataTranslate.PlaceholderTimeFormat}
            PropsSecondTranslate = {DataTranslate.SecondTranslate}
            PropsMinuteTranslate = {DataTranslate.MinuteTranslate}
            PropsHourTranslate = {DataTranslate.HourTranslate}
            PropsTimeValueTranslate = {DataTranslate.TimeValueTranslate}
            PropsPlaceholderTimeValue = {DataTranslate.PlaceholderTimeValue}
            PropsRequiredPointsTranslate = {DataTranslate.RequiredPointsTranslate}
            PropsPlaceholderRequiredPoints = {DataTranslate.PlaceholderRequiredPoints}
            PropsExperiencePointsTranslate = {DataTranslate.ExperiencePointsTranslate}
            PropsPlaceholderExperiencePoints = {DataTranslate.PlaceholderExperiencePoints}
            PropsBtnUploadAchievementTranslate = {DataTranslate.BtnUploadAchievement}
            PropsConfirmTitleTranslate = {DataTranslate.ConfirmTitleTranslate}
            PropsConfirmMessageTranslate = {DataTranslate.ConfirmMessageTranslate}
            PropsConfirmTranslate = {DataTranslate.ConfirmTranslate}
            PropsCancelTranslate = {DataTranslate.CancelTranslate}
        />
        <AssignBadge
            OpenModal = {OpenAssignBadgeModal}
            CloseModal = {() => {setOpenAssignBadgeModal(false)}}
            notifications = {notifications}
            fetchAchievement = {fetchAchievement}
            AchievementID = {AchievementID}
            PropsAssignBadgeTranslate = {DataTranslate.AssignBadgeTranslate}
            PropsSearchBadgeTranslate = {DataTranslate.SearchBadgeTranslate}
            PropsUploadBadgeTranslate = {DataTranslate.UploadBadgeTranslate}
            PropsDragImageTitleTranslate = {DataTranslate.DragImageTitleTranslate}
            PropsDragImageDescriptionTranslate = {DataTranslate.DragImageDescriptionTranslate}
            PropsBadgeNameTranslate = {DataTranslate.BadgeNameTranslate}
            PropsPlaceholderBadgeName = {DataTranslate.PlaceholderBadgeName}
            PropsBtnUploadBadgeTranslate = {DataTranslate.BtnUploadBadge}

        />
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Deleting" centered>
            <Group>
                <Group grow>
                    <Text>Are you sure you want to Delete this Achievement? 
                    </Text>
                </Group>
            </Group>
            <br/>
            <Group position='right'>
                <Button onClick = {RemoveAchievement} color="teal" size="sm" uppercase> Confirm </Button>
                <Button onClick = {CancelConfirmation} color="red" size="sm" uppercase> Cancel </Button>
            </Group>
        </Modal>
        </div>
    )
}