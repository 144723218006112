import { Card, Text, Group, Stack, Loader } from '@mantine/core';
export const LanguageCard = ({LanguageTranslated, TranslateLoading}) => {
    
  
    return(
        LanguageTranslated.map((code, index) => (
            <Card padding="xs" radius="md" key = {index} sx={{
                boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                cursor : 'pointer',
                transition : 'transform .2s ease-in-out',
                MozTransition : 'transform .2s ease-in-out',
                WebkitTransition : 'transform .2s ease-in-out',
                marginTop: -10,
                ":hover" : {
                    background : 'transparent',
                    WebkitTransform : 'scale(1.01)',
                    msTransform : 'scale(1.01)',
                    transform : 'scale(1.01)'
                }
            }}>
                {TranslateLoading ? 
                    <>
                        <Group position='center'>
                            <Loader color="violet" variant="dots" /><br/>
                        </Group>
                        <Group position='center'>
                            <i style={{fontFamily : 'poppins', fontSize : 12}}>Translating...</i>
                        </Group>
                    </>
                   
                    : 
                    <>
                        <Stack spacing={1}>
                            <Text fw = {700} fz="lg" color="black" sx={{fontFamily : 'poppins'}}>
                                {code.verse}
                            </Text>
                        </Stack>
                        <Group grow>
                            <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}> {code.word}</Text>
                        </Group>
                        <Stack spacing={1}>
                            <Text fw={900} sx={{fontFamily : 'poppins', paddingTop : 10, fontSize : 18}}>Message</Text>
                            <Text fw={500} fz="sm" sx={{fontFamily : 'poppins', textAlign : 'justify'}}>{code.message}</Text>
                        </Stack>
                        <Stack spacing={1}>
                            <Text fw={900} sx={{fontFamily : 'poppins', paddingTop : 10, fontSize : 18}}>Reflection</Text>
                            <Text fw={500} fz="sm" sx={{fontFamily : 'poppins', textAlign : 'justify'}}>{code.reflection}</Text>
                        </Stack>
                        <Stack spacing={1}>
                            <Text fw={900} sx={{fontFamily : 'poppins', paddingTop : 10, fontSize : 18}}>Prayer</Text>
                            <Text fw={500} fz="sm" sx={{fontFamily : 'poppins', textAlign : 'justify'}}>{code.prayer}</Text>
                        </Stack>
                        <Group position='right'>
                            <Text fw = {700} variant="gradient" gradient={{ from: 'violet', to: 'darkblue' }} sx={{fontFamily : 'poppins', fontSize : 13}}>Translated in {code.language}</Text>
                        </Group>
                    </>
                }
            </Card>
        ))
    )
}