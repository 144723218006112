import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom'
//! Mantine
import { createStyles, Avatar, Group, Text, Stack, Menu, ActionIcon,
    Card,  Divider, Image, MediaQuery, Button, Grid} from '@mantine/core'
//! Framer Motion
import { motion } from 'framer-motion'
import { IconDots, IconHeart, IconMessage, IconShare3} from '@tabler/icons-react';
//! React Web Share
import { RWebShare } from "react-web-share";
//! Utils
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import TheOneLogo from '../../Assets/images/logo/circular-logo.png'
//! Moment
import Moment from 'moment';

export const ComponentFeeds = ({notifications}) => {
    const useStyles = createStyles((theme) => ({
        inner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.spacing.xl * 3,
            paddingBottom: theme.spacing.xl * 3,

            [theme.fn.smallerThan('md')]: {
                paddingTop: theme.spacing.xl * 1,
                paddingTop: theme.spacing.xl * 1,
            }, 
        },
        content: {
            maxWidth: '95%',
            [theme.fn.smallerThan('md')]: {
                maxWidth: '100%',
            },
        },
        control: {
            [theme.fn.smallerThan('md')]: {
                flex: 1,
            },
        }
    }));
    const { classes } = useStyles();
    const [DefaultCommunityID, setDefaulCommunityID] = useState('');
    const [Feeds, setFeeds] = useState([]);
    const [FeedsComment, setFeedsComment] = useState([]);
    const { ReturnPostID, ReturnPostSize, ReturnPageSize } = useParams()
    useEffect(() => {
        fetchDefaultCommunityID();
        fetchFeeds();
        fetchFeedsComment()
        window.addEventListener("scroll", GetMorePostOnScroll);
        setTimeout(() => {
            ReturnPostID && ScrollToPost()
        }, 1000);
    }, [ReturnPostID]);
    const fetchDefaultCommunityID = async() => {
        const DefaultCommunityEP = `/Communities/Default`
        await api.get(DefaultCommunityEP).then((result) => {
            setDefaulCommunityID(result.data.id)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    //! Get Post Content
    const [IsGettingMorePost, SetIsGettingMorePost] = useState(false)
    const HasMorePost = useRef(true)
    const PostSize = useRef(1);
    const PageSize = useRef(10);
    //! Get More Post on Scroll
    const GetMorePostOnScroll = (e) => {
        const scrollTop = e.target.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const scrollHeight = e.target.documentElement.scrollHeight;
        if (scrollTop + windowHeight + 1 >= scrollHeight) {
            if(HasMorePost.current){
                PostSize.current = PostSize.current + 1
                PageSize.current = PageSize.current + 10
                fetchFeeds()
            }
        }
    } 
    const fetchFeeds = async() => {
        if(ReturnPageSize === undefined){
            SetIsGettingMorePost(true)
            const PostContentEP = `/Posts?communityId=19&PageNumber=`+PostSize.current+`&PageSize=10`
            await api.get(PostContentEP).then((result) => {
                const NextPage = result.nextPage
                HasMorePost.current = NextPage !== null ? true : false
                setFeeds((prev) => [...prev, ...result.data.data])
                SetIsGettingMorePost(false)
            }).catch(error => {
                const Response = error.response.status
                if(Response === 401){
                    GetNewToken(Token, RefreshToken)
                }
                if(Response === 404){
                }
            })
        }else{
            SetIsGettingMorePost(true)
            const PostContentEP = `/Posts?communityId=19&PageNumber=`+PostSize.current+`&PageSize=`+ReturnPageSize+``
            await api.get(PostContentEP).then((result) => {
                const NextPage = result.nextPage
                HasMorePost.current = NextPage !== null ? true : false
                setFeeds((prev) => [...prev, ...result.data.data])
                SetIsGettingMorePost(false)
            }).catch(error => {
                const Response = error.response.status
                if(Response === 401){
                    GetNewToken(Token, RefreshToken)
                }
                if(Response === 404){
                }
            })
        }
    }
    //! ScrollToPostFunction
    const ScrollToPost = () => {
        const PostView = document.querySelector(`#SelectedPost${ReturnPostID}`)
        PostView.scrollIntoView({
            block: "start"
        })
    }
    const fetchFeedsComment = async (PostID) => {
        if(PostID === undefined){
        }else{
            const FeedsCommentEP = `/Comments?postId=`+PostID
            await api.get(FeedsCommentEP).then((result) => {
                setFeedsComment(result.data.data)
            }).catch(error => {
                const Response = error.response.status
                if(Response === 401){
                    GetNewToken(Token, RefreshToken)
                }
                if(Response === 404){
                }
            })
        }
      
    }
    return(
        <motion.div className={classes.inner} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className={`${classes.content} PostContent`}>
                {Feeds.map((feed, index) =>
                    <div key = {index} >
                    <Card shadow="lg" radius="xs" withBorder>
                        <Card.Section>
                            {/* HEADER POST */}
                            <Group sx={{ padding : '15px'}} position="apart">
                                <Group>
                                    <Avatar 
                                        radius="xl" 
                                        size="lg" 
                                        src={feed.owner.profileLink === null ? TheOneLogo : feed.owner.profileLink}
                                    />
                                    <Stack spacing={5}>
                                        <Text color="black" fz="md" fw={700} sx={{ lineHeight: 0 }}>{feed.owner.fullName}</Text>
                                        <Text fz="sm" color="dimmed">
                                            { Moment(feed.postedOffset, "YYYY-MM-DD").fromNow()}
                                        </Text>
                                    </Stack>
                                </Group>
                                <Group>
                                    <Menu shadow="md" width={250} position="left-start" offset={-5} withArrow>
                                        <Menu.Target>
                                            <ActionIcon radius="xl">
                                                <IconDots color = "black" size={20} />
                                            </ActionIcon>
                                        </Menu.Target>
                                    </Menu>
                                </Group>
                            </Group>
                            {/* END OF HEADER POST */}
                            {/* POST CONTENT */}
                            <Group position = "center" sx={{ padding : '20px', marginTop : '-30px'}} grow>
                                <Text fw={450}>
                                {feed.caption}
                                </Text>
                            </Group>
                            <Group position = "center" sx={{ padding : '10px', marginTop : '-20px'}} grow>
                                <Grid gutter="xs" grow>
                                    {feed.images.map((img, index) =>
                                    <Grid.Col span={6} key = {index}>
                                        <Image 
                                            radius="xs"
                                            mx="auto" 
                                            src={img.name} 
                                            alt="Random image" 
                                        />
                                    </Grid.Col>
                                    )}
                                </Grid>
                            </Group>
                            <Group position = "right" sx={{ paddingRight : '20px', paddingTop : '20px', marginTop : '-20px'}}>
                                <Text fw={450}>
                                    {feed.likes === 0 ? '' : feed.likes > 1 ? feed.likes + ' Likes' : feed.likes + ' Like'} 
                                </Text>
                                <Text fw={450}>
                                    {feed.comments === 0 ? '' : feed.comments > 1 ? feed.likes + ' Comments' : feed.comments + ' Comment'} 
                                </Text>
                            </Group>
                            {/* END OF POST CONTENT */}
                            {/* LIKE COMMENT SHARE */}
                            <Divider/>
                                <Group grow py={6} noWrap>
                                    <MediaQuery smallerThan="sm" styles={{
                                        '.LikeBtnLabel': {
                                            display: "none"
                                        }
                                    }}>
                                        <Button variant="subtle" color="red">
                                            <IconHeart size={16}/>
                                            <Text> Like</Text>
                                        </Button>
                                    </MediaQuery>
                                    <MediaQuery smallerThan="sm" styles={{
                                        '.CommentBtnLabel': {
                                            display: "none"
                                        },
                                        padding: "0px"
                                    }}>
                                        <Button 
                                            variant="subtle" 
                                            color="blue" 
                                            fullWidth
                                            onClick={() => {fetchFeedsComment(feed.id)}}
                                        >
                                            <IconMessage size={16} />
                                            <Text> Comment</Text>
                                        </Button>
                                    </MediaQuery>
                                    <MediaQuery smallerThan="sm" styles={{
                                        '.ShareBtnLabel': {
                                            display: "none"
                                        },
                                        '.ShareButton': {
                                            padding: "0px"
                                        }
                                    }}>
                                        <Group>
                                            <RWebShare
                                                data={{
                                                    title: "Share to...",
                                                    url: "https://bibletheone.com"
                                                }}
                                                onClick={() => console.log("Sample")}
                                            >
                                                <Button variant="subtle" color="violet" fullWidth>
                                                    <IconShare3 size={16}/>
                                                    <Text>Share</Text>
                                                </Button>
                                            </RWebShare>
                                        </Group>
                                    </MediaQuery>
                                </Group>
                            <Divider/>
                            <br/>
                            {/* END OFLIKE COMMENT SHARE */}
                        </Card.Section>
                    </Card>
                    <br></br>
                    </div>
                )}
            </div>
        </motion.div>      
    )
}