
import React, { useState, useEffect, useRef } from "react";
import { Card, Image, Text, Button, Group, useMantineTheme, rem, Modal, SimpleGrid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX, IconPhoto, IconCheck,  } from '@tabler/icons-react';
import { Token } from '../../Utils'
import Axios from 'axios';
import  LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
export const UploadImageEvent = ({notifications, OpenModal, CloseModal, fetchEvent, EventID}) => {
    const theme = useMantineTheme();
    const APILink = process.env.REACT_APP_API_LINK
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('')
    const [ConfirmModal, setConfirmModal] = useState(false);
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UploadImageTranslate, setUploadImageTranslate] = useState('Upload new Image')
    const [DragImageTranslate, setDragImageTranslate] = useState('Drag images here or click to select files')
    const [DragImageDescTranslate, setDragImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [BtnUploadImageTranslate, setBtnUploadImageTranslate] = useState('UPLOAD IMAGE EVENT')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Uploading')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Upload this Image?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadImageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadImageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadImageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadImageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    function handleImage(files){
        setImage(files)
    }
    const UploadImagesEventFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Events Image',
            message: 'Events Image will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const formData = new FormData()
        image.map((files) => {
            formData.append('files', files)
        })
        Axios.post(''+APILink+'/api/TheOneEvents/Images/'+EventID,
        formData,{
            headers : {
                Authorization: 'Bearer '+Token,
                'Content-Type': 'multipart/form-data',
                'Application' : 'application/json'
            }
        }).then((res) => {
            if(res.status === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchEvent()
                    setImg([])
                    setImage('')
                    CloseModal()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Events Image was uploaded',
                        message: 'Events Image successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        })
    }
    const LazyImage = ({src, index, maxWidth}) => {
        const rootRef = useRef();
        const [isVisible, setIsVisible] = useState(false);
        useEffect(() => {
            const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
            };
            
            const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                setIsVisible(true);
                }
            });
            };
        
            if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
            }
        }, [isVisible]);
        return (
                <Image 
                    key = {index} 
                    maw={maxWidth} 
                    mx="auto"
                    alt="Event Image" 
                    src={isVisible ? src : LogoBlurd} 
                    ref={rootRef}
                    placeholder={<Text align="center">No Image Detected</Text>}
                />
            
        )
      
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={UploadImageTranslate}
            size="xl"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
            <Card shadow="xl" padding="lg" radius="sm" withBorder>
                <Card.Section>
                    {img.length === 1 ? 
                        img.map((url, index) =>
                            <LazyImage
                                src={url}
                                maxWidth={300}
                                index = {index}
                            />
                        )
                    :
                    img.length === 2  ? 
                        <SimpleGrid cols={2}  spacing="xs" verticalSpacing="xs">
                            {img.map((url, index) =>
                                <LazyImage
                                    src={url}
                                    maxWidth={300}
                                    index = {index}
                                />
                            )}
                        </SimpleGrid>
                    :
                    img.length === 3  ? 
                        <SimpleGrid cols={3}  spacing="xs" verticalSpacing="xs">
                             {img.map((url, index) =>
                                <LazyImage
                                    src={url}
                                    maxWidth={250}
                                    index = {index}
                                />
                            )}
                        </SimpleGrid>
                    :
                    <SimpleGrid cols={4}  spacing="xs" verticalSpacing="xs">
                        {img.map((url, index) =>
                            <LazyImage
                                src={url}
                                maxWidth={200}
                                index = {index}
                            />
                        )} 
                    </SimpleGrid>
                    }
                </Card.Section>
                <Card.Section >
                    <Dropzone
                        onDrop={
                            (files) => {
                                handleImage(files)
                                const selectedFIles =[];
                                const targetFilesObject= [...files]
                                targetFilesObject.map((file)=>{
                                    return selectedFIles.push(URL.createObjectURL(file))
                                })
                                setImg(selectedFIles);
                            } 
                        }
                        maxSize={3 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                    >
                        <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>

                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>

                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline  sx = {{fontFamily : 'poppins'}}>
                                    {DragImageTranslate}
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7}  sx = {{fontFamily : 'poppins'}}>
                                    {DragImageDescTranslate}
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>
                </Card.Section>
            </Card>
            <Group mt="md" mb="xs" grow>
                <Button  sx = {{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {BtnUploadImageTranslate}
                </Button>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text  sx = {{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button  sx = {{fontFamily : 'poppins'}} onClick = {UploadImagesEventFunction} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button  sx = {{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>
        </>
    )
}