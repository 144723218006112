import React, { useState, useEffect, useMemo } from "react";
import { Button, Group, Modal, useMantineTheme, Textarea, Select, Divider, Text } from '@mantine/core';
import { IconUpload, IconCheck, IconX } from '@tabler/icons-react';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios'; 
export const UploadChoices = ({notifications, OpenModal, CloseModal, fetchQuestions, SelectedQuestionID}) => {
    const theme = useMantineTheme();
    const [Choices, setChoices] = useState("");
    const [DuplicateMessage, setDuplicateMessage] = useState("");
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AddNewAnswerTranslate, setAddNewAnswerTranslate] = useState('Add New Answer')
    const [ChoicesTranslate, setChoicesTranslate] = useState('Choices')
    const [PlaceholderChoices, setPlaceholderChoices] = useState('Enter Choices')
    const [BtnUploadChoices, setBtnUploadChoices] = useState('UPLOAD CHOICES')
    const [DuplicateMessageTranslate, setDuplicateMessageTranslate] = useState('this answer has a duplicate keyword')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddNewAnswerTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddNewAnswerTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ChoicesTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChoicesTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderChoices,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderChoices(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadChoices,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadChoices(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DuplicateMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDuplicateMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleChangeChoices = (event) => {
        setChoices(event.target.value)
    }
    const UploadChoices = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Choices',
            message: 'Choices will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UploadEP = `/questions/`+SelectedQuestionID+`/choices?answer=`+Choices+``
        await api.post(UploadEP,{
        }).then(result => {
            if(result['status'] === 200){
                setTimeout(() => {
                    CloseModal();
                    fetchQuestions();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Choices was uploaded',
                        message: 'Choices successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
           if(error.request['status'] === 400){
                setTimeout(() => {
                setDuplicateMessage("Duplicate")
                notifications.update({
                        id: 'load-data',
                        color: 'red',
                        title: 'Choices Duplicate',
                        message: Choices + ' is Already in the Choices',
                        icon: <IconX size={32} />,
                        autoClose: 3000,
                    });
                }, 1000); 
           }
        })
    }
    return(
    <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={AddNewAnswerTranslate}
            size="sm"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
            <Group grow>
                <Textarea
                    placeholder={PlaceholderChoices}
                    label={ChoicesTranslate}
                    variant="filled"
                    size="lg"
                    minRows={3}
                    withAsterisk
                    onChange={handleChangeChoices}
                    error={
                        DuplicateMessage === 'Duplicate' ?
                        DuplicateMessageTranslate
                        :
                        ''
                    }
                    sx = {{fontFamily : 'poppins'}}
                />
            </Group>
            <br/>
            <Group grow>
                <Button sx = {{fontFamily : 'poppins'}} color="violet" size="md" leftIcon ={<IconUpload/>} onClick={UploadChoices}>
                    {BtnUploadChoices}
                </Button> 
            </Group>   
        </Modal>
    </>
    )
}