import React, { useState, useEffect } from "react";
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { Group, Text, Skeleton } from '@mantine/core';
import Axios from 'axios';
import ReactECharts from 'echarts-for-react';
export const AchievementLeaderBoards = () => {
    var app = {};
    var option;
    let obj = {}
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
      "LeaderBoardTitle" : 'USER LEADERBOARD',
      "BaseExperienceTranslate" : 'Base Experience',
      "TargetExperienceTranslate" : 'Target Experience',
      "ExperienceTranslate" : 'Experience',
      "LevelTranslate" : 'Level'
    })
    useEffect(() => {
        fetchLeaderBoards();
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchLeaderBoards = async () => { 
        setLoading(true)
        const LeaderBoardsEP = `/Experiences/Top?count=5`
        await api.get(LeaderBoardsEP).then((result) => {
            setData(result.data)
            setLoading(false)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
    };
    const labelOption = {
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 18,
      rich: {
        name: {}
      }
    };
    option = {
      grid: { top: 8, right: 8, bottom: 24, left: 46 },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: [data[0]?.userProfile.fullName, data[1]?.userProfile.fullName, data[2]?.userProfile.fullName, data[3]?.userProfile.fullName, data[4]?.userProfile.fullName]
        }
        
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: DataTranslate.BaseExperienceTranslate,
          type: 'bar',
          barGap: 0,
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [data[0]?.baseExperience, data[1]?.baseExperience, data[2]?.baseExperience, data[3]?.baseExperience, data[4]?.baseExperience]
        },
        {
          name: DataTranslate.TargetExperienceTranslate,
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [data[0]?.targetExperience, data[1]?.targetExperience, data[2]?.targetExperience, data[3]?.targetExperience, data[4]?.targetExperience]
        },
        {
          name: DataTranslate.ExperienceTranslate,
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [data[0]?.experience, data[1]?.experience, data[2]?.experience, data[3]?.experience, data[4]?.experience]
        },
        {
          name: DataTranslate.LevelTranslate,
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: [data[0]?.level, data[1]?.level, data[2]?.level, data[3]?.level, data[4]?.level]
        }
      ]
    };
    return (
       <>
       <Text fw={900} align="center"  sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>{DataTranslate.LeaderBoardTitle}</Text>
       {loading ?
        <Group spacing={8}>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
          <Skeleton height={110} width="33%" mb="xl" className="PlanContent"/>
        </Group>
        :
        <ReactECharts option={option} />
      } 
       </>
    )
  }