import React, { useState, useEffect } from "react";
import { Button, TextInput, Group, Switch, Modal, Text, ActionIcon, Stack, Menu, Divider, Badge } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconPencil, IconPlus, IconRefresh, IconCheck, IconBible, IconDotsVertical, IconTrash, IconFlag, IconLanguage, IconUpload, IconCodeDots } from '@tabler/icons-react';
import DataTable from 'react-data-table-component';
import { UploadVersion } from "../BibleVersion/UploadVersion";
import Axios from 'axios';
import AllLanguage from '../../Helpers/Country_Translate.json'
export const ListofVersions = ({notifications}) => {
    const [BibleVersions, setBibleVersions] = useState([]);
    const [UploadModal, setUploadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [SelectedVersionID, setSelectedVersionID] = useState("")
    const [search, setSearch] = useState('');
    const [LanguageCode, setLanguageCode] = useState('')
    const [page, setPage] = useState(1);
    const [UpdateLanguageCode, setUpdateLanguageCode] = useState(false)
    const [UpdateLanguageModal, setUpdateLanguageModal] = useState(false)
    const [VersionCode, setVersionCode] = useState("")
    const [VersioName, setVersionName] = useState("")
    const [LanguageName, setLanguageName] = useState('')
    const countPerPage = 10;
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblBibleVersionTranslate, setTblBibleVersionTranslate] = useState('BIBLE VERSIONS')
    const [SearchBibleVersionTranslate, setSearchBibleVersionTranslate] = useState('Search Bible Version')
    const [StatusTranslate, setStatusTranslate] = useState('STATUS')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [UpdateVersionTranslate, setUpdateVersionTranslate] = useState('Update Version')
    const [UpdateLanguageTranslate, setUpdateLanguageTranslate] = useState('Update Code')
    const [RefreshTranslate, setRefreshTranslate] = useState('Refresh Version')
    const [RemoveVersionTranslate, setRemoveVersionTranslate] = useState('Remove Version')

    const [UpdateBibleLanguageTranslate, setUpdateBibleLanguageTranslate] = useState('Update Bible Language')
    const [LanguageCodeTranslate, setLanguageCodeTranslate] = useState('Language Code')
    const [PlaceholderLanguageCode, setPlaceholderLanguageCode] = useState('Enter Language Code')
    const [BtnUpdateLanguage, setBtnUpdateLanguage] = useState('UPDATE LANGUAGE')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchBibleVersion();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblBibleVersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblBibleVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchBibleVersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchBibleVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + StatusTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setStatusTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateVersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateLanguageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateLanguageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RefreshTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRefreshTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveVersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateBibleLanguageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateBibleLanguageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + LanguageCodeTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setLanguageCodeTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderLanguageCode,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderLanguageCode(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUpdateLanguage,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUpdateLanguage(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const handleLanguageCode = (event) => {
        setLanguageCode(event.target.value);
    }
    const fetchBibleVersion = async () => {
        setLoading(true);   
        const ListVersionEP = `/BibleVersions`
        await api.get(ListVersionEP).then((result) => {
            setBibleVersions(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const TurnOnOff = async (id) => {
        const PublishedEP = `/Bibles/`+id+`/`
            await api.put(PublishedEP, {
            }).then(result => {
                fetchBibleVersion()
            }).catch(error =>{
        })
    }
    const ConfirmAction = async (id) => {
        setConfirmModal(true)
        setSelectedVersionID(id)
    }
    const RemoveFunction = async () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Bible Version',
            message: 'Bible Version will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const VersionEP = `/v2/BibleVersions/`+SelectedVersionID+``
            await api.delete(VersionEP, {
            }).then(result => {
                if(result['status'] === 204){
                    setTimeout(() => {
                        fetchBibleVersion();
                       
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Bible Version was deleted',
                            message: 'Bible Version successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
        })
    }
    const CallUpdateLanguageCode = async(ID,Code,Name) => {
        setUpdateLanguageCode(true)
        setSelectedVersionID(ID)
        setVersionCode(Code)
        setVersionName(Name)
    }
    const CallUpdateLanguageName = async(ID,Code,Name) => {

        setUpdateLanguageModal(true)
        setSelectedVersionID(ID)
        setVersionCode(Code)
        setVersionName(Name)
    }
    
    const UpdateLanguageFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Language',
            message: 'Language will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UpdateLanguageEP = `/Bibles/`+SelectedVersionID+`/language?language=`+LanguageCode+``
        api.put(UpdateLanguageEP).then(result => {
       if(result['status'] === 204){
           notifications.show({
               id: 'load-data',
               loading: true,
               title: 'Updating Language',
               message: 'Language will be loaded, you cannot close this yet',
               autoClose: false,
               withCloseButton: false,
               });
               setTimeout(() => {

               //! RESET INPUTS
               fetchBibleVersion()
               setUpdateLanguageCode(false)
               //! END OF RESET INPUTS

               notifications.update({
                   id: 'load-data',
                   color: 'teal',
                   title: 'Language was updated',
                   message: 'Language successsfully updated',
                   icon: <IconCheck size="1rem" />,
                   autoClose: 2000,
               });
           }, 1000); 
       }else{}
       }).catch(error =>{
       })
    }
    
    const UpdateLanguageName = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Language',
            message: 'Language will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UpdateLanguageEP = `/Bibles/`+SelectedVersionID+`/languageCategory?languageCategory=`+LanguageName+``
        api.put(UpdateLanguageEP).then(result => {
       if(result['status'] === 200  ){
           notifications.show({
               id: 'load-data',
               loading: true,
               title: 'Updating Language',
               message: 'Language will be loaded, you cannot close this yet',
               autoClose: false,
               withCloseButton: false,
               });
               setTimeout(() => {

               //! RESET INPUTS
               fetchBibleVersion()
               setUpdateLanguageModal(false)
               //! END OF RESET INPUTS

               notifications.update({
                   id: 'load-data',
                   color: 'teal',
                   title: 'Language was updated',
                   message: 'Language successsfully updated',
                   icon: <IconCheck size="1rem" />,
                   autoClose: 2000,
               });
           }, 1000); 
       }else{}
       }).catch(error =>{
       })
    }
    const RefreshBibleVersion = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Refreshing Version',
            message: 'Version will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UpdateLanguageEP = `/Scriptures/`+ID+``
        api.put(UpdateLanguageEP).then(result => {
        if(result['status'] === 204){
                setTimeout(() => {
                //! RESET INPUTS
                fetchBibleVersion()
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Version was Refreshed',
                    message: 'Version successsfully Refreshed',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
        })
    }
    // BibleVersions.sort(function(a,b){
    //     return a.language.localeCompare(b.language);
    // });
    const GetSelectedLanguage = async(item) => {
        setLanguageName(item)
    }
    const columns = [ 
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{TblBibleVersionTranslate}</Text>,
            cell : row =>
            <Group sx={{width : '100%'}} position="apart">
                <Group spacing={3}>
                    <IconBible size={70} color="#4a1e9e"/>
                    <Stack spacing = {0.5} justify="flex-start" className="ListOfAudioContent">
                        <Text fw={700} sx={{color : '#4a1e9e', fontSize : 18, wordBreak : 'break-all', lineHeight : 1, fontFamily : 'poppins'}}>
                            {row.versionAbbreviation}
                        </Text>
                        <Text fw={600} lineClamp = {1} sx={{ fontSize : 15, fontFamily : 'poppins'}}>{ row.version }</Text>
                        <Group spacing={5}>
                            <Group>
                                {row.isReady ? <Badge sx={{color : '#4a1e9e', fontFamily : 'poppins'}} color="yellow" variant="light">Active</Badge> : <Badge sx={{color : 'red'}} color="yellow" variant="light"><i>Inactive</i></Badge>}
                            </Group>
                            <Group>
                                {row.language != '' ? <Badge sx={{color : '#4a1e9e', fontFamily : 'poppins'}} color="yellow" variant="light">{row.language}</Badge> : <Badge sx={{color : 'red'}} color="yellow" variant="light"><i>No Language Code</i></Badge>}
                            </Group>
                            <Group>
                                {row.languageCategory != '' ? <Badge sx={{color : '#4a1e9e', fontFamily : 'poppins'}} color="yellow" variant="light">{row.languageCategory}</Badge> : <Badge sx={{color : 'red'}} color="yellow" variant="light"><i>No Language Name</i></Badge>}
                            </Group>
                        </Group>
                    </Stack>
                </Group>
                <Menu shadow="md" width={200} position="left-start" offset={-10} withArrow>
                    <Menu.Target>
                        <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                            <IconDotsVertical size="1.625rem" />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label><Text sx={{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{StatusTranslate}</Text></Menu.Label>
                        <Menu.Item sx={{fontFamily : 'poppins'}}>
                            <Group grow>
                                <Switch 
                                    size = "lg" 
                                    onLabel="PUBLISHED" 
                                    offLabel="UNPUBLISHED" 
                                    radius="sm" 
                                    color="violet" 
                                    checked={row.isReady} 
                                    onClick={() => {TurnOnOff(row.id)}}
                                />
                            </Group>
                        </Menu.Item>
                        <Divider></Divider>
                        <Menu.Label><Text sx={{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{ActionTranslate}</Text></Menu.Label>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconPencil/>}>{UpdateVersionTranslate}</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconCodeDots/>} onClick={() => {CallUpdateLanguageCode(row.id, row.versionAbbreviation, row.version)}}>{UpdateLanguageTranslate}</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconLanguage/>} onClick={() => {CallUpdateLanguageName(row.id, row.versionAbbreviation, row.version)}}>Update Language</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconRefresh/>} onClick={() => {RefreshBibleVersion(row.id)}}>{RefreshTranslate}</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconTrash/>} color="red" onClick = {() => {ConfirmAction(row.id)}}>{RemoveVersionTranslate}</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>
        },
        
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group >
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchBibleVersionTranslate}
                    onChange={ handleSearch }
                />
                <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled" 
                    onClick = {() => {setUploadModal(true)}}
                >
                    <IconPlus/>
                </ActionIcon>
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={
                    BibleVersions.filter((SearchVersion) => {
                        if(search === ""){
                            return SearchVersion
                        }else if(SearchVersion.version.toLowerCase().includes(search.toLowerCase())){
                            return SearchVersion
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={BibleVersions.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />      
            <UploadVersion
                OpenModal = {UploadModal}
                CloseModal = {() => {setUploadModal(false)}}
                notifications = {notifications}
                fetchBibleVersion = {fetchBibleVersion}
            />
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title="Confirm Action" centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>Are you sure you want to delete this Bible Version?</Text> 
                    </Group>
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {RemoveFunction} color="teal" size="sm" uppercase> Confirm </Button>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {() => {setConfirmModal(false)}} color="red" size="sm" uppercase> Cancel </Button>
                </Group>
            </Modal>
            <Modal opened={UpdateLanguageCode} onClose={() => {setUpdateLanguageCode(false)}} title={UpdateBibleLanguageTranslate}centered>
                <Group>
                    <Text fw={800} sx={{color : '#4a1e9e', fontSize : 18, wordBreak : 'break-all', lineHeight : 1, fontFamily : 'poppins'}}>
                        {VersionCode} - { VersioName }
                    </Text> 
                </Group>
                <br/>
                <Group grow>
                    <TextInput
                        icon={<IconFlag/>}
                        radius="sm"
                        size="md"
                        placeholder={PlaceholderLanguageCode}
                        label = {LanguageCodeTranslate}
                        onChange={ handleLanguageCode }
                        withAsterisk
                        sx={{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {UpdateLanguageFunction} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                        {BtnUpdateLanguage}
                    </Button>
                </Group>
            </Modal>
            <Modal opened={UpdateLanguageModal} onClose={() => {setUpdateLanguageModal(false)}} title="Update Language Name"centered>
                <Group position="center">
                    <input onChange={(val) => {GetSelectedLanguage(val.target.value)}} type="text" list="LanguageList" placeholder="Select Language" style = {{
                            padding : '10px',
                            fontSize :'15px',
                            fontWeight : 'bold',
                            borderRadius : '3px',
                            fontFamily : 'poppins'
                    }}/>
                    <datalist id="LanguageList">
                        {AllLanguage?.text?.map((language, index) =>
                            <option key={ index } value={language.language}></option>
                        )}
                    </datalist >
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {UpdateLanguageName} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                        {BtnUpdateLanguage}
                    </Button>
                </Group>
            </Modal>
         
        </div> 
    )
}