import React, { useState, useEffect } from "react";
import { api, Token } from '../../Utils'
import { TextInput, Group, Text, Tooltip, ActionIcon, Modal, Button, Menu, Grid, Card, Image, Badge } from '@mantine/core'
import { IconSearch, IconPlus, IconDots, IconTrash, IconVersions, IconDownload, IconUpload, IconCheck } from '@tabler/icons-react';
import { motion } from "framer-motion";
import Moment from 'moment';
import TheOneLogo from "../../Assets/images/logo/square-logo.png"
import Axios from 'axios';
export const ListofAppVersion = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const [AppVersion, setAppVersion] = useState([])
    const [CurrentAppVersion, setCurrentAppVersion] = useState([])
    const [search, setSearch] = useState('')
    const [UploadAppVersionModal, setUploadAppVersionModal] = useState(false)
    const [APKFile, setAPKFile] = useState('')
    const [AppVersionInput, setAppVersionInput] = useState('')
    useEffect(() => {
        fetchAppVersion()
        fetchCurrentAppVersion()
    }, []);
    const fetchAppVersion = async () => { 
        const ListAppVersionEP = `/AppVersions/All`
        await api.get(ListAppVersionEP).then((result) => {
            setAppVersion(result.data)
        }).catch(error => {
        })
    }
    const fetchCurrentAppVersion = async() => {
        const ListAppVersionEP = `/AppVersions`
        await api.get(ListAppVersionEP).then((result) => {
            setCurrentAppVersion(result.data)
        }).catch(error => {
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value)
    }
    const handleFileUpload = (files) => {
        setAPKFile(files.target.files[0])
    }
    const handleAppVersion = (event) => {
        setAppVersionInput(event.target.value)
    }
    AppVersion.sort(function(a,b){
        return new Date(b.publishedDate) - new Date(a.publishedDate);
    });
    const UploadAppVersion = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading App Version',
            message: 'App Version will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const formData = new FormData();
        formData.append('apk', APKFile)
        Axios.post(''+APILink+'/api/AppVersions?version='+AppVersionInput+'', formData,{
            headers : {
                Authorization: 'Bearer '+Token,
                'Content-Type': 'multipart/form-data',
                'Application' : 'application/json'
            }
            }).then((res) => {
                console.log(res)
                if(res.status === 201){
                    setTimeout(() => {
                        //! RESET INPUTS
                        fetchAppVersion()
                        fetchCurrentAppVersion()
                        setUploadAppVersionModal(false)
                        //! END OF RESET INPUTS
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'App Version was uploaded',
                            message: 'App Version successsfully Updated',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000);
                }
            }).then((error) =>{
                console.log(error)
            })
    }
    const RemoveAppVersion = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing App Version',
            message: 'App Version will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RemoveAppVersionEP = `AppVersions/`+ID+``
        await api.delete(RemoveAppVersionEP, {
        }).then(result => {
                if(result['status'] === 204){
                    fetchAppVersion()
                    fetchCurrentAppVersion()
                    setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'App Version was deleted',
                            message: 'App Version successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{  
        })

    }
    const DownloadAPK = async(apk) => {
        window.location.href = 'https://theonecdn-adbub4bjb0e4gha7.z01.azurefd.net/apk-container/'+apk+''
    }
    return(
        <>
            <div style={{paddingLeft : '50px'}} className="FirstContainer">
                <Group>
                    <TextInput
                        icon={<IconSearch/>}
                        radius="sm"
                        size="md"
                        placeholder="Search App Version"
                        onChange={ handleSearch }
                    />    
                    <Tooltip
                        label="Upload App Version"
                        sx={{background : '#6c2be2'}}
                        withArrow
                    >
                        <ActionIcon
                            sx={{background : '#4a1e9e',  ":hover" : {
                                background : '#6c2be2'
                            }}}
                            size="lg" 
                            radius="xl" 
                            variant="filled"
                            onClick={() => {setUploadAppVersionModal(true)}}
                        >
                            <IconPlus />
                        </ActionIcon>
                    </Tooltip>   
                </Group>  
                <br></br> 
                <Group position="center">
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0 * 0.1 }} exit={{ opacity: 0 }} style={{width : '20%'}} className="AchievementContent">
                        <Card withBorder radius="lg" sx={{
                            boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                            display: 'block',
                            top: '0px',
                            position: 'relative',
                            textDecoration: 'none',
                            zIndex: 0,
                            overflow: 'hidden',
                            border: '1px solid #f2f8f9',
                            cursor: 'pointer',
                            width : '100%',
                            ":hover" : {
                                boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                            }}}
                        >
                            <Card.Section>   
                                <Image 
                                    src={TheOneLogo} 
                                    withPlaceholder
                                />
                            </Card.Section>
                            <Group sx={{position : 'absolute', top : 3, right : 3}}>
                                    <Menu shadow="md" width={250} position="right-start" offset={1} withArrow>
                                        <Menu.Target>
                                            <ActionIcon radius="xl" size="md" sx={{background : 'transparent',  ":hover" : {background : '#4a1e9e'}}}>
                                                <IconDots size={22} color="white" />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx = {{fontFamily : 'poppins'}}>ACTION</Text></Menu.Label>
                                            <Menu.Item color = "black" icon = {<IconDownload/>} sx = {{fontFamily : 'poppins'}} onClick={() => {DownloadAPK(CurrentAppVersion.apk);}}>Download APK</Menu.Item>
                                            <Menu.Item color = "red" icon = {<IconTrash/>} sx = {{fontFamily : 'poppins'}} onClick={() => {RemoveAppVersion(CurrentAppVersion.id);}}>Remove App Version</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                            
                                <Group position="apart" mt="md" >
                                    <Text  lineClamp = {1} sx={{color : 'black', fontSize : 20, fontFamily : 'poppins', fontWeight : 700}}>
                                        THE ONE APP VERSION
                                    </Text>
                                </Group>
                                <Text size="md" color="dark" fw={800} sx={{ fontFamily : 'poppins', lineHeight : 1}}>
                                    <Group position="apart">
                                        <Group spacing={5}>
                                            <IconVersions/>
                                            {CurrentAppVersion.version}
                                        </Group>
                                        <Badge color="green" variant="filled">
                                            <Text fw={600} sx={{ fontSize : 10, fontFamily : 'poppins'}}>LATEST VERSION</Text>
                                        </Badge>
                                    </Group>
                                </Text>
                                <Group sx={{position : 'absolute', top : 5, left : 5}}>
                                    <Badge color="orange" variant="filled">
                                        <Text fw={600} sx={{ fontSize : 13, fontFamily : 'poppins'}}>{Moment(CurrentAppVersion.publishedDate).format('MMMM DD, YYYY')}</Text>
                                    </Badge>
                                </Group>
                        </Card>
                    </motion.div>
                </Group>
                <br></br>
                <Grid gutter="xs" grow>
                    {AppVersion.filter((SearchAppVersion) => {
                        if(search === ""){
                            return SearchAppVersion
                        }else if(SearchAppVersion.version.toLowerCase().includes(search.toLowerCase())){
                                return SearchAppVersion
                        }
                        }).slice(1).map((appversion, index) =>
                        <motion.div key = {index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: index * 0.1 }} exit={{ opacity: 0 }} style={{width : '20%'}} className="AchievementContent">
                            <Grid.Col> 
                                <Card withBorder radius="lg" sx={{
                                    boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                                    display: 'block',
                                    top: '0px',
                                    position: 'relative',
                                    textDecoration: 'none',
                                    zIndex: 0,
                                    overflow: 'hidden',
                                    border: '1px solid #f2f8f9',
                                    cursor: 'pointer',
                                    ":hover" : {
                                        boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                                    }}}
                                >
                                    
                                    <Card.Section>   
                                        <Image 
                                            src={TheOneLogo} 
                                            withPlaceholder
                                        />
                                    </Card.Section>
                                    <Group sx={{position : 'absolute', top : 3, right : 3}}>
                                        <Menu shadow="md" width={250} position="right-start" offset={1} withArrow>
                                            <Menu.Target>
                                                <ActionIcon radius="xl" size="md" sx={{background : 'transparent',  ":hover" : {background : '#4a1e9e'}}}>
                                                    <IconDots size={22} color="white" />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                <Menu.Label><Text fw={800} fz="sm" color = "#6c2be2" sx = {{fontFamily : 'poppins'}}>ACTION</Text></Menu.Label>
                                                <Menu.Item color = "black" icon = {<IconDownload/>} sx = {{fontFamily : 'poppins'}} onClick={() => {DownloadAPK(CurrentAppVersion.apk);}}>Download APK</Menu.Item>
                                                <Menu.Item color = "red" icon = {<IconTrash/>} sx = {{fontFamily : 'poppins'}} onClick={() => {RemoveAppVersion(appversion.id)}}>Remove App Version</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </Group>
                                    <Group position="apart" mt="md" >
                                        <Text  lineClamp = {1} sx={{color : 'black', fontSize : 20, fontFamily : 'poppins', fontWeight : 700}}>
                                            THE ONE APP VERSION
                                        </Text>
                                    </Group>
                                    <Text size="md" color="dark" fw={800} sx={{ fontFamily : 'poppins', lineHeight : 1}}>
                                        <Group position="apart">
                                            <Group spacing={5}>
                                                <IconVersions/>
                                                {appversion.version}
                                            </Group>
                                            <Badge color="red" variant="filled">
                                                <Text fw={600} sx={{ fontSize : 10, fontFamily : 'poppins'}}>OLD VERSION</Text>
                                            </Badge>
                                        </Group>
                                    </Text>
                                    <Group sx={{position : 'absolute', top : 5, left : 5}}>
                                        <Badge color="orange" variant="filled">
                                            <Text fw={600} sx={{ fontSize : 13, fontFamily : 'poppins'}}>{Moment(appversion.publishedDate).format('MMMM DD, YYYY')}</Text>
                                        </Badge>
                                    </Group>
                                </Card>
                            </Grid.Col>
                        </motion.div>
                    )}  
                </Grid>
            </div>
            <Modal size = "xl" opened={UploadAppVersionModal} onClose={() => {setUploadAppVersionModal(false)}} title="Upload new App Version" centered>
                <Group mt="md" mb="xs" grow>
                    <input type="file" onChange={handleFileUpload}/>
                </Group>
                <Group  grow>
                    <TextInput
                        radius="xs"
                        size="md"
                        placeholder="Enter Latest App Version"
                        label="App Version Name"
                        sx = {{fontFamily : 'poppins'}}
                        withAsterisk
                        onChange={handleAppVersion}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Button onClick = {UploadAppVersion} leftIcon={<IconUpload/>} color="violet" size="md" uppercase>
                        UPLOAD APP VERSION
                    </Button>
                </Group>
            </Modal>
        </>
    )
}