import { Anchor, Image, Group, Skeleton, NavLink, Text } from '@mantine/core';
import SidebarItems from '../../Helpers/SidebarItems'
import Axios from 'axios';
import TheOneLogo from '../../Assets/images/logo/main-logo.png'
import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
export const SideBar = ({IsSideBarOpen, ToggleSideBar}) => {
    const MyToken = localStorage.getItem("__SESS__U__TOWA__");
    var sourceLang = 'en'; 
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [ClickedItem, setClickedItem] = useState('')
    const [ClickedNavSubtitle, setClickedNavSubtitle] = useState('')
    const [SuperAdminStatus, setSuperAdminStatus] = useState('')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        setClickedItem(localStorage.getItem('ClickedItem'))
        setClickedNavSubtitle(localStorage.getItem('ClickedNavSubtitle'))
        SuperAdminValidation()
    }, [DefaultLanguage]);
    const NavItemContent = (item) => {
        const [ItemTranslate, setItemTranslate] = useState('')
        const [isLoading, setisLoading] = useState(true)
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + item.item,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setisLoading(false)
                setItemTranslate(translated[0])
            })
        }).then((error) =>{
        })
        return(
            <>
                {isLoading ? 
                    <Skeleton height={15} width="70%" radius="xl" /> 
                    :  
                    <span className="nav__name">{ItemTranslate}</span>
                }
            </>
           
        )
    }
    const ClickItem = (item, item_subtitle) => {
        localStorage.setItem('ClickedItem', item)
        localStorage.setItem('ClickedNavSubtitle', item_subtitle)
    }
    const SuperAdminValidation = () => {
        if(MyToken === null){
        }else{
            const DecodedToken = jwt_decode(MyToken)
            if(DecodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Super Admin' || DecodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0] === 'Super Admin'){
                setSuperAdminStatus('Super Admin')
            }else{
                setSuperAdminStatus('Admin')
            }
        } 
    }
    return (
        <>
        {MyToken === null ? 
            <></>
            : 
            <div className={`TheOne__Nav ${IsSideBarOpen ? "show-menu" : ""}`} id="navbar">
                <nav className="nav__container">
                    <div>
                        <div className="nav__logo">
                            <span className="nav__logo-name">
                                <Group position = "center">
                                    <Image maw={80} src={TheOneLogo} alt="TheOne" className="header__logo" />
                                </Group>
                            </span>
                        </div>
                        <div className="nav__list">
                        <hr></hr>
                            {SidebarItems.map((sideBarItem, indx) => 
                                sideBarItem.NavContent ?
                                    <NavLink 
                                        key={indx}
                                        label={
                                            
                                            <Anchor href={sideBarItem.NavLink} className="nav__link" onClick={ToggleSideBar} target="_self" sx={{
                                                transition : 'transform .2s ease-in-out',
                                                MozTransition : 'transform .2s ease-in-out',
                                                WebkitTransition : 'transform .2s ease-in-out',
                                                ":hover" : {
                                                        background : 'transparent',
                                                        WebkitTransform : 'scale(.9)',
                                                        msTransform : 'scale(.9)',
                                                        transform : 'scale(.9)'
                                                }
                                            }}>
                                                <NavItemContent item = {sideBarItem.NavSubTitle}/>
                                            </Anchor>
                                        }
                                        icon = {sideBarItem.NavSubTitleIcon}
                                        sx={{paddingTop : 5, paddingBottom : 5, paddingLeft : 0, borderRadius : 15}}
                                        active={sideBarItem.NavSubTitle === ClickedNavSubtitle ? true : false}
                                        color="violet"
                                        variant="light"
                                        
                                    >
                                        {sideBarItem.NavContent.map((navItem, indx) =>
                                            <div key={indx}>
                                                <Anchor href={navItem.NavItem.NavLink} className="nav__link" onClick={ToggleSideBar} target="_self" sx={{
                                                        transition : 'transform .2s ease-in-out',
                                                        MozTransition : 'transform .2s ease-in-out',
                                                        WebkitTransition : 'transform .2s ease-in-out',
                                                ":hover" : {
                                                        background : 'transparent',
                                                        WebkitTransform : 'scale(.9)',
                                                        msTransform : 'scale(.9)',
                                                        transform : 'scale(.9)'
                                                }
                                                }}>
                                                    <NavLink label={<NavItemContent 
                                                        item = {navItem.NavItem.NavLabel}/>}
                                                        icon = {navItem.NavItem.NavIcon} 
                                                        sx={{paddingLeft : 0, borderRadius : 15}}
                                                        onClick={() => {ClickItem(navItem.NavItem.NavLabel, sideBarItem.NavSubTitle)}} 
                                                        active={navItem.NavItem.NavLabel === ClickedItem ? true : false}
                                                        color="violet"
                                                        variant="light"
                                                    />
                                                </Anchor>
                                            </div>
                                        )} 
                                    </NavLink>
                                :
                                sideBarItem.UserStatus === SuperAdminStatus ? 
                                <NavLink 
                                    label={
                                        <Anchor href={sideBarItem.NavLink} className="nav__link" onClick={ToggleSideBar} target="_self" sx={{
                                            transition : 'transform .2s ease-in-out',
                                            MozTransition : 'transform .2s ease-in-out',
                                            WebkitTransition : 'transform .2s ease-in-out',
                                            ":hover" : {
                                                    background : 'transparent',
                                                    WebkitTransform : 'scale(.9)',
                                                    msTransform : 'scale(.9)',
                                                    transform : 'scale(.9)'
                                            }
                                        }}>
                                            <NavItemContent item = {sideBarItem.NavSubTitle} sx={{padding : 0}}/>
                                        </Anchor>
                                    }
                                    icon = {sideBarItem.NavSubTitleIcon}
                                    sx={{paddingTop : 5, paddingBottom : 5, paddingLeft : 0, borderRadius : 15}}
                                    onClick={() => {ClickItem(sideBarItem.NavSubTitle)}} 
                                    active={sideBarItem.NavSubTitle === ClickedItem ? true : false}
                                    color="violet"
                                    variant="light"
                                />
                                :
                                sideBarItem.UserStatus === 'Super Admin & Admin' ? 
                                <NavLink 
                                    label={
                                        <Anchor href={sideBarItem.NavLink} className="nav__link" onClick={ToggleSideBar} target="_self" sx={{
                                            transition : 'transform .2s ease-in-out',
                                            MozTransition : 'transform .2s ease-in-out',
                                            WebkitTransition : 'transform .2s ease-in-out',
                                            ":hover" : {
                                                    background : 'transparent',
                                                    WebkitTransform : 'scale(.9)',
                                                    msTransform : 'scale(.9)',
                                                    transform : 'scale(.9)'
                                            }
                                        }}>
                                            <NavItemContent item = {sideBarItem.NavSubTitle} sx={{padding : 0}}/>
                                        </Anchor>
                                    }
                                    icon = {sideBarItem.NavSubTitleIcon}
                                    sx={{paddingTop : 5, paddingBottom : 5, paddingLeft : 0, borderRadius : 15}}
                                    onClick={() => {ClickItem(sideBarItem.NavSubTitle)}} 
                                    active={sideBarItem.NavSubTitle === ClickedItem ? true : false}
                                    color="violet"
                                    variant="light"
                                />
                                :
                                <NavLink 
                                    label={
                                        <Anchor href={sideBarItem.NavLink} className="nav__link" onClick={ToggleSideBar} target="_self" sx={{
                                            transition : 'transform .2s ease-in-out',
                                            MozTransition : 'transform .2s ease-in-out',
                                            WebkitTransition : 'transform .2s ease-in-out',
                                            ":hover" : {
                                                    background : 'transparent',
                                                    WebkitTransform : 'scale(.9)',
                                                    msTransform : 'scale(.9)',
                                                    transform : 'scale(.9)'
                                            }
                                        }}>
                                            <NavItemContent item = {sideBarItem.NavSubTitle} sx={{padding : 0}}/>
                                        </Anchor>
                                    }
                                    icon = {sideBarItem.NavSubTitleIcon}
                                    sx={{paddingTop : 5, paddingBottom : 5, paddingLeft : 0, borderRadius : 15, display : 'none'}}
                                    onClick={() => {ClickItem(sideBarItem.NavSubTitle)}} 
                                    active={sideBarItem.NavSubTitle === ClickedItem ? true : false}
                                    color="violet"
                                    variant="light"
                                />
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        }
        </>
       
    )
}
