import React, { useState, useEffect } from "react"
import DataTable from 'react-data-table-component'
import ReactTimeAgo from 'react-time-ago'
import Axios from 'axios'
import { api } from '../../Utils'
import { IconSearch, IconCheck, IconCopy, IconEye, IconPlus, IconLockPin, IconDotsVertical, IconTrash, IconUpload, IconKey, IconLockOpen, IconLock, IconAlertCircle, IconNote } from '@tabler/icons-react';
import { Button, Text, Group, CopyButton, ActionIcon, Tooltip, TextInput, Menu, useMantineTheme, Modal, PasswordInput, Textarea } from '@mantine/core'

export const ListofPasswordManager = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme()
    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const countPerPage = 10
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [UploadUserAccountModal, setUploadUserAccountModal] = useState(false)
    const [UpdatePasswordModal, setUpdatePasswordModal] = useState(false)
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [UpdateNoteModal, setUpdateNoteModal] = useState(false)
    const [ToUploadData, setToUploadData ] = useState({
        'PlatForm' : '',
        'UserNameEmail' : '',
        'Password' : '',
        'Note' : ''
    })
    const [ToUpdatePasswordData, setToUpdatePasswordData] = useState({
        'UserCurrentPassword' : '',
        'UserNewPassword' : ''
    })
    const [ToUpdateNoteData, setToUpdateNoteData] = useState({
        'AccountCurrentNote' : '',
        'AccountNewNote' : ''
    })
    const [AlertStatus, setAlertStatus] = useState('')
    const [AlertTitle, setAlertTitle] = useState('')
    const [AlertDescription, setAlertDescription] = useState('')
    const [UserAccountID, setUserAccountID] = useState('')
    useEffect(() => {
        fetchListOfPasswords()
    }, []);
    const fetchListOfPasswords = async() => {
        setLoading(true);   
        const ListPasswordEP = `/passwords`
        await api.get(ListPasswordEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            console.log(error)
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const UploadUserAccountFunction = async() => {
        setConfirmModal(false)
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Account',
            message: 'Account will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UploadUserAccountEP = `/passwords`
        await api.post(UploadUserAccountEP, {
            platform: ToUploadData.PlatForm,
            email_UserName: ToUploadData.UserNameEmail ,
            password: ToUploadData.Password ,
            notes: ToUploadData.Note,
        }).then(res => {
            if(res.status === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    setUploadUserAccountModal(false)
                    fetchListOfPasswords()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Account was uploaded',
                        message: 'Account successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).catch(error =>{
            console.log(error)
        }) 
    }
    const RemoveUserAccountFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Account',
            message: 'Account will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const RemoveUserAccountEP = `/passwords/`+UserAccountID+``
        await api.delete(RemoveUserAccountEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchListOfPasswords()
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Account was deleted',
                        message: 'Account successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
                
            }else{}
        }).catch(error =>{  
        })
    }
    const UpdateUserAccountPasswordFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Account',
            message: 'Account will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const UpdateAccountEP = `/passwords/`+UserAccountID+`?newPassword=`+ToUpdatePasswordData.UserNewPassword+``
        api.put(UpdateAccountEP, {
        }).then(result => {
        if(result['status'] === 200){
            setTimeout(() => {
                //! RESET INPUTS
                fetchListOfPasswords()
                setUpdatePasswordModal(false)
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Account was updated',
                    message: 'Account successsfully updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const UpdateNoteFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Note',
            message: 'Note will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const UpdateAccountEP = `/passwords/`+UserAccountID+`/notes?notes=`+ToUpdateNoteData.AccountNewNote+``
        api.put(UpdateAccountEP, {
        }).then(result => {
        if(result['status'] === 204){
            setTimeout(() => {
                //! RESET INPUTS
                fetchListOfPasswords()
                setUpdateNoteModal(false)
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Note was updated',
                    message: 'Note successsfully updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const GetUserAccountInformation = (UserID, Password) => {
        setUserAccountID(UserID)
        setUpdatePasswordModal(true)
        setToUpdatePasswordData((current) => ({...current, UserCurrentPassword: Password}))
    }
    const GetAccountNoteInformation = (UserID, Notes) => {
        setUpdateNoteModal(true)
        setUserAccountID(UserID)
        setToUpdateNoteData((current) => ({...current, AccountCurrentNote: Notes}))
    }
    const ConfirmFunction = (alert, id) => {
        setConfirmModal(true)
        setUserAccountID(id)
        setAlertStatus(alert)
        if(alert === 'Upload'){
            setAlertTitle('Uploading Account')
            setAlertDescription('Are you sure you want to Upload this Information?')
        }if(alert === 'Delete'){
            setAlertTitle('Delete Account')
            setAlertDescription('Are you sure you want to Delete this Information?')
        }if(alert === 'UpdatePassword'){
            setAlertTitle('Update Account Password')
            setAlertDescription('Are you sure you want to Update Pasword?')
        }if(alert === 'UpdateNote'){
            setAlertTitle('Update Account Note')
            setAlertDescription('Are you sure you want to Update Note?')
        }
    }

    const [SelectedUserPasswordID, setSelectedUserPasswordID] = useState('')
    const [ViewPasswordStatus, setViewPasswordStatus] = useState(false)
    const ViewPasswordFunction = (id) => {
        setSelectedUserPasswordID(id)
        if(SelectedUserPasswordID === id){
            setViewPasswordStatus(true)
        }else{
            setViewPasswordStatus(false)
        }
    }
    const columns = [ 
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>PLATFORM</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.platform}</Text>
        },
          {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>NOTE</Text>,
            cell : row => <Text lineClamp = {1} fw={500} fz={14} sx={{fontFamily : 'poppins'}} onClick={() => {GetAccountNoteInformation(row.id, row.notes)}}>{row.notes}</Text>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>EMAIL | USERNAME</Text>,
            cell : row => 
            <Group sx={{width : '100%'}} position="apart">
                <Text lineClamp = {1} fw={500} fz={14} sx={{fontFamily : 'poppins', lineBreak : 'break-all', width : '80%'}}>{row.email_UserName}</Text>
                <CopyButton value={row.email_UserName} timeout={2000}>
                    {({ copied, copy }) => (
                        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                            <ActionIcon color={copied ? 'teal' : 'dark'} onClick={copy}>
                                {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                            </ActionIcon>
                        </Tooltip>
                    )}
                </CopyButton>
            </Group>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>DATE CREATED</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}><ReactTimeAgo date={Date.parse(row.dateCreated)} /></Text>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>PASSWORD</Text>,
            cell : row => 
            <Group>
                {SelectedUserPasswordID === row.id ? 
                    <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.passwords[0].key}</Text>
                    :
                    <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>•••••••••••</Text>
                }
                <Group spacing={0}>
                    <CopyButton value={row.passwords[0].key} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'dark'} onClick={copy}>
                                    {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                    <ActionIcon color='dark' onClick={() => {ViewPasswordFunction(row.id)}}>
                        {SelectedUserPasswordID === row.id ? <IconLockOpen size="1rem" /> : <IconLock size="1rem" />}
                    </ActionIcon>
                </Group>
            </Group>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>LAST UPDATED PASSWORD</Text>,
            cell : row =>
            <Group sx={{width : '100%'}} position="apart">
                <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}><ReactTimeAgo date={Date.parse(row.passwords[0].dateCreated)} /></Text>
                <Menu shadow="md" width={200} position="left-start" offset={-10} withArrow>
                    <Menu.Target>
                        <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                            <IconDotsVertical size="1.625rem" />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label><Text sx={{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">ACTION</Text></Menu.Label>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconLockPin/>} color="black" onClick={() => {GetUserAccountInformation(row.id, row.passwords[0].key)}}>Update Password</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconNote/>} color="black" onClick={() => {GetAccountNoteInformation(row.id, row.notes)}}>Update Note</Menu.Item>
                        <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconTrash/>} color="red" onClick={() => {ConfirmFunction('Delete', row.id)}}>Remove Account</Menu.Item>
                    </Menu.Dropdown>
                </Menu>  
            </Group>
            
        },
        
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group >
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder= 'Search Username or Email'
                    onChange={ handleSearch }
                />
                <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick={() => {setUploadUserAccountModal(true)}}
                >
                    <IconPlus/>
                </ActionIcon>
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={
                    data.filter((SearchUser) => {
                        if(search === ""){
                            return SearchUser
                        }else if(SearchUser.email_UserName.toLowerCase().includes(search.toLowerCase())){
                            return SearchUser
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            <Modal
                opened = {UploadUserAccountModal}
                onClose={() => {setUploadUserAccountModal(false)}}
                title="UPLOAD USER ACCOUNT"
                overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
                }}
            >
                <Group mt={10} grow>
                    <TextInput
                        placeholder="Enter Platform"
                        label="Platform"
                        size="md"
                        radius="sm"
                        sx={{fontFamily : 'poppins'}}
                        onChange={(e) => setToUploadData((current) => ({...current, PlatForm: e.target.value}))}
                        withAsterisk
                    />
                </Group>
                <Group mt={10} grow>
                    <TextInput
                        placeholder="Enter Username or Email"
                        label="Username or Email"
                        size="md"
                        radius="sm"
                        sx={{fontFamily : 'poppins'}}
                        onChange={(e) => setToUploadData((current) => ({...current, UserNameEmail: e.target.value}))}
                        withAsterisk
                    />
                </Group>
                <Group mt={10} grow>
                    <PasswordInput
                        placeholder="Enter your password"
                        label="Password"
                        size="md"
                        radius="sm"
                        sx={{fontFamily : 'poppins'}}
                        onChange={(e) => setToUploadData((current) => ({...current, Password: e.target.value}))}
                        withAsterisk
                    />
                </Group>
                <Group mt={10} grow>
                    <Textarea
                        placeholder="Enter Note"
                        label="Note"
                        size="md"
                        radius="sm"
                        sx={{fontFamily : 'poppins'}}
                        onChange={(e) => setToUploadData((current) => ({...current, Note: e.target.value}))}
                        minRows={5}
                        withAsterisk
                    />
                </Group>
                <Group mt={10} grow>
                    <Button sx={{fontFamily : 'poppins'}} onClick={() => {ConfirmFunction('Upload')}} leftIcon = {<IconUpload/>} color="violet" size="md" radius="sm" uppercase>
                        UPLOAD USER
                    </Button>
                </Group>
            </Modal> 
            <Modal
                opened = {UpdatePasswordModal}
                onClose={() => {setUpdatePasswordModal(false)}}
                title="UPDATE PASSWORD"
                overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
                }}
            >
                <Group mt={10} grow>
                    <PasswordInput
                        label="Current Password"
                        size="md"
                        radius="sm"
                        sx={{fontFamily : 'poppins'}}
                        value={ToUpdatePasswordData.UserCurrentPassword}
                        withAsterisk
                    />
                </Group>
                <Group mt={10} grow>
                    <PasswordInput
                        placeholder="Enter your new password"
                        label="New Password"
                        size="md"
                        radius="sm"
                        sx={{fontFamily : 'poppins'}}
                        onChange={(e) => setToUpdatePasswordData((current) => ({...current, UserNewPassword: e.target.value}))}
                        withAsterisk
                    />
                </Group>
                <Group mt={10} grow>
                    <Button sx={{fontFamily : 'poppins'}} onClick={() => {ConfirmFunction('UpdatePassword', UserAccountID)}} leftIcon = {<IconKey/>} color="violet" size="md" radius="sm" uppercase>
                        UPDATE PASSWORD
                    </Button>
                </Group>
            </Modal> 
            <Modal
                size="xl"
                opened = {UpdateNoteModal}
                onClose={() => {setUpdateNoteModal(false)}}
                title="UPDATE NOTE"
                overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
                }}
            >
                <Group mt={10} grow>
                    <Textarea
                         label="Current Note"
                         size="md"
                         radius="sm"
                         sx={{fontFamily : 'poppins'}}
                         minRows={5}
                         withAsterisk
                         value={ToUpdateNoteData.AccountCurrentNote}
                         readOnly
                    />

                    <Textarea
                         label="Enter New Note"
                         placeholder="Enter new note"
                         size="md"
                         radius="sm"
                         sx={{fontFamily : 'poppins'}}
                         minRows={5}
                         onChange={(e) => setToUpdateNoteData((current) => ({...current, AccountNewNote: e.target.value}))}
                         withAsterisk
                    />
                </Group>
                <Group mt={10} grow>
                    <Button onClick={() => {ConfirmFunction('UpdateNote', UserAccountID)}} sx={{fontFamily : 'poppins'}} leftIcon = {<IconKey/>} color="violet" size="md" radius="sm" uppercase>
                        UPDATE NOTE
                    </Button>
                </Group>
            </Modal>  
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={AlertTitle} centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>{AlertDescription}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                {AlertStatus === 'Upload' ? 
                    <Button sx={{fontFamily : 'poppins'}} onClick = {UploadUserAccountFunction} color="teal" size="sm" uppercase> Upload Account </Button>
                    :
                    AlertStatus === 'Delete' ? 
                        <Button sx={{fontFamily : 'poppins'}} onClick = {RemoveUserAccountFunction} color="teal" size="sm" uppercase> Delete Account </Button>
                        :
                        AlertStatus === 'UpdatePassword' ?
                            <Button sx={{fontFamily : 'poppins'}} onClick = {UpdateUserAccountPasswordFunction} color="teal" size="sm" uppercase> Update Password </Button>
                            :
                            AlertStatus === 'UpdateNote' ?
                                <Button sx={{fontFamily : 'poppins'}} onClick = {UpdateNoteFunction} color="teal" size="sm" uppercase> Update Note </Button>
                                :
                                ''    
                }
                    <Button sx={{fontFamily : 'poppins'}} onClick = {() => {setConfirmModal(false)}} color="red" size="sm" uppercase> Cancel </Button>
                </Group>
            </Modal>
        </div>
    )
}