import { ListofDailyDevotion } from "../../Components/DataTables/ListofDailyDevotion"
import { notifications } from '@mantine/notifications';
export const DailyDevotion = () => {
    return(
        <>
            <ListofDailyDevotion
                notifications = {notifications}
            />
        </>
    )
}