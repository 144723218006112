import React, { useState } from "react";
import { Button, Group, Modal, useMantineTheme, Textarea, Text, TextInput, Card,
    Image, rem, Divider
 } from '@mantine/core';
import { IconUpload, IconCheck, IconX, IconPhoto } from '@tabler/icons-react';
import { Token } from '../../Utils'
import Axios from 'axios';
export const UploadSubscriptionPlan = ({notifications, OpenModal, CloseModal, fetchSubscriptionPlan, PropsAddNewSubscriptionPlanTranslate, PropsPlanNameTranslate, PropsPlaceholderPlanName, PropsPlanPriceTranslate, PropsPlaceholderPlanPrice, PropsPlanDurationTranslate, PropsPlaceholderPlanDuration, PropsBtnUploadPlan, PropsConfirmTitleTranslate, PropsConfirmMessageTranslate, PropsConfirmTranslate, PropsCancelTranslate}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [Name, setName] = useState('');
    const [Price, setPrice] = useState(0);
    const [Duration, setDuration] = useState(0);
    const handleChangeName = (event) => {
        setName(event.target.value)
    }
    const handleChangePrice = (event) => {
        setPrice(event.target.value)
    }
    const handleChangeDuration = (event) => {
        setDuration(event.target.value)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const UploadSubscriptionPlanFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Subscription Plan',
            message: 'Subscription Plan will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        Axios.post(''+APILink+'/api/subscriptionPlans',{
            name: Name,
            price: Price,
            durationInMonths: Duration,
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchSubscriptionPlan()
                    CloseModal();
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Subscription Plan was uploaded',
                        message: 'Subscription Plan successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={PropsAddNewSubscriptionPlanTranslate}
            size="md"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderPlanName}
                    label={PropsPlanNameTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    onChange={handleChangeName}
                    sx={{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderPlanPrice}
                    label={PropsPlanPriceTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    onChange={handleChangePrice}
                    sx={{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderPlanDuration}
                    label={PropsPlanDurationTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    onChange={handleChangeDuration}
                    sx={{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}}onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {PropsBtnUploadPlan}
                </Button>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={PropsConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text sx={{fontFamily : 'poppins'}}>{PropsConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UploadSubscriptionPlanFunction} color="teal" size="sm" uppercase> {PropsConfirmTranslate} </Button>
                <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {PropsCancelTranslate} </Button>
            </Group>
        </Modal>
        </>
    )

}