import React, { useState, useEffect } from "react";
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { Modal, Group, Text, Avatar, TextInput, ActionIcon, Card, useMantineTheme, Image, rem, Button, ScrollArea } from '@mantine/core'
import { IconCheck, IconSearch, IconPlus, IconUpload, IconX, IconPhoto, IconPhotoShare, IconTrash } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import Axios from 'axios';
export const AssignBadge = ({OpenModal, CloseModal, fetchAchievement, notifications, AchievementID, PropsAssignBadgeTranslate, PropsSearchBadgeTranslate, PropsUploadBadgeTranslate, PropsDragImageTitleTranslate, PropsDragImageDescriptionTranslate, PropsBadgeNameTranslate, PropsPlaceholderBadgeName, PropsBtnUploadBadgeTranslate}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [OpenUploadBadge, setOpenUploadBadge] = useState(false)
    const [img, setImg] = useState([])
    const [image, setImage] = useState('')
    const [BadgeName, setBadgeName] = useState('')
    useEffect(() => {
        fetchBadge();
    }, [])
    const handleChangeBadgeName = (event) => {
        setBadgeName(event.target.value)
    }
    function handleImage(files){
        setImage(files)
    }
    const fetchBadge = async() => {
        setLoading(true);   
        const ListBadgeEP = `/Badges?PageNumber=1&PageSize=100`
        await api.get(ListBadgeEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const AssignBadgeFunction = (BadgeID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Badge',
            message: 'Badge will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const AssignBadgeEP = `/Achievements/`+AchievementID+`?badgeId=`+BadgeID+``
        api.put(AssignBadgeEP,{
       }).then(result => {
       if(result['status'] === 204){
            setTimeout(() => {

               //! RESET INPUTS
               fetchAchievement()
               CloseModal(false)
               setSearch('')
               //! END OF RESET INPUTS

               notifications.update({
                   id: 'load-data',
                   color: 'teal',
                   title: 'Badge was uploaded',
                   message: 'Badge successsfully uploaded',
                   icon: <IconCheck size="1rem" />,
                   autoClose: 2000,
               });
           }, 1000); 
       }else{}
       }).catch(error =>{
       })
    }
    const UploadBadge = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Badge',
            message: 'Badge will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const formData = new FormData();
        image.map((files) => {
            formData.append('files', files)
        })
        Axios.post(''+APILink+'/api/Badges?badgeName='+BadgeName+'', formData,{
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchAchievement()
                    setOpenUploadBadge(false);
                    fetchBadge();
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Badge was uploaded',
                        message: 'Badge successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })
    }
    const DeleteBadge = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Badge',
            message: 'Badge will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const BadgeEP = `/Badges/`+ID+``
        await api.delete(BadgeEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchBadge();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Badge was deleted',
                        message: 'Badge successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    return(
        <>
        <Modal size = "lg" opened={OpenModal} onClose={CloseModal} title = {PropsAssignBadgeTranslate} centered>
            <Group spacing={5}>
                <TextInput
                    icon={<IconSearch/>}
                    radius="xl"
                    size="md"
                    placeholder={PropsSearchBadgeTranslate}
                    onChange={ handleSearch }
                />
                <ActionIcon
                    sx={{background : 'rgb(71 46 121)',  ":hover" : {
                        background : 'rgb(39, 20, 77)'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled"
                    onClick={() => {setOpenUploadBadge(true)}}
                >
                    <IconPlus />
                </ActionIcon>
            </Group>
            <br></br>
            <ScrollArea h={350} scrollbarSize={12} scrollHideDelay={0}>
                {data.filter((SearchBadge) => {
                    if(search === ""){
                        return SearchBadge
                    }else if(SearchBadge.name.toLowerCase().includes(search.toLowerCase())){
                        return SearchBadge
                    }
                }).map((badge, indx) => 
                <Group  key = {indx} position="apart" sx={{ cursor : 'pointer', transition: '0.3s', ":hover" : { background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)' }, padding : 12}}>
                    <Group spacing={8}>
                        <Avatar src= {badge.image['name']} sx={{boxShadow : '1px 2px 2px 1px rgb(114, 110, 110)', background : 'transparent', width : 60, height : 60, borderRadius : 50}} color="violet" variant="filled" />
                        <Text fw={900} fz="lg" sx={{color : 'rgb(39, 20, 77)', fontFamily : 'poppins'}}>{badge.name}</Text>
                    </Group>
                    <Group spacing={8}>
                        <ActionIcon
                            color="teal"
                            size="lg" 
                            radius="xl" 
                            onClick={() => {AssignBadgeFunction(badge.id)}}
                        >
                            <IconCheck stroke={2}/>
                        </ActionIcon>
                        <ActionIcon
                            color="red"
                            size="lg" 
                            radius="xl" 
                            onClick={() => {DeleteBadge(badge.id)}}
                        >
                            <IconTrash stroke={2}/>
                        </ActionIcon>
                    </Group>
                </Group>
                )}
            </ScrollArea>
        </Modal>
         <Modal size = "lg" opened={OpenUploadBadge} onClose={() => {setOpenUploadBadge(false)}} title = {PropsUploadBadgeTranslate} centered>
            <Card shadow="xl" padding="lg">
                <Card.Section>
                    {img.map((url, index) => 
                        <Image 
                            key = {index}
                            style = {{ padding : '30px'}} 
                            maw={340} 
                            mx="auto" 
                            radius="sm" 
                            alt="Bible Story Image"
                            src={ url }
                        />
                    )}
                </Card.Section>
                <Card.Section >
                    <Dropzone
                        onDrop={
                            (files) => {
                                handleImage(files)
                                const selectedFIles =[];
                                const targetFilesObject= [...files]
                                targetFilesObject.map((file)=>{
                                    return selectedFIles.push(URL.createObjectURL(file))
                                })
                                setImg(selectedFIles);
                            } 
                        }
                        maxSize={3 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                    >
                        <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>

                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>

                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                    {PropsDragImageTitleTranslate}
                                </Text>
                                <Text size="xs" color="dimmed" inline mt={14} sx={{fontFamily : 'poppins'}}>
                                   {PropsDragImageDescriptionTranslate}
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>
                    <br/>
                    <Group grow>
                        <TextInput
                            placeholder={PropsPlaceholderBadgeName}
                            label={PropsBadgeNameTranslate}
                            variant="filled"
                            size="md"
                            withAsterisk
                            onChange={handleChangeBadgeName}
                            sx={{fontFamily : 'poppins'}}
                        />
                    </Group>
                    <Group mt="md" mb="xs" grow>
                        <Button sx={{fontFamily : 'poppins'}} onClick={UploadBadge} leftIcon = {<IconPhotoShare/>} color="violet" size="md" uppercase>
                           {PropsBtnUploadBadgeTranslate}
                        </Button>
                    </Group>
                </Card.Section>
            </Card>
          
         </Modal>
         </>
    )
}