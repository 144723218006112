import React, { useState, useEffect } from "react";
import { Group, Text, Loader  } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
import ReactECharts from 'echarts-for-react';
import Moment from 'moment'; 
export const TotalUserChart = () => {
    var sourceLang = 'en';
    const [DailyUser, setDailyUser] = useState([])
    const [loading, setLoading] = useState(false);
    const [DefaultLanguage , setDefaultLanguage] = useState('en');
    const DateToday = Moment().toISOString(new Date());
    const [DataTranslate, setDataTranslate] = useState({
        "UserTitleTranslate" : 'DAILY USERS',
        "MonTranslate" : 'Mon',
        "TueTranslate" : 'Tue',
        "WedTranslate" : 'Wed',
        "ThuTranslate" : 'Thu',
        "FriTranslate" : 'Fri',
        "SatTranslate" : 'Sat',
        "SunTranslate" : 'Sun',
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation()
        fetchDailyUsers()
    }, [DefaultLanguage]);
    let obj = {}
    const fetchDailyUsers = async() => {
        setLoading(true);  
        const TotalUserTodayEP = `statistics/registeredUserDaily?date=`+DateToday+`&previousDaysCount=7`
        await api.get(TotalUserTodayEP).then((result) => {
            setDailyUser(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    DailyUser.sort(function(a,b){
        return new Date(a.date) - new Date(b.date);
    });
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const options = {
        grid: { top: 8, right: 8, bottom: 24, left: 46},
        xAxis: {
          type: 'category',
          data: [Moment(DailyUser[0]?.date).format('ddd'),
            Moment(DailyUser[1]?.date).format('ddd'),
            Moment(DailyUser[2]?.date).format('ddd'),
            Moment(DailyUser[3]?.date).format('ddd'),
            Moment(DailyUser[4]?.date).format('ddd'),
            Moment(DailyUser[5]?.date).format('ddd'),
            Moment(DailyUser[6]?.date).format('ddd')],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [DailyUser[0]?.count, DailyUser[1]?.count, DailyUser[2]?.count, DailyUser[3]?.count, DailyUser[4]?.count, DailyUser[5]?.count, DailyUser[6]?.count],
            type: 'line',
          },
        ],
        tooltip: {
          trigger: 'axis',
        },
    }
    return(
        <>  
            <Group position="center">
                <Text fw = {900} sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>{DataTranslate.UserTitleTranslate}</Text>
            </Group>
            {loading ?
                <Group position="center" sx={{padding : 20}}>
                    <Loader color="violet" variant="dots" size="md"/>
                </Group>
                :
                <ReactECharts option={options} />
            }
        </>
    )
}