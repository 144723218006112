
import { notifications } from "@mantine/notifications"
import { ListOfQuestions } from "../../Components/DataTables/ListOfQuestions"
export const BibleQuiz = () => {
    return (
        <>
        <ListOfQuestions
            notifications = {notifications}
        />
        </>
    )
}