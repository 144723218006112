import React, { useState, useEffect } from "react";
import { Image, Text, Button, Grid, Skeleton, Group, Divider, Card, Overlay   } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconHeartbeat, IconMicrophone, IconMusic, IconPlayerPlayFilled, IconUser } from '@tabler/icons-react'
import { Link } from "react-router-dom";
import Axios from 'axios';
export const TotalWorship = () => {
    const [TotalMusic, setTotalMusic] = useState([])
    const [TotalPodcast, setTotalPodcast] = useState([])
    const [TotalInstrumentals, setTotalInstrumentals] = useState([])
    const [Artist, setArtist] = useState([])
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false)
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [PreacherTitle, setPreacherTitle] = useState('Preachers')
    const [PreachingTitle, setPreachingTitle] = useState('Preaching')
    const [MusicTitle, setMusicTitle] = useState('Music')
    const [MeditationTitle, setMeditationTitle] = useState('Meditation')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        FetchArtist();
        FetchTotalPodcast();
        FetchTotalMusic();
        FetchTotalInstrumentals();
        setOpened(true)
        fetchTranslation()
    }, [opened, DefaultLanguage]);
    const FetchTotalMusic = async() => { 
        setLoading(true)
        const TotalMusicEP = `/Audios?category=0&PageNumber=1&PageSize=7`
        await api.get(TotalMusicEP).then((result) => {
            setTotalMusic(result.data.data)
            setLoading(false)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const FetchTotalPodcast = async() => {
        setLoading(true)
        const TotalPodcastEP = `/Audios?category=1&PageNumber=1&PageSize=7`
        await api.get(TotalPodcastEP).then((result) => {
            setTotalPodcast(result.data.data)
            setLoading(false)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const FetchTotalInstrumentals = async() => {
        setLoading(true)
        const TotalInstrumentalsEP = `/Audios?category=2&PageNumber=1&PageSize=7`
        await api.get(TotalInstrumentalsEP).then((result) => {
            setTotalInstrumentals(result.data.data)
            setLoading(false)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const FetchArtist = async() => {
        setLoading(true)
        const ArtistEP = `/Artists?PageNumber=1&PageSize=7`
        await api.get(ArtistEP).then((result) => {
            setArtist(result.data.data)
            setLoading(false)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PreacherTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPreacherTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PreachingTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPreachingTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MusicTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMusicTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MeditationTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMeditationTitle(translated[0])
            })
        }).then((error) =>{
        })
    }
    return( 
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Text fw={800} sx={{color : '#4a1e9e', fontSize : 32, fontFamily : 'poppins'}}><IconUser size={30}/>{PreacherTitle}</Text>
            {loading ? 
                <Group spacing={8}>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                </Group>
                : 
                <Grid gutter="xs" grow>
                    {Artist?.map ((artist, idx) =>  
                        <div key = {idx} style={{width : '14.2%'}} className="WorshipContentImage">
                            <Grid.Col>
                                <Card radius="md" sx={{
                                    height : '240px',
                                    backgroundSize : 'cover',
                                    backgroundPosition : 'center',
                                    backgroundImage : 'url('+artist.profile+')'
                                }}>
                                <Overlay 
                                    opacity={0.55} 
                                    zIndex={0} 
                                    sx={{ 
                                        backgroundColor : 'transparent', 
                                        backgroundImage : 'linear-gradient(105deg, rgba(0, 0, 0, 0.95) 20%, rgba(0, 0, 0, 0.50) 100%)'
                                    }} 
                                />
                                    <div style={{
                                        position : 'absolute',
                                        inset : 0,
                                        padding : 10,
                                        zIndex : 1
                                    }}>
                                        <Text size="lg" fw={700} sx={{color : 'white', marginBottom : 10 / 2, fontFamily : 'poppins'}}>
                                            {artist.fullName} 
                                        </Text>
                                        <Text lineClamp = {5} size="sm" sx={{color : 'white', maxWidth : '220px', fontFamily : 'poppins'}}>
                                            {artist.description}
                                        </Text>
                                        <Link to={"/worship"}>
                                            <Button radius="xl" size="xs" sx={{
                                                position : 'absolute',
                                                bottom : 15,
                                                right : 15,
                                                background : '#6c2be2',  
                                                ":hover" : {
                                                    background : '#4a1e9e'
                                                }
                                            }}>
                                                <Group spacing={5} position="center">
                                                    <IconPlayerPlayFilled size={16}/>
                                                    <Text sx={{fontFamily : 'poppins'}}>PLAY</Text>
                                                </Group>  
                                            </Button>
                                        </Link>
                                    </div>
                                </Card>
                            </Grid.Col>
                        </div>
                    )}   
                </Grid>
            }
            <Text fw={800} sx={{color : '#4a1e9e', fontSize : 32, paddingTop : 15, fontFamily : 'poppins'}}><IconMicrophone size={30}/>{PreachingTitle}</Text>
            {loading ? 
                <Group spacing={8}>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                </Group>
                : 
                <Grid gutter="xs">
                    {TotalPodcast?.map ((podcast, idx) =>  
                        <div key = {idx} style={{width : '14.2%'}}  className="WorshipContentImage">
                            <Grid.Col>
                                <Card radius="md" sx={{
                                        height : '240px',
                                        backgroundSize : 'cover',
                                        backgroundPosition : 'center',
                                        backgroundImage : 'url('+podcast.image+')'
                                    }}>
                                <Overlay 
                                    opacity={0.55} 
                                    zIndex={0} 
                                    sx={{ 
                                        backgroundColor : 'transparent', 
                                        backgroundImage : 'linear-gradient(105deg, rgba(0, 0, 0, 0.95) 20%, rgba(0, 0, 0, 0.50) 100%)'
                                    }} 
                                />
                                    <div style={{
                                        position : 'absolute',
                                        inset : 0,
                                        padding : 10,
                                        zIndex : 1
                                    }}>
                                        <Text size="lg" fw={700} sx={{color : 'white', marginBottom : 10 / 2, fontFamily : 'poppins'}}>
                                            {podcast.artist} 
                                        </Text>
                                        <Text lineClamp = {5} size="sm" sx={{color : 'white', maxWidth : '220px', fontFamily : 'poppins'}}>
                                            {podcast.title}
                                        </Text>
                                        <Link to={"/worship"}>
                                            <Button radius="xl" size="xs" sx={{
                                                position : 'absolute',
                                                bottom : 15,
                                                right : 15,
                                                background : '#6c2be2',  
                                                ":hover" : {
                                                    background : '#4a1e9e'
                                                }
                                            }}>
                                                <Group spacing={5} position="center">
                                                    <IconPlayerPlayFilled size={16}/>
                                                    <Text sx={{fontFamily : 'poppins'}}>PLAY</Text>
                                                </Group>  
                                            </Button>
                                        </Link>
                                    </div>
                                </Card>
                            </Grid.Col>
                        </div>
                    )}   
                </Grid>
            }
            <Text fw={800} sx={{color : '#4a1e9e', fontSize : 32, paddingTop : 15, fontFamily : 'poppins'}}><IconMusic size={30}/>{MusicTitle}</Text>
            {loading ? 
                <Group spacing={8}>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent" />
                </Group>
                : 
                <Grid gutter="xs">
                    {TotalMusic?.map ((music, idx) =>  
                        <div key = {idx} style={{width : '14.2%'}}  className="WorshipContentImage">
                            <Grid.Col>
                                <Card radius="md" sx={{
                                            height : '240px',
                                            backgroundSize : 'cover',
                                            backgroundPosition : 'center',
                                            backgroundImage : 'url('+music.image+')'
                                        }}>
                                <Overlay 
                                    opacity={0.55} 
                                    zIndex={0} 
                                    sx={{ 
                                        backgroundColor : 'transparent', 
                                        backgroundImage : 'linear-gradient(105deg, rgba(0, 0, 0, 0.95) 20%, rgba(0, 0, 0, 0.50) 100%)'
                                    }} 
                                />
                                    <div style={{
                                        position : 'absolute',
                                        inset : 0,
                                        padding : 10,
                                        zIndex : 1
                                    }}>
                                        <Text size="lg" fw={700} sx={{color : 'white', marginBottom : 10 / 2, fontFamily : 'poppins'}}>
                                            {music.artist} 
                                        </Text>
                                        <Text lineClamp = {5} size="sm" sx={{color : 'white', maxWidth : '220px', fontFamily : 'poppins'}}>
                                            {music.title}
                                        </Text>
                                        <Link to={"/worship"}>
                                            <Button radius="xl" size="xs" sx={{
                                                position : 'absolute',
                                                bottom : 15,
                                                right : 15,
                                                background : '#6c2be2',  
                                                ":hover" : {
                                                    background : '#4a1e9e'
                                                }
                                            }}>
                                                <Group spacing={5} position="center">
                                                    <IconPlayerPlayFilled size={16}/>
                                                    <Text sx={{fontFamily : 'poppins'}}>PLAY</Text>
                                                </Group>  
                                            </Button>
                                        </Link>
                                    </div>
                                </Card>
                            </Grid.Col>
                        </div>
                    )}   
                </Grid>
            }
            <Text fw={800} sx={{color : '#4a1e9e', fontSize : 32, paddingTop : 15, fontFamily : 'poppins'}}><IconHeartbeat size={30}/>{MeditationTitle} </Text>
            {loading ? 
                <Group spacing={8}>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent"/>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent"/>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent"/>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent"/>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent"/>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent"/>
                    <Skeleton height={230} width="13.8%" mb="xl" className="PlanContent"/>
                </Group>
                : 
                <Grid gutter="xs">
                    {TotalInstrumentals?.map ((instrumentals, idx) =>  
                        <div key = {idx} style={{width : '14.2%'}}  className="WorshipContentImage">
                            <Grid.Col>
                                <Card radius="md" sx={{
                                                height : '240px',
                                                backgroundSize : 'cover',
                                                backgroundPosition : 'center',
                                                backgroundImage : 'url('+instrumentals.image+')'
                                            }}>
                                <Overlay 
                                    opacity={0.55} 
                                    zIndex={0} 
                                    sx={{ 
                                        backgroundColor : 'transparent', 
                                        backgroundImage : 'linear-gradient(105deg, rgba(0, 0, 0, 0.95) 20%, rgba(0, 0, 0, 0.50) 100%)'
                                    }} 
                                />
                                    <div style={{
                                        position : 'absolute',
                                        inset : 0,
                                        padding : 10,
                                        zIndex : 1
                                    }}>
                                        <Text size="lg" fw={700} sx={{color : 'white', marginBottom : 10 / 2, fontFamily : 'poppins'}}>
                                            {instrumentals.artist} 
                                        </Text>
                                        <Text lineClamp = {5} size="sm" sx={{color : 'white', maxWidth : '220px', fontFamily : 'poppins'}}>
                                            {instrumentals.title}
                                        </Text>
                                        <Link to={"/worship"}>
                                            <Button radius="xl" size="xs" sx={{
                                                position : 'absolute',
                                                bottom : 15,
                                                right : 15,
                                                background : '#6c2be2',  
                                                ":hover" : {
                                                    background : '#4a1e9e'
                                                }
                                            }}>
                                                <Group spacing={5} position="center">
                                                    <IconPlayerPlayFilled size={16}/>
                                                    <Text sx={{fontFamily : 'poppins'}}>PLAY</Text>
                                                </Group>  
                                            </Button>
                                        </Link>
                                    </div>
                                </Card>
                            </Grid.Col>
                        </div>
                    )}   
                </Grid>
            }
        </div>
    )
}