import React, { useState, useEffect, useMemo } from "react";
import { createStyles, Image, TextInput, Group, Divider, Title, Popover, Button, Autocomplete, ScrollArea  } from '@mantine/core';
import { motion } from 'framer-motion'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconListDetails } from '@tabler/icons-react';
import './Devotion.css'
import { UploadVerseDevotion } from "../../Components/Component";
import { ListofDevotionVerse } from "../../Components/Component";
import Axios from 'axios';
export const Devotion = () => {
    const [data, setData] = useState([]);
    const [dataVersion, setDataVersion] = useState([]);
    const [loading, setLoading] = useState(false);
    const [DevotionID, setDevotionID] = useState("");
    const [DevotionFeelings, setDevotionFeelings] = useState("");
    const [search, setSearch] = useState('');
    const [OpenDevotionVerseModal, setOpenDevotionVerseModal] = useState(false);
    const [opened, setOpened] = useState(false);
    const [SelectedFeelingToView, setSelectedFeelingToView] = useState("");
    const [SelectedVersionToView, setSelectedVersionToView] = useState("");
    const [DevotionVerse, setDeovtionVerse] = useState([])
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "ListOfFeelingsTranslate" : 'List of Feelings',
        "ViewDevotionTranslate" : 'View Devotion Verses',
        "SearchFeelingsTranslate" : 'Search Feelings',
        "BackTranslate" : 'BACK',
        "Step1Translate" : 'Step 1',
        "Step2Translate" : 'Step 2',
        "Step3Translate" : 'Step 3',
        "Step4Translate" : 'Step 4',
        "Step5Translate" : 'Step 5',
        "SearchBookTranslate" : 'Search Book Name',
        "ChapterNumberTranslate" : 'CHAPTER NUMBER',
        "VerseNumberTranslate" : 'VERSE CHAPTER',
        "ExplanationTranslate" : 'Explanation',
        "PlaceholderExplanation" : 'Enter Explanation',
        "BtnUploadDevotion" : 'UPLOAD DEVOTION',
        "SelectFeelingsTranslate" : 'Select Feelings',
        "SelectTranslate" : 'Select',
        "SelectBibleVersionTranslate" : 'Select Bible Version',
        "FindTranslate" : 'Find',
        "UploadDevotionVerse" : "Upload Devotion Verse"
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchDevotion();
        fetchVersion();
        fetchTranslation();
    }, [DefaultLanguage]);
    let obj = {}
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchDevotion = async () => {
        setLoading(true);   
        const DevotionEP = `/Devotions`
        await api.get(DevotionEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const fetchVersion = async () => {
        setLoading(true);   
        const ListVersionEP = `/BibleVersions`
        await api.get(ListVersionEP).then((result) => {
            setDataVersion(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const SelectFeelings = (id, feelings) => {
        setDevotionID(id)
        setDevotionFeelings(feelings)
        fetchVersion() 
    }
    const useStyles = createStyles((theme) => ({
        inner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.spacing.xl * 3,
            paddingBottom: theme.spacing.xl * 3,

            [theme.fn.smallerThan('md')]: {
                paddingTop: theme.spacing.xl * 1,
                paddingTop: theme.spacing.xl * 1,
            }, 
        },
        content: {
            maxWidth: '95%',
            [theme.fn.smallerThan('md')]: {
                maxWidth: '100%',
            },
        },
        control: {
            [theme.fn.smallerThan('md')]: {
                flex: 1,
            },
        }
    }));
    const ViewSelectFeelings = async (e) => {
        const FeelingsID = e.target.value
        setSelectedFeelingToView(FeelingsID)
    }
    const ViewSelecVersion = async (e) => {
        const VersionID = e.target.value
        setSelectedVersionToView(VersionID)
    }
    const ViewListofVerse = () => {
        setOpenDevotionVerseModal(true)
        setOpened(false)
        const ListofDevotionVerse = `/Highlights?devotionId=`+SelectedFeelingToView+`&bibleVersionId=`+SelectedVersionToView+``
        api.get(ListofDevotionVerse).then((result) => {
            setDeovtionVerse(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const { classes } = useStyles();
    return (
    <motion.div  className={classes.inner} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <div className={`${classes.content}`}>
            <Title className = "PageTitle" color = "black" order={4} size="h1" sx={{fontFamily : 'poppins'}}>
                {DataTranslate.ListOfFeelingsTranslate}
            </Title>
            <Popover  opened={opened} width="target" position="bottom" withArrow shadow="md" sx={{fontFamily : 'poppins'}}>
                <Group position="right">
                    <Popover.Target >
                        <Button onClick = {() => {setOpened(true)}} leftIcon = {<IconListDetails/>} w={280}>{DataTranslate.ViewDevotionTranslate}</Button>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <label style={{fontFamily : 'poppins'}}>{DataTranslate.SelectFeelingsTranslate}</label>
                        <Group grow>
                            <select onChange={ViewSelectFeelings} style = {{padding : '7px', fontWeight : 'bolder', fontFamily : 'poppins'}}>
                                    <option value = "no version">{DataTranslate.SelectTranslate}</option>
                                {data.map((feelings, index) =>
                                    <option key={ index } value = {feelings.id}>
                                        { feelings.feeling }
                                    </option>
                                )}
                            </select>
                        </Group>
                        <label style={{fontFamily : 'poppins'}}>{DataTranslate.SelectBibleVersionTranslate}</label>
                        <Group grow>               
                            <select onChange={ViewSelecVersion} style = {{padding : '7px', fontWeight : 'bolder', fontFamily : 'poppins'}}>
                                <option>{DataTranslate.SelectTranslate}</option>
                                {dataVersion.map((version, index) =>
                                    <option value={ version.id } key={ index }>
                                        { version.version }
                                    </option>
                                )}
                            </select>
                        </Group>
                        <br/>
                        <Group grow>
                            <Button sx = {{fontFamily : 'poppins'}} onClick = {() => {ViewListofVerse()}}leftIcon = {<IconSearch/>} color="violet" radius="sm" size="sm">{DataTranslate.FindTranslate}</Button>
                        </Group>
                    </Popover.Dropdown>
                    <TextInput
                        icon={<IconSearch/>}
                        radius="md"
                        size="md"
                        placeholder={DataTranslate.SearchFeelingsTranslate}
                        onChange={ handleSearch }
                        sx = {{fontFamily : 'poppins'}}
                    />
                </Group>
                <br></br>
                <Group spacing='xs'>
                    {
                        data.filter((SearchDevotion) => {
                        if(search === ""){
                            return SearchDevotion
                        }else if(SearchDevotion.feeling.toLowerCase().includes(search.toLowerCase())){
                            return SearchDevotion
                        }
                        }).map((devotion, index) => (
                            <Image 
                                key = {index} 
                                onClick = {() => { SelectFeelings(devotion.id, devotion.feeling); }}
                                className = "Img-Devotion"
                                maw={200} mx="auto"
                                radius="md"
                                src={devotion.image} 
                                alt={devotion.feeling} 
                                style = {devotion.feeling === DevotionFeelings ? {
                                    WebkitFilter : 'blur(5px)',
                                    msFilter : 'blur(5px)',
                                    filter : 'blur(5px)'
                                } : ''}
                            /> 
                        ))
                    }
                </Group>
            </Popover>
            <br></br>
            <Divider/>
            {/* UPLOADING LAYOUT */}
            <Title className = "PageTitle" color = "black" order={4} size="h1" sx = {{fontFamily : 'poppins'}}>
                {DataTranslate.UploadDevotionVerse}
            </Title>
            <UploadVerseDevotion 
                DevotionID = {DevotionID}
                dataVersion = {dataVersion}
                PropsBackTranslate = {DataTranslate.BackTranslate}
                PropsStep1Translate = {DataTranslate.Step1Translate}
                PropsStep2Translate = {DataTranslate.Step2Translate}
                PropsStep3Translate = {DataTranslate.Step3Translate}
                PropsStep4Translate = {DataTranslate.Step4Translate}
                PropsStep5Translate = {DataTranslate.Step5Translate}
                PropsSearchBookTranslate = {DataTranslate.SearchBookTranslate}
                PropsChapterNumberTranslate = {DataTranslate.ChapterNumberTranslate}
                PropsVerseNumberTranslate = {DataTranslate.VerseNumberTranslate}
                PropsExplanationTranslate = {DataTranslate.ExplanationTranslate}
                PropsPlaceholderExplanationTranslate = {DataTranslate.PlaceholderExplanation}
                PropsBtnUploadDevotion = {DataTranslate.BtnUploadDevotion}
            />
            <ListofDevotionVerse
                data = {DevotionVerse}
                OpenModal = {OpenDevotionVerseModal}
                CloseModal = {() => {setOpenDevotionVerseModal(false)}}
            />
        </div>
    </motion.div>    
    )
}