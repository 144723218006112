import { Card, Image, Text, Button, Group, useMantineTheme, rem, TextInput,
    Textarea, Switch, Modal, FileInput, Divider, Select } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX, IconPhoto, IconCalendar, IconCheck, IconFileUpload  } from '@tabler/icons-react';
import React, { useState, useEffect, forwardRef } from "react";
import { Token } from '../../Utils'
import Axios from 'axios';
export const UploadWorship = ({notifications, OpenModal, CloseModal, fetchWorship}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [img, setImg] = useState([]);
    const [image, setImage] = useState('')
    const [Audio, setAudio] = useState('');
    const [Title, setTitle] = useState('');
    const [Artist, setArtist] = useState('');
    const [Duration, setDuration] = useState('');
    const [WorshipType, setWorshipType] = useState(null)
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UploadWorshipTranslate, setUploadWorshipTranslate] = useState('Upload Worship')
    const [DragImageTitleTranslate, setDragImageTitleTranslate] = useState('Drag images here or click to select files')
    const [DragImageDescTranslate, setDragImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [AudioFileTranslate, setAudioFileTranslate] = useState('Audio File')
    const [TitleTranslate, setTitleTranslate] = useState('Title')
    const [ArtistTranslate, setArtistTranslate] = useState('Artist')
    const [DurationTranslate, setDurationTranslate] = useState('Duration')
    const [WorshipTypeTranslate, setWorshipTypeTranslate] = useState('Worship Type')
    const [PlaceholderTitle, setPlaceholderTitle] = useState('Enter Title')
    const [PlaceholderArtist, setPlaceholderArtist] = useState('Enter Artist')
    const [PlaceholderDuration, setPlaceholderDuration] = useState('Enter Duration')
    const [PlaceholderWorshipType, setPlaceholderWorshipType] = useState('Pick One')
    const [MusicTranslate, setMusicTranslate] = useState('Music')
    const [PreachingTranslate, setPreachingTranslate] = useState('Preaching')
    const [MeditationTranslate, setMeditationTranslate] = useState('Meditation')
    const [BtnUploadWorship, setBtnUploadWorship] = useState('UPLOAD WORSHIP')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Uploading')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Upload this Audio?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadWorshipTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadWorshipTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AudioFileTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAudioFileTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ArtistTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setArtistTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DurationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDurationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + WorshipTypeTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setWorshipTypeTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderArtist,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderArtist(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDuration,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDuration(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderWorshipType,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderWorshipType(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MusicTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMusicTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PreachingTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPreachingTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MeditationTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMeditationTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadWorship,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadWorship(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    function handleImage(files){
        setImage(files[0])
    }
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleChangeArtist = (event) => {
        setArtist(event.target.value)
    }
    const handleChangeDuration = (event) => {
        setDuration(event.target.value)
    }
    const handleChangeWorshipType = (value) => {
        setWorshipType(value)
    }
    const UploadWorshipFunction = () => {
        setConfirmModal(false)
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Worship',
            message: 'Worship will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const formData = new FormData();
        formData.append('image', image)
        formData.append('music', Audio)
        Axios.post(''+APILink+'/api/Audios?title='+Title+'&artist='+Artist+'&duration='+Duration+'&category='+WorshipType+'', formData,{
            headers : {
                Authorization: 'Bearer '+Token,
                'Content-Type': 'multipart/form-data',
                'Application' : 'application/json'
            }
            }).then((res) => {
                if(res.status === 201){
                    setTimeout(() => {
                        //! RESET INPUTS
                        fetchWorship()
                        CloseModal();
                        //! END OF RESET INPUTS
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Worship was uploaded',
                            message: 'Worship successsfully Updated',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000);
                }
            }).then((error) =>{
                console.log(error)
        })
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    return(
        <>
        <Modal size = "xl" opened={OpenModal} onClose={CloseModal} title={UploadWorshipTranslate} centered>
            <Card shadow="xl" padding="lg" radius="sm" withBorder>
                <Card.Section>
                {
                    img.map((url, index) =>
                    <Image 
                        key = {index} 
                        maw={300} 
                        mx="auto"
                        alt="Event Image" 
                        src={url}
                        placeholder={<Text align="center">No Image Detected</Text>}
                    />
                    )
                }  
                </Card.Section>
                <Card.Section >
                    <Dropzone
                        onDrop={
                            (files) => {
                                handleImage(files)
                                const selectedFIles =[];
                                const targetFilesObject= [...files]
                                targetFilesObject.map((file)=>{
                                    return selectedFIles.push(URL.createObjectURL(file))
                                })
                                setImg(selectedFIles);
                            } 
                        }
                        maxSize={3 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                    >
                        <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>

                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>

                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                    {DragImageTitleTranslate}
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7} sx={{fontFamily : 'poppins'}}>
                                    {DragImageDescTranslate}
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>
                </Card.Section>
            </Card>
            <br/>
            <Divider/>
            <Group grow>
                <FileInput 
                    label={AudioFileTranslate}
                    required 
                    icon={<IconFileUpload size={rem(14)} 
                    sx={{color : 'black'}} />} 
                    value={Audio} 
                    onChange={setAudio}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group grow>
                <TextInput
                    placeholder={PlaceholderTitle}
                    label={TitleTranslate}
                    size="md"
                    withAsterisk
                    onChange={handleChangeTitle}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group grow>
                <TextInput
                    placeholder={PlaceholderArtist}
                    label={ArtistTranslate}
                    size="md"
                    withAsterisk
                    onChange={handleChangeArtist}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group grow>
                <TextInput
                    placeholder={PlaceholderDuration}
                    label={DurationTranslate}
                    size="md"
                    withAsterisk
                    onChange={handleChangeDuration}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group grow>
                <Select
                    label={WorshipTypeTranslate}
                    placeholder={PlaceholderWorshipType}
                    size="md"
                    data={[
                        { value: '0', label: MusicTranslate },
                        { value: '1', label: PreachingTranslate },
                        { value: '2', label: MeditationTranslate },
                    ]}
                    dropdownPosition="bottom"
                    transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
                    withinPortal
                    onChange={handleChangeWorshipType}
                    sx={{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {BtnUploadWorship}
                </Button>
            </Group>
        </Modal>
         <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text sx={{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UploadWorshipFunction} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
            </Group>
        </Modal>
        </>
    )
}