import React, { useState, useEffect } from "react"
import { Modal, Group, TextInput, Textarea, Button, Divider } from '@mantine/core'
import { IconUpload, IconCheck } from '@tabler/icons-react'
import { api } from '../../Utils'
import Axios from 'axios';
export const UploadBibleStory = ({OpenModal, CloseModal, notifications, fetchBibleStories}) => {
    const [YoutubeLink, setYoutubeLink] = useState("")
    const [Title, setTitle] = useState("")
    const [Description, setDescription] = useState("")
    const [GetYoutubeID, setGetYoutubeID] = useState("")
    const YoutubeLinkCount = YoutubeLink.length - 11;
    let EmbedLink = ""
    EmbedLink = 'https://www.youtube.com/embed/'+GetYoutubeID
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AddNewBibleStoryTranslate, setAddNewBibleStoryTranslate] = useState('Add New Bible Story')
    const [YoutubeLinkTranslate, setYoutubeLinkTranslate] = useState('Youtube Link')
    const [PlaceholderYoutubeLink, setPlaceholderYoutubeLink] = useState('Enter Youtube Link')
    const [TitleTranslate, setTitleTranslate] = useState('Title')
    const [PlaceholderTitle, setPlaceholderTitle] = useState('Enter Title')
    const [DescriptionTranslate, setDescriptionTranslate] = useState('Description')
    const [PlaceholderDescription, setPlaceholderDescription] = useState('Enter Description')
    const [BtnUploadBibleStory, setBtnUploadBibleStory] = useState('UPLOAD BIBLE STORY')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        FetchYoutubeID();
        fetchTranslation();
    }, [GetYoutubeID, DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddNewBibleStoryTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddNewBibleStoryTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + YoutubeLinkTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setYoutubeLinkTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderYoutubeLink,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderYoutubeLink(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DescriptionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDescriptionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDescription,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDescription(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadBibleStory,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadBibleStory(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleYoutubeLink = (event) => {
        setYoutubeLink(event.target.value)
    }
    const handleTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleDescription = (event) => {
        setDescription(event.target.value)
    }
    const FetchYoutubeID = async () => {
        setGetYoutubeID(YoutubeLink.substring(YoutubeLinkCount))
    }
    const UploadBibleStoryFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Bible Story',
            message: 'Bible Story will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const BibleStoryEP = `/BibleStories`
        await api.post(BibleStoryEP, {
            title : Title,
            description : Description,
            youtubeId : GetYoutubeID
        }).then(result => {
            if(result['status'] === 201){
                fetchBibleStories();
                setTimeout(() => {
                    setYoutubeLink("")
                    setTitle("")
                    setDescription("")
                    setGetYoutubeID("")
                    CloseModal()
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Bible Story was uploaded',
                        message: 'Bible Story successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
        })
    }
    return(
        <Modal size = "lg" opened={OpenModal} onClose={CloseModal} title={AddNewBibleStoryTranslate} centered>
            <Group grow>
                <iframe 
                    src = {EmbedLink}
                    height={280}
                    frameBorder="0" 
                    allowFullScreen>
                </iframe> 
            </Group>
            <br/>
            <Divider/>
            <br/>
            <Group grow>
                <TextInput
                    placeholder={PlaceholderYoutubeLink}
                    label={YoutubeLinkTranslate}
                    radius="xs"
                    size="md"
                    onChange={handleYoutubeLink}
                    onBlur={() => {FetchYoutubeID()}}
                    withAsterisk
                    sx = {{fontFamily : 'poppins'}}
                />
            </Group>
            <Group grow>
                <TextInput
                    placeholder={PlaceholderTitle}
                    label={TitleTranslate}
                    radius="xs"
                    size="md"
                    onChange={handleTitle}
                    withAsterisk
                    sx = {{fontFamily : 'poppins'}}
                />
            </Group>
            <Group grow>
                <Textarea
                    placeholder={PlaceholderDescription}
                    label={DescriptionTranslate}
                    radius="xs"
                    size="md"
                    onChange={handleDescription}
                    minRows={5}
                    withAsterisk
                    sx = {{fontFamily : 'poppins'}}
                />
            </Group>
            <Group mt="md" mb="xs" grow>
                <Button sx = {{fontFamily : 'poppins'}} onClick = {UploadBibleStoryFunction} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {BtnUploadBibleStory}
                </Button>
            </Group>
        </Modal>
    )
}