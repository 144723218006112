import React, { useState, useEffect } from "react";
import { Group, Badge, RingProgress, Text, Loader, Divider  } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
import ReactECharts from 'echarts-for-react';
export const TotalBibleVersions = () => {
    var sourceLang = 'en';
    const [TotalPublished, setTotalPublished] = useState(0)
    const [TotalUnpublished, setTotalUnpublished] = useState(0)
    const [loading, setLoading] = useState(false)
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [BibleVersionTitle, setBibleVersionTitle] = useState('BIBLE VERSION')
    const [TotalPublishedTitle, setTotalPublishedTitle] = useState('VERSION')
    const [UnpublishedTitle, setUnpublishedTitle] = useState('NOT PUBLISHED')
    const [PublishedTitle, setPublishedTitle] = useState('PUBLISHED')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        FetchBibleVersions();
        fetchTranslation();
    }, [DefaultLanguage]);
    const FetchBibleVersions = async() => {
        setLoading(true);  
        const BibleVersionsEP = `/BibleVersions`
        await api.get(BibleVersionsEP).then((result) => {
            setTotalPublished(result.data.filter(version => version.isReady === true))
            setTotalUnpublished(result.data.filter(version => version.isReady === false))
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BibleVersionTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBibleVersionTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TotalPublishedTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTotalPublishedTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UnpublishedTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUnpublishedTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PublishedTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPublishedTitle(translated[0])
            })
        }).then((error) =>{
        })
    }
    const options = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: [
            {
              name: 'Bible Version',
              type: 'pie',
              radius: '50%',
              data: [

                { value: TotalUnpublished.length, name: UnpublishedTitle },
                { value: TotalPublished.length, name: PublishedTitle },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
    }
    return(
        <>
        <Group position="center">
            <Text fw = {900} sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>{BibleVersionTitle}</Text>
        </Group>
        {loading ?
            <Group position="center" sx={{padding : 20}}>
                <Loader color="violet" variant="dots" size="md"/>
            </Group>
            :
            <ReactECharts option={options} />
        }
        </>
    )
}