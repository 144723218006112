
import React, { useState, useEffect, useMemo, forwardRef } from "react";
import { Modal, Card, Image, Badge, Button, Group, TextInput, Textarea, Text,
    rem, useMantineTheme } from '@mantine/core';
import Moment from 'moment';
import { IconPencil, IconCalendar, IconUpload, IconX, IconPhoto, IconCheck } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Token, api, api_multipart, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
export const UpdateWoftd = ({OpenUpdateModal, CloseUpdateModal, GetTitle, GetContent, GetImage, GetDate, GetWoftdID, fetchWordOfTheDay, notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [startDate, setStartDate] = useState(new Date());
    const [Files, setFiles] = useState(null);
    const [img, setImg] = useState([]);
    const [VerseContent, setVerseContent] = useState("");
    const PostDate = Moment(startDate).format('MM-DD-YYYY');

    //! TRANSLATION
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [UpdateWoftTitle, setUpdateWoftTitle] = useState('Update Word of the Day')
    const [DragImageTitle, setDragImageTitle] = useState('Drag images here or click to select files')
    const [DragImageDesc, setDragImageDesc] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [TitleTranslate, setTitleTranslate] = useState('Title')
    const [VerseContenTranslate, setVerseContentTranslate] = useState('Verse Content')
    const [DateTitle, setDateTitle] = useState('Select Date')
    const [ButtonUpdate, setButtonUpdate] = useState('UPDATE WORD OF THE DAY')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'));
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UpdateWoftTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUpdateWoftTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDesc,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDesc(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + VerseContenTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setVerseContentTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DateTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDateTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ButtonUpdate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setButtonUpdate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const DatePickerBtn = forwardRef(({ value, onClick }, ref) => (
        <>
            <Text sx={{fontFamily : 'poppins'}}>{DateTitle}</Text>
            <Group grow>
                <Button sx={{fontFamily : 'poppins'}} leftIcon = {<IconCalendar/>} variant="outline" color="dark" size="sm" uppercase  onClick={onClick} ref={ref} >
                    {value}
                </Button>
            </Group>
        </>
    ));

    const handleVerseContent = (event) => {
        setVerseContent(event.target.value)
    }
    const UpdateWoftdFunction  = async () => {
        let countedFile = Files.length
        const ImageArray = []
        for (let i = 0; i < countedFile; i++) {
            let data = Files[i]
            ImageArray.push(data)  
        }
        Axios({
            url : APILink+'/api/Daily/'+GetWoftdID+'?verse='+VerseContent+'&date='+PostDate+'',
            method : 'PUT',
            headers : {
                Authorization: 'Bearer '+Token,
                'Content-Type': 'multipart/form-data',
                'Application' : 'application/json'
            },
            data :{
                ImageArray
            }
        }).then(res =>{
            if(res['status'] === 204){
                fetchWordOfTheDay();
                notifications.show({
                    id: 'load-data',
                    loading: true,
                    title: 'Updating Word of the Day',
                    message: 'Word of the Day will be loaded, you cannot close this yet',
                    autoClose: false,
                    withCloseButton: false,
                    });
                    setTimeout(() => {
     
                    //! RESET INPUTS
                    CloseUpdateModal()
                    setImg([])
                    setVerseContent("")
                    setStartDate(new Date())
                    //! END OF RESET INPUTS
     
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Word of the Day was uploaded',
                        message: 'Word of the Day successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
            console.log(error)
        })   
        
    }
    return(
        <Modal size = "lg" opened={OpenUpdateModal} onClose={CloseUpdateModal} title={UpdateWoftTitle} centered>
            <Card shadow="sm" padding="lg" radius="sm" withBorder>
                <Card.Section>
                {img.map((url, index) =>
                    <Image 
                        key = {index} 
                        style = {{ padding : '30px'}} 
                        maw={340} 
                        mx="auto" 
                        radius="sm" 
                        src={url} 
                        alt="Word of The Day" 
                    />
                )}
                    <Dropzone
                        onDrop={
                            (files) => {
                                setFiles(files)
                                const selectedFIles =[];
                                const targetFilesObject= [...files]
                                targetFilesObject.map((file)=>{
                                    return selectedFIles.push(URL.createObjectURL(file))
                                })
                                setImg(selectedFIles);
                            } 
                        }
                        maxSize={3 * 1024 ** 2}
                        accept={IMAGE_MIME_TYPE}
                    >
                        <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                            <Dropzone.Accept>
                                <IconUpload
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Accept>

                            <Dropzone.Reject>
                                <IconX
                                    size="3.2rem"
                                    stroke={1.5}
                                    color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                />
                            </Dropzone.Reject>

                            <Dropzone.Idle>
                                <IconPhoto size="3.2rem" stroke={1.5} />
                            </Dropzone.Idle>

                            <div>
                                <Text size="xl" inline sx={{fontFamily : 'poppins'}}>
                                    {DragImageTitle}
                                </Text>
                                <Text size="sm" color="dimmed" inline mt={7} sx={{fontFamily : 'poppins'}}>
                                    {DragImageDesc}
                                </Text>
                            </div>
                        </Group>
                    </Dropzone>
                </Card.Section>
                <Group mt="md" mb="xs" grow>
                    <TextInput label = {TitleTranslate} defaultValue = {GetTitle} withAsterisk sx={{fontFamily : 'poppins'}}/>
                    <DatePicker  
                        selected={startDate}
                        onChange={(date) => setStartDate(date)} 
                        customInput={<DatePickerBtn />}
                    />
                </Group>
                <Textarea  onChange={handleVerseContent} label = {VerseContenTranslate} defaultValue = {GetContent} minRows={10} withAsterisk sx={{fontFamily : 'poppins'}}/>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UpdateWoftdFunction} leftIcon = {<IconPencil/>} variant="filled" color="blue" fullWidth mt="md" radius="sm" uppercase>{ButtonUpdate}</Button>
            </Card>
        </Modal>
    )
}