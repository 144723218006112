import React, { useState } from "react";
import { Button, Group, Modal, useMantineTheme, Textarea, Text, TextInput, Card,
    Image, rem, Divider
 } from '@mantine/core';
import { IconUpload, IconCheck, IconX, IconPhoto } from '@tabler/icons-react';
import { Token } from '../../Utils'
import Axios from 'axios';
export const UploadBenefits = ({notifications, OpenModal, CloseModal, fetchSubscriptionPlan, PlanID, PropsAddNewBenefitsTranslate, PropsBenefitsTranslate, PropsPlaceholderBenefits, PropsBtnUploadBenefits, PropsConfirmTitleTranslate, PropsConfirmMessageTranslate, PropsConfirmTranslate, PropsCancelTranslate}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme();
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [Benefits, setBenefits] = useState('');
    const handleChangeBenefits = (event) => {
        setBenefits(event.target.value)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const UploadBenefitsFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Benefits',
            message: 'Benefits will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        Axios.post(''+APILink+'/api/subscriptionPlans/'+PlanID+'/subscriptionBenefit?benefit='+Benefits+'',{
        headers : {
            Authorization: 'Bearer '+Token,
            'Content-Type': 'multipart/form-data',
            'Application' : 'application/json'
        }
        }).then((res) => {
            if(res.status === 200){
                setTimeout(() => {
                    //! RESET INPUTS
                    fetchSubscriptionPlan()
                    CloseModal();
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Benefits was uploaded',
                        message: 'Benefits successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        }).then((error) =>{
            console.log(error)
        })
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title={PropsAddNewBenefitsTranslate}
            size="md"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            centered
        >
            <Group grow>
                <TextInput
                    placeholder={PropsPlaceholderBenefits}
                    label={PropsBenefitsTranslate}
                    radius="xs"
                    size="md"
                    withAsterisk
                    onChange={handleChangeBenefits}
                    sx={{fontFamily : 'poppins'}}
                />
            </ Group>
            <Group mt="md" mb="xs" grow>
                <Button sx={{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                    {PropsBtnUploadBenefits}
                </Button>
            </Group>
        </Modal>
        <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={PropsConfirmTitleTranslate} centered>
            <Group>
                <Group grow>
                    <Text sx={{fontFamily : 'poppins'}}>{PropsConfirmMessageTranslate}</Text>
                </Group>
                
            </Group>
            <br/>
            <Group position='right'>
                <Button sx={{fontFamily : 'poppins'}} onClick = {UploadBenefitsFunction} color="teal" size="sm" uppercase> {PropsConfirmTranslate} </Button>
                <Button sx={{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {PropsCancelTranslate} </Button>
            </Group>
        </Modal>
        </>
    )

}