import { motion } from 'framer-motion'
import { IconUpload, IconSettingsQuestion, IconCheck } from '@tabler/icons-react';
import LandscapeLogo from '../../Assets/images/logo/theone-default.png'
import { useState, useEffect } from 'react'
import { notifications } from '@mantine/notifications';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { createStyles, Card, Image, Button, Group, TextInput, Textarea, Tooltip } from '@mantine/core'
import { Base64 } from 'js-base64';
import { useLocation } from 'react-router-dom'
import Axios from 'axios';
export const Announcement = () => {
    const [GetVerseContent, setGetVerseContent] = useState("")
    const location = useLocation()
    const [Title, setTitle] = useState("");
    const [Description, setDescription] = useState("");
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [AnnouncementTitleTranslate, setAnnouncementTitleTranslate] = useState('Announcement Title')
    const [PlaceHolderAnnouncement, setPlaceHolderAnnouncement] = useState('enter announcement title')
    const [AnnouncementDescTranslate, setAnnouncementDescTranslate] = useState('Announcement Description')
    const [PlaceHolderAnnouncementDesc, setPlaceHolderAnnouncementDesc] = useState('enter announcement description')
    const [BtnUploadTranslate, setBtnUploadTranslate] = useState('Upload Announcement')
    const [TooltipTranslate, setTooltipTranslate] = useState('"Upload announcement & all user will notify')

    const [NotifUploadTranslate, setNotifUploadTranslate] = useState('Uploading your announcement')
    const [NotifUploadMessageTranslante, setNotifUploadMessageTranslante] = useState('Announcement will be loaded, you cannot close this yet')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        if(location.state === null){}else{
            const { content } = location.state
            setGetVerseContent(content)
        }
        fetchTranslation()
    }, [GetVerseContent, DefaultLanguage]);
    const handleChangeTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }
    const UploadAnnouncement = async () => {
        if(Title != '' & Description != ''){
            const AnnounceEP = `/Notifications/?title=`+Title+`&body=`+Description+``
            await api.post(AnnounceEP).then((result) => {
                if(result['status'] === 200){
                    notifications.show({
                        id: 'load-data',
                        loading: true,
                        title: NotifUploadTranslate,
                        message: NotifUploadMessageTranslante,
                        autoClose: false,
                        withCloseButton: false,
                      });
                      setTimeout(() => {
                        document.getElementById("input_title").value = ""
                        document.getElementById("input_desc").value = ""
                        setGetVerseContent("")
                        notifications.update({
                          id: 'load-data',
                          color: 'teal',
                          title: 'Announcement was uploaded',
                          message: 'Announcment successsfully uploaded',
                          icon: <IconCheck size="1rem" />,
                          autoClose: 2000,
                        });
                    }, 3000);
                }else{}
            }).catch(error => {
                const Response = error.response.status
                if(Response === 401){
                    GetNewToken(Token, RefreshToken)
                }
            })   
        }
    }
    const useStyles = createStyles((theme) => ({
        inner: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.spacing.xl * 3,
            paddingBottom: theme.spacing.xl * 3,
  
            [theme.fn.smallerThan('md')]: {
                paddingTop: theme.spacing.xl * 1,
                paddingTop: theme.spacing.xl * 1,
            }, 
        },
        content: {
            maxWidth: 480,
            [theme.fn.smallerThan('md')]: {
                maxWidth: '100%',
            },
        },
        control: {
            [theme.fn.smallerThan('md')]: {
                flex: 1,
            },
        }
    }));
    const { classes } = useStyles();
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AnnouncementTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAnnouncementTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceHolderAnnouncement,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceHolderAnnouncement(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AnnouncementDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAnnouncementDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceHolderAnnouncementDesc,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceHolderAnnouncementDesc(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TooltipTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTooltipTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NotifUploadTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNotifUploadTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + NotifUploadMessageTranslante,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setNotifUploadMessageTranslante(translated[0])
            })
        }).then((error) =>{
        }) 
    }
    return (
        <div>
            <motion.div  className={classes.inner} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Card shadow="xl" padding="lg" radius="md" style = {{width : '500px'}} >
                    <Card.Section>
                        <Group position="right">
                            <Tooltip label={TooltipTranslate} color="violet" position="top-start" withArrow arrowPosition="center" >
                                <IconSettingsQuestion style = {{position : 'absolute', zIndex : '100', top : '5px', right : '5px', color : 'white'}}/>
                            </Tooltip>
                        </Group>
                        <Image
                        src={LandscapeLogo}
                        height={230}
                        alt="Norway"
                        />
                    </Card.Section>
                    <div style={{margin : '20px'}}> 
                        <Group grow>
                            <TextInput
                                placeholder={PlaceHolderAnnouncement}
                                label={AnnouncementTitleTranslate}
                                radius="md"
                                size="md"
                                onChange={handleChangeTitle}
                                withAsterisk
                                id = "input_title"
                                sx={{fontFamily : 'poppins'}}
                            />
                        </Group>
                        <br></br>
                        <Group grow>
                            <Textarea
                                placeholder={PlaceHolderAnnouncementDesc}
                                label={AnnouncementDescTranslate}
                                radius="md"
                                size="md"
                                minRows={10}
                                maxRows={50}
                                onChange={handleChangeDescription}
                                withAsterisk
                                defaultValue={Base64.decode(GetVerseContent)}
                                id = "input_desc"
                                sx={{fontFamily : 'poppins'}}
                            />
                        </Group>
                        <br></br>
                        <Group grow>
                            <Button sx={{fontFamily : 'poppins'}} onClick={() => {UploadAnnouncement()}} leftIcon = {<IconUpload/>}color="violet" radius="xl" size="md" uppercase>
                                {BtnUploadTranslate}
                            </Button>
                        </Group>
                    </div>
                </Card>
            </motion.div>
        </div>
    )
}