import { notifications } from '@mantine/notifications';
import { ListofBibleStories } from '../../Components/DataTables/ListofBibleStories';
export const BibleStories = () => {
    return (
        <>
            <ListofBibleStories
                notifications = {notifications}
            />
        </>
    )
}