import React, { useState, useEffect } from "react";
import { Token, api, RefreshToken, GetNewToken, TextCleaner } from '../../Utils'
import { Modal, Group, Button, ActionIcon, Paper, Blockquote, Title, Divider, Tooltip, Text, List, CloseButton, Textarea, TextInput  } from '@mantine/core'
import { IconX, IconArrowLeft, IconSpeakerphone, IconBible, IconCheck, IconPencil } from '@tabler/icons-react';
import { Base64 } from 'js-base64';
import { Link } from "react-router-dom";
export const ViewVerseModal = ({OpenVerseModal, CloseVerseModal, SelectedChapterID, SelectedToVersion, SelectedBookName, SelectedChapterNumber, ReOpenModal, ReOpenModalChapter, notifications}) => {
    const [BibleVerse, setBibleVerse] = useState([]);
    const [UpdateVerseModal, setUpdateVerseModal] = useState(false)
    const [GetVerseID, setGetVerseID] = useState('')
    const [GetVerseContent, setGetVerseContent] = useState('')
    const [GetVerseNumber, setGetVerseNumber] = useState('')
    const [GetVerseIsTitle, setGetVerseIsTitle] = useState(false)
    const [ToUpdateVerse, setToUpdateVerse] = useState('')
    const [ToUpdateVerseNumber, setToUpdateVerseNumber] = useState('')
    useEffect(() => {
        if(SelectedChapterID === ''){}else{FetchBibleVerse();}
    }, [SelectedChapterID]);
    const FetchBibleVerse = () => {
        const ListVerseEP = `/Verses?chapterId=`+SelectedChapterID+``
        api.get(ListVerseEP).then((result) => {
            setBibleVerse(result.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){GetNewToken(Token, RefreshToken) }
            if(Response === 404){}
        })
    }
    //! REMOVE FUNCTION
    const RemoveVerse = async(VerseID) => {
        const VerseEP = `/Verses/`+VerseID+``
            await api.delete(VerseEP, {
            }).then(result => {
                if(result['status'] === 204){
                    notifications.show({
                        id: 'load-data',
                        loading: true,
                        title: 'Removing Verse',
                        message: 'Verse will be deleted, you cannot close this yet',
                        autoClose: false,
                        withCloseButton: false,
                        });
                        setTimeout(() => {
                        FetchBibleVerse();
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Verse was deleted',
                            message: 'Verse successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
        })
    }
    //! UPDATE FUNCTION
    const UpdateVerse = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Bible Verse',
            message: 'Bible Verse will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UpdateVerseEP = `/Verses/`+GetVerseID+``
        api.put(UpdateVerseEP, {
            title: ToUpdateVerse ? ToUpdateVerse : GetVerseContent,
            verseNumber: ToUpdateVerseNumber ? ToUpdateVerseNumber : GetVerseNumber,
            verseContent: ToUpdateVerse ? ToUpdateVerse : GetVerseContent,
            isTitle : GetVerseIsTitle
        }).then(result => {
        if(result['status'] === 204){
            setTimeout(() => {
                //! RESET INPUTS
                FetchBibleVerse();
                ReOpenModal();
                setToUpdateVerse('');
                setToUpdateVerseNumber('');
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Bible Verse was updating',
                    message: 'Bible Verse successsfully updated',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
      
    }
    const OpenModalUpdate = (VerseID, VerseContent, VerseNumber, VerseisTitle ) => {
        setGetVerseID(VerseID)
        setUpdateVerseModal(true)
        setGetVerseContent(VerseContent)
        setGetVerseNumber(VerseNumber)
        setGetVerseIsTitle(VerseisTitle)
        CloseVerseModal(false)
    }
    const handleVerseContent = (event) => {
        setToUpdateVerse(event.target.value)
    }
    const handleVerseNumber = (event) => {
        setToUpdateVerseNumber(event.target.value)
    }
    return(
    <>
        <Modal size='md' opened={UpdateVerseModal} onClose={() => {setUpdateVerseModal(false)}}  title = "Update Verse" centered>
            <Group grow>
                <TextInput onChange={handleVerseNumber} label = "Verse Number" defaultValue={GetVerseNumber} withAsterisk/>
            </Group>
            <Group grow>
                <Textarea  onChange={handleVerseContent} label = "Verse" defaultValue = {GetVerseContent} minRows={10} withAsterisk/>
            </Group>
            <Button onClick = {UpdateVerse} leftIcon = {<IconPencil/>} variant="filled" color="violet" fullWidth mt="md" radius="sm" uppercase>
                Update Verse
            </Button>
        </Modal>
        <Modal size = "70%" opened={OpenVerseModal} onClose={CloseVerseModal} title={
            <ActionIcon onClick = {() => {ReOpenModalChapter(); CloseVerseModal()}} size="lg" color="violet" radius="xl" variant="light" >
                <IconArrowLeft size={30} />
            </ActionIcon>
        }>
            <Text align="center" fw={700} color="black" sx={{fontSize: 35, fontFamily : 'poppins'}}>Chapter {SelectedChapterNumber}</Text>
            <Divider></Divider>
            <br/>
            <List
                spacing="xs"
                size="sm"
                center
            >
                {BibleVerse.map((verse, index) =>
                    <Group position="apart">
                        <Group sx={{width : '95%'}} className="EventContent">
                            {verse.isTitle ? 
                                <List.Item
                                    sx={{
                                        cursor : 'pointer',
                                        padding : 3,
                                        ":hover" : {
                                            background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)',
                                            borderRadius : 2,
                                            padding : 3
                                            
                                        }
                                    }}
                                    onClick={() => {OpenModalUpdate(verse.id, verse.title, verse.verseNumber, verse.isTitle)}}
                                >
                                    <Text sx = {{fontFamily : 'poppins'}} fw={800} fz='xl'>{ verse.title}</Text>
                                </List.Item>
                                : 
                                <List.Item
                                    key = {index}
                                    icon = {
                                        <ActionIcon color="violet" size="lg" radius="xl" variant="filled" sx={{background : '#4a1e9e',  ":hover" : {
                                            background : '#6c2be2'
                                        }}}>
                                            {verse.verseNumber}
                                        </ActionIcon> 
                                    }
                                    sx={{
                                        cursor : 'pointer',
                                        padding : 3,
                                        ":hover" : {
                                            background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)',
                                            borderRadius : 2,
                                            padding : 3
                                             
                                        }
                                    }}
                                    onClick={() => {OpenModalUpdate(verse.id, verse.verseContent, verse.verseNumber, verse.isTitle)}}
                                >
                                    <Text sx = {{fontFamily : 'poppins'}} fw={500} fz='md'>{ verse.verseContent}</Text>
                                </List.Item>
                            }
                           
                        </Group>
                        <Group>
                            <CloseButton size="lg" iconSize={20} onClick={() => {RemoveVerse(verse.id)}}/>
                        </Group>
                    </Group>
                )}
            </List>
        </Modal>
    </>
    )
}