import React, { useState, useEffect } from "react";
import { Token, api, RefreshToken, GetNewToken, TextCleaner } from '../../Utils'
import { Modal, Group, Button, ActionIcon, Title, Divider, Text } from '@mantine/core'
import { IconX, IconCheck } from '@tabler/icons-react';
import { ViewVerseModal } from "./ViewVerseModal";
import Axios from 'axios'; 
export const ViewChapterModal = ({OpenChapterModal, CloseChapterModal, SelectedBookID, SelectedToVersion, SelectedBookName, ReOpenModalChapter, notifications}) => {
    const [BibleChapter, setBibleChapter] = useState([]);
    const [OpenViewVerseModal, setOpenViewVerseModal] = useState(false);
    const [SelectedChapterID, setSelectedChapterID] = useState("")
    const [SelectedChapterNumber, setSelectedChapterNumber] = useState("")
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [ChapterNumberTranslate, setChapterNumberTranslate] = useState('CHAPTER NUMBER')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        if(SelectedBookID === ''){}else{FetchBibleChapter();}
        fetchTranslation();
    }, [SelectedBookID, DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ChapterNumberTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChapterNumberTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const FetchBibleChapter = () => {
        const ListChapterEP = `/Chapters?bookId=`+SelectedBookID+``
        api.get(ListChapterEP).then((result) => {
            setBibleChapter(result.data)
            
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){GetNewToken(Token, RefreshToken) }
            if(Response === 404){}
        })
    }
    const ViewOpenVerse = (ChapterID, ChapterNumber) => {
        setOpenViewVerseModal(true)
        CloseChapterModal(false)
        setSelectedChapterID(ChapterID)
        if(ChapterNumber == 0){
            setSelectedChapterNumber("Intro")
        }else{
            setSelectedChapterNumber(ChapterNumber)
        }
    }
    const RemoveChapter = async (ChapterID) => {
        const ChapterEP = `/Chapters/`+ChapterID+``
            await api.delete(ChapterEP, {
            }).then(result => {
                if(result['status'] === 204){
                    notifications.show({
                        id: 'load-data',
                        loading: true,
                        title: 'Removing Chapter',
                        message: 'Chapter will be deleted, you cannot close this yet',
                        autoClose: false,
                        withCloseButton: false,
                        });
                        setTimeout(() => {
                        FetchBibleChapter();
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Chapter was deleted',
                            message: 'Chapter successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
                notifications.show({
                    id: 'load-data',
                    title: 'you can"t delete this Chapter Number',
                    message: 'this chapter has a bible verse already uploaded, you need to remove bible verse first.',
                    color : 'red',
                    icon : <IconX/>
                });
                setTimeout(() => {}, 1000)
        })
    }
    return(
        <>
        <ViewVerseModal
            OpenVerseModal = {OpenViewVerseModal}
            CloseVerseModal = {() => {setOpenViewVerseModal(false)}}
            ReOpenModal = {() => {setOpenViewVerseModal(true)}}
            ReOpenModalChapter = {ReOpenModalChapter}
            SelectedChapterID = {SelectedChapterID}
            SelectedToVersion = {SelectedToVersion}
            SelectedBookName = {SelectedBookName}
            SelectedChapterNumber = {SelectedChapterNumber}
            notifications = {notifications}
        />
        <Modal size = "xl" opened={OpenChapterModal} onClose={CloseChapterModal} centered title = {
            <Title order={3} weight={700} align="center">
                {ChapterNumberTranslate}
            </Title>
        }>
            <Text align="center" fw={800} color="black" sx={{fontSize: 35, fontFamily : 'poppins'}}>{SelectedBookName}</Text>
            <Divider></Divider>
            <Group sx = {{padding : '20px'}} position='center' spacing="md" >
                {BibleChapter.map((chapter, index) =>
                    <div key = {index} className='ChapterBtn' style = {{position : 'relative'}} >
                        <ActionIcon 
                            color="dark" 
                            size="md" 
                            radius="xl"
                            variant="filled"
                            style={{
                                zIndex : '100',
                                position : 'absolute',
                                top : '8%',
                                left : '90%',
                                transform : 'translate(-50%, -50%)'
                            }}
                            onClick={() => {RemoveChapter(chapter.id)}}
                        >
                            <IconX size="1.625rem"  onClick={() => {RemoveChapter(chapter.id)}} />
                        </ActionIcon>
                        <Button
                            style = {{width : '80px', height : '70px', fontFamily : 'poppins'}}
                            variant="gradient"
                            gradient={{ from: 'purple', to: 'darkblue' }}
                            onClick = {() => {ViewOpenVerse(chapter.id, chapter.chapterNumber)}}
                        >
                            {chapter.chapterNumber === 0 ?
                                'Intro'
                                :
                                chapter.chapterNumber
                            }
                        </Button>  
                    </div>
                )}
            </Group>
        </Modal>
        </>
    )
}