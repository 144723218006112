import React, { useState, useEffect } from "react";
import { Modal, Group, Textarea, Button, Card, Text, Stack } from '@mantine/core'
import { IconUpload, IconCheck, IconLanguage } from "@tabler/icons-react";
import { Token, api, api_multipart, RefreshToken, GetNewToken } from '../../Utils'
import Axios from 'axios';
import { LanguageCard } from '../LanguageCardContent/LanguageCardDirection'
export const UploadDirection = ({OpenModal, CloseModal, notifications, fetchDailyDevotion, fetchComment, ID, PropsAddNewDiresctionTranslate, PropsDirectionTranslate, PropsPlaceholderDirection, PropsBtnUploadDirection}) => {
    const [Direction, setDirection] = useState("");
    var sourceLang = 'en';
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [TranslateLoading, setTranslateLoading] = useState(false)
    let objTranslated = {}
    let ArrayTranslated = []
    useEffect(() => {
        fetchLanguages();
    }, []);
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const handleChangeDirection = (event) => {
        setDirection(event.target.value)
    }
    const UploadDirectionFunction = async () => {
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };  
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Direction',
            message: 'Direction will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const UploadEP = `/DailyDevotions/`+ID+`/Directions?direction=`+Direction+``
        await api.post(UploadEP,[],{
        }).then(result => {
            LanguageTranslated.map((item) => {
                var LocalizationData = [{
                    "direction": item.direction,
                    "language": item.language
                }];
                const UploadLocalizationEP = `/DailyDevotions/directions/`+result.data.id+`/localizations`
                api.post(UploadLocalizationEP, LocalizationData, axiosConfig,{
                }).then((res) => {
                   console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
            })
            if(result['status'] === 200){
                setTimeout(() => {
                    fetchDailyDevotion();
                    fetchComment();
                    CloseModal();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Direction was uploaded',
                        message: 'Direction successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedDirection = ''
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": Direction},
                ]
            }).then((result) => {
                GetTranslatedDirection = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedDirection = gettranslation.text
                    })
                ))
                objTranslated = {}
                objTranslated['language'] = code.languageCode
                objTranslated['direction'] = GetTranslatedDirection
                ArrayTranslated.push(objTranslated)
                setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
            }).catch((error) => { 
            })
            setTranslateLoading(false)
        }   
    }
    return(
        <>
        <Modal opened={OpenModal} onClose={CloseModal} size="100%" centered>
            <Group position="center" align='start'>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Group position = "center">
                        <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>{PropsAddNewDiresctionTranslate}</Text>
                    </Group>
                    <Group grow>
                        <Textarea
                            placeholder={PropsPlaceholderDirection}
                            label={PropsDirectionTranslate}
                            radius="md"
                            size="md"
                            withAsterisk
                            onChange={handleChangeDirection}
                            minRows={15}
                        />
                    </Group>
                    <br></br>
                    <Group grow>
                        <Button onClick = {UploadDirectionFunction} leftIcon = {<IconUpload/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            {PropsBtnUploadDirection}
                        </Button>
                        <Button onClick={TranslateSelectedLanguages} leftIcon = {<IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                            Translate
                        </Button>
                    </Group>
                </Card>
                <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                    <Stack justify="flex-start">
                        <Group position = "center">
                            <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>TRANSLATED CONTENT</Text>
                        </Group>
                        <LanguageCard
                            LanguageTranslated = {LanguageTranslated}
                            TranslateLoading = {TranslateLoading}
                        />
                    </Stack>
                </Card>
            </Group>
        </Modal>
        </>
    )
}