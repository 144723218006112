import React, { useState } from "react";
import { Modal, useMantineTheme } from '@mantine/core';
import { api } from '../../Utils'
import { IconUpload, IconCheck, IconFileImport } from '@tabler/icons-react';
import { FileInput, rem, Group, Button } from '@mantine/core';
export const UploadBatchQuestion = ({notifications, OpenModal, CloseModal, fetchQuestions}) => {
    const theme = useMantineTheme();
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const fileReader = new FileReader();
    const handleOnChange = (e) => {
        setFile(e);
    };
    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        const array = csvRows.map(i => {
        const values = i.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
            object[header] = values[index];
            return object;
        }, {});
        return obj;
        });
        setArray(array);
    };
    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (file) {
            fileReader.onload = function (event) {
            const text = event.target.result;
            csvFileToArray(text);
            };
            fileReader.readAsText(file);
        }
    };
    const headerKeys = Object.keys(Object.assign({}, ...array));
 
    const UploadBatchFunction = () => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Question',
            message: 'Question will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        array.map((value) =>{
            const UploadEP = `/questions`
            api.post(UploadEP, {
                text : value.question,
                type : value.question_type,
                difficulty : value.difficulty,
                answer : value.answer,
            }).then(result => {
                if(result['status'] === 201){
                    if(value.question_type === '1'){
                        const ChoicesEP = `/questions/`+result.data['id']+`/choices?answer=`+value.choice_1+``
                        api.post(ChoicesEP,{
                        }).then(resultChoice1 => {
                            const ChoicesEP = `/questions/`+result.data['id']+`/choices?answer=`+value.choice_2+``
                            api.post(ChoicesEP,{
                            }).then(resultChoice2 => {
                                const ChoicesEP = `/questions/`+result.data['id']+`/choices?answer=`+value.choice_3+``
                                api.post(ChoicesEP,{
                                }).then(resultChoice3 => {
                                    if(resultChoice3['status'] === 200){
                                        setTimeout(() => {
                                            CloseModal();
                                            fetchQuestions();
                                            notifications.update({
                                                id: 'load-data',
                                                color: 'teal',
                                                title: 'Question was uploaded',
                                                message: 'Question successsfully uploaded',
                                                icon: <IconCheck size="1rem" />,
                                                autoClose: 2000,
                                            });
                                        }, 1000); 
                                    }else{}
                                }).catch(error =>{})
                            }).catch(error =>{})
                        }).catch(error =>{})
                    }else{
                        let OtherChoice = ""
                        if(value.answer === value.tf_choice_1){
                            OtherChoice = value.tf_choice_2
                        }else{
                            OtherChoice = value.tf_choice_1
                        }
                        const ChoicesEP = `/questions/`+result.data['id']+`/choices?answer=`+OtherChoice+``
                        api.post(ChoicesEP,{
                        }).then(resultChoice => {
                            if(resultChoice['status'] === 200){
                                notifications.show({
                                    id: 'load-data',
                                    loading: true,
                                    title: 'Upload Question',
                                    message: 'Question will be loaded, you cannot close this yet',
                                    autoClose: false,
                                    withCloseButton: false,
                                    });
                                    setTimeout(() => {
                                    CloseModal();
                                    fetchQuestions();
                                    localStorage.removeItem('value')
                                    notifications.update({
                                        id: 'load-data',
                                        color: 'teal',
                                        title: 'Question was uploaded',
                                        message: 'Question successsfully uploaded',
                                        icon: <IconCheck size="1rem" />,
                                        autoClose: 2000,
                                    });
                                }, 1000); 
                            }else{}
                        }).catch(error =>{})
                    }
                }else{}
            }).catch(error =>{
                console.log(error)
            })
        })
    }
    return(
        <>
        <Modal 
            opened={OpenModal}
            onClose={CloseModal}
            title="Add Batch of Questions" 
            
            size="xl"
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
        >
  
        <form>
            <Group grow>
                <FileInput 
                    placeholder="input csv file here" 
                    icon={<IconUpload size={rem(14)}/>} 
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                />
            </Group>
            <br/>
            <Group grow>
                <Button left = {<IconFileImport/>} onClick ={(e) => {handleOnSubmit(e); UploadBatchFunction();}}>UPLOAD</Button>
            </Group>
        </form>
        <br />
        <table>
            <thead>
            <tr key={"header"}>
                {headerKeys.map((key, index) => (
                    <th key = {index}>{key}</th>
                ))}
            </tr>
            </thead>
            <tbody>
                {array.map((item, index) => (
                    <tr key={index}>
                    {Object.values(item).map((val, index) => (
                        <td key={index}>{val}</td>
                    ))}
                    </tr>
                ))}
            </tbody>
        </table>
        </Modal>
        </>
    )
}