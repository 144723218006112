import React, { useState, useEffect } from "react";
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconPlus } from '@tabler/icons-react';
import { TextInput, Group, Text, ActionIcon, Tooltip, Card, List, ThemeIcon, Grid, Modal, Button, Stack, Avatar } from '@mantine/core'
import { UploadSubscriptionPlan, UploadBenefits  } from "../Component";
import star from '../../Assets/images/logo/star.png'
import crown from '../../Assets/images/logo/crown.png'
import Axios from 'axios';
export const ListofSubscriptionPlan = ({notifications}) => {
    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [OpenUploadPlan, setOpenUploadPlan] = useState(false);
    const [OpenUploadBenefits, setOpenUploadBenefits] = useState(false);
    const [PlanID, setPlanID] = useState("")
    const [BenefitID, setBenefitID] = useState("")
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [ConfirmModalBenefit, setConfirmModalBenefit] = useState(false);
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SearchSubscriptionPlanTranslate" : 'Search Subscription Plan',
        "CreateSubscriptionPlanTranslate" : 'Create Subscription Plan',
        "AddBenefitsTranslate" : 'ADD BENEFITS',
        "AddNewSubscriptionPlanTranslate" : 'Add New Subscription Plan',
        "PlanNameTranslate" : 'Plan Name',
        "PlaceholderPlanName" : 'Enter Plan Name',
        "PlanPriceTranslate" : 'Plan Price',
        "PlaceholderPlanPrice" : 'Enter Plan Price',
        "PlanDurationTranslate" : 'Plan Duration',
        "PlaceholderPlanDuration" : 'Enter Plan Duration',
        "BtnUploadPlan" : 'UPLOAD PLAN',
        "ConfirmTitleTranslate" : 'Confirm Uploading',
        "ConfirmTitle1Translate" : 'Confirm Deleting',
        "ConfirmMessageTranslate" : 'Are you sure you wan to Upload this Plan ? ',
        "ConfirmMessage1Translate" : 'Are you sure you wan to Upload this Benefits ? ',
        "ConfirmMessage2Translate" : 'Are you sure you wan to Delete this Plan ? ',
        "ConfirmMessage3Translate" : 'Are you sure you wan to Delete this Benefits ? ',
        "ConfirmTranslate" : 'Cofirm',
        "CancelTranslate" : 'Cancel',
        "AddNewBenefitsTranslate" : 'Add New Benefits',
        "BenefitsTranslate" : 'Benefits',
        "PlaceholderBenefits" : 'Enter Benefits',
        "BtnUploadBenefits" : 'UPLOAD BENEFITS',
        "RemovePlanTranslate" : 'Click to Remove Plan'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchSubscriptionPlan();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchSubscriptionPlan = async() => {
        setLoading(true);   
        const SubscriptionPlanEP = `/subscriptionPlans`
        await api.get(SubscriptionPlanEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CallConfirmModal = async(ID) => {
        setConfirmModal(true)
        setPlanID(ID)
    }
    const CallConfirmModalBenefit = async(ID,BID) => {
        setConfirmModalBenefit(true)
        setPlanID(ID)
        setBenefitID(BID)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
        setConfirmModalBenefit(false)
    }
    const DeletePlan = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Plan',
            message: 'Plan will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const BlogsEP = `/subscriptionPlans/`+PlanID+``
        await api.delete(BlogsEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchSubscriptionPlan();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Plan was deleted',
                        message: 'Plan successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const DeleteBenefit = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Benefit',
            message: 'Benefit will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModalBenefit(false);
        const BlogsEP = `/subscriptionPlans/`+PlanID+`/subscriptionBenefit/`+BenefitID+``
        await api.delete(BlogsEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchSubscriptionPlan();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Benefit was deleted',
                        message: 'Benefit successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const GetUploadBenefits = async(ID) => {
        setPlanID(ID)
        setOpenUploadBenefits(true)
    }
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group >
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchSubscriptionPlanTranslate}
                    onChange={ handleSearch }
                />
                <Tooltip
                    label={DataTranslate.CreateSubscriptionPlanTranslate}
                    sx={{background : '#6c2be2'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e', transition: '0.3s',  ":hover" : {
                            background : '#6c2be2'
                            
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadPlan(true)}}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>  
            </Group>
            <br></br> 
            <Grid gutter="xs" grow>
            {Data.filter((SearchPlan) => {
            if(search === ""){
                return SearchPlan
            }else if(SearchPlan.name.toLowerCase().includes(search.toLowerCase())){
                return SearchPlan
            }
            }).map((plan, index) =>
                 <div key = {index} style={{width : '20%'}} className="PlanContent">
                    <Grid.Col> 
                        <Card padding="xl" radius="lg" withBorder sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}}>
                            <Card.Section>
                                <Group sx={{background : '#6c2be2', padding : '5px'}} position="apart">
                                    <Group spacing={10}>
                                        {
                                            plan.name === "Premium" ? 
                                            <Avatar src={crown}/>
                                            :
                                            <Avatar src={star}/>
                                        }
                                        
                                        <Text  sx={{fontFamily : 'poppins'}} fw={900} fz={30} color="white">{plan.name}</Text>
                                    </Group>
                                    <Group>
                                        <Tooltip
                                            label={DataTranslate.RemovePlanTranslate}
                                            sx={{background : '#4a1e9e'}}
                                            withArrow
                                        >
                                            <ActionIcon 
                                                sx={{color : '#4a1e9e'}}
                                                size="lg" 
                                                radius="xl" 
                                                variant="light"
                                                onClick={() => {CallConfirmModal(plan.id)}}
                                            >
                                                <IconTrash />
                                            </ActionIcon>
                                        </Tooltip>
                                    </Group>
                                </Group>
                            </Card.Section>
                            <Stack align="center" spacing="xs">
                                <Text fw={900} fz={50} sx={{color : '#4a1e9e', fontFamily : 'poppins'}}>₱{plan.price}.00</Text>
                                <Text fw={800} fz={25} sx={{color : '#4a1e9e', fontFamily : 'poppins'}}>{
                                    plan.durationInMonths > 1 ?
                                    plan.durationInMonths + ' Months'
                                    :
                                    plan.durationInMonths + ' Month'
                                }</Text>
                            </Stack>
                            <List
                                spacing="xs"
                                size="xl"
                                icon={
                                    <ThemeIcon sx={{background : '#4a1e9e'}} size={24} radius="xl">
                                        <IconCheck size="1rem" stroke={4} />
                                    </ThemeIcon>
                                }
                            >
                                {plan.benefits.map((benefits, index) =>
                                    <List.Item 
                                        sx={{":hover" : {background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)'}, cursor : 'pointer', borderRadius : '2px', padding : '3px'}}
                                        onClick={() => {CallConfirmModalBenefit(plan.id, benefits.id )}}
                                        key = {index}
                                    >
                                        <Text fw={600} fz={20} lineClamp={1} sx={{color : '#4a1e9e', fontFamily : 'poppins'}}>{benefits.benefit}</Text>
                                    </List.Item>
                                )}      
                            </List>
                            <Group mt="md" mb="xs" grow>
                                <Button sx={{fontFamily : 'poppins'}} onClick={() => {GetUploadBenefits(plan.id)}} leftIcon = {<IconPlus/>} sx={{background : '#4a1e9e',  ":hover" : {background : '#6c2be2'}}} size="md" radius="xl" uppercase>
                                    {DataTranslate.AddBenefitsTranslate}
                                </Button>
                            </Group>
                        </Card>
                    </Grid.Col>
                </div>
            )} 
            </Grid>
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={DataTranslate.ConfirmTitle1Translate} centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>{DataTranslate.ConfirmMessage2Translate}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {DeletePlan} color="teal" size="sm" uppercase> {DataTranslate.ConfirmTranslate} </Button>
                    <Button sx={{fontFamily : 'poppins'}}onClick = {CancelConfirmation} color="red" size="sm" uppercase> {DataTranslate.CancelTranslate} </Button>
                </Group>
            </Modal>
            <Modal opened={ConfirmModalBenefit} onClose={() => {setConfirmModalBenefit(false)}} title={DataTranslate.ConfirmTitle1Translate} centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>{DataTranslate.ConfirmMessage3Translate}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {DeleteBenefit} color="teal" size="sm" uppercase> {DataTranslate.ConfirmTranslate} </Button>
                    <Button sx={{fontFamily : 'poppins'}}onClick = {CancelConfirmation} color="red" size="sm" uppercase> {DataTranslate.CancelTranslate} </Button>
                </Group>
            </Modal>
            <UploadSubscriptionPlan
                notifications={notifications}
                OpenModal={OpenUploadPlan}
                CloseModal={() => {setOpenUploadPlan(false)}}
                fetchSubscriptionPlan = {fetchSubscriptionPlan}
                PropsAddNewSubscriptionPlanTranslate = {DataTranslate.AddNewSubscriptionPlanTranslate}
                PropsPlanNameTranslate = {DataTranslate.PlanNameTranslate}
                PropsPlaceholderPlanName = {DataTranslate.PlaceholderPlanName}
                PropsPlanPriceTranslate = {DataTranslate.PlanPriceTranslate}
                PropsPlaceholderPlanPrice = {DataTranslate.PlaceholderPlanPrice}
                PropsPlanDurationTranslate = {DataTranslate.PlanDurationTranslate}
                PropsPlaceholderPlanDuration = {DataTranslate.PlaceholderPlanDuration}
                PropsBtnUploadPlan = {DataTranslate.BtnUploadPlan}
                PropsConfirmTitleTranslate = {DataTranslate.ConfirmTitleTranslate}
                PropsConfirmMessageTranslate = {DataTranslate.ConfirmMessageTranslate}
                PropsConfirmTranslate = {DataTranslate.ConfirmTranslate}
                PropsCancelTranslate = {DataTranslate.CancelTranslate}
            />
            <UploadBenefits
                notifications={notifications}
                OpenModal={OpenUploadBenefits}
                CloseModal={() => {setOpenUploadBenefits(false)}}
                fetchSubscriptionPlan = {fetchSubscriptionPlan}
                PlanID = {PlanID}
                PropsAddNewBenefitsTranslate = {DataTranslate.AddNewBenefitsTranslate}
                PropsBenefitsTranslate = {DataTranslate.BenefitsTranslate}
                PropsPlaceholderBenefits = {DataTranslate.PlaceholderBenefits}
                PropsBtnUploadBenefits = {DataTranslate.BtnUploadBenefits}
                PropsConfirmTitleTranslate = {DataTranslate.ConfirmTitleTranslate}
                PropsConfirmMessageTranslate = {DataTranslate.ConfirmMessage1Translate}
                PropsConfirmTranslate = {DataTranslate.ConfirmTranslate}
                PropsCancelTranslate = {DataTranslate.CancelTranslate}
            />
        </div>
    )
}