import React, { useState, useEffect } from "react";
import { Button, TextInput, Group, Tooltip, ActionIcon, Modal, Text, Stack, Menu, Grid, Card, Image, Badge, rem, useMantineTheme, Textarea, Drawer, Divider, List} from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconPlus, IconDots, IconUpload, IconX, IconPhoto, IconTrashFilled, IconDotsVertical, IconEdit, IconEye, IconLanguage } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import Axios from 'axios';
import DataTable from 'react-data-table-component';
import { LanguageCardReadingPlan } from '../LanguageCardContent/LanguageCardReadingPlan';
export const ListofReadingPlan = ({notifications}) => {
    const APILink = process.env.REACT_APP_API_LINK
    const theme = useMantineTheme()
    const [data, setData] = useState([])
    const [PlanItem, setPlanItem] = useState([])
    const [img, setImg] = useState([])
    const [image, setImage] = useState('')
    const [Title, setTitle] = useState('')
    const [Description, setDescription] = useState('')
    const [ReadingPlanID, setReadingPlanID] = useState('')
    const [search, setSearch] = useState('');
    const [searchVerseItem, setsearchVerseItem] = useState('')
    const [SearchBook, setSearchBook] = useState('')
    const [ItemTitle, setItemTitle] = useState('')
    const [ConfirmModal, setConfirmModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [UploadModal, setUploadModal] = useState(false)
    const [UploadItemModal, setUploadItemModal] = useState(false)
    const [UploadVerseModal, setUploadVerseModal] = useState(false)
    const [OpenVerseModal, setOpenVerseModal] = useState(false)
    const [BibleVersions, setBibleVersions] = useState([])
    const [BibleBooks, setBibleBooks] = useState([])
    const [BibleChapter, setBibleChapter] = useState([])
    const [BibleVerses, setBibleVerses] = useState([])
    const [BibleVerse, setBibleVerse] = useState([]);
    const [ListOfSelectedVerse, setListOfSelectedVerse] = useState([]);
    const [SelectedToVersion, setSelectedToVersion] = useState("")
    const [SelectedBook, setSelectedBook] = useState("")
    const [SelectedChapter, setSelectedChapter] = useState("")
    const [ToSaveBookID, setToSaveBookID] = useState("")
    const [ToSaveChapterID, setToSaveChapterID] = useState("")
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [SearchReadingPlanTranslate, setSearchReadingPlanTranslate] = useState('Search Reading Plan')
    const [CreateReadingPlanTranslate, setCreateReadingPlanTranslate] = useState('Create Reading Plan')
    const [ItemsTranslate, setItemsTranslate] = useState('ITEMS')
    const [DayTranslate, setDayTranslate] = useState('DAY')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [AddItemTranslate, setAddItemTranslate] = useState('Add Item')
    const [DeletePlanTranslate, setDeletePlanTranslate] = useState('Delete Plan')
    const [UploadReadingPlantranslate, setUploadReadingPlantranslate] = useState('Upload Reading Plan')
    const [DragImageTitleTranslate, setDragImageTitleTranslate] = useState('Drag images here or click to select files')
    const [DragImageDescTranslate, setDragImageDescTranslate] = useState('Attach as many files as you like, each file should not exceed 5mb')
    const [TitleTranslate, setTitleTranslate] = useState('Title')
    const [PlaceholderTitle, setPlaceholderTitle] = useState('Enter Title')
    const [DescriptionTranslate, setDescriptionTranslate] = useState('Description')
    const [PlaceholderDescription, setPlaceholderDescription] = useState('Enter Description')
    const [BtnUploadPlan, setBtnUploadPlan] = useState('UPLOAD PLAN')
    const [UploadItemTranslate, setUploadItemTranslate] = useState('Upload Item')
    const [BookTranslate, setBookTranslate] = useState('Book')
    const [PlaceholderBook, setPlaceholderBook] = useState('Book Name')
    const [ChapterTranslate, setChapterTranslate] = useState('Chapter')
    const [PlaceholderChapter, setPlaceholderChapter] = useState('Chapter Number')
    const [SelectVersionTranslate, setSelectVersionTranslate] = useState('Select Version')
    const [SearchBookTranslate, setSearchBookTranslate] = useState('Search Book')
    const [BtnUploadItem, setBtnUploadItem] = useState('UPLOAD ITEM')
    const [ConfirmTitleTranslate, setConfirmTitleTranslate] = useState('Confirm Deleting')
    const [ConfirmMessageTranslate, setConfirmMessageTranslate] = useState('Are you sure you want to Delete this Church?')
    const [ConfirmTranslate, setConfirmTranslate] = useState('Confirm')
    const [CancelTranslate, setCancelTranslate] = useState('Cancel')

    const [DrawerTitle, setDrawerTitle] = useState('')
    const [GetBookName, setGetBookName] = useState('')
    const [GetChapterNumber, setGetChapterNumber] = useState('')
    const [GetChapterID, setGetChapterID] = useState('')
    const [GetPlanItemID, setGetPlanItemID] = useState('')
    const [ListOfVerseItem, setListOfVerseItem] = useState([])
    const [page, setPage] = useState(1)
    const countPerPage = 10

    const [LanguageTranslated, setLanguageTranslated] = useState([])
    const [DefaultLanguages, setDefaultLanguages] = useState([])
    const [TranslateLoading, setTranslateLoading] = useState(false)
    let objTranslated = {}
    let ArrayTranslated = []
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        localStorage.removeItem("GetVerses");
        fetchReadingPlan();
        fetchBibleVersion();
        if(SelectedToVersion === ""){}else{fetchBibleBooks();}
        fetchTranslation();
        fetchLanguages();
    }, [SelectedToVersion, DefaultLanguage]);
    const fetchLanguages = async() => { 
        const ListLanguagesEP = `/Languages`
        await api.get(ListLanguagesEP).then((result) => {
            setDefaultLanguages(result.data)
        }).catch(error => {
        })
    }
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchReadingPlanTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchReadingPlanTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DayTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDayTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateReadingPlanTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateReadingPlanTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ItemsTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setItemsTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddItemTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddItemTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DeletePlanTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDeletePlanTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadReadingPlantranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadReadingPlantranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DragImageDescTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDragImageDescTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderTitle,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderTitle(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DescriptionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDescriptionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderDescription,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderDescription(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadPlan,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadPlan(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + UploadItemTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setUploadItemTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BookTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBookTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderBook,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderBook(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ChapterTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setChapterTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlaceholderChapter,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlaceholderChapter(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SelectVersionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSelectVersionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchBookTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchBookTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + BtnUploadItem,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setBtnUploadItem(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTitleTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTitleTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmMessageTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmMessageTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ConfirmTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setConfirmTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CancelTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCancelTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const fetchReadingPlan = async () => {
        setLoading(true);   
        const ListReadingPlanEP = `/readingplans`
        await api.get(ListReadingPlanEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    }
    const handleSearchVerseItem = (event) => {
        setsearchVerseItem(event.target.value);
    }
    const handleSearchBook = (event) => {
        setSearchBook(event.target.value)
    }
    const handleTitle = (event) => {
        setTitle(event.target.value)
    }
    const handleDescription = (event) => {
        setDescription(event.target.value)
    }
    const handleImage = (files, setImage) =>{
        let Reader = new FileReader()
        Reader.readAsDataURL(files[0])
        Reader.onload = () => {
            setImage(Reader.result.split(",")[1])
        }
    }
    const handleBookName = (event) => {
        setSelectedBook(event.target.value)
    }
    const handleChapterNumber = (event) => {
        setSelectedChapter(event.target.value)
    }
    const handleItemTitle = (event) => {
        setItemTitle(event.target.value)
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const UploadReadingPlanFunction = async() => {
        let objSample = {}
        let ArraySample = []
        LanguageTranslated.length = LanguageTranslated.length - 1
        LanguageTranslated.map((translated) => {
            objSample = {}
            objSample['title'] = translated.title
            objSample['description'] = translated.description
            objSample['language'] = translated.code
            ArraySample.push(objSample) 
        })
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Uploading Plan',
            message: 'Plan will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        var postData = {
            "title": Title,
            "description" : Description,
            "image" : image,
            "localizations": ArraySample
        }
        let axiosConfig = {
            headers : {
                Authorization: 'Bearer '+Token
            }
        };  
        Axios.post(''+APILink+'/api/readingplans', postData, axiosConfig)
        .then((res) => {
            if(res.status === 201){
                setTimeout(() => {
                    //! RESET INPUTS
                    setUploadModal(false)
                    setImg([])
                    fetchReadingPlan()
                    //! END OF RESET INPUTS
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Plan was uploaded',
                        message: 'Plan successsfully Updated',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000);
            }
        })
        .catch((err) => {
        console.log(err);
        })
    }
    const UploadReadingPlanItemFunction = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Item',
            message: 'Item will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const UploadItemEP = `/readingplans/`+ReadingPlanID+`/items`
        api.post(UploadItemEP, {
            bookId : ToSaveBookID,
            chapterId : ToSaveChapterID,
            title : ItemTitle
        }).then(result => {
            if(result['status'] === 200){
                setTimeout(() => {
                    fetchReadingPlan();
                    setSearchBook('');
                    fetchBibleBooks();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Item was uploaded',
                        message: 'Item successsfully uploaded',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        })

       
    }
    const RemoveReadingPlanFunction = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Plan',
            message: 'Plan will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RemovePlanEP = `/readingplans/`+ID+``
        await api.delete(RemovePlanEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchReadingPlan();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Plan was deleted',
                        message: 'Plan successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const RemoveReadingPlanItemFunction = async(ItemID, PlanID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Item',
            message: 'Item will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RemovePlanItemEP = `/readingplans/`+PlanID+`/items/`+ItemID+``
        await api.delete(RemovePlanItemEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchReadingPlan();
                ViewReadingPlanItem(PlanID)
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Item was deleted',
                        message: 'Item successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    const CallUploadItemModal = async(ID) => {
        setUploadItemModal(true)
        setReadingPlanID(ID)
    }
    const ViewReadingPlanItem = async(ID) => {
        setLoading(true);   
        const ReadingPlanItemEP = `/readingplans/`+ID+`/items`
        await api.get(ReadingPlanItemEP).then((result) => {
            setPlanItem(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    PlanItem.sort(function(a,b){
        return new Date(a.dateCreated) - new Date(b.dateCreated);
    });
    const SelectedVersion = async (e) => {
        const VersionID = e.target.value
        setSelectedToVersion(VersionID)
    }
    const SelectedBookFunction = async(BookID, BookName) => {
        setDrawerTitle('CHAPTER NUMBER')
        setSelectedBook(BookName)
        setToSaveBookID(BookID)
        const ListChapterEP = `/Chapters?bookId=`+BookID+``
        api.get(ListChapterEP).then((result) => {
            setBibleChapter(result.data)
            setBibleBooks([])
            
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){GetNewToken(Token, RefreshToken) }
            if(Response === 404){}
        })
    }
    const SelectedChapterFunction = async(ChapterID, ChapterNumber) => {
        setDrawerTitle('VERSE NUMBER')
        setSelectedChapter(ChapterNumber)
        setToSaveChapterID(ChapterID)
        const ListChapterEP = `/Verses?chapterId=`+ChapterID+``
        api.get(ListChapterEP).then((result) => {
            setBibleVerses(result.data)
            setBibleChapter([])
            
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){GetNewToken(Token, RefreshToken) }
            if(Response === 404){}
        })
    }
    const fetchBibleVersion = async () => {
        setLoading(true);   
        const ListVersionEP = `/BibleVersions`
        await api.get(ListVersionEP).then((result) => {
            setBibleVersions(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchBibleBooks = async () => {
        setBibleChapter([])
        setBibleVerses([])
        setLoading(true);   
        const ListBookEP = `/BibleBooks?versionId=`+SelectedToVersion+`&includeBookCover=true`
        await api.get(ListBookEP).then((result) => {
            setBibleBooks(result.data)
            setBibleChapter([])
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const OpenUploadVerseItem = async(BookName, ChapterNumber, ChapterID, PlanItemID) => {
            setGetBookName(BookName)
            setGetChapterNumber(ChapterNumber)
            setGetChapterID(ChapterID)
            setGetPlanItemID(PlanItemID)
            setLoading(true);   
            const ListOfVerseItemEP = `/readingplans/items/`+PlanItemID+`/verses`
            await api.get(ListOfVerseItemEP).then((result) => {
                setListOfVerseItem(result.data)
                setLoading(false);
            }).catch(error => {
            })
    }
    const OpenListOfVerseModal = async(ChapterID) => {
        setOpenVerseModal(true)
        const ListVerseEP = `/Verses?chapterId=`+ChapterID+``
        api.get(ListVerseEP).then((result) => {
            setBibleVerse(result.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){GetNewToken(Token, RefreshToken) }
            if(Response === 404){}
        })
    }
    const UploadVerseItem = async(VerseID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Upload Verse Item',
            message: 'Verse Item will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false);
        const UploadVerseItemEP = `readingplans/items/`+GetPlanItemID+`/verses?verseId=`+VerseID+``
        api.post(UploadVerseItemEP)
        .then(result => {
            setOpenVerseModal(false)
            const ListOfVerseItemEP = `/readingplans/items/`+GetPlanItemID+`/verses`
            api.get(ListOfVerseItemEP).then((result) => {
                setListOfVerseItem(result.data)
                setLoading(false);
            }).catch(error => {
            })
            setTimeout(() => {
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Verse Item was uploaded',
                    message: 'Verse Item successsfully uploaded',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        })
    }
    const RemoveVerseItem = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Verse Item',
            message: 'Verse Item will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const RemoveVerseItemEP = `/readingplans/items/`+GetPlanItemID+`/verses/`+ID+``
        await api.delete(RemoveVerseItemEP, {
        }).then(result => {
                const ListOfVerseItemEP = `/readingplans/items/`+GetPlanItemID+`/verses`
                api.get(ListOfVerseItemEP).then((result) => {
                    setListOfVerseItem(result.data)
                    setLoading(false);
                }).catch(error => {
                })
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Verse Item was deleted',
                        message: 'Verse Item successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 

        }).catch(error =>{  
        })
    }
    const SelectedVerse = (verseID, VerseNumber) => {
        let SelectedVerses = localStorage.getItem("GetVerses")
        SelectedVerses = SelectedVerses ? JSON.parse(SelectedVerses) : []
        let VerseObj = {}
        if(!SelectedVerses.some(Verse => Verse.VerseId === verseID)){
            VerseObj['VerseNumber'] = VerseNumber
            VerseObj['VerseId'] = verseID
            SelectedVerses.push(VerseObj) 
            localStorage.setItem("GetVerses", JSON.stringify(SelectedVerses))
        }else{
            //! If the selected verse is exist then remove to list
            const IndexOfObject = SelectedVerses.findIndex(Object => {
                return Object.VerseId === verseID
            })
            SelectedVerses.splice(IndexOfObject, 1)
            localStorage.setItem("GetVerses", JSON.stringify(SelectedVerses))
        }
        setListOfSelectedVerse(SelectedVerses) 
    };
    const columns = [
        {
            name: <Text fw={500} fz={22} sx = {{fontFamily : 'poppins'}}>{GetBookName} Chapter {GetChapterNumber}</Text>,
            cell: row => 
            <Group position="apart" sx={{width : '100%'}}>
                <Group sx={{maxWidth : '88%'}} className="BlogsContent">
                    <Stack spacing={1} justify="flex-start">
                        <Text fw={700}  sx={{color : 'black', fontSize : 16, fontFamily : 'poppins'}}>
                            <Text fw={900} sx={{color : '#6c2be2', fontSize : 14, fontFamily : 'poppins'}}> Verse Number {row.verseNumber}</Text>
                            {row.verseContent}
                        </Text>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">ACTION</Text></Menu.Label>
                            <Menu.Item sx = {{fontFamily : 'poppins'}} icon = {<IconTrashFilled/>} color="red" onClick={() => {RemoveVerseItem(row.id)}}>Remove Verse Item</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ]
    const TranslateSelectedLanguages = () => {
        setTranslateLoading(true)
        setLanguageTranslated([])
        for (let code of DefaultLanguages) {
            let GetTranslatedTitle = ''
            let GetTranslatedDescription = ''
            Axios({
                url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                method: "POST",
                headers: {
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                    "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                },
                data:[
                    {"Text": Title},
                ]
            }).then((result) => {
                GetTranslatedTitle = ''
                result.data.map((a) => (
                    a.translations.map((gettranslation) => {
                        GetTranslatedTitle = gettranslation.text
                    })
                ))
                Axios({
                    url: 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from='+sourceLang+'&to='+code.languageCode+'',
                    method: "POST",
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_KEY,
                        "Ocp-Apim-Subscription-Region": process.env.REACT_APP_OCP_APIM_REGION
                    },
                    data:[
                        {"Text": Description}
                    ]
                }).then((result) => {
                    GetTranslatedDescription= ''
                    result.data.map((a) => (
                        a.translations.map((gettranslation) => {
                            GetTranslatedDescription = gettranslation.text
                        })
                    ))
                    objTranslated = {}
                    objTranslated['code'] = code.languageCode
                    objTranslated['title'] = GetTranslatedTitle
                    objTranslated['description'] = GetTranslatedDescription
                    ArrayTranslated.push(objTranslated)
                    setLanguageTranslated(current => [...ArrayTranslated, ArrayTranslated.concat(current)]);
                    setTranslateLoading(false)
                }).catch((error) => { 
                })
            }).catch((error) => { 
            })
        }
    }
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group >
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchReadingPlanTranslate}
                    onChange={ handleSearch }
                />
                <Tooltip
                    label={CreateReadingPlanTranslate}
                    sx={{background : '#6c2be2'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : '#4a1e9e', transition: '0.3s',  ":hover" : {
                            background : '#6c2be2'
                            
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setUploadModal(true)}}
                    >
                        <IconPlus />
                    </ActionIcon>
                </Tooltip>  
            </Group>
            <br></br> 
            {/* LIST OF READING PLAN LAYOUT */}
            <Grid gutter="xs" grow>
            {data.filter((SearchPlan) => {
            if(search === ""){
                return SearchPlan
            }else if(SearchPlan.title.toLowerCase().includes(search.toLowerCase())){
                return SearchPlan
            }
            }).map((readingplan, index) =>
                <div key = {index} style={{width : '33.33%'}} className="PlanContent">
                    <Grid.Col> 
                        <Card padding="lg" radius="lg" sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}}>
                            <Card.Section>
                                <div className="container" style={{position : 'relative'}}>
                                    <Image
                                    src={readingplan.image}
                                    height={250}
                                    alt={readingplan.title}
                                    />
                                    <Badge variant="filled" radius="xs" 
                                        onClick={() => {ViewReadingPlanItem(readingplan.id);  setUploadVerseModal(true);}}
                                        sx={{position : 'absolute',
                                        top : 0,
                                        left : 0,
                                        height : 55,
                                        background : '#4a1e9e',
                                        cursor : 'pointer',
                                        transition : 'transform .2s ease-in-out',
                                        MozTransition : 'transform .2s ease-in-out',
                                        WebkitTransition : 'transform .2s ease-in-out',
                                        ":hover" : {
                                            WebkitTransform : 'scale(1.1)',
                                            msTransform : 'scale(1.1)',
                                            transform : 'scale(1.1)'
                                        }
                                    }}
                                    >
                                        <Text sx = {{fontFamily : 'poppins'}} align="center" fw={800} fz="xs">{readingplan.days}</Text>
                                        <Text sx = {{fontFamily : 'poppins'}} align="center" fw={800} fz="xs">{ItemsTranslate}</Text>
                                    </Badge>
                                    <Menu position="right-start" withArrow arrowPosition="center" shadow="md" width={200} offset={1} transitionProps={{ transition: 'rotate-right', duration: 150 }}>
                                        <Menu.Target>
                                            <ActionIcon size="lg" radius="xl" variant="filled" sx={{ position: 'absolute', top: 5, right: 5, background : '#4a1e9e',  ":hover" : {
                                                    background : '#6c2be2'
                                                }
                                            }}
                                                >
                                                <IconDots size={28} stroke={2} color="white" />
                                            </ActionIcon> 
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{ActionTranslate}</Text></Menu.Label>
                                            <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconPlus size={20}/>} onClick={() => {CallUploadItemModal(readingplan.id)}}>{AddItemTranslate}</Menu.Item>
                                            <Menu.Item sx = {{fontFamily : 'poppins'}} color="red" icon={<IconTrash size={20}/>} onClick={() => {RemoveReadingPlanFunction(readingplan.id)}}>{DeletePlanTranslate}</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </div>
                            </Card.Section>
                            <Group position="apart" mt="md" >
                                <Text sx = {{fontFamily : 'poppins'}} weight={700} fz={20} lineClamp={1}>{readingplan.title}</Text>
                            </Group>
                            <Text size="sm" color="dark" lineClamp={5} sx={{wordBreak : 'break-all', fontFamily : 'poppins'}}>{readingplan.description}</Text>
                        </Card>
                    </Grid.Col>
                </div>  
            )}
            </Grid>
            {/* END OF LIST OF READING PLAN LAYOUT */}
            {/* UPLOADING READING PLAN LAYOUT */}
            <Modal title={UploadReadingPlantranslate} size = "100%" opened={UploadModal} onClose={() => {setUploadModal(false)}} centered>
                <Group align='start' position='center'>
                    <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                        <Card shadow="xl" padding="lg">
                            <Card.Section>
                                {img.map((url, index) => <Image key = {index} style = {{ padding : '30px'}} maw={340} mx="auto" radius="sm" src={url} alt="Ads" />)}
                            </Card.Section>
                            <Card.Section >
                                <Dropzone
                                    onDrop={
                                        (files) => {
                                            handleImage(files, setImage)
                                            const selectedFIles =[];
                                            const targetFilesObject= [...files]
                                            targetFilesObject.map((file)=>{
                                                return selectedFIles.push(URL.createObjectURL(file))
                                            })
                                            setImg(selectedFIles);
                                        } 
                                    }
                                    maxSize={3 * 1024 ** 2}
                                    accept={IMAGE_MIME_TYPE}
                                >
                                    <Group position="center"  style={{ minHeight: rem(100), pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload
                                                size="3.2rem"
                                                stroke={1.5}
                                                color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                                            />
                                        </Dropzone.Accept>

                                        <Dropzone.Reject>
                                            <IconX
                                                size="3.2rem"
                                                stroke={1.5}
                                                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                                            />
                                        </Dropzone.Reject>

                                        <Dropzone.Idle>
                                            <IconPhoto size="3.2rem" stroke={1.5} />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text size="xl" inline sx = {{fontFamily : 'poppins'}}>
                                            {DragImageTitleTranslate}
                                            </Text>
                                            <Text size="xs" color="dimmed" inline mt={14} sx = {{fontFamily : 'poppins'}}>
                                                {DragImageDescTranslate}
                                            </Text>
                                        </div>
                                    </Group>
                                </Dropzone>
                            </Card.Section>
                        </Card>
                        <Group mt="md" mb="xs" grow>
                            <TextInput
                                label = {TitleTranslate}
                                placeholder={PlaceholderTitle}
                                size='md'
                                radius="sm"
                                withAsterisk
                                onChange={handleTitle}
                                sx = {{fontFamily : 'poppins'}}
                            />
                        </Group>
                        <Group mt="md" mb="xs" grow>
                            <Textarea
                                label = {DescriptionTranslate}
                                placeholder={PlaceholderDescription}
                                size='md'
                                radius="sm"
                                minRows={5}
                                withAsterisk
                                onChange={handleDescription}
                                sx = {{fontFamily : 'poppins'}}
                            />
                        </Group>
                        <Group mt="md" mb="xs" grow>
                            <Button sx = {{fontFamily : 'poppins'}} onClick={() => {setConfirmModal(true)}} leftIcon = {<IconUpload/>} color="violet" size="md" uppercase>
                                {BtnUploadPlan}
                            </Button>
                            <Button onClick={TranslateSelectedLanguages} leftIcon = {<IconLanguage/>} color="violet" size="md" sx = {{fontFamily : 'poppins'}} uppercase>
                                Translate
                            </Button>
                        </Group>
                    </Card>
                    <Card maw = {700} shadow="sm" padding="md" radius="md" withBorder sx={{width : '100%'}}>
                        <Stack justify="flex-start">
                            <Group position = "center">
                                <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 20}}>TRANSLATED CONTENT</Text>
                            </Group>
                            <LanguageCardReadingPlan
                                LanguageTranslated = {LanguageTranslated}
                                TranslateLoading = {TranslateLoading}
                            />
                        </Stack>
                    </Card>
                </Group>
            </Modal>
            {/* END OF UPLOADING READING PLAN LAYOUT */}
            {/* UPLOADING READING PLAN ITEM LAYOUT */}
            <Drawer title={UploadItemTranslate} size = "lg" opened={UploadItemModal} onClose={() => {setUploadItemModal(false)}} position="right">
                <Group grow>
                    <TextInput
                        placeholder={PlaceholderBook}
                        label={BookTranslate}
                        variant="filled"
                        size="md"
                        value={SelectedBook}
                        onChange={handleBookName}
                        sx = {{fontFamily : 'poppins'}}
                    />
                    <TextInput
                        placeholder={PlaceholderChapter}
                        label={ChapterTranslate}
                        variant="filled"
                        size="md"
                        value={SelectedChapter}
                        onChange={handleChapterNumber}
                        sx = {{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group grow>
                    <Textarea
                        placeholder="Enter Item Title"
                        label= "Item Title"
                        variant="filled"
                        minRows={5} 
                        size="md"
                        // value={
                        //     ListOfSelectedVerse.map((selected) => 
                        //         selected.VerseNumber
                        //     )
                        // }
                        onChange={handleItemTitle}
                        sx = {{fontFamily : 'poppins'}}
                    />
                </Group>
                <Group mt="md" mb="xs" grow>
                    <Button sx = {{fontFamily : 'poppins'}} onClick={UploadReadingPlanItemFunction} leftIcon = {<IconUpload/>} sx={{background : '#4a1e9e', transition: '0.3s',  ":hover" : { background : '#6c2be2'}}} size="md" uppercase>
                       {BtnUploadItem}
                    </Button>
                </Group>
                <Divider/>
                <br/>

                <Group mt="md" mb="xs" grow>
                    <select onChange={SelectedVersion} style = {{
                        padding : '8px',
                        fontSize :'15px',
                        fontWeight : 'bold',
                        borderRadius : '3px',
                        width: 420,
                        fontFamily : 'poppins'
                    }}>
                        <option value = "0">{SelectVersionTranslate}</option>
                        {BibleVersions.map((version, index) =>
                            <option key={ index } value = {version.id}>
                                { version.version }
                            </option>
                        )}
                    </select>
                    <TextInput
                        placeholder={SearchBookTranslate}
                        size="md"
                        icon={<IconSearch/>}
                        onChange={handleSearchBook}
                    />
                </Group>
                {/* BIBLE BOOKS */}
                <Grid gutter="xs" grow>
                {BibleBooks.filter((srchBook) => {
                    if(SearchBook === ""){
                        return srchBook
                    }else if(srchBook.bookName.toLowerCase().includes(SearchBook.toLowerCase())){
                        return srchBook
                    }
                    }).map((book, index) => (
                    <div key = {index} style={{width : '50%'}}>
                        <Grid.Col>
                            <Group grow>
                                <Button sx = {{fontFamily : 'poppins'}} size="md" variant="gradient" gradient={{ from: '#4a1e9e', to: '#6c2be2' }} onClick={() => {SelectedBookFunction(book.id, book.bookName)}}>{book.bookName}</Button>
                            </Group>
                        </Grid.Col> 
                    </div>
                    ))
                }
                </Grid>
                {/* CHAPTER NUMBER */}
                <Group grow>
                    <Text align="center" fw={800} color="black" sx={{fontSize: 35, fontFamily : 'poppins'}}>{DrawerTitle}</Text>
                </Group>
                <Group position='center' spacing="md" >
                    {BibleChapter.map((chapter, index) =>
                        <Button
                            key = {index}
                            style = {{width : '80px', height : '70px', fontFamily : 'poppins'}}
                            variant="gradient"
                            gradient={{ from: '#4a1e9e', to: '#6c2be2' }}
                            onClick={() => {SelectedChapterFunction(chapter.id, chapter.chapterNumber)}}
                        >
                            {chapter.chapterNumber === 0 ?
                                'Intro'
                                :
                                chapter.chapterNumber
                            }
                        </Button>  
                    )}
                </Group>
                {/* VERSE NUMBER */}
                <Group position='center' spacing="md" >
                    {BibleVerses.map((verse, index) =>
                        <Button
                            key = {index}
                            style = {{width : '80px', height : '70px', fontFamily : 'poppins', position : 'relative'}}
                            variant="gradient"
                            gradient={{ from: '#4a1e9e', to: '#6c2be2' }}
                            onClick={() => {SelectedVerse(verse.id, verse.verseNumber)}}
                        > 
                          {ListOfSelectedVerse.map((selected) =>
                            selected.VerseId === verse.id ? 
                            <ActionIcon radius="xl" variant="filled" size="xs" color="green" sx={{top : 2, right : 2, position : 'absolute'}}>
                                <IconCheck/>
                            </ActionIcon>
                            :
                            ''
                          )}
                         {verse.verseNumber}
                        </Button>  
                    )}
                </Group>
            </Drawer>
            {/* END OF UPLOADING READING PLAN ITEM LAYOUT */}
            {/* CONFIRMATION LAYOUT */}
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={ConfirmTitleTranslate} centered>
                <Group>
                    <Group grow>
                        <Text sx = {{fontFamily : 'poppins'}}>{ConfirmMessageTranslate}</Text>
                    </Group>
                    
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {UploadReadingPlanFunction} color="teal" size="sm" uppercase> {ConfirmTranslate} </Button>
                    <Button sx = {{fontFamily : 'poppins'}} onClick = {CancelConfirmation} color="red" size="sm" uppercase> {CancelTranslate} </Button>
                </Group>
            </Modal>
            {/* END OF CONFIRMATION LAYOUT */}
            <Modal opened={UploadVerseModal} onClose={() => {setUploadVerseModal(false)}} size="55%" title = "List of Items">
                    {PlanItem.map((plan, index) =>
                     <Group grow sx={{paddingTop : 7}}>
                        <Card key = {index} padding="xs" radius="md" sx={{
                            boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                            cursor : 'pointer',
                            transition : 'transform .2s ease-in-out',
                            MozTransition : 'transform .2s ease-in-out',
                            WebkitTransition : 'transform .2s ease-in-out',
                            ":hover" : {
                                background : 'transparent',
                                WebkitTransform : 'scale(1.01)',
                                msTransform : 'scale(1.01)',
                                transform : 'scale(1.01)'
                            }
                        }}>
                            <Group position="apart">
                                <Group spacing={8}>
                                    <Badge variant="filled" radius="xs" sx={{ height : 50, background : '#4a1e9e'}}>
                                        <Text sx = {{fontFamily : 'poppins'}} align="center" fw={800} fz="xs"> {DayTranslate}<br/>{index + 1} </Text>
                                    </Badge>
                                    <Stack spacing={1}>
                                        <Text fw = {900} sx={{lineHeight : 1, fontSize : 16, fontFamily : 'poppins'}}>{plan.book.bookName}</Text>
                                        <Text color = "#495057" fw = {700} sx={{fontSize : 14, fontFamily : 'poppins'}}>Chapter {plan.chapter.chapterNumber} : {plan.title}</Text>
                                    </Stack>
                                </Group>
                                <Group position="right" spacing={5}>
                                    <ActionIcon color="green" size="md" radius="xl" variant="filled" onClick = {() => {OpenListOfVerseModal(plan.chapter.id)}}>
                                        <IconPlus size="1.125rem" />
                                    </ActionIcon>
                                    <ActionIcon color="blue" size="md" radius="xl" variant="filled">
                                        <IconEdit size="1.125rem" />
                                    </ActionIcon>
                                    <ActionIcon color="violet" size="md" radius="xl" variant="filled" onClick={() => OpenUploadVerseItem(plan.book.bookName, plan.chapter.chapterNumber, plan.chapter.id, plan.id )}>
                                        <IconEye size="1.125rem" />
                                    </ActionIcon>
                                </Group>
                            </Group>

                        </Card>
                    </Group>
                    )}
               

                {/* <Group >
                    <TextInput
                        icon={<IconSearch/>}
                        radius="sm"
                        size="md"
                        placeholder="Search Verse"
                        onChange={ handleSearchVerseItem }
                    />
                    <Tooltip
                        label="Upload Bible Verse"
                        sx={{background : '#6c2be2'}}
                        onClick = {() => {OpenListOfVerseModal(GetChapterID)}}
                        withArrow
                    >
                        <ActionIcon
                            sx={{background : '#4a1e9e', transition: '0.3s',  ":hover" : {
                                background : '#6c2be2'
                                
                            }}}
                            size="lg" 
                            radius="xl" 
                            variant="filled"
                        >
                            <IconPlus />
                        </ActionIcon>
                    </Tooltip>  
                </Group> */}
                <br/>
                <DataTable
                    columns={columns}
                    data={
                        ListOfVerseItem.filter((getSearchVerseItem) => {
                            if(searchVerseItem === ""){
                                return getSearchVerseItem
                            }else if(getSearchVerseItem.verseContent.toLowerCase().includes(searchVerseItem.toLowerCase())){
                                return getSearchVerseItem
                            }
                        })
                    }
                    pagination
                    progressPending={loading}
                    paginationTotalRows={ListOfVerseItem.length}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                    responsive = ""
                    
                />
            </Modal>
            <Modal size = "70%" opened={OpenVerseModal} onClose={() => {setOpenVerseModal(false)}}>
                <Text align="center" fw={700} color="black" sx={{fontSize: 35, fontFamily : 'poppins'}}>Chapter {GetChapterNumber}</Text>
                <Divider></Divider>
                <br/>
                <List
                    spacing="xs"
                    size="sm"
                    center
                >
                    {BibleVerse.map((verse, index) =>
                        <Group position="apart">
                            <Group sx={{width : '95%'}} className="EventContent">
                                {verse.isTitle ? 
                                    <List.Item
                                        sx={{
                                            cursor : 'pointer',
                                            padding : 3,
                                            ":hover" : {
                                                background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)',
                                                borderRadius : 2,
                                                padding : 3
                                            }
                                        }}
                                        onClick={() => {UploadVerseItem(verse.id)}}
                                    >
                                        <Text sx = {{fontFamily : 'poppins'}} fw={800} fz='xl'>{ verse.title}</Text>
                                    </List.Item>
                                    : 
                                    <List.Item
                                        key = {index}
                                        icon = {
                                            <ActionIcon color="violet" size="lg" radius="xl" variant="filled" sx={{background : '#4a1e9e',  ":hover" : {
                                                background : '#6c2be2'
                                            }}}>
                                                {verse.verseNumber}
                                            </ActionIcon> 
                                        }
                                        sx={{
                                            cursor : 'pointer',
                                            padding : 3,
                                            ":hover" : {
                                                background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)',
                                                borderRadius : 2,
                                                padding : 3
                                                
                                            }
                                        }}
                                        onClick={() => {UploadVerseItem(verse.id)}}
                                    >
                                        <Text sx = {{fontFamily : 'poppins'}} fw={500} fz='md'>{ verse.verseContent}</Text>
                                    </List.Item>
                                }
                            
                            </Group>
                        </Group>
                    )}
                </List>
            </Modal>
        </div>
    )
}