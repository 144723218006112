import React, { useState, useEffect, useRef } from "react"
import { TextInput, Group, ActionIcon, Button, Image, Grid, Text, Menu, Spoiler, Modal } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconPlus, IconCheck, IconCameraPlus, IconDots, IconPhoto} from '@tabler/icons-react'
import { UploadBibleStory, UpdateBibleStoryImage } from "../Component"
import { IconPlayerPlay } from "@tabler/icons-react"
import  LogoBlurd  from "../../Assets/images/logo/main-logo-blurd.png"
import Axios from 'axios';
export const ListofBibleStories = ({notifications}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [UploadBibleStoryModal, setUploadBibleStoryModal] = useState(false)
    const [UpdateBibleStoryModal, setUpdateBibleStoryModal] = useState(false)
    const [BibleStoryID, setBibleStoryID] = useState("")
    const [BibleStoryCurrentImage, setBibleStoryCurrentImage] = useState("")
    const [OpenVideoPlayerModal, setOpenVideoPlayerModal] = useState(false)
    const [search, setSearch] = useState('');
    const [Link, setLink] = useState('');
    const [Title, setTitle] = useState('')
    const [Description, setDescription] = useState('')
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [SearchBibleStoryTranslate, setSearchBibleStoryTranslate] = useState('Search Bible Story')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [AddCoverTranslate, setAddCoverTranslate] = useState('Add Cover')
    const [DeleteStoryTranslate, setDeleteStoryTranslate] = useState('Delete Story')
    const [PlayTranslate, setPlayTranslate] = useState('PLAY')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchBibleStories();
        fetchTranslation();
    }, [DefaultLanguage])
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchBibleStoryTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchBibleStoryTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddCoverTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddCoverTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + DeleteStoryTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setDeleteStoryTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + PlayTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setPlayTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchBibleStories = async () => {
        setLoading(true);   
        const ListBibleStoriesEP = `/BibleStories?PageNumber=1&PageSize=100`
        await api.get(ListBibleStoriesEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    };
    const RemoveBibleStory = async (id) => {
        const RemoveBibleStoryEP = `/BibleStories/`+id+``
        await api.delete(RemoveBibleStoryEP, {
        }).then(result => {
            if(result['status'] === 204){
                notifications.show({
                    id: 'load-data',
                    loading: true,
                    title: 'Removing Bible Story',
                    message: 'Bible Story will be deleted, you cannot close this yet',
                    autoClose: false,
                    withCloseButton: false,
                    });
                    setTimeout(() => {
                    fetchBibleStories();
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Bible Story was deleted',
                        message: 'Bible Story successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
    })
    }
    const UpdateBibleStoryOpenModal = async(id, imgLink) => {
        setUpdateBibleStoryModal(true)
        setBibleStoryID(id)
        setBibleStoryCurrentImage(imgLink)
    }
    const OpenPlayer = (url,title,description) => {
        setOpenVideoPlayerModal(true)
        setLink(url)
        setTitle(title)
        setDescription(description)
    }
    const LazyImage = ({cover}) => {
        const rootRef = useRef();
        const [isVisible, setIsVisible] = useState(false);
        useEffect(() => {
            const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
            };
            
            const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                setIsVisible(true);
                }
            });
            };
        
            if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
            }
        }, [isVisible]);
        return (
            <Image 
                src={isVisible ? cover : LogoBlurd} 
                ref={rootRef}
                withPlaceholder 
                placeholder={
                    
                    <>
                        <IconPhoto size="3.2rem" stroke={1.5} />
                        <div>
                            <Text size="xl" inline>
                                No Image Cover Found
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7}>
                                Attach Bible Story Cover
                            </Text>
                        </div>
                    </>
            } 
                className="CoverImage"
            />
        )
        
    }
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group>
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchBibleStoryTranslate}
                    onChange={ handleSearch }
                />
                <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled" 
                    onClick = {() =>{setUploadBibleStoryModal(true)}}
                >
                    <IconPlus/>
                </ActionIcon>
            </Group>
            <br></br>
            <Grid gutter="xs" grow>
                {data.filter((SearchStory) => {
                if(search === ""){
                    return SearchStory
                }else if(SearchStory.title.toLowerCase().includes(search.toLowerCase())){
                    return SearchStory
                } 
                }).map((story, index) =>
                    <div key = {index} style={{width : '20%'}} className="PlanContent">
                        <Grid.Col>
                            <div style={{position : "relative"}}>
                                <div className="ImageContainer">
                                    <LazyImage
                                        cover = {story.image}
                                    />
                                    <div className="PlayBtnContainer">
                                        <Button sx = {{fontFamily : 'poppins'}} color="violet" radius="xl" size="lg" uppercase leftIcon = {<IconPlayerPlay/>} sx={{
                                             background : '#4a1e9e',  ":hover" : {
                                                background : '#6c2be2'
                                            }
                                        }}
                                        onClick={() => {OpenPlayer(story.youtubeId, story.title, story.description)}}
                                        >
                                           {PlayTranslate}
                                        </Button>
                                    </div>
                                    <Menu position="right-start" withArrow arrowPosition="center" shadow="md" width={200} offset={1} transitionProps={{ transition: 'rotate-right', duration: 150 }}>
                                        <Menu.Target>
                                            <ActionIcon size="lg" radius="xl" variant="filled" sx={{
                                                position: 'absolute',
                                                top: 5,
                                                right: 5,
                                                background : '#4a1e9e',  ":hover" : {
                                                    background : '#6c2be2'
                                                }
                                            }}
                                                >
                                                <IconDots size={28} stroke={2} color="white" />
                                            </ActionIcon> 
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{ActionTranslate}</Text></Menu.Label>
                                            <Menu.Item sx = {{fontFamily : 'poppins'}} icon={<IconCameraPlus size={20}/>} onClick={() => {UpdateBibleStoryOpenModal(story.id, story.image)}}>{AddCoverTranslate}</Menu.Item>
                                            <Menu.Item sx = {{fontFamily : 'poppins'}} color="red" icon={<IconTrash size={20}/>} onClick = {() => {RemoveBibleStory(story.id)}}>{DeleteStoryTranslate}</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                               </div>
                            </div>
                        </Grid.Col>
                    </div>
                )} 
            </Grid>
            <UploadBibleStory
                notifications = {notifications}
                OpenModal = {UploadBibleStoryModal}
                CloseModal = {() => {setUploadBibleStoryModal(false)}}
                fetchBibleStories = {fetchBibleStories}
            />
            <UpdateBibleStoryImage
                notifications = {notifications}
                OpenModal = {UpdateBibleStoryModal}
                CloseModal = {() => {setUpdateBibleStoryModal(false)}}
                fetchBibleStories = {fetchBibleStories}
                BibleStoryID = {BibleStoryID}
                BibleStoryCurrentImage = {BibleStoryCurrentImage}
            />
            <Modal 
                opened={OpenVideoPlayerModal}
                onClose={() => {setOpenVideoPlayerModal(false)}}
                size="xl"
                overlayProps={{
                    opacity: 0.55,
                    blur: 10,
                }}
                withCloseButton = {false}
                centered
            >             
                <Group grow>
                    <iframe 
                        src = {'https://www.youtube.com/embed/'+Link}
                        height={500}
                        width={500}
                        frameBorder="0" 
                        allowFullScreen>
                    </iframe> 
                </Group>
                <br/>
                <Group>
                    <Text fw={700}  sx={{fontSize : '30px', fontFamily : 'poppins'}}>{Title}</Text>
                </Group> 
                <Group>
                    <Spoiler maxHeight={93} showLabel="Show more" hideLabel="Show less">
                        <Text sx = {{fontFamily : 'poppins'}} fw={500} fz="sm">{Description}</Text>
                    </Spoiler>
                </Group> 
            </Modal>
        </div>
    )
}