import React, { useState, useEffect } from "react";
import { Text, Group, Title, Progress, Stack, Card, Image, ActionIcon, Avatar, List, Divider, TextInput, Modal, Grid } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconArrowLeft, IconArrowRight, IconLock, IconStarFilled, IconSearch, IconError404} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import TheOneLogo from '../../Assets/images/logo/circular-logo.png'
import CoverImage from '../../Assets/images/logo/profile-bg.png'
import TextLogo from '../../Assets/images/logo/text-logo.png'
import Axios from 'axios';
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import DataTable from 'react-data-table-component';
export const ActivityLogs = () => {
    TimeAgo.addLocale(en)
    let divider = 100
    const [Users, setUsers] = useState([])
    const [Search, setSearch] = useState('')
    const [SearchUserModal, setSearchUserModal] = useState(false)
    const [GetUser, setGetUser] = useState([])
    const [Stats,setStats] = useState([])
    const [UserAchievement, setUserAchievement] = useState([])
    const [CurrentAvchievement, setCurrentAvchievement] = useState([])
    const [UserPost, setUserPost] = useState([])
    const [loading, setLoading] = useState(false);
    const [NextPage, setNextPage] = useState('');
    const [PreviousPage, setPreviousPage] = useState('')
    const [PageNumber, setPageNumber] = useState(0)
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SearchUserTranslate" : 'Search User',
        "PostTranslate" : 'Post',
        "LikeTranslate" : 'Like',
        "BadgeTranslate" : 'Badge',
        "PhotosTranslate" : 'Photos',
        "BadgeCollectionTranslate" : 'Badge Collection',
        "UserTaskTranslate" : 'USERS TASK', 
        "UserActivityTranslate" : 'USERS ACTIVITY'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchTranslation();
    }, [DefaultLanguage])
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const handleUser = async() => {
        const SearchUserEP = `/UserProfiles/Search/`+Search
        await api.get(SearchUserEP).then((result) => {
            setUsers(result.data.data)
            setSearchUserModal(true)
        }).catch(error => {
            const Response = error.response.status
            console.log(Response)
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }if (Response === 404){
                notifications.show({
                    id: 'load-data',
                    title: 'Name or Email Not Found',
                    message: 'Please input Name or Email of the User',
                    icon: <IconError404 size="1rem" />,
                    autoClose: true,
                    withCloseButton: false,
                });
            }
        })
    }
    const SelectedUser = async(id) => {
        setSearchUserModal(false)
        // USER PROFILE
        const GetUserEP = `/UserProfiles/`+id
        await api.get(GetUserEP).then((result) => {
            setGetUser(result.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
        })
        //USER EXPERIENCE
        const GetStatsEP = `/Experiences/Logs/`+id+'?PageNumber=1&PageSize=10'
        await api.get(GetStatsEP).then((result) => {
            setStats(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
        })
        //USER ACHIEVEMENT
        const UserAchievementEP = `/Achievements/UserAchievements?userId=`+id+`&PageNumber=1&PageSize=100`
        await api.get(UserAchievementEP).then((result) => {
            setUserAchievement(result.data.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
        })
        //! USER CURRENT TASK
        const CurrentAchievementEP = `/Achievements/Tasks/`+id+`?PageNumber=1&PageSize=100`
        await api.get(CurrentAchievementEP).then((result) => {
            setCurrentAvchievement(result.data.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
        })

        //! USER POST IMAGES
        const UserPostEP = `/Posts/UsersPosts/`+id+`?PageNumber=1&PageSize=10&requestOriginalImage=true`
        await api.get(UserPostEP).then((result) => {
            setUserPost(result.data.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
        })                   
    }
    const CallNextPage = async() => {
        Axios({
            url: NextPage,
            method: 'get',
            headers: {
                'Authorization': 'Bearer '+Token,
                'My-Custom-Header': 'foobar'
            }
        }).then(result => {
            setStats(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setPreviousPage(result.data.previousPage)
        }).catch(err => {
            console.log(err);
        });
    }
    const CallPreviousPage = async() => {
        Axios({
            url: PreviousPage,
            method: 'get',
            headers: {
                'Authorization': 'Bearer '+Token,
                'My-Custom-Header': 'foobar'
            }
        }).then(result => {
            setStats(result.data.data)
            setNextPage(result.data.nextPage)
            setPageNumber(result.data.pageNumber)
            setPreviousPage(result.data.previousPage)
        }).catch(err => {
            console.log(err);
        });
    }
    Stats.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
    });
    CurrentAvchievement.sort(function(a,b){
        return new Date(b.count / b.requiredPoints * divider) - new Date(a.count / a.requiredPoints * divider);
    });
    const columns = [
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{DataTranslate.UserActivityTranslate}</Text>,
            cell : row =>
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group spacing={8}>
                    <Avatar  
                        sx={{ width: 70, height: 70, borderRadius : 80}} 
                        src={GetUser.profilePicture === undefined || GetUser.profilePicture === null ? TheOneLogo : GetUser.profilePicture} 
                    />
                    <Stack spacing={1} justify="flex-start">
                        <Text fw = {700} sx={{fontSize : 22, color : '#4a1e9e', fontFamily : 'poppins'}}>
                            {GetUser.fullName}
                        </Text>
                        <Text fz="sm" fw={500} sx={{lineHeight: 0.5, fontFamily : 'poppins'}}>
                            {
                                row.source === 0 ?
                                    "Create a post"
                                :
                                row.source === 1 ?
                                    "Heart in a post"
                                :
                                row.source === 2 ?
                                    "View a Blog"
                                :
                                row.source === 3 ?
                                    "Read a Bible Verse"
                                :
                                row.source === 4 ?
                                    "Invited User to the Group"
                                :
                                row.source === 5 ?
                                    "Joined to the Group"
                                :
                                row.source === 6 ?
                                    "Play Music"
                                :
                                row.source === 7 ?
                                    "Play Podcast"
                                :
                                row.source === 8 ?
                                    "Play Instrumentals"
                                :
                                row.source === 9 ?
                                    "is Login"
                                :
                                row.source === 10 ?
                                    "Other Activity"
                                :
                                row.source === 11 ?
                                    "Create new Journal"
                                :
                                row.source === 12 ?
                                    "Play Quiz"
                                :
                                ''
                            }
                        </Text>
                        <Text fz="sm" color="black" sx={{lineHeight: 1.5, fontFamily : 'poppins'}}>
                            <i>Experience gain : {row.experiencePoint}XP</i> 
                        </Text>
                    </Stack>
                </Group>
                <Group>
                    <Text sx = {{fontFamily : 'poppins'}}fw = {600} fz="xs" color="black" >
                        <ReactTimeAgo date={Date.parse(row.date)} />
                    </Text>
                </Group>
            </Group>
        }
    ];
    const NewListOfAchievements = [], 
    AchievementsPostAction = [], 
    AchievementsLikeAction = [], 
    AchievementsReadBibleAction = [], 
    AchievementsInviteUserAction = [], 
    AchievementsJoinGroupAction = [], 
    AchievementsListenToMusicAction = [], 
    AchievementsListToPodcastAction = [], 
    AchievementsCreateJournalAction = []
    UserAchievement.reduce((CurrentPointSource, NewPointSource, Index) => {
        let PointSource = NewPointSource.achievement.pointSource
        if(PointSource === 0){
            AchievementsPostAction.push(UserAchievement[Index])
        }else if(PointSource === 1){
            AchievementsLikeAction.push(UserAchievement[Index])
        }else if(PointSource === 2){

        }else if(PointSource === 3){
            AchievementsReadBibleAction.push(UserAchievement[Index])
        }else if(PointSource === 4){
            AchievementsInviteUserAction.push(UserAchievement[Index])
        }else if(PointSource === 5){
            AchievementsJoinGroupAction.push(UserAchievement[Index])
        }else if(PointSource === 6){
            AchievementsListenToMusicAction.push(UserAchievement[Index])
        }else if(PointSource === 7){
            AchievementsListToPodcastAction.push(UserAchievement[Index])
        }else if(PointSource === 8){

        }else if(PointSource === 9){
    
        }else if(PointSource === 10){

        }else if(PointSource === 11){
            AchievementsCreateJournalAction.push(UserAchievement[Index])
        }
    }, 0)
    AchievementsPostAction.length > 0 && NewListOfAchievements.push(AchievementsPostAction[AchievementsPostAction.length - 1])
    AchievementsLikeAction.length > 0 && NewListOfAchievements.push(AchievementsLikeAction[AchievementsLikeAction.length - 1])
    AchievementsReadBibleAction.length > 0 && NewListOfAchievements.push(AchievementsReadBibleAction[AchievementsReadBibleAction.length - 1])
    AchievementsInviteUserAction.length > 0 && NewListOfAchievements.push(AchievementsInviteUserAction[AchievementsInviteUserAction.length - 1])
    AchievementsJoinGroupAction.length > 0 && NewListOfAchievements.push(AchievementsJoinGroupAction[AchievementsJoinGroupAction.length - 1])
    AchievementsListenToMusicAction.length > 0 && NewListOfAchievements.push(AchievementsListenToMusicAction[AchievementsListenToMusicAction.length - 1])
    AchievementsListToPodcastAction.length > 0 && NewListOfAchievements.push(AchievementsListToPodcastAction[AchievementsListToPodcastAction.length - 1])
    AchievementsCreateJournalAction.length > 0 && NewListOfAchievements.push(AchievementsCreateJournalAction[AchievementsCreateJournalAction.length - 1])
    return (
    <div style={{paddingLeft : '50px'}} className="FirstContainer">
        <Group spacing={5}>
            <TextInput
                icon={<IconSearch/>}
                radius="sm"
                size="md"
                placeholder={DataTranslate.SearchUserTranslate}
                onChange={handleSearch}
            />
            <ActionIcon sx={{background : '#4a1e9e',  ":hover" : {
                    background : '#6c2be2'
                }}}
                size="lg" 
                radius="xl" 
                variant="filled" 
                onClick={handleUser}
            >
                <IconSearch/>
            </ActionIcon>
        </Group>
        <br/>
        <Group align="flex-start" className="WebLayout">
            <div style={{ width : '25%'}}>  
                <Group position="center" sx={{padding : 7}}>
                    <Avatar src={GetUser.profilePicture === undefined || GetUser.profilePicture === null ? TheOneLogo : GetUser.profilePicture} sx={{ width: 180, height: 180, boxShadow : '1px 2px 5px 3px rgb(114, 110, 110)', borderRadius : 180}}/>
                </Group>
                <Group position="center">
                    <Text fw={700} sx={{ lineHeight: 1, fontSize : '25px', color : '#4a1e9e', fontFamily : 'poppins', lineBreak : 'break-all'}}>{GetUser.fullName}</Text>
                </Group>
                
                <Group position="center">
                    <Text color="black" fw={600} sx={{fontSize : '15px', fontFamily : 'poppins'}}>{GetUser.email}</Text>
                </Group>
                <Group position="center">
                    <Text color="black" fw={600} sx={{fontSize : '15px', fontFamily : 'poppins'}}>{GetUser.contactNumber}</Text>
                </Group>
                <Group position="center">
                    <Text fz="lg" align="center" color="black" sx={{fontFamily : 'poppins'}}>
                        <Title order={1} >{GetUser.postCount === undefined ? '0' : GetUser.postCount}</Title>
                        {DataTranslate.PostTranslate}
                    </Text>
                    <Divider orientation="vertical"/>
                    <Text fz="lg" align="center" color="black" sx={{fontFamily : 'poppins'}}>
                        <Title order={1}>0</Title>
                        {DataTranslate.LikeTranslate}
                    </Text>
                    <Divider orientation="vertical" />
                    <Text fz="lg" align="center" color="black" sx={{fontFamily : 'poppins'}}>
                        <Title order={1}>{NewListOfAchievements.length}</Title>
                        {DataTranslate.BadgeTranslate}
                    </Text>
                </Group>
                <Group>
                    <Text fz="lg" color="gray" fw={600} sx={{fontFamily : 'poppins'}}>{DataTranslate.PhotosTranslate}</Text>
                    <Grid grow gutter="xs" >
                        {UserPost.slice(0, 6).map((userpost) =>
                            userpost.images.slice(0, 6).map((image, index) =>
                                    <Grid.Col key = {index} span={4} sx={{maxWidth : '200px', minWidth : '200px', minHeight : '100px', maxHeight : '100px', textAlign : 'center', position : 'relative', overflow : 'hidden'}}>
                                        <Image src={image.name}/>
                                    </Grid.Col>
                                )
                            )
                        }
                    </Grid>
                </Group>
            </div>
            <div style={{ width : '70%', position : "relative"}}>
                <Image src={CoverImage} radius="lg" height={240} />
                <Image src={TextLogo} sx={{position: 'absolute', top : 75, left : 330}} maw={600}/>
                <Group sx={{paddingTop : 10}} align="flex-start">
                    <Card  radius="lg" sx={{width : '45%', boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', }}>
                        <Text fz="lg" color="gray" fw={500} sx={{paddingBottom : 10, fontFamily : 'poppins'}}>{DataTranslate.BadgeCollectionTranslate}</Text>
                        <Grid grow gutter="xs">
                            {NewListOfAchievements.map((user_achievement, index)=>
                                <div key = {index} style={{width : '10%'}} className="PlanContent">
                                    <Grid.Col>
                                        <Avatar  src = {user_achievement.achievement.imageBadge} sx={{ width: 50, height: 50, boxShadow : '1px 2px 5px 3px rgb(114, 110, 110)', borderRadius : 180}}/>
                                    </Grid.Col>
                                </div>
                            )}
                        </Grid>
                        <br/>
                        <Divider/>
                        <Text fw={900} align="center" sx={{fontSize: 25, fontFamily : 'poppins'}}>{DataTranslate.UserTaskTranslate}</Text>
                        {CurrentAvchievement.map((currrent, index) =>
                       <Group grow sx={{paddingTop : 7}}>
                            <Card key = {index} padding="xs" radius="md" sx={{
                                boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                                cursor : 'pointer',
                                transition : 'transform .2s ease-in-out',
                                MozTransition : 'transform .2s ease-in-out',
                                WebkitTransition : 'transform .2s ease-in-out',
                                ":hover" : {
                                    background : 'transparent',
                                    WebkitTransform : 'scale(1.01)',
                                    msTransform : 'scale(1.01)',
                                    transform : 'scale(1.01)'
                                }
                            }}>
                                <Group position="apart">
                                    <Group spacing={2}>
                                        <Avatar  
                                            sx={{ width: 70, height: 70, borderRadius : 80, marginRight : '10px', marginTop : '10px'}} 
                                            src={currrent.imageBadge}
                                        />
                                        <Stack spacing={1}>
                                            <Text fw = {700} fz="md" color="black" sx={{fontFamily : 'poppins'}}>
                                                {currrent.achievementName}
                                            </Text>
                                            <Text fz={15} color = "black" sx={{fontFamily : 'poppins'}}>
                                                {currrent.achievementDescription}
                                            </Text>
                                            <Group spacing={1}>
                                                <IconStarFilled fontSize="medium" style={{color : '#FFD700'}}/>
                                                <Text color = "black" fz={12} sx={{fontFamily : 'poppins'}}>
                                                    <i>+ {currrent.experiencePoints} XP</i>
                                                </Text>
                                            </Group>
                                            <Progress label = { <Text fw={700} color = "white" sx={{fontFamily : 'poppins'}}>{currrent.count} /  {currrent.requiredPoints} </Text>} radius="xl" color="#6c2be2" size="xl" animate  value={(currrent.count / currrent.requiredPoints * divider)}/>
                                        </Stack>
                                    </Group>
                                    <Group>
                                        <Stack spacing={1}>
                                            <Text fw = {600} fz="xs" color="black" sx={{fontFamily : 'poppins'}}>
                                                <IconLock/>
                                            </Text>
                                        </Stack>
                                    </Group>
                                </Group>
                            </Card>
                        </Group>
                        )}
                 
                    </Card>
                    <Card padding="md" radius="lg" withBorder sx={{width : '53.7%'}}>
                        <DataTable
                            columns={columns}
                            data={Stats}
                            progressPending={loading}
                            responsive = ""
                        />
                        <Group position="right">
                            <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                                    background : '#6c2be2'
                                }}}
                                size="lg" 
                                radius="xl" 
                                variant="filled" 
                                onClick={CallPreviousPage}
                            >
                                <IconArrowLeft/>
                            </ActionIcon>
                            <Text fw={700} fz="md" color="dark">
                                {PageNumber} - {Stats.length} of {Stats.length}
                            </Text>
                            <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                                    background : '#6c2be2'
                                }}}
                                size="lg" 
                                radius="xl" 
                                variant="filled" 
                                onClick={CallNextPage}
                            >
                                <IconArrowRight/>
                            </ActionIcon>
                        </Group>
                    </Card>
                </Group>
            </div>  
        </Group>
        <Group className="MobileLayout">
            <div style={{width : '100%', position : 'relative'}}>
                <Image src={CoverImage} radius="sm" sx={{width : '100%'}}/>
                <Group position="center" sx={{position : 'absolute', top : '30%', left : '29%', zIndex : 100}}>
                    <Avatar 
                        src={GetUser.profilePicture === undefined || GetUser.profilePicture === null ? TheOneLogo : GetUser.profilePicture} 
                        sx={{ width: 180, height: 180, boxShadow : '1px 2px 5px 3px black', borderRadius : 180}}
                    />
                </Group>
            </div>
            <Card shadow="xl" padding="xl" radius="sm" withBorder>
                <Group position="center">
                    <Text fw={700} sx={{ lineHeight: 1, fontSize : '30px', color : '#4a1e9e', paddingTop : 5}}>{GetUser.fullName}</Text>
                </Group>
                <Group position="center">
                    <Text color="black" fw={600} sx={{fontSize : '15px'}}>{GetUser.email}</Text>
                </Group>
                <Group position="center">
                    <Text color="black" fw={600} sx={{fontSize : '15px'}}>{GetUser.contactNumber}</Text>
                </Group>
                <Group position="center">
                    <Text fz="lg" align="center" color="black">
                        <Title order={1} >{GetUser.postCount === undefined ? '0' : GetUser.postCount}</Title>
                        {DataTranslate.PostTranslate}
                    </Text>
                    <Divider orientation="vertical"/>
                    <Text fz="lg" align="center" color="black">
                        <Title order={1}>0</Title>
                        {DataTranslate.LikeTranslate}
                    </Text>
                    <Divider orientation="vertical" />
                    <Text fz="lg" align="center" color="black">
                        <Title order={1}>{NewListOfAchievements.length}</Title>
                        {DataTranslate.BadgeTranslate}
                    </Text>
                </Group>
            </Card>
            <Card shadow="xl" padding="xl" radius="sm" withBorder>
                <Group>
                    <Text fz="lg" color="gray" fw={600}>{DataTranslate.PhotosTranslate}</Text>
                    <Grid grow gutter="xs" >
                        {UserPost.slice(0, 6).map((userpost) =>
                            userpost.images.slice(0, 1).map((image, index) =>
                                    <Grid.Col key = {index} span={4} sx={{width : '100%', textAlign : 'center', position : 'relative', overflow : 'hidden'}}>
                                        <Image src={image.name}/>
                                    </Grid.Col>
                                )
                            )
                        }
                    </Grid>
                </Group>
            </Card>
            <Card shadow="xl" padding="xl" radius="sm" withBorder>
                <Text fz="lg" color="gray" fw={500} sx={{paddingBottom : 10}}>{DataTranslate.BadgeCollectionTranslate}</Text>
                <Grid grow gutter="xs">
                    {NewListOfAchievements.slice(0, 5).map((user_achievement, index)=>
                        <div key = {index} style={{width : '20%'}}>
                            <Grid.Col>
                                <Avatar  src = {user_achievement.achievement.imageBadge} sx={{ width: 50, height: 50, boxShadow : '1px 2px 5px 3px rgb(114, 110, 110)', borderRadius : 180}}/>
                            </Grid.Col>
                        </div>
                    )}
                </Grid>
                <br/>
                <Divider/>
                <Text fw={900} align="center" sx={{fontSize: 25}}>{DataTranslate.UserTaskTranslate}</Text>
                <List 
                    sx={{
                    overflow: 'auto',
                    maxHeight: 770,
                    '& ul': { padding: 0 },
                    }}
                >
                    {CurrentAvchievement.map((currrent, index) =>
                    <>
                        <List.Item 
                            sx={{ width : '100%', ":hover" : {
                                    background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)',
                                    cursor : 'pointer',
                                }
                            }}
                            icon = {
                                <Avatar  
                                    sx={{ width: 80, height: 80, borderRadius : 80, marginRight : '10px', marginTop : '10px'}} 
                                    src={currrent.imageBadge}
                                />
                            }
                        >   
                        <Group position = "apart">
                            <Text fw = {700} fz="lg" color="black" >
                                {currrent.achievementName}
                            </Text>
                            <Text fw = {600} fz="xs" color="black" >
                                <IconLock/>
                            </Text>
                        </Group>
                        <React.Fragment>
                            <Group position = "apart">
                                <Text fz={15} color = "black">
                                    {currrent.achievementDescription}
                                </Text>
                                <Text fw={600} color = "black">
                                    {currrent.count} /  {currrent.requiredPoints}
                                </Text>
                            </Group>
                            <Group spacing={1}>
                                <IconStarFilled fontSize="medium" style={{color : '#FFD700'}}/>
                                <Text color = "black" fz={12}>
                                    <i>+ {currrent.experiencePoints} XP</i>
                                </Text>
                            </Group>
                            
                            <Progress radius="xl" color="#6c2be2" size="xl" animate  value={
                                (currrent.count / currrent.requiredPoints * divider)
                            } />
                        </React.Fragment>
                        </List.Item>
                    </>
                    )}
                </List>
            </Card>
            <DataTable
                columns={columns}
                data={Stats}
                progressPending={loading}
                responsive = ""
            />
            <Group position="right">
                <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled" 
                    onClick={CallPreviousPage}
                >
                    <IconArrowLeft/>
                </ActionIcon>
                <Text fw={700} fz="md" color="dark">
                    {PageNumber} - {Stats.length} of {Stats.length}
                </Text>
                <ActionIcon  sx={{background : '#4a1e9e',  ":hover" : {
                        background : '#6c2be2'
                    }}}
                    size="lg" 
                    radius="xl" 
                    variant="filled" 
                    onClick={CallNextPage}
                >
                    <IconArrowRight/>
                </ActionIcon>
            </Group>
        </Group>
        <Modal opened={SearchUserModal} onClose={() => {setSearchUserModal(false)}} title="List of Users" size="lg" centered>
            {Users.map((user, index) =>
            <Group key = {index} position="apart" sx={{ cursor : 'pointer', transition: '0.3s', ":hover" : { background : 'linear-gradient(to bottom right, #bb98ff, #ddcbff)' }, padding : 8}} onClick={() => {SelectedUser(user.id)}}>
                <Group spacing={8}>
                    <Avatar src={user.profilePicture? user.profilePicture : TheOneLogo} sx={{boxShadow : '1px 2px 2px 1px rgb(114, 110, 110)'}} color="violet" variant="filled" radius="xl" size="lg"/>
                    <Stack spacing={0} justify="flex-start" className="ListOfUserContent">
                        <Text fw={700} fz="xl" sx={{lineHeight : 1}}>{user.fullName}</Text>
                        <Text fw={500} fz="md" color="dark" >{user.email}</Text>
                    </Stack>
                </Group>
            </Group>
            )}
        </Modal>
    </div>
    )
}