import React, { useState, useEffect } from "react";
import { Group, Text, Loader  } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import ReactECharts from 'echarts-for-react';
import Moment from 'moment'; 
export const FlatFormChart = () => {
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState([])
    const DateToday = Moment().toISOString(new Date());
    let ProviderArray = []
    let DateArrayToSet = []
    let DateArray = []
    let PlatformArray = []
    let obj = {}
    useEffect(() => {
        fetchData()
    }, []);
    const fetchData = async() => {
        setLoading(true);  
        const PlatformDataEP = `statistics/platformusers?date=`+DateToday+`&lastDays=7`
        await api.get(PlatformDataEP).then((result) => {
            setData(result.data)
            setLoading(false);
        }).catch(error => {
            console.log(error)
        })
    }
    Data.map((item) => {
        ProviderArray.push(item.provider ?  item.provider : 'Unspecified')
        item.data.map((date) => {
            obj = {}
            obj['provider'] = item.provider ? item.provider : 'Unspecified'
            obj['date'] = date.date
            obj['count'] = date.count
            PlatformArray.push(obj)
          
            DateArrayToSet.push(date.date)
            DateArray = [...new Set(DateArrayToSet)]
        })
    })
    DateArray.sort(function(a,b){
        return a.localeCompare(b);
    });
    PlatformArray.sort(function(a,b){
        return a.date.localeCompare(b.date);
    });
    const AppleData = PlatformArray.filter((newVal) => {
        return newVal.provider === 'Apple'
    })
    const FacebookData = PlatformArray.filter((newVal) => {
        return newVal.provider === 'Facebook'
    })
    const GoogleData = PlatformArray.filter((newVal) => {
        return newVal.provider === 'Google' 
    })
    const GuestData = PlatformArray.filter((newVal) => {
        return newVal.provider === 'Guest'
    })
    const TheOneData = PlatformArray.filter((newVal) => {
        return newVal.provider === 'TheOne'
    })
    const UnspecifiedData = PlatformArray.filter((newVal) => {
        return newVal.provider === 'Unspecified'
    })
    const options = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ProviderArray
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [Moment(DateArray[0]).format('ll'),Moment(DateArray[1]).format('ll'),Moment(DateArray[2]).format('ll'),Moment(DateArray[3]).format('ll'),Moment(DateArray[4]).format('ll'),Moment(DateArray[5]).format('ll'),Moment(DateArray[6]).format('ll')]
        },
        yAxis: {
            type: 'value',
        },
        series: [
          {
            name: 'Facebook',
            type: 'line',
            stack: 'Facebook',
            data: [
              DateArray[0] !== FacebookData[0]?.date ? 
                DateArray[0] !== FacebookData[1]?.date ?
                  DateArray[0] !== FacebookData[2]?.date ?
                    DateArray[0] !== FacebookData[3]?.date ?
                      DateArray[0] !== FacebookData[4]?.date ?
                        DateArray[0] !== FacebookData[5]?.date ?
                          DateArray[0] !== FacebookData[6]?.date ?
                          0
                          :
                          FacebookData[6]?.count
                        :
                        FacebookData[5]?.count
                      :
                      FacebookData[4]?.count
                    :
                    FacebookData[3]?.count
                  :
                  FacebookData[2]?.count
                :
                FacebookData[1]?.count
              : 
              FacebookData[0]?.count,
              DateArray[1] !== FacebookData[0]?.date ? 
                DateArray[1] !== FacebookData[1]?.date ?
                  DateArray[1] !== FacebookData[2]?.date ?
                    DateArray[1] !== FacebookData[3]?.date ?
                      DateArray[1] !== FacebookData[4]?.date ?
                        DateArray[1] !== FacebookData[5]?.date ?
                          DateArray[1] !== FacebookData[6]?.date ?
                          0
                          :
                          FacebookData[6]?.count
                        :
                        FacebookData[5]?.count
                      :
                      FacebookData[4]?.count
                    :
                    FacebookData[3]?.count
                  :
                  FacebookData[2]?.count
                :
                FacebookData[1]?.count
              : 
              FacebookData[0]?.count, 
              DateArray[2] !== FacebookData[0]?.date ? 
                DateArray[2] !== FacebookData[1]?.date ?
                  DateArray[2] !== FacebookData[2]?.date ?
                    DateArray[2] !== FacebookData[3]?.date ?
                      DateArray[2] !== FacebookData[4]?.date ?
                        DateArray[2] !== FacebookData[5]?.date ?
                          DateArray[2] !== FacebookData[6]?.date ?
                          0
                          :
                          FacebookData[6]?.count
                        :
                        FacebookData[5]?.count
                      :
                      FacebookData[4]?.count
                    :
                    FacebookData[3]?.count
                  :
                  FacebookData[2]?.count
                :
                FacebookData[1]?.count
              : 
              FacebookData[0]?.count,
              DateArray[3] !== FacebookData[0]?.date ? 
                DateArray[3] !== FacebookData[1]?.date ?
                  DateArray[3] !== FacebookData[2]?.date ?
                    DateArray[3] !== FacebookData[3]?.date ?
                      DateArray[3] !== FacebookData[4]?.date ?
                        DateArray[3] !== FacebookData[5]?.date ?
                          DateArray[3] !== FacebookData[6]?.date ?
                          0
                          :
                          FacebookData[6]?.count
                        :
                        FacebookData[5]?.count
                      :
                      FacebookData[4]?.count
                    :
                    FacebookData[3]?.count
                  :
                  FacebookData[2]?.count
                :
                FacebookData[1]?.count
              : 
              FacebookData[0]?.count, 
              DateArray[4] !== FacebookData[0]?.date ? 
                DateArray[4] !== FacebookData[1]?.date ?
                  DateArray[4] !== FacebookData[2]?.date ?
                    DateArray[4] !== FacebookData[3]?.date ?
                      DateArray[4] !== FacebookData[4]?.date ?
                        DateArray[4] !== FacebookData[5]?.date ?
                          DateArray[4] !== FacebookData[6]?.date ?
                          0
                          :
                          FacebookData[6]?.count
                        :
                        FacebookData[5]?.count
                      :
                      FacebookData[4]?.count
                    :
                    FacebookData[3]?.count
                  :
                  FacebookData[2]?.count
                :
                FacebookData[1]?.count
              : 
              FacebookData[0]?.count, 
              DateArray[5] !== FacebookData[0]?.date ? 
                DateArray[5] !== FacebookData[1]?.date ?
                  DateArray[5] !== FacebookData[2]?.date ?
                    DateArray[5] !== FacebookData[3]?.date ?
                      DateArray[5] !== FacebookData[4]?.date ?
                        DateArray[5] !== FacebookData[5]?.date ?
                          DateArray[5] !== FacebookData[6]?.date ?
                          0
                          :
                          FacebookData[6]?.count
                        :
                        FacebookData[5]?.count
                      :
                      FacebookData[4]?.count
                    :
                    FacebookData[3]?.count
                  :
                  FacebookData[2]?.count
                :
                FacebookData[1]?.count
              : 
              FacebookData[0]?.count, 
              DateArray[6] !== FacebookData[0]?.date ? 
                DateArray[6] !== FacebookData[1]?.date ?
                  DateArray[6] !== FacebookData[2]?.date ?
                    DateArray[6] !== FacebookData[3]?.date ?
                      DateArray[6] !== FacebookData[4]?.date ?
                        DateArray[6] !== FacebookData[5]?.date ?
                          DateArray[6] !== FacebookData[6]?.date ?
                          0
                          :
                          FacebookData[6]?.count
                        :
                        FacebookData[5]?.count
                      :
                      FacebookData[4]?.count
                    :
                    FacebookData[3]?.count
                  :
                  FacebookData[2]?.count
                :
                FacebookData[1]?.count
              : 
              FacebookData[0]?.count
            ],
          },
          {
            name: 'Google',
            type: 'line',
            stack: 'Google',
            data: [
              DateArray[0] !== GoogleData[0]?.date ? 
                DateArray[0] !== GoogleData[1]?.date ?
                  DateArray[0] !== GoogleData[2]?.date ?
                    DateArray[0] !== GoogleData[3]?.date ?
                      DateArray[0] !== GoogleData[4]?.date ?
                        DateArray[0] !== GoogleData[5]?.date ?
                          DateArray[0] !== GoogleData[6]?.date ?
                          0
                          :
                          GoogleData[6]?.count
                        :
                        GoogleData[5]?.count
                      :
                      GoogleData[4]?.count
                    :
                    GoogleData[3]?.count
                  :
                  GoogleData[2]?.count
                :
                GoogleData[1]?.count
              : 
              GoogleData[0]?.count,
              DateArray[1] !== GoogleData[0]?.date ? 
                DateArray[1] !== GoogleData[1]?.date ?
                  DateArray[1] !== GoogleData[2]?.date ?
                    DateArray[1] !== GoogleData[3]?.date ?
                      DateArray[1] !== GoogleData[4]?.date ?
                        DateArray[1] !== GoogleData[5]?.date ?
                          DateArray[1] !== GoogleData[6]?.date ?
                          0
                          :
                          GoogleData[6]?.count
                        :
                        GoogleData[5]?.count
                      :
                      GoogleData[4]?.count
                    :
                    GoogleData[3]?.count
                  :
                  GoogleData[2]?.count
                :
                GoogleData[1]?.count
              : 
              GoogleData[0]?.count, 
              DateArray[2] !== GoogleData[0]?.date ? 
                DateArray[2] !== GoogleData[1]?.date ?
                  DateArray[2] !== GoogleData[2]?.date ?
                    DateArray[2] !== GoogleData[3]?.date ?
                      DateArray[2] !== GoogleData[4]?.date ?
                        DateArray[2] !== GoogleData[5]?.date ?
                          DateArray[2] !== GoogleData[6]?.date ?
                          0
                          :
                          GoogleData[6]?.count
                        :
                        GoogleData[5]?.count
                      :
                      GoogleData[4]?.count
                    :
                    GoogleData[3]?.count
                  :
                  GoogleData[2]?.count
                :
                GoogleData[1]?.count
              : 
              GoogleData[0]?.count,
              DateArray[3] !== GoogleData[0]?.date ? 
                DateArray[3] !== GoogleData[1]?.date ?
                  DateArray[3] !== GoogleData[2]?.date ?
                    DateArray[3] !== GoogleData[3]?.date ?
                      DateArray[3] !== GoogleData[4]?.date ?
                        DateArray[3] !== GoogleData[5]?.date ?
                          DateArray[3] !== GoogleData[6]?.date ?
                          0
                          :
                          GoogleData[6]?.count
                        :
                        GoogleData[5]?.count
                      :
                      GoogleData[4]?.count
                    :
                    GoogleData[3]?.count
                  :
                  GoogleData[2]?.count
                :
                GoogleData[1]?.count
              : 
              GoogleData[0]?.count, 
              DateArray[4] !== GoogleData[0]?.date ? 
                DateArray[4] !== GoogleData[1]?.date ?
                  DateArray[4] !== GoogleData[2]?.date ?
                    DateArray[4] !== GoogleData[3]?.date ?
                      DateArray[4] !== GoogleData[4]?.date ?
                        DateArray[4] !== GoogleData[5]?.date ?
                          DateArray[4] !== GoogleData[6]?.date ?
                          0
                          :
                          GoogleData[6]?.count
                        :
                        GoogleData[5]?.count
                      :
                      GoogleData[4]?.count
                    :
                    GoogleData[3]?.count
                  :
                  GoogleData[2]?.count
                :
                GoogleData[1]?.count
              : 
              GoogleData[0]?.count, 
              DateArray[5] !== GoogleData[0]?.date ? 
                DateArray[5] !== GoogleData[1]?.date ?
                  DateArray[5] !== GoogleData[2]?.date ?
                    DateArray[5] !== GoogleData[3]?.date ?
                      DateArray[5] !== GoogleData[4]?.date ?
                        DateArray[5] !== GoogleData[5]?.date ?
                          DateArray[5] !== GoogleData[6]?.date ?
                          0
                          :
                          GoogleData[6]?.count
                        :
                        GoogleData[5]?.count
                      :
                      GoogleData[4]?.count
                    :
                    GoogleData[3]?.count
                  :
                  GoogleData[2]?.count
                :
                GoogleData[1]?.count
              : 
              GoogleData[0]?.count, 
              DateArray[6] !== GoogleData[0]?.date ? 
                DateArray[6] !== GoogleData[1]?.date ?
                  DateArray[6] !== GoogleData[2]?.date ?
                    DateArray[6] !== GoogleData[3]?.date ?
                      DateArray[6] !== GoogleData[4]?.date ?
                        DateArray[6] !== GoogleData[5]?.date ?
                          DateArray[6] !== GoogleData[6]?.date ?
                          0
                          :
                          GoogleData[6]?.count
                        :
                        GoogleData[5]?.count
                      :
                      GoogleData[4]?.count
                    :
                    GoogleData[3]?.count
                  :
                  GoogleData[2]?.count
                :
                GoogleData[1]?.count
              : 
              GoogleData[0]?.count
            ],
          },
          {
            name: 'Apple',
            type: 'line',
            stack: 'Apple',
            data: [
              DateArray[0] !== AppleData[0]?.date ? 
                DateArray[0] !== AppleData[1]?.date ?
                  DateArray[0] !== AppleData[2]?.date ?
                    DateArray[0] !== AppleData[3]?.date ?
                      DateArray[0] !== AppleData[4]?.date ?
                        DateArray[0] !== AppleData[5]?.date ?
                          DateArray[0] !== AppleData[6]?.date ?
                          0
                          :
                          AppleData[6]?.count
                        :
                        AppleData[5]?.count
                      :
                      AppleData[4]?.count
                    :
                    AppleData[3]?.count
                  :
                  AppleData[2]?.count
                :
                AppleData[1]?.count
              : 
              AppleData[0]?.count,
              DateArray[1] !== AppleData[0]?.date ? 
                DateArray[1] !== AppleData[1]?.date ?
                  DateArray[1] !== AppleData[2]?.date ?
                    DateArray[1] !== AppleData[3]?.date ?
                      DateArray[1] !== AppleData[4]?.date ?
                        DateArray[1] !== AppleData[5]?.date ?
                          DateArray[1] !== AppleData[6]?.date ?
                          0
                          :
                          AppleData[6]?.count
                        :
                        AppleData[5]?.count
                      :
                      AppleData[4]?.count
                    :
                    AppleData[3]?.count
                  :
                  AppleData[2]?.count
                :
                AppleData[1]?.count
              : 
              AppleData[0]?.count, 
              DateArray[2] !== AppleData[0]?.date ? 
                DateArray[2] !== AppleData[1]?.date ?
                  DateArray[2] !== AppleData[2]?.date ?
                    DateArray[2] !== AppleData[3]?.date ?
                      DateArray[2] !== AppleData[4]?.date ?
                        DateArray[2] !== AppleData[5]?.date ?
                          DateArray[2] !== AppleData[6]?.date ?
                          0
                          :
                          AppleData[6]?.count
                        :
                        AppleData[5]?.count
                      :
                      AppleData[4]?.count
                    :
                    AppleData[3]?.count
                  :
                  AppleData[2]?.count
                :
                AppleData[1]?.count
              : 
              AppleData[0]?.count,
              DateArray[3] !== AppleData[0]?.date ? 
                DateArray[3] !== AppleData[1]?.date ?
                  DateArray[3] !== AppleData[2]?.date ?
                    DateArray[3] !== AppleData[3]?.date ?
                      DateArray[3] !== AppleData[4]?.date ?
                        DateArray[3] !== AppleData[5]?.date ?
                          DateArray[3] !== AppleData[6]?.date ?
                          0
                          :
                          AppleData[6]?.count
                        :
                        AppleData[5]?.count
                      :
                      AppleData[4]?.count
                    :
                    AppleData[3]?.count
                  :
                  AppleData[2]?.count
                :
                AppleData[1]?.count
              : 
              AppleData[0]?.count, 
              DateArray[4] !== AppleData[0]?.date ? 
                DateArray[4] !== AppleData[1]?.date ?
                  DateArray[4] !== AppleData[2]?.date ?
                    DateArray[4] !== AppleData[3]?.date ?
                      DateArray[4] !== AppleData[4]?.date ?
                        DateArray[4] !== AppleData[5]?.date ?
                          DateArray[4] !== AppleData[6]?.date ?
                          0
                          :
                          AppleData[6]?.count
                        :
                        AppleData[5]?.count
                      :
                      AppleData[4]?.count
                    :
                    AppleData[3]?.count
                  :
                  AppleData[2]?.count
                :
                AppleData[1]?.count
              : 
              AppleData[0]?.count, 
              DateArray[5] !== AppleData[0]?.date ? 
                DateArray[5] !== AppleData[1]?.date ?
                  DateArray[5] !== AppleData[2]?.date ?
                    DateArray[5] !== AppleData[3]?.date ?
                      DateArray[5] !== AppleData[4]?.date ?
                        DateArray[5] !== AppleData[5]?.date ?
                          DateArray[5] !== AppleData[6]?.date ?
                          0
                          :
                          AppleData[6]?.count
                        :
                        AppleData[5]?.count
                      :
                      AppleData[4]?.count
                    :
                    AppleData[3]?.count
                  :
                  AppleData[2]?.count
                :
                AppleData[1]?.count
              : 
              AppleData[0]?.count, 
              DateArray[6] !== AppleData[0]?.date ? 
                DateArray[6] !== AppleData[1]?.date ?
                  DateArray[6] !== AppleData[2]?.date ?
                    DateArray[6] !== AppleData[3]?.date ?
                      DateArray[6] !== AppleData[4]?.date ?
                        DateArray[6] !== AppleData[5]?.date ?
                          DateArray[6] !== AppleData[6]?.date ?
                          0
                          :
                          AppleData[6]?.count
                        :
                        AppleData[5]?.count
                      :
                      AppleData[4]?.count
                    :
                    AppleData[3]?.count
                  :
                  AppleData[2]?.count
                :
                AppleData[1]?.count
              : 
              AppleData[0]?.count
            ],
          },
          {
            name: 'Guest',
            type: 'line',
            stack: 'Guest',
            data: [
              DateArray[0] !== GuestData[0]?.date ? 
                DateArray[0] !== GuestData[1]?.date ?
                  DateArray[0] !== GuestData[2]?.date ?
                    DateArray[0] !== GuestData[3]?.date ?
                      DateArray[0] !== GuestData[4]?.date ?
                        DateArray[0] !== GuestData[5]?.date ?
                          DateArray[0] !== GuestData[6]?.date ?
                          0
                          :
                          GuestData[6]?.count
                        :
                        GuestData[5]?.count
                      :
                      GuestData[4]?.count
                    :
                    GuestData[3]?.count
                  :
                  GuestData[2]?.count
                :
                GuestData[1]?.count
              : 
              GuestData[0]?.count,
              DateArray[1] !== GuestData[0]?.date ? 
                DateArray[1] !== GuestData[1]?.date ?
                  DateArray[1] !== GuestData[2]?.date ?
                    DateArray[1] !== GuestData[3]?.date ?
                      DateArray[1] !== GuestData[4]?.date ?
                        DateArray[1] !== GuestData[5]?.date ?
                          DateArray[1] !== GuestData[6]?.date ?
                          0
                          :
                          GuestData[6]?.count
                        :
                        GuestData[5]?.count
                      :
                      GuestData[4]?.count
                    :
                    GuestData[3]?.count
                  :
                  GuestData[2]?.count
                :
                GuestData[1]?.count
              : 
              GuestData[0]?.count, 
              DateArray[2] !== GuestData[0]?.date ? 
                DateArray[2] !== GuestData[1]?.date ?
                  DateArray[2] !== GuestData[2]?.date ?
                    DateArray[2] !== GuestData[3]?.date ?
                      DateArray[2] !== GuestData[4]?.date ?
                        DateArray[2] !== GuestData[5]?.date ?
                          DateArray[2] !== GuestData[6]?.date ?
                          0
                          :
                          GuestData[6]?.count
                        :
                        GuestData[5]?.count
                      :
                      GuestData[4]?.count
                    :
                    GuestData[3]?.count
                  :
                  GuestData[2]?.count
                :
                GuestData[1]?.count
              : 
              GuestData[0]?.count,
              DateArray[3] !== GuestData[0]?.date ? 
                DateArray[3] !== GuestData[1]?.date ?
                  DateArray[3] !== GuestData[2]?.date ?
                    DateArray[3] !== GuestData[3]?.date ?
                      DateArray[3] !== GuestData[4]?.date ?
                        DateArray[3] !== GuestData[5]?.date ?
                          DateArray[3] !== GuestData[6]?.date ?
                          0
                          :
                          GuestData[6]?.count
                        :
                        GuestData[5]?.count
                      :
                      GuestData[4]?.count
                    :
                    GuestData[3]?.count
                  :
                  GuestData[2]?.count
                :
                GuestData[1]?.count
              : 
              GuestData[0]?.count, 
              DateArray[4] !== GuestData[0]?.date ? 
                DateArray[4] !== GuestData[1]?.date ?
                  DateArray[4] !== GuestData[2]?.date ?
                    DateArray[4] !== GuestData[3]?.date ?
                      DateArray[4] !== GuestData[4]?.date ?
                        DateArray[4] !== GuestData[5]?.date ?
                          DateArray[4] !== GuestData[6]?.date ?
                          0
                          :
                          GuestData[6]?.count
                        :
                        GuestData[5]?.count
                      :
                      GuestData[4]?.count
                    :
                    GuestData[3]?.count
                  :
                  GuestData[2]?.count
                :
                GuestData[1]?.count
              : 
              GuestData[0]?.count, 
              DateArray[5] !== GuestData[0]?.date ? 
                DateArray[5] !== GuestData[1]?.date ?
                  DateArray[5] !== GuestData[2]?.date ?
                    DateArray[5] !== GuestData[3]?.date ?
                      DateArray[5] !== GuestData[4]?.date ?
                        DateArray[5] !== GuestData[5]?.date ?
                          DateArray[5] !== GuestData[6]?.date ?
                          0
                          :
                          GuestData[6]?.count
                        :
                        GuestData[5]?.count
                      :
                      GuestData[4]?.count
                    :
                    GuestData[3]?.count
                  :
                  GuestData[2]?.count
                :
                GuestData[1]?.count
              : 
              GuestData[0]?.count, 
              DateArray[6] !== GuestData[0]?.date ? 
                DateArray[6] !== GuestData[1]?.date ?
                  DateArray[6] !== GuestData[2]?.date ?
                    DateArray[6] !== GuestData[3]?.date ?
                      DateArray[6] !== GuestData[4]?.date ?
                        DateArray[6] !== GuestData[5]?.date ?
                          DateArray[6] !== GuestData[6]?.date ?
                          0
                          :
                          GuestData[6]?.count
                        :
                        GuestData[5]?.count
                      :
                      GuestData[4]?.count
                    :
                    GuestData[3]?.count
                  :
                  GuestData[2]?.count
                :
                GuestData[1]?.count
              : 
              GuestData[0]?.count
            ],
          },
          {
            name: 'TheOne',
            type: 'line',
            stack: 'TheOne',
            data: [
              DateArray[0] !== TheOneData[0]?.date ? 
                DateArray[0] !== TheOneData[1]?.date ?
                  DateArray[0] !== TheOneData[2]?.date ?
                    DateArray[0] !== TheOneData[3]?.date ?
                      DateArray[0] !== TheOneData[4]?.date ?
                        DateArray[0] !== TheOneData[5]?.date ?
                          DateArray[0] !== TheOneData[6]?.date ?
                          0
                          :
                          TheOneData[6]?.count
                        :
                        TheOneData[5]?.count
                      :
                      TheOneData[4]?.count
                    :
                    TheOneData[3]?.count
                  :
                  TheOneData[2]?.count
                :
                TheOneData[1]?.count
              : 
              TheOneData[0]?.count,
              DateArray[1] !== TheOneData[0]?.date ? 
                DateArray[1] !== TheOneData[1]?.date ?
                  DateArray[1] !== TheOneData[2]?.date ?
                    DateArray[1] !== TheOneData[3]?.date ?
                      DateArray[1] !== TheOneData[4]?.date ?
                        DateArray[1] !== TheOneData[5]?.date ?
                          DateArray[1] !== TheOneData[6]?.date ?
                          0
                          :
                          TheOneData[6]?.count
                        :
                        TheOneData[5]?.count
                      :
                      TheOneData[4]?.count
                    :
                    TheOneData[3]?.count
                  :
                  TheOneData[2]?.count
                :
                TheOneData[1]?.count
              : 
              TheOneData[0]?.count, 
              DateArray[2] !== TheOneData[0]?.date ? 
                DateArray[2] !== TheOneData[1]?.date ?
                  DateArray[2] !== TheOneData[2]?.date ?
                    DateArray[2] !== TheOneData[3]?.date ?
                      DateArray[2] !== TheOneData[4]?.date ?
                        DateArray[2] !== TheOneData[5]?.date ?
                          DateArray[2] !== TheOneData[6]?.date ?
                          0
                          :
                          TheOneData[6]?.count
                        :
                        TheOneData[5]?.count
                      :
                      TheOneData[4]?.count
                    :
                    TheOneData[3]?.count
                  :
                  TheOneData[2]?.count
                :
                TheOneData[1]?.count
              : 
              TheOneData[0]?.count,
              DateArray[3] !== TheOneData[0]?.date ? 
                DateArray[3] !== TheOneData[1]?.date ?
                  DateArray[3] !== TheOneData[2]?.date ?
                    DateArray[3] !== TheOneData[3]?.date ?
                      DateArray[3] !== TheOneData[4]?.date ?
                        DateArray[3] !== TheOneData[5]?.date ?
                          DateArray[3] !== TheOneData[6]?.date ?
                          0
                          :
                          TheOneData[6]?.count
                        :
                        TheOneData[5]?.count
                      :
                      TheOneData[4]?.count
                    :
                    TheOneData[3]?.count
                  :
                  TheOneData[2]?.count
                :
                TheOneData[1]?.count
              : 
              AppleData[0]?.count, 
              DateArray[4] !== TheOneData[0]?.date ? 
                DateArray[4] !== TheOneData[1]?.date ?
                  DateArray[4] !== TheOneData[2]?.date ?
                    DateArray[4] !== TheOneData[3]?.date ?
                      DateArray[4] !== TheOneData[4]?.date ?
                        DateArray[4] !== TheOneData[5]?.date ?
                          DateArray[4] !== TheOneData[6]?.date ?
                          0
                          :
                          TheOneData[6]?.count
                        :
                        TheOneData[5]?.count
                      :
                      TheOneData[4]?.count
                    :
                    TheOneData[3]?.count
                  :
                  TheOneData[2]?.count
                :
                TheOneData[1]?.count
              : 
              TheOneData[0]?.count, 
              DateArray[5] !== TheOneData[0]?.date ? 
                DateArray[5] !== TheOneData[1]?.date ?
                  DateArray[5] !== TheOneData[2]?.date ?
                    DateArray[5] !== TheOneData[3]?.date ?
                      DateArray[5] !== TheOneData[4]?.date ?
                        DateArray[5] !== TheOneData[5]?.date ?
                          DateArray[5] !== TheOneData[6]?.date ?
                          0
                          :
                          TheOneData[6]?.count
                        :
                        TheOneData[5]?.count
                      :
                      TheOneData[4]?.count
                    :
                    TheOneData[3]?.count
                  :
                  TheOneData[2]?.count
                :
                TheOneData[1]?.count
              : 
              TheOneData[0]?.count, 
              DateArray[6] !== TheOneData[0]?.date ? 
                DateArray[6] !== TheOneData[1]?.date ?
                  DateArray[6] !== TheOneData[2]?.date ?
                    DateArray[6] !== TheOneData[3]?.date ?
                      DateArray[6] !== TheOneData[4]?.date ?
                        DateArray[6] !== TheOneData[5]?.date ?
                          DateArray[6] !== TheOneData[6]?.date ?
                          0
                          :
                          TheOneData[6]?.count
                        :
                        TheOneData[5]?.count
                      :
                      TheOneData[4]?.count
                    :
                    TheOneData[3]?.count
                  :
                  TheOneData[2]?.count
                :
                TheOneData[1]?.count
              : 
              TheOneData[0]?.count
            ],
          },
          // {
          //   name: 'Unspecified',
          //   type: 'line',
          //   stack: 'Unspecified',
          //   data: [
          //     DateArray[0] !== UnspecifiedData[0]?.date ? 
          //       DateArray[0] !== UnspecifiedData[1]?.date ?
          //         DateArray[0] !== UnspecifiedData[2]?.date ?
          //           DateArray[0] !== UnspecifiedData[3]?.date ?
          //             DateArray[0] !== UnspecifiedData[4]?.date ?
          //               DateArray[0] !== UnspecifiedData[5]?.date ?
          //                 DateArray[0] !== UnspecifiedData[6]?.date ?
          //                 0
          //                 :
          //                 UnspecifiedData[6]?.count
          //               :
          //               UnspecifiedData[5]?.count
          //             :
          //             UnspecifiedData[4]?.count
          //           :
          //           UnspecifiedData[3]?.count
          //         :
          //         UnspecifiedData[2]?.count
          //       :
          //       UnspecifiedData[1]?.count
          //     : 
          //     UnspecifiedData[0]?.count,
          //     DateArray[1] !== UnspecifiedData[0]?.date ? 
          //       DateArray[1] !== UnspecifiedData[1]?.date ?
          //         DateArray[1] !== UnspecifiedData[2]?.date ?
          //           DateArray[1] !== UnspecifiedData[3]?.date ?
          //             DateArray[1] !== UnspecifiedData[4]?.date ?
          //               DateArray[1] !== UnspecifiedData[5]?.date ?
          //                 DateArray[1] !== UnspecifiedData[6]?.date ?
          //                 0
          //                 :
          //                 UnspecifiedData[6]?.count
          //               :
          //               UnspecifiedData[5]?.count
          //             :
          //             UnspecifiedData[4]?.count
          //           :
          //           UnspecifiedData[3]?.count
          //         :
          //         UnspecifiedData[2]?.count
          //       :
          //       UnspecifiedData[1]?.count
          //     : 
          //     UnspecifiedData[0]?.count, 
          //     DateArray[2] !== UnspecifiedData[0]?.date ? 
          //       DateArray[2] !== UnspecifiedData[1]?.date ?
          //         DateArray[2] !== UnspecifiedData[2]?.date ?
          //           DateArray[2] !== UnspecifiedData[3]?.date ?
          //             DateArray[2] !== UnspecifiedData[4]?.date ?
          //               DateArray[2] !== UnspecifiedData[5]?.date ?
          //                 DateArray[2] !== UnspecifiedData[6]?.date ?
          //                 0
          //                 :
          //                 UnspecifiedData[6]?.count
          //               :
          //               UnspecifiedData[5]?.count
          //             :
          //             UnspecifiedData[4]?.count
          //           :
          //           UnspecifiedData[3]?.count
          //         :
          //         UnspecifiedData[2]?.count
          //       :
          //       UnspecifiedData[1]?.count
          //     : 
          //     UnspecifiedData[0]?.count,
          //     DateArray[3] !== UnspecifiedData[0]?.date ? 
          //       DateArray[3] !== UnspecifiedData[1]?.date ?
          //         DateArray[3] !== UnspecifiedData[2]?.date ?
          //           DateArray[3] !== UnspecifiedData[3]?.date ?
          //             DateArray[3] !== UnspecifiedData[4]?.date ?
          //               DateArray[3] !== UnspecifiedData[5]?.date ?
          //                 DateArray[3] !== UnspecifiedData[6]?.date ?
          //                 0
          //                 :
          //                 UnspecifiedData[6]?.count
          //               :
          //               UnspecifiedData[5]?.count
          //             :
          //             UnspecifiedData[4]?.count
          //           :
          //           UnspecifiedData[3]?.count
          //         :
          //         UnspecifiedData[2]?.count
          //       :
          //       UnspecifiedData[1]?.count
          //     : 
          //     UnspecifiedData[0]?.count, 
          //     DateArray[4] !== UnspecifiedData[0]?.date ? 
          //       DateArray[4] !== UnspecifiedData[1]?.date ?
          //         DateArray[4] !== UnspecifiedData[2]?.date ?
          //           DateArray[4] !== UnspecifiedData[3]?.date ?
          //             DateArray[4] !== UnspecifiedData[4]?.date ?
          //               DateArray[4] !== UnspecifiedData[5]?.date ?
          //                 DateArray[4] !== UnspecifiedData[6]?.date ?
          //                 0
          //                 :
          //                 UnspecifiedData[6]?.count
          //               :
          //               UnspecifiedData[5]?.count
          //             :
          //             UnspecifiedData[4]?.count
          //           :
          //           UnspecifiedData[3]?.count
          //         :
          //         UnspecifiedData[2]?.count
          //       :
          //       UnspecifiedData[1]?.count
          //     : 
          //     UnspecifiedData[0]?.count, 
          //     DateArray[5] !== UnspecifiedData[0]?.date ? 
          //       DateArray[5] !== UnspecifiedData[1]?.date ?
          //         DateArray[5] !== UnspecifiedData[2]?.date ?
          //           DateArray[5] !== UnspecifiedData[3]?.date ?
          //             DateArray[5] !== UnspecifiedData[4]?.date ?
          //               DateArray[5] !== UnspecifiedData[5]?.date ?
          //                 DateArray[5] !== UnspecifiedData[6]?.date ?
          //                 0
          //                 :
          //                 UnspecifiedData[6]?.count
          //               :
          //               UnspecifiedData[5]?.count
          //             :
          //             UnspecifiedData[4]?.count
          //           :
          //           UnspecifiedData[3]?.count
          //         :
          //         UnspecifiedData[2]?.count
          //       :
          //       UnspecifiedData[1]?.count
          //     : 
          //     UnspecifiedData[0]?.count, 
          //     DateArray[6] !== UnspecifiedData[0]?.date ? 
          //       DateArray[6] !== UnspecifiedData[1]?.date ?
          //         DateArray[6] !== UnspecifiedData[2]?.date ?
          //           DateArray[6] !== UnspecifiedData[3]?.date ?
          //             DateArray[6] !== UnspecifiedData[4]?.date ?
          //               DateArray[6] !== UnspecifiedData[5]?.date ?
          //                 DateArray[6] !== UnspecifiedData[6]?.date ?
          //                 0
          //                 :
          //                 UnspecifiedData[6]?.count
          //               :
          //               UnspecifiedData[5]?.count
          //             :
          //             UnspecifiedData[4]?.count
          //           :
          //           UnspecifiedData[3]?.count
          //         :
          //         UnspecifiedData[2]?.count
          //       :
          //       UnspecifiedData[1]?.count
          //     : 
          //     UnspecifiedData[0]?.count
          //   ],
            
          // }
          
        ]
    };
    return(
        <>
        <Group position="center">
            <Text fw = {900} sx={{color : '#4a1e9e', fontSize : 25, fontFamily: 'poppins'}}>PLATFORM USERS</Text>
        </Group>
        {loading ?
            <Group position="center" sx={{padding : 20}}>
                <Loader color="violet" variant="dots" size="md"/>
            </Group>
            :
            <ReactECharts option={options} />
        }
        </>
    )
}