import React, { useState, useEffect } from "react";
import { TextInput, Group, Switch, ActionIcon, Text, Stack, Menu } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconPencil, IconCheck, IconBrandAndroid, IconBrandApple, IconDotsVertical, IconTrash } from '@tabler/icons-react';
import DataTable from 'react-data-table-component';
import { UpdateAdLocation } from "../Ads/UpdateAdLocation";
import { UploadAdLocation } from "../Ads/UploadAdLocation";
import Axios from 'axios';
export const ListofAds = ({notifications}) => {
    const [AdLocation, setAdLocation] = useState([])
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [search, setSearch] = useState('');
    const[UpdateId, setUpdateId] = useState("");
    const[UpdateName, setUpdateName] = useState("");
    const[UpdateProvider, setUpdateProvider] = useState("");
    const[UpdateIos, setUpdateIos] = useState("");
    const[UpdateAndroid, setUpdateAndroid] = useState("");
    const[UpdateProviderID, setProviderID] = useState("");
    const [OpenAdlocationUpdateModal, setOpenAdlocationUpdateModal] = useState(false);
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TblProviderTranslate, setTblProviderTranslate] = useState('PROVIDER')
    const [SearchProviderTranslate, setSearchProviderTranslate] = useState('Search Location')
    const [StatusTranslate, setStatusTranslate] = useState('STATUS')
    const [ActionTranslate, setActionTranslate] = useState('ACTION')
    const [EditAdsTranslation, setEditAdsTranslation] = useState('Edit Ads')
    const [RemoveAdsTranslation, setRemoveAdsTranslation] = useState('Remove Ads')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchAdLocation();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchProviderTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchProviderTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + StatusTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setStatusTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + ActionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setActionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EditAdsTranslation,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEditAdsTranslation(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveAdsTranslation,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveAdsTranslation(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchAdLocation = async () => {
        setLoading(true);   
        const ListAdsEP = `/AdLocations`
        await api.get(ListAdsEP).then((result) => {
            setAdLocation(result.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const RemoveLocation = async (LocationID) => {
        const DeleteLocationEP = `/AdLocations/`+LocationID+``
            await api.delete(DeleteLocationEP, {
            }).then(result => {
                if(result['status'] === 204){
                    notifications.show({
                        id: 'load-data',
                        loading: true,
                        title: 'Removing Location',
                        message: 'Location will be deleted, you cannot close this yet',
                        autoClose: false,
                        withCloseButton: false,
                        });
                    setTimeout(() => {
                        fetchAdLocation();
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Location was deleted',
                            message: 'Location successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
        })
    }
    const TurnOnOff = async (id) => {
        const UpdateLocationEP = `/AdLocations/`+id+`/state`
            await api.put(UpdateLocationEP, {
            }).then(result => {
                if(result['status'] === 204){
                    fetchAdLocation()
                }else{}
            }).catch(error =>{
        })
    }
    const UpdateAdLocationFunction = async (ProviderID,LocationID,locationName,locationProvider,iosAdUnit,androidAdUnit) => {
        setUpdateId(LocationID)
        setProviderID(ProviderID)
        setUpdateName(locationName)
        setUpdateProvider(locationProvider)
        setUpdateIos(iosAdUnit)
        setUpdateAndroid(androidAdUnit)
        setOpenAdlocationUpdateModal(true);
    }
    AdLocation.sort(function(a,b){
        return a.name.localeCompare(b.name);
    });
    const columns = [
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>{TblProviderTranslate}</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.adProvider['adProvider']}</Text>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>LOCATION</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.name}</Text>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>IOS AD UNIT</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.iosAdUnit}</Text>
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>ANDROID AD UNIT</Text>,
            cell : row => <Text fw={500} fz={14} sx={{fontFamily : 'poppins'}}>{row.androidAdUnit}</Text>
                
        },
        {
            name: <Text fw={500} fz={18} sx={{fontFamily : 'poppins'}}>STATUS</Text>,
            cell : row => 
            <Group sx={{width : '100%'}} position="apart">
                <Group grow>
                    <Switch size = "lg" onLabel="ACTIVE" offLabel="INACTIVE" radius="xs" color="green" checked={row.state} onClick={() => {TurnOnOff(row.id)}} />
                </Group>
                <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                    <Menu.Target>
                        <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                            <IconDotsVertical size="1.625rem" />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{StatusTranslate}</Text></Menu.Label>
                        <Menu.Label><Text sx = {{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{ActionTranslate}</Text></Menu.Label>
                        <Menu.Item sx = {{fontFamily : 'poppins'}} icon = {<IconPencil/>} onClick = {() => {UpdateAdLocationFunction(row.adProviderId,row.id,row.name,row['adProvider'].adProvider,row.iosAdUnit,row.androidAdUnit)}}>{EditAdsTranslation}</Menu.Item>
                        <Menu.Item sx = {{fontFamily : 'poppins'}} color = "red" icon = {<IconTrash/>} onClick = {() => {RemoveLocation(row.id)}}>{RemoveAdsTranslation}</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>
        },
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group>
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchProviderTranslate}
                    onChange={ handleSearch }
                />
                <UploadAdLocation
                    notifications = {notifications}
                    fetchAdLocation = {fetchAdLocation}
                />
                <UpdateAdLocation
                    notifications = {notifications}
                    fetchAdLocation = {fetchAdLocation}
                    UpdateId = {UpdateId}
                    UpdateProviderID = {UpdateProviderID}
                    UpdateName = {UpdateName}
                    UpdateProvider = {UpdateProvider}
                    UpdateIos = {UpdateIos}
                    UpdateAndroid = {UpdateAndroid}
                    OpenUpdateModal = {OpenAdlocationUpdateModal}
                    CloseUpdateModal = {() => {setOpenAdlocationUpdateModal(false)}}
                />
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={
                    AdLocation.filter((SearchAds) => {
                        if(search === ""){
                            return SearchAds
                        }else if(SearchAds.name.toLowerCase().includes(search.toLowerCase())){
                            return SearchAds
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={AdLocation.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
        </div>  
    )
}