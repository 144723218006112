import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconPlus, IconLanguage, IconPencil} from '@tabler/icons-react';
import { TextInput, Group, List, Text, Stack, ActionIcon, Tooltip, Card, Chip, Modal, Divider, Loader} from '@mantine/core'
import { UploadQuestions } from "../BibleQuiz/UploadQuestions";
import { UploadChoices } from "../BibleQuiz/UploadChoices"
import { UploadBatchQuestion } from "../BibleQuiz/UploadBatchQuestion";
import Axios from 'axios'; 
export const ListOfQuestions = ({notifications}) => {
    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const [OpenUploadQuestionModal, setOpenUploadQuestionModal] = useState(false);
    const [OpenUploadChoices, setUploadChoices] = useState(false)
    const [OpenUploadBatchQuestion, setOpenUploadBatchQuestion] = useState(false)
    const [TranslateQuestionModal, setTranslateQuestionModal] = useState(false)
    const [SelectedQuestionID, setSelectedQuestionID] = useState("")
    const [search, setSearch] = useState('');
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [TranslateLoading, setTranslateLoading] = useState(false)
    const [TblQuestionTranslate, setTblQuestionTranslate] = useState('QUESTIONS')
    const [SearchQuestionTranslate, setSearchQuestionTranslate] = useState('Search Question')
    const [CreateQuestionTranslate, setCreateQuestionTranslate] = useState('Create Question')
    const [AddChoicesTranslate, setAddChoicesTranslate] = useState('Add Choices')
    const [RemoveQuestionTranslate, setRemoveQuestionTranslate] = useState('Remove Question')
    const [MultipleChoiceTranslate, setMultipleChoiceTranslate] = useState('Multiple Choice')
    const [TrueOrFalseTranslate, setTrueOrFalseTranslate] = useState('True or False')
    const [QuestionDifficultyTranslate, setQuestionDifficultyTranslate] = useState('Question Difficulty')
    const [EasyTranslate, setEasyTranslate] = useState('Easy')
    const [MediumTranslate, setMediumTranslate] = useState('Medium')
    const [HardTranslate, setHardTranslate] = useState('Hard')
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchQuestions();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TblQuestionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTblQuestionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + SearchQuestionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setSearchQuestionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + CreateQuestionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setCreateQuestionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + AddChoicesTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setAddChoicesTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + RemoveQuestionTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setRemoveQuestionTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MultipleChoiceTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMultipleChoiceTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + TrueOrFalseTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setTrueOrFalseTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + QuestionDifficultyTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setQuestionDifficultyTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + EasyTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setEasyTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + MediumTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setMediumTranslate(translated[0])
            })
        }).then((error) =>{
        })
        Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q=" + HardTranslate,{
        }).then((res) => {
            res.data[0].map((translated) => {
                setHardTranslate(translated[0])
            })
        }).then((error) =>{
        })
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchQuestions = async() => {
        setLoading(true);   
        const QuestionEP = `/questions?PageNumber=1&PageSize=1000`
        await api.get(QuestionEP).then((result) => {
            setData(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const DeleteQuestionFunction = async (ID) => {
        const QuestionEP = `/questions/`+ID+``
            await api.delete(QuestionEP, {
            }).then(result => {
                if(result['status'] === 204){
                    notifications.show({
                        id: 'load-data',
                        loading: true,
                        title: 'Removing Question',
                        message: 'Question will be deleted, you cannot close this yet',
                        autoClose: false,
                        withCloseButton: false,
                        });
                        fetchQuestions();
                        setTimeout(() => {
                        notifications.update({
                            id: 'load-data',
                            color: 'teal',
                            title: 'Question was deleted',
                            message: 'Question successsfully deleted',
                            icon: <IconCheck size="1rem" />,
                            autoClose: 2000,
                        });
                    }, 1000); 
                }else{}
            }).catch(error =>{
        })
    }
    const SelectQuestion = async (ID) => {
        setUploadChoices(true)
        setSelectedQuestionID(ID)
    }
    const [LocalizationQuestion, setLocalizationQuestion] = useState([])
    const [LocalizationChoices, setLocalizationChoices] = useState([])
    const OpenTranslateModal = async(QuestionID, Choices) => {
        setTranslateQuestionModal(true)
        const ListOfLocalization = `/questions/`+QuestionID+`/localizations`
        await api.get(ListOfLocalization).then((result) => {
            setLocalizationQuestion(result.data)
        }).catch(error => {
        })
        Choices.map((choices) => {
            const ListOfLocalization = `/questions/choices/`+choices.id+`/localizations`
            api.get(ListOfLocalization).then((result) => {
                setLocalizationChoices(result.data)
            }).catch(error => {
            })
        })
    }
    const columns = [
        { 
            name: <Text fw={500} fz={22} sx = {{fontFamily : 'poppins'}}>{TblQuestionTranslate}</Text>,
            cell : row =>
            <div style={{width : '100%'}}>
                <Card padding="xl" radius="lg" sx = {{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}} withBorder >
                    <Card.Section>
                        <Group sx={{background : '#4a1e9e', padding : '10px'}} position="apart">
                            <Stack align="flex-start" spacing="xs">
                                <Text sx = {{fontFamily : 'poppins'}} fw={700} fz={20} ta="left" color="white">
                                    {
                                        row.type === 0 ?
                                        TrueOrFalseTranslate
                                        :
                                        MultipleChoiceTranslate
                                    }
                                </Text>
                                <Text sx = {{fontFamily : 'poppins'}} fw={600} fz={14} ta="left" color="white">
                                    {
                                        row.difficulty === 0 ? 
                                        QuestionDifficultyTranslate+' : '+EasyTranslate
                                        :
                                        row.difficulty === 1 ?
                                        QuestionDifficultyTranslate+' : '+MediumTranslate
                                        :
                                        row.difficulty === 2 ?
                                        QuestionDifficultyTranslate+' : '+HardTranslate
                                        :
                                        ''  
                                    }
                                </Text>
                            </Stack>
                            <Group spacing={5}>
                                <Tooltip
                                    label={AddChoicesTranslate}
                                    sx={{background : '#4a1e9e'}}
                                    withArrow
                                >
                                    <ActionIcon 
                                        sx={{color : '#6c2be2'}}
                                        size="lg" 
                                        radius="xl" 
                                        variant="light"
                                        onClick={() => {SelectQuestion(row.id)}}
                                    >
                                        <IconPlus />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip
                                    label="Translate Question"
                                    sx={{background : '#4a1e9e'}}
                                    withArrow
                                >
                                    <ActionIcon 
                                        sx={{color : '#6c2be2'}}
                                        size="lg" 
                                        radius="xl" 
                                        variant="light"
                                        onClick={() => {OpenTranslateModal(row.id, row.choices)}}
                                    >
                                        <IconLanguage />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip
                                    label={RemoveQuestionTranslate}
                                    sx={{background : '#4a1e9e'}}
                                    withArrow
                                >
                                    <ActionIcon 
                                        sx={{color : '#6c2be2'}}
                                        size="lg" 
                                        radius="xl" 
                                        variant="light"
                                        onClick={() => {DeleteQuestionFunction(row.id)}}
                                    >
                                        <IconTrash />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        </Group>
                    </Card.Section>
                    <Group position="left" mt="md" mb="xs">
                        <Text fw={900} fz={20} sx={{color : '#4a1e9e', fontFamily : 'poppins'}}>{row.text}</Text>
                    </Group>
                    <List
                        spacing="xs"
                        size="xl"
                        type="ordered"
                    >
                        {row.choices.map((choices, index) =>
                            row.answerId === choices.id ? 
                            <List.Item sx={{cursor : 'pointer'}} key = {index}>
                                <Group spacing={5}>
                                    <Text sx = {{fontFamily : 'poppins'}} lineClamp={1}>
                                        <Chip defaultChecked color="green" variant="filled">{choices.value}</Chip>
                                    </Text>
                                   
                                </Group>
                            </List.Item>
                            :
                            <List.Item sx={{cursor : 'pointer'}} key = {index}>
                                <Text sx = {{fontFamily : 'poppins'}} fw={600} fz={20} lineClamp={1}>{choices.value}</Text>
                            </List.Item>
                          
                        )}      
                    </List>
                </Card>
            </div>
        }
    ];
    return (
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group >
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={SearchQuestionTranslate}
                    onChange={ handleSearch }
                />
                <Tooltip
                    label={CreateQuestionTranslate}
                    sx={{background : 'rgb(39, 20, 77)'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : 'rgb(71 46 121)', transition: '0.3s',  ":hover" : {
                            background : 'rgb(39, 20, 77)'
                            
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadQuestionModal(true)}}
                    >
                        <IconPlus size="1.625rem" />
                    </ActionIcon>
                </Tooltip>  
                {/* <Tooltip
                    label="Create Question"
                    sx={{background : 'rgb(39, 20, 77)'}}
                    withArrow
                >
                    <ActionIcon
                        sx={{background : 'rgb(71 46 121)', transition: '0.3s',  ":hover" : {
                            background : 'rgb(39, 20, 77)'
                            
                        }}}
                        size="lg" 
                        radius="xl" 
                        variant="filled"
                        onClick={() => {setOpenUploadBatchQuestion(true)}}
                    >
                        <QueueIcon size="1.625rem"/>
                    </ActionIcon>
                </Tooltip>   */}
            </Group>
            <br></br>
            <DataTable
                columns={columns}
                data={
                    Data.filter((SearchQuestion) => {
                        if(search === ""){
                            return SearchQuestion
                        }else if(SearchQuestion.text.toLowerCase().includes(search.toLowerCase())){
                            return SearchQuestion
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={Data.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            <UploadQuestions
                notifications={notifications}
                OpenModal = {OpenUploadQuestionModal}
                CloseModal = {() => {setOpenUploadQuestionModal(false)}}
                fetchQuestions = {fetchQuestions}
            />
            <UploadChoices
              notifications={notifications}
              OpenModal = {OpenUploadChoices}
              CloseModal = {() => {setUploadChoices(false)}}
              fetchQuestions = {fetchQuestions}
              SelectedQuestionID = {SelectedQuestionID}
            />
            <UploadBatchQuestion
              notifications={notifications}
              OpenModal = {OpenUploadBatchQuestion}
              CloseModal = {() => {setOpenUploadBatchQuestion(false)}}
              fetchQuestions = {fetchQuestions}
            />
            <Modal opened={TranslateQuestionModal} onClose={() => {setTranslateQuestionModal(false)}} size="xl">
                <Group position = "center">
                    <Text fw = {700} sx={{fontFamily : 'poppins', fontSize : 30}}>LIST OF LANGUAGE LOCALIZATION</Text>
                </Group>
                <Divider></Divider>
                <br/>
                <Group position="apart">
                    <Text fz="sm" fw={500} sx={{fontFamily : 'poppins'}}> Total of {LocalizationQuestion.length} Languages</Text>
                </Group>
                {LocalizationQuestion.map((code, index) => 
                    <Card padding="xs" radius="md" key = {index} sx={{
                        boxShadow : 'rgba(0, 0, 0, 0.10) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px', 
                        cursor : 'pointer',
                        transition : 'transform .2s ease-in-out',
                        MozTransition : 'transform .2s ease-in-out',
                        WebkitTransition : 'transform .2s ease-in-out',
                        marginTop: 5,
                        ":hover" : {
                            background : 'transparent',
                            WebkitTransform : 'scale(1.01)',
                            msTransform : 'scale(1.01)',
                            transform : 'scale(1.01)'
                        }
                    }}>
                        
                            {TranslateLoading ? 
                                <>
                                    <Group position='center'>
                                        <Loader color="violet" variant="dots" /><br/>
                                    </Group>
                                    <Group position='center'>
                                        <i style={{fontFamily : 'poppins', fontSize : 12}}>Translating...</i>
                                    </Group>
                                </>
                            
                                : 
                                <>
                                    <Group grow>
                                        <Text fw = {700} fz="md" color="black" sx={{fontFamily : 'poppins'}}> {code.text}</Text>
                                    </Group>

                                    <Group position="right">
                                        <Text fw = {500} fz="sm" color="black" sx={{fontFamily : 'poppins'}}> Translated in{code.language}</Text>
                                    </Group>
                                </>
                            }
                    </Card>
                )}
            </Modal>
        </div>
    )
}
