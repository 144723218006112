import React, { useState, useEffect } from "react";
import { Card, Image, Group, Text, Button, Grid, TextInput, ActionIcon, Tooltip } from '@mantine/core'
import { IconWorld, IconLock, IconSearch, IconPlus, IconCheck, IconEye, IconTrash } from '@tabler/icons-react';
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { UploadGroup } from "./UploadGroup";
import Axios from 'axios';
export const ComponentGroup = ({notifications}) => {
    const [ListOfGroup, setListOfGroup] = useState([]);
    const [UploadModal, setUploadModal] = useState(false)
    const [search, setSearch] = useState('');
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "SearchGroupTranslate" : 'Search Group',
        "CrateNewGroupTranslate" : 'Create New Group',
        "ViewGroupTranslate" : 'VIEW',
        "RemoveGroupTranslate" : 'REMOVE',
        "AddNewGroupTranslate" : 'Add New Group',
        "DragImageTitleTranslate" : 'Drage images here or click to select files',
        "DragImageDescriptionTranslate" : 'Attatch as many files as you like, each file should not exceed 5mb',
        "TitleTranslate" : 'Title',
        "PlaceholderTitle" : 'Enter Group Title',
        "DescriptionTranslate" : 'Description',
        "PlaceholderDescription" : 'Enter Group Description',
        "RegisteredEmailTranslate" : 'Email',
        "PlaceholderRegisteredEmail" : 'Enter Registered Email',
        "ContactNumberTranslate" : 'Contact Number',
        "PlaceholderContactNumber" : 'Enter Contact Number',
        "AddressTranslate" : 'Enter Address',
        "PlaceholderAddress" : 'Enter Group Address',
        "PublicTranslate" : 'PUBLIC',
        "PrivateTranslate" : 'PRIVATE',
        "SelectGroupPrivacyTranslate" : 'Select Group Privacy',
        "BtnUploadGroup" : 'UPLOAD GROUP'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchListOfGroup();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    ListOfGroup.sort(function(a, b){
        return new Date(b.createdDate) - new Date(a.createdDate);
    });
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchListOfGroup = async() =>{
        const ListOfGroupEP = `/Communities/All?PageNumber=1&PageSize=100`
        await api.get(ListOfGroupEP).then((result) => {
            setListOfGroup(result.data.data)
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const RemoveGroup = async(ID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Group',
            message: 'Group will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const GroupEP = `/Communities/`+ID+``
        await api.delete(GroupEP, {
        }).then(result => {
            if(result['status'] === 204){
                fetchListOfGroup();
                setTimeout(() => {
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Group was deleted',
                        message: 'Group successsfully deleted',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{  
        })
    }
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group>
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchGroupTranslate}
                    onChange={ handleSearch }
                />
                <Tooltip
                    label={DataTranslate.CrateNewGroupTranslate}
                    color="#6c2be2"
                    position="top"
                    withArrow
                    > 
                    <ActionIcon onClick = {() => {setUploadModal(true)}} size="lg" radius="xl" variant="filled" sx={{background : '#4a1e9e', ":hover" : {background : '#6c2be2'}}}>
                        <IconPlus size="2.125rem" />
                    </ActionIcon>
                </Tooltip>
            </Group>
            <br></br>
            <Grid gutter="xs" grow>
                {ListOfGroup.filter((SearchGroup) => {
                    if(search === ""){
                        return SearchGroup
                    }else if(SearchGroup.title.toLowerCase().includes(search.toLowerCase())){
                        return SearchGroup
                    }
                }).map((group, index) =>
                <div key = {index} style={{width : '20%'}} className="GroupContent">
                    <Grid.Col> 
                        <Card padding="xl" radius="lg" withBorder sx={{boxShadow : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'}}>
                            <Card.Section>
                                <Image
                                    src={group.cover}
                                    height={220}
                                    alt="Norway"
                                />
                            </Card.Section>
                            <Group position="apart" mt="md" mb="xs">
                                <Text sx={{fontFamily : 'poppins'}} lineClamp={1} weight={700}>{group.title}</Text>
                            </Group>
                            <Group mt="md" mb="xs" spacing="xs">
                                {group.isPrivate ? <IconLock/> : <IconWorld/>}<Text weight={400}>{group.isPrivate ? 'Private Group' : 'Public Group'}</Text>
                                <Text sx={{fontFamily : 'poppins'}}>•</Text>
                                <Text sx={{fontFamily : 'poppins'}} weight={400}>{group.totalMembers > 1 ? group.totalMembers  +' Members' : group.totalMembers +' Member'}</Text>
                            </Group>
                            <Group grow>
                                <Button sx={{fontFamily : 'poppins'}} radius = "sm" leftIcon = {<IconEye/>} sx={{background : '#4a1e9e', ":hover" : {background : '#6c2be2'}}} size="sm" uppercase>
                                    {DataTranslate.ViewGroupTranslate}
                                </Button>
                                <Button sx={{fontFamily : 'poppins'}} radius = "sm" leftIcon = {<IconTrash/>} onClick = {() => {RemoveGroup(group.id)}}sx={{background : '#b30000', ":hover" : {background : 'red'}}} size="sm" uppercase>
                                    {DataTranslate.RemoveGroupTranslate}
                                </Button>
                            </Group>
                        </Card>
                    </Grid.Col>
                </div>
                )}
            </Grid>
            <UploadGroup
                OpenModal = {UploadModal}
                CloseModal = {() => {setUploadModal(false)}}
                fetchListOfGroup = {fetchListOfGroup}
                notifications = {notifications}
                PropsAddNewGroupTranslate = {DataTranslate.AddNewGroupTranslate}
                PropsDragImageTitleTranslate = {DataTranslate.DragImageTitleTranslate}
                PropsDragImageDescriptionTranslate = {DataTranslate.DragImageDescriptionTranslate}
                PropsTitleTranslate = {DataTranslate.TitleTranslate}
                PropsPlaceholderTitle = {DataTranslate.PlaceholderTitle}
                PropsDescriptionTranslate = {DataTranslate.DescriptionTranslate}
                PropsPlaceholderDescription = {DataTranslate.PlaceholderDescription}
                PropsEmailTranslate = {DataTranslate.RegisteredEmailTranslate}
                PropsPlaceholderEmail = {DataTranslate.PlaceholderRegisteredEmail}
                PropsContactNumberTranslate = {DataTranslate.ContactNumberTranslate}
                PropsPlaceholderContactNumber = {DataTranslate.PlaceholderContactNumber}
                PropsAddressTranslate = {DataTranslate.AddressTranslate}
                PropsPlaceholderAddress = {DataTranslate.PlaceholderAddress}
                PropsPublicTranslate = {DataTranslate.PublicTranslate}
                PropsPrivateTranslate = {DataTranslate.PrivateTranslate}
                PropsSelectGroupPrivacyTranslate = {DataTranslate.SelectGroupPrivacyTranslate}
                PropsBtnUploadGroup = {DataTranslate.BtnUploadGroup}
            />
        </div>
    )
}