import { ListofWordOfTheDay } from "../../Components/DataTables/ListofWordOfTheDay"
import { notifications } from '@mantine/notifications';
export const WordOfTheDay = () => {
    return (
    <>
        <ListofWordOfTheDay
            notifications = {notifications}
        />
    </>
    )
}