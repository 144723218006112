import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import Moment from 'moment';
import { Button, TextInput, Group, ActionIcon, Modal, Text, Avatar, Stack, Badge, rem, Card, Menu, Grid, Image, Divider, MediaQuery, Paper } from '@mantine/core'
import { Token, api, RefreshToken, GetNewToken } from '../../Utils'
import { IconSearch, IconTrash, IconCheck, IconEye, IconPencil, IconMessage, IconUser, IconDots, IconHeart, IconShare3, IconKeyOff, IconDotsVertical} from '@tabler/icons-react';
import { RWebShare } from "react-web-share";
import UserDefaultLogo from "../../Assets/images/logo/circular-logo.png"
import Axios from 'axios';
export const ListofConcern = ({notifications}) => {
    const countPerPage = 10;
    const [page, setPage] = useState(1);
    const [Concern, setConcern] = useState([]);
    const [ConcernID, setConcernID] = useState('')
    const [ConcernTitle, setConcernTitle] = useState('')
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [ConfirmModal, setConfirmModal] = useState(false);
    const [OpenReportedModal, setOpenReportedModal] = useState('')
    const [ReportedPost, setReportedPost] = useState([])
    const [ReportedComment, setReportedComment] = useState([])
    const [ReportedUser, setReportedUser] = useState([])
    let obj = {}
    var sourceLang = 'en';
    const [DefaultLanguage , setDefaultLanguage] = useState('en')
    const [DataTranslate, setDataTranslate] = useState({
        "ReportedPostTranslate" : 'REPORTED POST',
        "ReportedCommentTranslate" : 'REPORTED COMMENT',
        "ReportedUserTranslate" : 'REPORTED USER',
        "SearchConcernTranslate" : 'Search Concern',
        "PostTranslate" : 'POST',
        "CommentTranslate" : 'COMMENT',
        "UserTranslate" : 'USER',
        "ReportedTranslate" : 'REPORTED BY',
        "ActionTranslate" : 'ACTION',
        "ViewConcernTranslate" : 'View Concern',
        "RemoveConcenrn" : 'Remove Concern',
        "ConfirmTitleTranslate" : 'Confirm Deleting',
        "ConfirmMessageTranslate" : 'Are you sure you wan to Delete this Concern ? ',
        "ConfirmTranslate" : 'Cofirm',
        "CancelTranslate" : 'Cancel',
        "ResolveTranslate" : 'RESOLVE'
    })
    useEffect(() => {
        setDefaultLanguage(localStorage.getItem('SelectedCode'))
        fetchUserConcernPost();
        fetchTranslation();
    }, [DefaultLanguage]);
    const fetchTranslation = () => {
        for (const key in DataTranslate) {
            obj = {}
            Axios.get("https://translate.googleapis.com/translate_a/single?client=gtx&sl="+ sourceLang + "&tl=" + DefaultLanguage + "&dt=t&q="+ `${DataTranslate[key]}` ,{
            }).then((res) => {
                res.data[0].map((translated) => {
                    obj[`${key}`] = `${translated[0]}`
                    setDataTranslate(obj)  
                })
            }).then((error) =>{
            })
        }
    }
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const fetchUserConcernPost = async () => {
        setConcernTitle('Reported Post')
        setLoading(true);   
        const ListofConcernPostEP = `/Reports/Posts?PageNumber=1&PageSize=1000`
        await api.get(ListofConcernPostEP).then((result) => {
            setConcern(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchUserConcernComment = async () => {
        setLoading(true);   
        setConcernTitle('Reported Comment')
        const ListofConcernCommentEP = `/Reports/Comments?PageNumber=1&PageSize=1000`
        await api.get(ListofConcernCommentEP).then((result) => {
            setConcern(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const fetchUserConcern = async () => {
        setConcernTitle("Reported Users")
        setLoading(true);   
        const ListofConcernEP = `/Reports/Profiles?PageNumber=1&PageSize=1000`
        await api.get(ListofConcernEP).then((result) => {
            setConcern(result.data.data)
            setLoading(false);
        }).catch(error => {
            const Response = error.response.status
            if(Response === 401){
                GetNewToken(Token, RefreshToken)
            }
            if(Response === 404){
            }
        })
    }
    const CallOpenFunction = async(PostID, CommentID, UserID, ConcernID) => {
        setConcernID(ConcernID)
        if(ConcernTitle === 'Reported Post'){
            const ReportedPostEP = `/Posts/`+PostID+`?requestOriginalImage=true`
            await api.get(ReportedPostEP).then((result) => {
                setReportedPost(result.data)
            }).catch(error => {
                const Response = error.response.status
                if(Response === 401){
                    GetNewToken(Token, RefreshToken)
                }
                if(Response === 404){
                }
            })
        }else if(ConcernTitle === 'Reported Comment'){
            const ReportedCommentEP = `/Comments/`+CommentID+``
            await api.get(ReportedCommentEP).then((result) => {
                setReportedComment(result.data)
            }).catch(error => {
                const Response = error.response.status
                if(Response === 401){
                    GetNewToken(Token, RefreshToken)
                }
                if(Response === 404){
                }
            })
        }else if(ConcernTitle === 'Reported Users'){
            const ReportedUserEP = `/UserProfiles/`+UserID+``
            await api.get(ReportedUserEP).then((result) => {
                setReportedUser(result.data)
            }).catch(error => {
                const Response = error.response.status
                if(Response === 401){
                    GetNewToken(Token, RefreshToken)
                }
                if(Response === 404){
                }
            })
        }
        setOpenReportedModal(true) 
    }
    const CancelConfirmation = async () => {
        setConfirmModal(false)
    }
    const CallRemoveReport = async(ConcernID) => {
        setConfirmModal(true)
        setConcernID(ConcernID)
    }
    const RemoveReport = async() => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Removing Report',
            message: 'Report will be deleted, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        setConfirmModal(false)
        const RevokeEP = `/Reports/`+ConcernID+``
        await api.delete(RevokeEP, {
        }).then(result => {
            if(result['status'] === 204){
                setTimeout(() => {
                    setOpenReportedModal(false)
                    if(ConcernTitle === 'Reported Post'){
                        fetchUserConcernPost();
                    }else if(ConcernTitle === 'Reported Comment'){
                        fetchUserConcernComment();
                    }else if(ConcernTitle === 'Reported Users'){
                        fetchUserConcern();
                    }
                    notifications.update({
                        id: 'load-data',
                        color: 'teal',
                        title: 'Report was remove from list of admin',
                        message: 'Report successsfully remove',
                        icon: <IconCheck size="1rem" />,
                        autoClose: 2000,
                    });
                }, 1000); 
            }else{}
        }).catch(error =>{
    })
    }
    const ResolveReport = async(PostID) => {
        notifications.show({
            id: 'load-data',
            loading: true,
            title: 'Updating Report',
            message: 'Report will be loaded, you cannot close this yet',
            autoClose: false,
            withCloseButton: false,
        });
        const DisableEP = `Posts/UserPost/`+PostID+``
        api.put(DisableEP, {
        }).then(result => {
        if(result['status'] === 204){
            setTimeout(() => {
                //! RESET INPUTS
                setOpenReportedModal(false)
                if(ConcernTitle === 'Reported Post'){
                    fetchUserConcernPost();
                }else if(ConcernTitle === 'Reported Comment'){
                    fetchUserConcernComment();
                }else if(ConcernTitle === 'Reported Users'){
                    fetchUserConcern();
                }
                const ResolveEP = `Reports/`+ConcernID+``
                api.put(ResolveEP, {
                }).then(result => {
                }).catch(error =>{
                })
                //! END OF RESET INPUTS
                notifications.update({
                    id: 'load-data',
                    color: 'teal',
                    title: 'Report was resolved',
                    message: 'Report successsfully resolve',
                    icon: <IconCheck size="1rem" />,
                    autoClose: 2000,
                });
            }, 1000); 
        }else{}
        }).catch(error =>{
            console.log(error)
        })
    }
    const columns = [
        {
            name: <Text fw={500} fz={22} sx={{fontFamily : 'poppins'}}>{DataTranslate.ReportedTranslate}</Text>,
            cell : row =>
            <Group spacing={8} position="apart" sx={{width : '100%'}}>
                <Group spacing={8}>
                    <Avatar 
                        sx={{width : 60, height : 60, borderRadius : 65, boxShadow : '1px 2px 5px 1px gray'}}
                        src={row.userProfile['profileLink'] ? row.userProfile['profileLink'] : UserDefaultLogo} 
                    />
                    <Stack spacing={1}>
                        <Text fw={700} fz="md" sx={{lineHeight: 1, color : '#4a1e9e', wordBreak : 'break-all', fontFamily : 'poppins'}}>{row.userProfile['fullName']}</Text>
                        <Text fw={600} fz="xs" sx={{fontFamily : 'poppins'}}>{row.userProfile['email'] === '' ? 'no email address' : row.userProfile['email']}</Text>
                        
                        <Group spacing={5} className="ListOfReasonContent">
                            <Badge sx={{color : '#6c2be2', fontFamily : 'poppins'}} color="yellow" variant="light" size="md">{row.reason}</Badge>
                            {row.isResolve ? <Badge sx={{color : '#4a1e9e', fontFamily : 'poppins'}} color="yellow" variant="light" size="md"><i>Resolved</i></Badge> : <Badge sx={{color : 'red', fontFamily : 'poppins'}} color="yellow" variant="light" size="md"><i>To Review</i></Badge>}
                        </Group>
                    </Stack>
                </Group>
                <Group>
                    <Menu shadow="md" width={250} position="left-start" offset={-10} withArrow>
                        <Menu.Target>
                            <ActionIcon color="dark" size="lg" radius="xs" variant="transparent">
                                <IconDotsVertical size="1.625rem" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label><Text sx={{fontFamily : 'poppins'}} fw={800} fz="sm" color = "#6c2be2">{DataTranslate.ActionTranslate}</Text></Menu.Label>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconEye/>} onClick={() => {CallOpenFunction(row.postId, row.communityCommentId, row.reportedProfileId, row.id)}}>{DataTranslate.ViewConcernTranslate}</Menu.Item>
                            <Menu.Item sx={{fontFamily : 'poppins'}} icon = {<IconTrash/>} onClick = {() => {CallRemoveReport(row.id)}} color="red">{DataTranslate.RemoveConcenrn}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        }
    ];
    return(
        <div style={{paddingLeft : '50px'}} className="FirstContainer">
            <Group position="center">
                <Text fw={800} sx={{fontSize : '40px', fontFamily : 'poppins'}} color="#4a1e9e">{ConcernTitle}</Text>  
            </Group>  
            <Group position="apart">
                <TextInput
                    icon={<IconSearch/>}
                    radius="sm"
                    size="md"
                    placeholder={DataTranslate.SearchConcernTranslate}
                    onChange={ handleSearch } 
                />
                <Button.Group>
                    <Button onClick = {fetchUserConcernPost} size = "md" radius="xl" leftIcon = {<IconPencil color="white"/>} variant="default" sx={{background : '#4a1e9e',  ":hover" : { background : '#6c2be2' }}}>
                        <Text sx={{fontFamily : 'poppins'}} color="white">{DataTranslate.PostTranslate}</Text>
                    </Button>
                    <Button onClick = {fetchUserConcernComment} size = "md" radius="xl" leftIcon = {<IconMessage color="white"/>} variant="default" sx={{background : '#4a1e9e',  ":hover" : { background : '#6c2be2' }}}>
                        <Text sx={{fontFamily : 'poppins'}} color="white">{DataTranslate.CommentTranslate}</Text>
                    </Button>
                    <Button onClick={fetchUserConcern} size = "md" radius="xl" leftIcon = {<IconUser color="white"/>} variant="default" sx={{background : '#4a1e9e',  ":hover" : { background : '#6c2be2' }}}>
                        <Text sx={{fontFamily : 'poppins'}} color="white">{DataTranslate.UserTranslate}</Text>
                    </Button>
                </Button.Group>
            </Group>
            <br/>
            <DataTable
                columns={columns}
                data={
                    Concern.filter((Search) => {
                        if(search === ""){
                            return Search
                        }else if(Search.reason.toLowerCase().includes(search.toLowerCase())){
                            return Search
                        }
                    })
                }
                progressPending={loading}
                pagination
                paginationTotalRows={Concern.length}
                paginationPerPage={countPerPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                onChangePage={page => setPage(page)}
                responsive = ""
            />
            <Modal opened={OpenReportedModal} onClose={() => {setOpenReportedModal(false)}} centered size="xl">
                {ConcernTitle === 'Reported Post' ?
                    //REPORTED POST CONTENT 
                    <div>
                    {ReportedPost.owner === undefined ?
                    ''
                    :
                    <>
                    <Card shadow="lg" radius="xs" withBorder sx={{
                            display: 'block',
                            top: '0px',
                            position: 'relative',
                            textDecoration: 'none',
                            zIndex: 0,
                            overflow: 'hidden',
                            border: '1px solid #f2f8f9'
                        }} >
                        <Card.Section withBorder inheritPadding py="xs" sx={{background : 'rgb(39, 20, 77)'}}>
                            <Text sx={{fontFamily : 'poppins'}} fw={700} fz="xl" color="white">{DataTranslate.ReportedPostTranslate}</Text>
                        </Card.Section>
                        <Card.Section>
                            {/* HEADER POST */}
                            <Group sx={{ padding : '20px'}} position="apart">
                                <Group>
                                    <Avatar 
                                        radius="xl" 
                                        size="lg" 
                                        src={ReportedPost.owner.profileLink === null ? UserDefaultLogo : ReportedPost.owner.profileLink}
                                    />
                                    <Stack spacing={5}>
                                        <Text color="black" fz="md" fw={700} sx={{ lineHeight: 0, fontFamily : 'poppins' }}>{ReportedPost.owner.fullName}</Text>
                                        <Text fz="sm" color="dimmed" sx={{fontFamily : 'poppins'}}>
                                            { Moment(ReportedPost.postedOffset, "YYYY-MM-DD").fromNow()}
                                        </Text>
                                    </Stack>
                                </Group>
                                <Group>
                                    <Menu shadow="md" width={250} position="top-start" offset={-5} withArrow>
                                        <Menu.Target>
                                            <ActionIcon radius="xl">
                                                <IconDots color = "black" size={20} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                                <Menu.Label sx={{fontFamily : 'poppins'}}>{DataTranslate.ActionTranslate}</Menu.Label>
                                                <Menu.Item sx={{fontFamily : 'poppins'}} onClick = {() => {ResolveReport(ReportedPost.id)}} icon={<IconCheck size={20} color="green" />} >{DataTranslate.ResolveTranslate}</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                            </Group>
                            {/* END OF HEADER POST */}
                            {/* POST CONTENT */}
                            <Group position = "center" sx={{ padding : '20px', marginTop : '-30px'}} grow>
                                <Text sx={{fontFamily : 'poppins'}} fw={450}>
                                {ReportedPost.caption}
                                </Text>
                            </Group>
                            <Group position = "center" sx={{ padding : '10px', marginTop : '-20px'}} grow>
                                <Grid gutter="xs" grow>
                                    {ReportedPost.images.map((img, index) =>
                                    <Grid.Col span={6} key = {index}>
                                        <Image 
                                            radius="xs"
                                            mx="auto" 
                                            src={img.name} 
                                            alt="Random image" 
                                        />
                                    </Grid.Col>
                                    )}
                                </Grid>
                            </Group>
                            <Group position = "right" sx={{ paddingRight : '20px', paddingTop : '20px', marginTop : '-20px'}}>
                                <Text sx={{fontFamily : 'poppins'}} fw={450}>
                                    {ReportedPost.likes === 0 ? '' : ReportedPost.likes > 1 ? ReportedPost.likes + ' Likes' : ReportedPost.likes + ' Like'} 
                                </Text>
                                <Text sx={{fontFamily : 'poppins'}} fw={450}>
                                    {ReportedPost.comments === 0 ? '' : ReportedPost.comments > 1 ? ReportedPost.likes + ' Comments' : ReportedPost.comments + ' Comment'} 
                                </Text>
                            </Group>
                            {/* END OF POST CONTENT */}
                            {/* LIKE COMMENT SHARE */}
                            <Divider/>
                                <Group grow py={6} noWrap>
                                    <MediaQuery smallerThan="sm" styles={{
                                        '.LikeBtnLabel': {
                                            display: "none"
                                        }
                                    }}>
                                        <Button variant="subtle" color="dark">
                                            <IconHeart size={16}/>
                                            <Text sx={{fontFamily : 'poppins'}}> Like</Text>
                                        </Button>
                                    </MediaQuery>
                                    <MediaQuery smallerThan="sm" styles={{
                                        '.CommentBtnLabel': {
                                            display: "none"
                                        },
                                        padding: "0px"
                                    }}>
                                        <Button 
                                            variant="subtle" 
                                            color="dark" 
                                            fullWidth
                                        >
                                            <IconMessage size={16} />
                                            <Text sx={{fontFamily : 'poppins'}}> Comment</Text>
                                        </Button>
                                    </MediaQuery>
                                    <MediaQuery smallerThan="sm" styles={{
                                        '.ShareBtnLabel': {
                                            display: "none"
                                        },
                                        '.ShareButton': {
                                            padding: "0px"
                                        }
                                    }}>
                                        <Group>
                                            <RWebShare
                                                data={{
                                                    title: "Share to...",
                                                    url: "https://bibletheone.com"
                                                }}
                                            >
                                                <Button variant="subtle" color="dark" fullWidth>
                                                    <IconShare3 size={16}/>
                                                    <Text sx={{fontFamily : 'poppins'}}>Share</Text>
                                                </Button>
                                            </RWebShare>
                                        </Group>
                                    </MediaQuery>
                                </Group>
                            <Divider/>
                            <br/>
                            {/* END OFLIKE COMMENT SHARE */}
                        </Card.Section>
                    </Card>
                    <br></br>
                    </>
                    }
                    </div>
                    :
                    ConcernTitle === 'Reported Comment' ?
                    // REPORTED COMMENT CONTENT
                    <div>
                    {ReportedComment.userProfile === undefined ?
                        ''
                        :
                        <Card shadow="lg" radius="xs" withBorder sx={{
                            display: 'block',
                            top: '0px',
                            position: 'relative',
                            textDecoration: 'none',
                            zIndex: 0,
                            overflow: 'hidden',
                            border: '1px solid #f2f8f9'
                        }} >
                            <Card.Section withBorder inheritPadding py="xs" sx={{background : 'rgb(39, 20, 77)'}}>
                                <Text sx={{fontFamily : 'poppins'}} fw={700} fz="xl" color="white">{DataTranslate.ReportedCommentTranslate}</Text>
                            </Card.Section>
                            <Card.Section>
                                <Group sx={{ padding : '20px'}} position="apart">
                                    <Group spacing={4}>
                                        <Avatar 
                                            radius="xl" 
                                            size="lg" 
                                            src={ReportedComment.userProfile.profilePicture === null ? UserDefaultLogo : ReportedComment.userProfile.profilePicture}
                                            sx={{marginTop : '-25px'}}
                                        />
                                        <Stack spacing={5}>
                                            <Paper shadow="xl" radius="md" p="md" withBorder maw={600}>
                                                <Text color="black" fz="md" fw={700} sx={{ lineHeight: 1, fontFamily : 'poppins'}}>{ReportedComment.userProfile.fullName}</Text>
                                                <Text sx={{ wordBreak: "break-all", fontFamily : 'poppins' }}>{ReportedComment.comment}</Text>
                                            </Paper>
                                        </Stack>
                                    </Group>
                                    <Group>
                                        <Menu shadow="md" width={250} position="top-start" offset={-5} withArrow>
                                            <Menu.Target>
                                                <ActionIcon radius="xl">
                                                    <IconDots color = "black" size={20} />
                                                </ActionIcon>
                                            </Menu.Target>
                                            <Menu.Dropdown>
                                                    <Menu.Label sx={{fontFamily : 'poppins'}}>{DataTranslate.ActionTranslate}</Menu.Label>
                                                    <Menu.Item sx={{fontFamily : 'poppins'}} onClick = {ResolveReport} icon={<IconCheck size={20} color="green" />} >{DataTranslate.ResolveTranslate}</Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    </Group>
                                </Group>
                            </Card.Section>
                        </Card>
                    }
                    </div>
                    :
                    ConcernTitle === 'Reported Users' ?
                    // REPORTED USER CONTENT
                    <div>
                    {ReportedUser === undefined ?
                    ''
                    :
                    <Card withBorder shadow="lg" radius="sm" sx={{
                        display: 'block',
                        top: '0px',
                        position: 'relative',
                        textDecoration: 'none',
                        zIndex: 0,
                        overflow: 'hidden',
                        border: '1px solid #f2f8f9'
                    }} >
                        <Card.Section withBorder inheritPadding py="xs" sx={{background : 'rgb(39, 20, 77)'}}>
                            <Text sx={{fontFamily : 'poppins'}} fw={700} fz="xl" color="white">{DataTranslate.ReportedUserTranslate}</Text>
                        </Card.Section>
                        <Group sx={{paddingTop : '20px'}}>
                            <Avatar 
                                sx={{width : 150, height : 150, borderRadius : 80, boxShadow : '1px 2px 5px 1px gray'}}
                                src={ReportedUser.profileLink ? ReportedUser.profileLink : UserDefaultLogo} 
                            />
                            <Stack spacing={1}>
                                <Text sx={{fontFamily : 'poppins'}} fw={700} fz="xl">{ReportedUser.fullName}</Text>
                                <Text sx={{fontFamily : 'poppins'}} fw={500} fz="lg" color="gray">{ReportedUser.email === '' ? 'no email address' : ReportedUser.email}</Text>
                                <Text sx={{fontFamily : 'poppins'}} fw={500} fz="lg" color="gray">{ReportedUser.contactNumber === null || ReportedUser.contactNumber === '' ? 'no contact number' : ReportedUser.contactNumber}</Text>
                            </Stack>
                        </Group>
                        <Group position="right">
                            <Button sx={{fontFamily : 'poppins'}} onClick = {ResolveReport} leftIcon = {<IconKeyOff/>} color="red" size="sm" radius="sm" uppercase>
                                {DataTranslate.ResolveTranslate}
                            </Button>
                        </Group>
                    
                    </Card>
                    }
                    </div>
                    :
                    ''
                }
            </Modal>
            <Modal opened={ConfirmModal} onClose={() => {setConfirmModal(false)}} title={DataTranslate.ConfirmTitleTranslate} centered>
                <Group>
                    <Group grow>
                        <Text sx={{fontFamily : 'poppins'}}>{DataTranslate.ConfirmMessageTranslate}</Text>
                    </Group>
                </Group>
                <br/>
                <Group position='right'>
                    <Button sx={{fontFamily : 'poppins'}} onClick = {RemoveReport} color="teal" size="sm" uppercase> {DataTranslate.ConfirmTranslate} </Button>
                    <Button sx={{fontFamily : 'poppins'}}onClick = {CancelConfirmation} color="red" size="sm" uppercase> {DataTranslate.CancelTranslate} </Button>
                </Group>
            </Modal>

        </div>
    )
}
